import Config from "Config";
import { getUser } from "Config";

const checkVariable = (mainVariable, urlVariable) => {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return (
    (mainVariable.startsWith(':uk') && regexExp.test(urlVariable)) ||
    (mainVariable.startsWith(':id') && !isNaN(urlVariable)) ||
    (mainVariable.startsWith(':type') && (urlVariable === 'pagar' || urlVariable === 'receber'))
  )
}

const compareChildren = (mainPathChildren, urlPathChildren) => {

  let total = urlPathChildren.length
  let total_comparison = 0;

  for (let i = 0; i < urlPathChildren.length; i++) {
    const checkChildren = ((mainPathChildren[i] === urlPathChildren[i]) ||
                          (checkVariable(mainPathChildren[i], urlPathChildren[i])));
    if(checkChildren) total_comparison++
  }
  return total === total_comparison ? true : false;
}

const hasPermission = urlPath => {
  const permissaoWeb = getUser().profile.permissaoWeb;

  if(!!permissaoWeb) {
    const AuthorizedPathArray = permissaoWeb.map(item => item.rota);

    if(!AuthorizedPathArray) {
      window.localStorage.removeItem(Config._USER);
      window.localStorage.removeItem(Config._TOKEN);
      window.location.reload();
      return;
    }

    if (urlPath in AuthorizedPathArray) return true;
    var comparison = false;

    for (const authorizedPath of AuthorizedPathArray) {

      const urlPathChildren = urlPath.split('/');
      const mainPathChildren = authorizedPath.split('/');

      comparison = (
        (urlPathChildren.length === mainPathChildren.length) &&
        compareChildren(mainPathChildren, urlPathChildren)
      )
      if(comparison) return true;
    }
    return comparison;
  }

  return false;
}

export default hasPermission;
