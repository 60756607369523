import React, { Component } from "react";
import Webcam from "react-webcam";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user"
};

export default class WebcamCapture extends Component {
  webcamRef = null;

  render() {
    return (
      <>
        <Webcam
          audio={false}
          height={600}
          ref={webcamRef => this.webcamRef = webcamRef}
          screenshotFormat="image/jpeg"
          width={600}
          videoConstraints={videoConstraints}
        />
        <button onClick={() => this.props.callback(this.webcamRef.getScreenshot())}>Capturar Foto</button>
        <button onClick={() => this.props.cancelar()}>Cancelar</button>
      </>
    );
  }
};
