/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { CardHeader, Button, Table, Container, Row, Card } from "reactstrap";
import Config from "Config";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { ButtonGroup } from "@material-ui/core";
import { withSnackbar } from "notistack";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from "react-confirm-alert";
import { jsonToUrlFilter } from "utils/filter"
import api from "services/api"
import moment from "moment"
import ButtonControl from "views/components/ButtonControl";

const ROUTE_API = '/api/comunicado';

class Comunicados extends React.Component {
  state = {
    data: [],
    totalData: 50,
    filter: {
      _page: 1,
      _limit: 50,
      _order: 'desc',
      _sort: 'data_cadastro',
    },
  };

  componentDidMount() {
    this.loadMore()
  }

  loadMore = async () => {
    this.setState({ loading: true, filter: { ...this.state.filter, _page: this.state.filter._page + 1 } })
    let response = await api.get(`${ROUTE_API}?${jsonToUrlFilter(this.state.filter)}`);
    this.setState({
      data: this.state.data.concat(response.data),
      totalData: response.total_data,
      loading: false
    })
  }

  handleRemove = (id) => {
    confirmAlert({
      title: 'Deseja Deletar o cadastro?',
      message: '',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim', onClick: () => {
            this.setState({ loading: true })

            fetch(Config._HOST + `/api/comunicado/${id}`,
              {
                method: 'DELETE',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                }
              },
            )
              .then(response => response.json())
              .then(data => {
                if (data) {
                  const dataUpdated = this.state.data.filter(row => row.id !== id);
                  this.setState({ data: dataUpdated });
                }

                this.props.enqueueSnackbar(data ? "Deletado com sucesso!" : 'Ops, ocorreu um erro!')
                this.setState({ loading: false })
              })
              .catch(e => {
                this.setState({ loading: false })
                this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
              })

          }
        }
      ]
    })
  }

  renderContent() {
    if (this.state.loading) {
      return <BoxLoading />
    }

    if (this.state.data && this.state.data.length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Não Existem Comunicados cadastrados</h3>
        </CardHeader>
      );
    }

    return (
      <div>
        <Table className="align-items-center table-flush table-with-button" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">NOME</th>
              <th scope="col">DATA CADASTRO</th>
              <th scope="col" className="text-right mt-padding-medium-right">AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data && this.state.data.map(single => {
              return (
                <tr key={single.id}>
                  <th>{single.nome ? single.nome : "-"}</th>
                  <th>{single.dataCadastro ? moment(single.dataCadastro).format("DD/MM/YYYY") : "-"}</th>
                  <td>
                    <div className="text-right">
                      <ButtonControl color="secondary" to={`/admin/comunicados/${single.id}/editar`}>
                        <i className="fas fa-edit"></i>
                      </ButtonControl>
                      <Button color="secondary" onClick={() => this.handleRemove(single.id)}>
                        <i className="fas fa-trash"></i>
                      </Button>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        {this.state.totalData > this.state.data.length ? (
          <Button style={{ backgroundColor: "#3f51b5", color: "white", width: '100%', height: "45px" }} disabled={this.state.loading} variant="contained" onClick={() => this.loadMore()}>
            BUSCAR MAIS {this.state.loading ? <BoxLoading /> : <p />}
          </Button>
        ) : <p />}
      </div>
    );
  }

  render() {
    return (
      <>

        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl
                    color='secondary' to='/admin/comunicados/novo'>
                    <i className="fas fa-plus"></i> NOVO
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"COMUNICADOS"} subTitulo={"Módulo de Gestão para Comunicados"}
        />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Total de Comunicados ({this.state.data ? this.state.data.length : 0})</h3>
                </CardHeader>

                {this.renderContent()}

              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(Comunicados);
