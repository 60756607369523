/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { ButtonGroup, FormControl } from "@material-ui/core";
import Filter from "views/components/Filter";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import Config from "Config";
import moment from "moment";
import { withSnackbar } from "notistack";
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import BoxLoading from "react-loadingg/lib/BoxLoading";
import { Button, Card, CardHeader, Container, Input, Row, Table } from "reactstrap";
import Col from "reactstrap/lib/Col";
import FormGroup from "reactstrap/lib/FormGroup";
import ButtonControl from "views/components/ButtonControl";


class ManutencaoOcorrencias extends React.Component {
  filter = {
    "setor.nome_like": "",
    "status_like": "",
    "dt_inicio_like": ""
  };

  state = {
    data: [],
    filter: this.filter,
  };

  componentDidMount() {
    this.handlePesquisar();
  }

  handlePesquisar = () => {
    this.setState({ loading: true });
    const filterState = this.state.filter;
    let filter = '';
    let page = 1;
    let pageLimit = 10;
    let order = 'desc';

    if (filterState) {
      for (const key in filterState) {
        if (typeof filterState[key] === "object") {
          for (const KeyChild in filterState[key]) {
            if (filterState[key].toString().length > 0 && filterState[key] !== 0)
              filter += '&' + key + '.' + KeyChild + '=' + encodeURIComponent(filterState[key][KeyChild].toString().trim());
          }
        }
        else {
          if (filterState[key].toString().length > 0 && filterState[key] !== 0)
            filter += '&' + key + '=' + encodeURIComponent(filterState[key].toString().trim());
        }
      }
    }
    else {
      for (const key in this.state.filter) {
        if (filterState[key].toString().length > 0 && filterState[key] !== 0)
          filter += '&' + key + '=' + encodeURIComponent(this.state.filter[key].toString().trim());
      }
    }

    fetch(Config._HOST + `/api/manutencao/ocorrencia?_page=${page}&_limit=${pageLimit}&_order=${order}${filter}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          data: response.data,
          loading: false,
        })
      }).catch(e => {
        this.setState({ loading: false });
      })
  }

  onChangeFilter = (campo, value) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [campo]: value
      }
    })
  }

  handleLimpar = () => {
    this.setState({
      filter: this.filter
    }, this.handlePesquisar)
  }

  handleRemove = (id) => {
    confirmAlert({
      title: 'Deseja Deletar o cadastro?',
      message: '',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim', onClick: () => {
            this.setState({ loading: true })

            fetch(Config._HOST + `/api/manutencao/ocorrencia`,
              {
                body: JSON.stringify({ id: id }),
                method: 'DELETE',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              },
            )
              .then(data => {
                this.setState({ loading: false })
                const dataUpdated = this.state.data.filter(row => row.id !== id);
                this.setState({ data: dataUpdated });
                this.props.enqueueSnackbar('Excluido')
              })
              .catch(e => {
                this.setState({ loading: false })
                this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
              })

          }
        }
      ]
    })

  }

  renderContent() {
    if (this.state.loading) return <BoxLoading />

    if (this.state.data.length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Não existem Ocorrências cadastradas</h3>
        </CardHeader>
      );
    }

    return (
      <div>
        <Table className="align-items-center table-flush table-with-button" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">AMBIENTE</th>
              <th scope="col">SETOR</th>
              <th scope="col">MANUTENÇÃO</th>
              <th scope="col">STATUS</th>
              <th scope="col">DATA</th>
              <th scope="col">AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map(single => {
              return (
                <tr key={single.id}>
                  <th>{single.setor.ambiente ? single.setor.ambiente.nome : "-"}</th>
                  <td>{single.setor ? single.setor.nome : "-"}</td>
                  <td>{single.manutencao ? single.manutencao.categoria.nome : "-"}</td>
                  <td>{single.status ? single.status : "-"}</td>
                  <th>
                    {single.dt_inicio ? (
                      moment(single.dt_inicio).format('DD/MM/YYYY') + " às " +
                      moment(single.dt_inicio).format('HH:mm')
                    ) : "-"}
                  </th>
                  <td>
                    <div>
                      <ButtonControl color='secondary' to={`/admin/manutencoes/ocorrencias/${single.id}`}>
                        <i className="fas fa-edit"></i>
                      </ButtonControl>
                      <Button color="orange" onClick={() => this.handleRemove(single.id)}>
                        <i className="fas fa-trash"></i>
                      </Button>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    const { filter } = this.state;

    return (
      <>

        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to='/admin/manutencoes'>
                    <i className="fas fa-arrow-left"></i> VOLTAR
                  </ButtonControl>
                  <ButtonControl color='secondary' to='/admin/manutencoes/ocorrencias/novo'>
                    <i className="fas fa-plus"></i> NOVO
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"OCORRÊNCIAS"} subTitulo={"Módulo de Gestão para Ocorrências"}
        />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 style={{ float: "left" }} className="mb-0">Total de Ocorrencias ({this.state.data.length})</h3>
                  <Filter handleLimpar={this.handleLimpar} handlePesquisar={this.handlePesquisar}>
                    <Row>
                      <Col lg="3">
                        <FormGroup className="mt-margin-remove">
                          <label className="form-control-label">Ambiente</label>
                          <Input
                            className="form-control"
                            placeholder="Ambiente"
                            type="text"
                            onChange={value => this.onChangeFilter("setor.ambiente.nome_like", value.target.value.toUpperCase())}
                            value={filter['setor.ambiente.nome_like']}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup className="mt-margin-remove">
                          <label className="form-control-label">Setor</label>
                          <Input
                            className="form-control"
                            placeholder="Setor"
                            type="text"
                            onChange={value => this.onChangeFilter("setor.nome_like", value.target.value.toUpperCase())}
                            value={filter['setor.nome_like']}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup className="mt-margin-remove">
                          <label className="form-control-label">Status</label>
                          <Input
                            className="form-control"
                            placeholder="Status"
                            type="text"
                            onChange={value => this.onChangeFilter("status_like", value.target.value.toUpperCase())}
                            value={filter['status_like']}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormControl>
                          <label className="form-control-label">Data</label>
                          <Input
                            type="date"
                            name="data"
                            className="form-control"
                            value={filter['dt_inicio_like']}
                            onChange={value => {
                              if (moment(value.target.value.toUpperCase()).isValid())
                                this.onChangeFilter('dt_inicio_like', value.target.value.toUpperCase())
                            }}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  </Filter>
                </CardHeader>

                {this.renderContent()}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(ManutencaoOcorrencias);
