import React, { Component } from 'react';
import { Button, Col, Row, Collapse } from 'reactstrap';

class Filter extends Component {
  state = { collapse: false };

  componentDidMount() {
    const { isOpened } = this.props;
    this.setState({ collapse: isOpened });
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    const { handleLimpar, handlePesquisar, appendButton, children, disabled } = this.props;

    return (
      <>
        <Button id="filter" className="btn-icon-only text-light btn-sm mt-margin-small-left" color="filter" onClick={this.toggle}>
          <i className="fas fa-filter" style={{ cursor: "pointer", color: "#505050" }} />
        </Button>
        <Collapse className="mt-filter" toggler="#filter" isOpen={this.state.collapse}>
          {children}
          <Row className="mt-margin-top">
            <Col lg="4">
              <Button type="submit" color="primary" onClick={handlePesquisar} disabled={!!disabled}>
                <i className="fas fa-search mt-margin-xsmall-right"></i>
                Pesquisar
              </Button>
              <Button type="submit" color="clear" onClick={handleLimpar} disabled={!!disabled}>
                <i className="fas fa-close mt-margin-xsmall-right"></i>
                Limpar
              </Button>
              {appendButton}
            </Col>
          </Row>
        </Collapse>
      </>
    );
  }
}

export default Filter;
