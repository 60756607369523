import React from "react";
import Config from "Config";
import { withSnackbar } from "notistack";
import 'react-big-calendar/lib/css/react-big-calendar.css';


// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button
} from "reactstrap";

import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { ButtonGroup } from "@material-ui/core";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import ButtonControl from "views/components/ButtonControl";

// const { width, height } = { width: window.innerWidth, height: window.innerHeight }

class CategoriaLista extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      data: [],
      dataComplete: [],
      loading: true
    }
  }

  componentDidMount() {
    this.findAll()
  }

  findAll = () => {
    this.setState({
      loading: true,
    })
    fetch(Config._HOST + "/api/categoria")
      .then(response => response.json())
      .then(response => {
        this.setState({
          data: response.status === 200 ? response.data : [],
          dataComplete: response.status === 200 ? response.data : [],
          loading: false,
        })
      }).catch(e => {
        this.setState({
          loading: false
        })
      })
  }

  render() {
    return (
      <>
        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <Button
                    onClick={() => this.props.history.push('/admin/categoria/novo')}>
                    <i className="fas fa-plus"></i> NOVO
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"Categoria"} subTitulo={"Controle geral de Categorias"} />
        {/* Page content */}
        <Container className="mt--7" fluid style={{ paddingBottom: 100 }}>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                {this.renderContent()}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }

  renderContent() {
    const { uk } = this.props.match.params;
    if (this.state.loading) return <BoxLoading />;

    if (this.state.data.length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Não existem Categorias cadastradas</h3>
        </CardHeader>
      )
    }

    return (
      <Table className="align-items-center table-flush table-with-button" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">NOME</th>
            <th scope="col">AÇÃO</th>
          </tr>
        </thead>
        <tbody>
          {this.state.data.map(single => {
            return <tr onClick={() => this.props.history.push("/admin/categoria/" + single.uk)}>
              <td>{single.nome}</td>
              <td>
                <div className="avatar-group">
                  <ButtonControl color='secondary' to={`/admin/associado/${uk}/dependentes/${single.uk}`}>
                    <i className="fas fa-edit" />
                  </ButtonControl>
                </div>
              </td>
            </tr>
          })}
        </tbody>
      </Table>
    )
  }
}

export default withSnackbar(CategoriaLista);
