/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Card, CardBody, Form, Container, Row, Col } from "reactstrap";
import { withSnackbar } from "notistack";
import { ButtonGroup } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import 'rodal/lib/rodal.css';
import FormFields from "./Form/FormFields";
import FormButtonAction from "views/components/FormButtonAction";
import api from "services/api";
import { BoxLoading } from "react-loadingg";
import { handleDeleteFile, handleUploadArrayFile } from "utils/file";
import { jsonToUrlFilter } from "utils/filter";
import ButtonControl from "views/components/ButtonControl";

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const HISTORY_BACK = '/admin/inscricoes';
const ROUTE_API = '/api/inscricoes';

class InscricaoCrud extends React.Component {
  state = {
    form: [],
    ativo: true,
    loading: false,
    cor_primaria: '#673ab7',
    cor_contraste: '#ffffff',
    descricao: '',
    files: [],
    filesRemovidos: [],

    contas: [],
    categorias: [],
    departamentos: [],

    taxa_inscricao_categoria: 0,
    taxa_inscricao_departamento: 0,
    taxa_inscricao_conta: 0,
    taxa_inscricao_valor: 0,
    taxa_inscricao_multa: 0,
    taxa_inscricao_juros: 0,
    taxa_inscricao_valor_nao_socio: 0,

    mensalidade_categoria: 0,
    mensalidade_departamento: 0,
    mensalidade_conta: 0,
    mensalidade_valor: 0,
    mensalidade_multa: 0,
    mensalidade_juros: 0,
    mensalidade_valor_nao_socio: 0,

    hibilitado_para_socio: true,
    hibilitado_para_nao_socio: true,
    mensalidade: false,
    taxa_inscricao: true,
    requer_aprovacao: true,
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.findCategorias();
    await this.findAllDepartamento();
    await this.findAllCaixa();
    !!this.props.match.params.id && await this.findBy();
    this.setState({ loading: false });
  }

  findBy = async () => {
    const { id } = this.props.match.params;
    const response = await api.get(`${ROUTE_API}/${id}`);
    const responseFiles = await api.get(`/api/bucket?${jsonToUrlFilter({ owner: response.uk })}`);

    this.setState({
      ...response,
      files: responseFiles.data
    });
  }

  findCategorias = async () => {
    const filter = {
      _limit: 999,
      _order: 'ASC',
      _sort: "nome",
      ativo: true,
      codigo_like: '1.%'
    }

    const response = await api.get(`/api/financeiro/categoria?${jsonToUrlFilter(filter)}`);
    this.setState({ categorias: response.data });
  }

  findAllDepartamento = async () => {
    const response = await api.get("/api/financeiro/departamento");

    if (response) {
      this.setState({
        departamentos: response.status === 200 ? response.data : []
      })
    }
  }

  findAllCaixa = async () => {
    const response = await api.get('/api/financeiro/caixa?ativo=true');

    if (response) {
      this.setState({ contas: response.data })
    }
  }

  create = async () => {

    const id = this.props.match.params.id !== undefined ? this.props.match.params.id : '';

    if (!this.state.titulo) {
      this.props.enqueueSnackbar("Cadastre um Título");
      return;
    }

    if (this.state.taxa_inscricao) {
      if (!this.state.taxa_inscricao_descricao) {
        this.props.enqueueSnackbar("Cadastre uma descrição da cobrança da taxa de inscrição");
        return;
      }
      if (!this.state.taxa_inscricao_categoria) {
        this.props.enqueueSnackbar("Selecione uma categoria na taxa de inscrição");
        return;
      }
      if (!this.state.taxa_inscricao_departamento) {
        this.props.enqueueSnackbar("Selecione um departamento na taxa de inscrição");
        return;
      }
      if (!this.state.taxa_inscricao_conta) {
        this.props.enqueueSnackbar("Selecione uma conta na taxa de inscrição");
        return;
      }
      if (!this.state.taxa_inscricao_valor) {
        this.props.enqueueSnackbar("Cadastre um valor na taxa de inscrição");
        return;
      }
      if (this.state.hibilitado_para_nao_socio && this.state.taxa_inscricao_valor_nao_socio <= 0) {
        this.props.enqueueSnackbar("Cadastre um valor na taxa de inscrição para não sócio");
        return;
      }
      if (!this.state.taxa_inscricao_multa && this.state.taxa_inscricao_multa <= 0) {
        this.props.enqueueSnackbar("Cadastre um valor para multa na taxa de inscrição");
        return;
      }
      if (!this.state.taxa_inscricao_juros && this.state.taxa_inscricao_juros <= 0) {
        this.props.enqueueSnackbar("Cadastre um valor para juros na taxa de inscrição");
        return;
      }
    }

    if (this.state.mensalidade) {
      if (!this.state.mensalidade_descricao) {
        this.props.enqueueSnackbar("Cadastre uma descrição da cobrança da mensalidade");
        return;
      }
      if (!this.state.mensalidade_categoria) {
        this.props.enqueueSnackbar("Selecione uma categoria na mensalidade");
        return;
      }
      if (!this.state.mensalidade_departamento) {
        this.props.enqueueSnackbar("Selecione um departamento na mensalidade");
        return;
      }
      if (!this.state.mensalidade_conta) {
        this.props.enqueueSnackbar("Selecione uma conta na mensalidade");
        return;
      }
      if (!this.state.mensalidade_valor) {
        this.props.enqueueSnackbar("Cadastre um valor na mensalidade");
        return;
      }
      if (this.state.hibilitado_para_nao_socio && this.state.mensalidade_valor_nao_socio <= 0) {
        this.props.enqueueSnackbar("Cadastre um valor na mensalidade para não sócio");
        return;
      }
      if (!this.state.mensalidade_multa && this.state.mensalidade_multa <= 0) {
        this.props.enqueueSnackbar("Cadastre um valor para multa na mensalidade");
        return;
      }
      if (!this.state.mensalidade_juros && this.state.mensalidade_juros <= 0) {
        this.props.enqueueSnackbar("Cadastre um valor para juros na mensalidade");
        return;
      }
    }

    const temp = { ...this.state };

    if(!temp.mensalidade) {
      delete temp.mensalidade_descricao;
      delete temp.mensalidade_categoria;
      delete temp.mensalidade_conta;
      delete temp.mensalidade_departamento;
      delete temp.mensalidade_juros;
      delete temp.mensalidade_multa;
      delete temp.mensalidade_valor;
    }

    if(!temp.taxa_inscricao) {
      delete temp.taxa_inscricao_descricao;
      delete temp.taxa_inscricao_categoria;
      delete temp.taxa_inscricao_conta;
      delete temp.taxa_inscricao_departamento;
      delete temp.taxa_inscricao_juros;
      delete temp.taxa_inscricao_multa;
      delete temp.taxa_inscricao_valor;
    }

    this.setState({ loading: true })
    let files = this.state.files;
    await handleDeleteFile(this.state.filesRemovidos);
    const data = { ...temp, form: JSON.stringify(this.state.form) }
    delete data['files'];
    delete data['filesRemovidos'];

    const response = !!id ? await api.put(`${ROUTE_API}/${id}`, data) : await api.post(`${ROUTE_API}`, data);

    if(!!response && !('data' in response && response.data.status !== 200)) {
      this.props.enqueueSnackbar(id ? "Atualizado com sucesso!" : "Cadastrado com sucesso!");

      if (files.length > 0) {
        this.props.enqueueSnackbar(await handleUploadArrayFile(response.uk, 'inscricoes', files) === 200 ? "Upload Realizada com sucesso" : "Falha no upload do arquivo.")
      }
    }
    else {
      this.props.enqueueSnackbar("Ops, ocorreu um erro!");
    }

    this.setState({ loading: false });
    if(!!response && !('data' in response && response.data.status !== 200)) this.props.history.push(HISTORY_BACK);
  }

  handleChange = value => {
    this.setState({ ...this.state, ...value });
  }

  handleChangeDropzone = (key, value) => {
    this.setState({ [key]: value });
  }

  render() {
    return (
      <>
        {this.state.loading && (
          <div className="container-load-with-margin">
            <BoxLoading />
          </div>
        )}

        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={HISTORY_BACK}>
                    <i className="fas fa-arrow-left"></i> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={this.props.match.params.id ? "EDITAR INSCRIÇÕES" : "NOVA INSCRIÇÕES"}
          subTitulo={"Gerenciamento das inscrições"}
        />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <FormFields {...this} />
                  <Form>
                    <FormButtonAction create={this.create} cancel={HISTORY_BACK} history={this.props.history} isEdit={this.props.match.params.id} />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(InscricaoCrud);
