
import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';

export default class ModalEmail extends Component {
  state = {
    modal: false,
    mensagem: ''
  }

  toggle = () => this.setState({ modal: !this.state.modal });

  render() {
    const { modal, mensagem } = this.state;

    return (
      <div>
        <button className="btn btn-outline-secondary" type="button" onClick={this.toggle}>
          <i className="fas fa-envelope" />
        </button>
        <Modal isOpen={modal} toggle={this.toggle} className="modal-lg">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}>&times;</button>}>Enviar mensagem</ModalHeader>
          <ModalBody>
            <Input
              className="form-control"
              placeholder="E-mail"
              type="textarea"
              rows="10"
              onChange={(value) => this.setState({ mensagem: value.target.value.toUpperCase() })}
              value={mensagem}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Cancelar</Button>
            <Button color="primary" onClick={this.toggle}>Enviar
              <i className="fas fa-paper-plane mt-margin-xsmall-left" />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
