import moment from 'moment';
import { withSnackbar } from "notistack";
import { Component } from "react";
import 'react-big-calendar/lib/css/react-big-calendar.css';


// reactstrap components
import { Card, CardHeader, Container, Row, Table } from "reactstrap";
import { ButtonGroup } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import api from "services/api";
import ButtonExport from "utils/ButtonExport";
import { jsonToUrlFilter } from "utils/filter";
import ButtonControl from 'views/components/ButtonControl';


class AgendaPrestadoresServico extends Component {

  state = {
    data: [],
    loading: false,
    listaPrestadoresServico: [],
  }

  componentDidMount() {
    this.findBy(this.props.match.params.uk)
  }

  findBy = async (uk) => {
    this.setState({ loading: true });

    const response = await api.get("/api/documento-agenda/" + uk);
    const responseListaPrestadoresServico = await api.get(`/api/documento-agenda/prestadores-servico?documento_agenda_entity.uk=${uk}&_limit=10000&_order=asc&_sort=nome_entregador`);

    const table_header = [];
    table_header.push({ key: 'Descrição do Evento', value: !!response.data.descricao ? response.data.descricao : '' });

    if(!!response.data.autorizado) {
      table_header.push({ key: 'Responsável pela retirada da chave', value: response.data.autorizado });
    }

    table_header.push({ key: 'Sócio (a)', value: response.data.responsavel.name });
    table_header.push({ key: 'Telefone', value: response.data.responsavel.listaTelefone.length > 0 ? response.data.responsavel.listaTelefone[0].telefone : '' });
    table_header.push({ key: 'Data', value: moment(response.data.dtInicio).format('DD/MM/YYYY') });


    this.setState({
      ...response.data,
      listaPrestadoresServico: responseListaPrestadoresServico.data,
      loading: false,
      export: {
        _view: {
          title: `Lista de Prestadores de Serviço "${response.data.ambiente.nome}"`,
          head: 'Nome Entregador,Nome Empresa,Telefone',
          body: 'nome_entregador,nome_empresa,telefone',
          template: 2,
          table_header: JSON.stringify(table_header)
        },
        _order: 'asc',
        _sort: 'nome_entregador',
        _limit: 10000,
        documento_agenda_entity: {
          id: response.data.id
        }
      }
    });
  }

  render() {
    const { listaPrestadoresServico, responsavel, loading, export: exportData } = this.state;
    const { uk } = this.props.match.params;

    return (
      <>
        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={`/admin/agenda/${uk}/visualizar`}>
                    <i className="fas fa-arrow-left" /> VOLTAR
                  </ButtonControl>
                  <ButtonControl color='secondary' to={`/admin/agenda/${uk}/prestadores-servico/novo`}>
                    <i className="fas fa-plus"></i> NOVO
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"Prestadores de Serviço"} subTitulo={!!responsavel ? "Controle geral de prestadores de serviço de " + responsavel.name : ''}
        />

        <Container className="mt--7" fluid style={{ paddingBottom: 100 }}>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 style={{ float: "left" }} className="mb-0 mt-margin-small-right">Prestadores de Serviço ({listaPrestadoresServico.length})</h3>

                  {exportData && !!listaPrestadoresServico.length && !loading && (
                    <>
                      <ButtonExport notification={this.props.enqueueSnackbar} name="Lista de Prestadores de Serviço" type="pdf" url={'/api/documento-agenda/prestadores-servico/export/pdf?' + jsonToUrlFilter(this.state.export)} />
                      <ButtonExport notification={this.props.enqueueSnackbar} name="Lista de Prestadores de Serviço" type="excel" url={'/api/documento-agenda/prestadores-servico/export/excel?' + jsonToUrlFilter(this.state.export)} />
                    </>
                  )}
                </CardHeader>
                {this.renderContent()}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }

  renderContent() {
    if (this.state.loading) return <BoxLoading />

    if (this.state.listaPrestadoresServico.length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Não existem prestadores de serviço cadastrados</h3>
        </CardHeader>
      )
    }

    return (
      <div>
        <Table className="align-items-center table-flush table-with-button" responsive>
          <thead className="thead-light">
            <tr>
              <th>NOME ENTREGADOR</th>
              <th>NOME EMPRESA</th>
              <th>TELEFONE</th>
              <th>DATA CADASTRO</th>
              <th>AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {this.state.listaPrestadoresServico.map(single => (
              <tr key={single.id}>
                <td title={single.nome_entregador}>{single.nome_entregador}</td>
                <td title={single.nome_empresa}>{single.nome_empresa}</td>
                <td>{single.telefone ? single.telefone : "-"}</td>
                <td>{moment(single.dtCadastro).format('DD/MM/YYYY HH:mm')}</td>
                <td>
                  <div className="avatar-group">
                    <ButtonControl color='secondary' to={`/admin/agenda/${this.props.match.params.uk}/prestadores-servico/${single.id}/editar`}>
                      <i className="fas fa-edit"></i>
                    </ButtonControl>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}

export default withSnackbar(AgendaPrestadoresServico);
