import { FormControl, MenuItem, Select } from '@material-ui/core';
import React, { Component } from 'react';
import { Button, Card, CardBody, Col, FormGroup, Input, Row } from 'reactstrap';
import { estados } from 'utils/estados';
import api from 'services/api';

export default class Enderecos extends Component {

  handleFindAddress = async (cep, index) => {
    if(cep.replace("-", "").length === 8) {
      const response = await api.get(`https://viacep.com.br/ws/${cep}/json/`);

      let { listaEnderecos } = this.props.state;
      listaEnderecos[index].estado = !!response.uf && estados.find(item => item.sigla === response.uf).id;
      listaEnderecos[index].cidade = response.localidade;
      listaEnderecos[index].logradouro = response.logradouro;
      listaEnderecos[index].bairro = response.bairro;
      this.setState({ listaEnderecos });
    }
  }

  render() {
    const { state, addEndereco, handleChangeTipoEndereco, removeEndereco } = this.props;

    return (
      <div className="mt-margin-left mt-margin-right">
        <Row>
          <Col lg="6">
            <Button onClick={addEndereco}>
              <i className="fas fa-plus" /> Adicionar
            </Button>
          </Col>
        </Row>

        {state.listaEnderecos && state.listaEnderecos.map((single, index) => {
          return (
            <Card key={index} className="mt-margin-top">
              <CardBody>
                <Row>
                  <Col lg="6" className="mt-text-left">
                    <h3>Endereço {index + 1}</h3>
                  </Col>
                  <Col lg="6" className="mt-text-right">
                    <Button onClick={() => removeEndereco(index, single)}>
                      <i className="fas fa-times" /> Excluir
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <Card className="mt-margin-top">
                      <CardBody>
                        <Row>
                          <Col lg="3">
                            <FormGroup className="mt-margin-bottom">
                              <FormControl variant="outlined" style={{ width: '100%' }}>
                                <label className="form-control-label">
                                  Endereço Principal
                                </label>
                                <Select
                                  value={single.principal ? single.principal : index === 0 ? true : false}
                                  onChange={e => {
                                    const { value } = e.target;
                                    let { listaEnderecos } = state;
                                    listaEnderecos[index].principal = value;
                                    this.setState({ listaEnderecos });
                                  }}
                                >
                                  <MenuItem value={true}>Sim</MenuItem>
                                  <MenuItem value={false}>Não</MenuItem>
                                </Select>
                              </FormControl>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <FormControl variant="outlined" style={{ width: '100%' }}>
                                <label className="form-control-label">Tipo Endereço</label>
                                <Select
                                  value={single.tipo ? single.tipo : 6}
                                  onChange={e => handleChangeTipoEndereco(e, index)}
                                >
                                  <MenuItem value={6}>Residencial</MenuItem>
                                  <MenuItem value={1}>Comercial</MenuItem>
                                  <MenuItem value={2}>Outros</MenuItem>
                                  <MenuItem value={3}>Cobrança</MenuItem>
                                  <MenuItem value={4}>Consultório</MenuItem>
                                  <MenuItem value={5}>Igreja/Denominação</MenuItem>
                                </Select>
                              </FormControl>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label className="form-control-label">CEP</label>
                              <Input
                                onChange={e => {
                                  const { value } = e.target;
                                  let { listaEnderecos } = state;
                                  listaEnderecos[index].cep = value.toUpperCase();
                                  this.setState({ listaEnderecos });
                                }}
                                onBlur={(e) => this.handleFindAddress(e.target.value, index)}
                                key={index}
                                value={single.cep ? single.cep : ''}
                                placeholder="Endereço"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <FormControl variant="outlined" style={{ width: '100%' }}>
                                <label className="form-control-label">Estado</label>
                                <Select
                                  value={single.estado ? single.estado : 0}
                                  onChange={e => {
                                    const { value } = e.target;
                                    let { listaEnderecos } = state;
                                    listaEnderecos[index].estado = value;
                                    this.setState({ listaEnderecos });
                                  }}
                                >
                                  <MenuItem value="0">Estado</MenuItem>
                                  {estados.map(item => <MenuItem value={item.id}>{item.sigla} - {item.name}</MenuItem>)}
                                </Select>
                              </FormControl>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <FormControl variant="outlined" style={{ width: '100%' }}>
                                <label className="form-control-label">Cidade</label>
                                <Input
                                  onChange={e => {
                                    const { value } = e.target;
                                    let { listaEnderecos } = state;
                                    listaEnderecos[index].cidade = value.toUpperCase();
                                    this.setState({ listaEnderecos });
                                  }}
                                  value={single.cidade ? single.cidade : ''}
                                  placeholder="Cidade"
                                  type="text"
                                />
                              </FormControl>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label className="form-control-label">Logradouro</label>
                              <Input
                                onChange={e => {
                                  const { value } = e.target;
                                  let { listaEnderecos } = state;
                                  listaEnderecos[index].logradouro = value.toUpperCase();
                                  this.setState({ listaEnderecos });
                                }}
                                value={single.logradouro ? single.logradouro : ''}
                                placeholder="Logradouro"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label className="form-control-label">Número</label>
                              <Input
                                onChange={e => {
                                  const { value } = e.target;
                                  let { listaEnderecos } = state;
                                  listaEnderecos[index].numero = value.toUpperCase();
                                  this.setState({ listaEnderecos });
                                }}
                                value={single.numero ? single.numero : ''}
                                placeholder="Número"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label className="form-control-label">Bairro</label>
                              <Input
                                onChange={e => {
                                  const { value } = e.target;
                                  let { listaEnderecos } = state;
                                  listaEnderecos[index].bairro = value.toUpperCase();
                                  this.setState({ listaEnderecos });
                                }}
                                value={single.bairro ? single.bairro : ''}
                                placeholder="Bairro"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label className="form-control-label">Complemento</label>
                              <Input
                                onChange={e => {
                                  const { value } = e.target;
                                  let { listaEnderecos } = state;
                                  listaEnderecos[index].complemento = value.toUpperCase();
                                  this.setState({ listaEnderecos });
                                }}
                                value={single.complemento ? single.complemento : ''}
                                placeholder="Complemento"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )
        })}
      </div>
    );
  }
}
