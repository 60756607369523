/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Component } from "react";

// reactstrap components
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import moment from "moment";
import { withSnackbar } from "notistack";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import BoxLoading from "react-loadingg/lib/BoxLoading";
import { Button, ButtonGroup, Card, CardHeader, Container, Row, Table } from "reactstrap";
import api from "services/api";
import { jsonToUrlFilter } from "utils/filter";
import { addMaskMoney } from "utils/Text/Mask";
import ButtonControl from "views/components/ButtonControl";

const ROUTE_API = '/api/financeiro/acordos';
const HISTORY_BACK = '/admin/financeiro/contas/receber';

class FinanceiroAcordoList extends Component {

  state = {
    data: [],
    totalData: 50,
    filter: {
      _page: 1,
      _limit: 50,
      _order: 'desc'
    },
  };

  componentDidMount() {
    this.loadMore()
  }

  loadMore = async () => {
    this.setState({ loading: true, filter: { ...this.state.filter, _page: this.state.filter._page + 1 } });
    let response = await api.get(`${ROUTE_API}?${jsonToUrlFilter(this.state.filter)}`);
    this.setState({
      data: this.state.data.concat(response.data),
      totalData: response.total_data,
      loading: false
    });
  }

  handleRemove = id => {
    confirmAlert({
      title: 'Deseja cancelar o acordo?',
      message: '',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim', onClick: async () => {
            this.setState({ loading: true });
            const response = await api.delete(`/api/financeiro/acordos/${id}`);

            if(!!response) {
              const dataUpdated = this.state.data.filter(row => row.id !== id);
              this.setState({ data: dataUpdated });
              this.props.enqueueSnackbar('Acordo cancelado com sucesso!');
            }
            else {
              this.props.enqueueSnackbar('Ops, ocorreu um erro!');
            }

            this.setState({ loading: false });
          }
        }
      ]
    })
  }

  renderContent() {
    if (this.state.loading) return <BoxLoading />;

    if (this.state.data && this.state.data.length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Não existem acordo cadastrado</h3>
        </CardHeader>
      );
    }

    return (
      <div>
        <Table className="align-items-center table-flush table-with-button" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">SÓCIO</th>
              <th scope="col">DATA DO ACORDO</th>
              <th scope="col">PARCELAS</th>
              <th scope="col">PARCELAS PAGAS</th>
              <th scope="col">TOTAL PAGO</th>
              <th scope="col">AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data && this.state.data.map(item => {
              let parcelasPagas = 0;
              let valorPago = 0;

              for (const key in item.financeiro.parcelamentos) {
                const data = item.financeiro.parcelamentos[key];
                if(!!data.valorPagamentoRecebido && data.status === 1) {
                  valorPago += !!data.valorPagamentoRecebido ? data.valorPagamentoRecebido : 0;
                  parcelasPagas++;
                }
              }

              return (
                <tr key={item.id}>
                  <td>{`(${item.financeiro.cliente.numeroTitulo}) ${item.financeiro.cliente.name}`}</td>
                  <th>{moment(item.data_acordo).format("DD/MM/YYYY")}</th>
                  <td>{!!item.financeiro.parcelamentos ? item.financeiro.parcelamentos.length : 0}</td>
                  <td>{parcelasPagas}</td>
                  <td>{addMaskMoney(valorPago)}</td>
                  <td>
                    <div className="text-right">
                      <ButtonControl color='secondary' to={`/admin/financeiro/acordo/${item.id}/editar`}>
                        <i className="fas fa-eye"></i>
                      </ButtonControl>
                      <Button color="orange" onClick={() => this.handleRemove(item.id)} disabled={parcelasPagas > 0} title={parcelasPagas > 0 ? 'Acordo não pode ser cancelado pois já tem parcela(s) paga(s)' : null }>
                        <i className="fas fa-trash"></i>
                      </Button>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>

      </div>
    );
  }

  render() {
    return (
      <>

        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup>
                  <ButtonControl color='secondary' to={HISTORY_BACK} style={{ marginRight: '0.5rem' }}>
                    <i className="fas fa-arrow-left"></i> VOLTAR
                  </ButtonControl>
                  <ButtonControl color='secondary' to='/admin/financeiro/acordo/novo'>
                    <i className="fas fa-plus" /> NOVO
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"Acordo"} subTitulo={"Módulo de Gestão de Acordo"}
        />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Total ({this.state.data ? this.state.data.length : 0})</h3>
                </CardHeader>

                {this.renderContent()}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(FinanceiroAcordoList);
