const getStatusAssociado = status => {
  switch (parseInt(status, 10)) {
    case 1:
      return 'Ativo';
    case 2:
      return 'Inativo';
    case 3:
      return 'Falecido';
    case 4:
      return 'Publico';
    case 5:
      return 'Suspenso';
    case 6:
      return 'Irregular';
    case 7:
      return 'Inadimplente';
    case 8:
      return 'Desligado';
    case 9:
      return 'Licenciado';
    case 10:
      return 'Bloqueado';
    default:
      return 'Sem Acesso'
  }
};

const getCorStatusAssociado = status => {
  switch (parseInt(status, 10)) {
    case 1:
      return "#46a746";
    case 6:
      return "#c8c812";
    case 7:
      return "#ffa500";
    default:
      return "#e72626";
  }
};

export { getStatusAssociado, getCorStatusAssociado };
