import React from "react";
import Config from "Config";
import { withSnackbar } from "notistack";
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Card, Container, Row } from "reactstrap";
import CardBody from "reactstrap/lib/CardBody";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import Col from "reactstrap/lib/Col";
import { ButtonGroup, List, ListItem, ListItemText } from "@material-ui/core";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import CardTitle from "reactstrap/lib/CardTitle";
import numeral from 'numeral';
import { getStatusReserva } from "utils/StatusReserva";
import ButtonControl from "views/components/ButtonControl";

class agendaDocumento extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      listaConvidados: [],
      listaPrestadoresServico: [],
      receitaDespesa: [],
      usuario: {
        name: null,
        uk: null
      },
      ambiente: {
        nome: null
      }
    }
  }

  componentDidMount() {
    let user = JSON.parse(window.localStorage.getItem(Config._USER))
    if (!user) {
      window.localStorage.removeItem(Config._USER)
      this.props.history.replace('/auth')
      return;
    }
    this.findBy(this.props.match.params.uk)
  }

  findBy = (uk) => {
    this.setState({ loading: true });

    fetch(Config._HOST + "/api/documento-agenda/" + uk)
      .then(response => response.json())
      .then(response => {
        this.setState({ ...response.data, loading: false })
      }).catch(e => {
        this.setState({
          loading: false
        })
      })
  }

  render() {
    const { uk } = this.props.match.params;
    const { financeiroContasEntity } = this.state;

    return (
      <>
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to='/admin/agenda'>
                    <i className="fas fa-arrow-left" /> VOLTAR
                  </ButtonControl>
                  <ButtonControl color='secondary' to={`/admin/agenda/${uk}/editar`}>
                    <i className="fas fa-pencil-alt" style={{ marginRight: 10 }} />Editar
                  </ButtonControl>

                  {[1, 4].indexOf(this.state.status) !== -1 && !this.state.checkIn && (
                    <ButtonControl color='secondary' to={`/admin/agenda/check/${uk}`}>
                      <i className="fas fa-clipboard-list mt-margin-small-right"></i>
                      Check-In
                    </ButtonControl>
                  )}

                  {[1, 4].indexOf(this.state.status) !== -1 && this.state.checkIn && (
                    <ButtonControl color='secondary' to={`/admin/agenda/check/${uk}`}>
                      <i className="fas fa-clipboard-list mt-margin-small-right"></i>
                      Check-Out
                    </ButtonControl>
                  )}

                  {this.state.ambiente.termoReserva && (
                    <a className='btn btn-secondary' href={process.env.REACT_APP_API_URL + '/api/pdf/emitir/' + this.state.ambiente.termoReserva.id} rel="noreferrer" target="_blank">
                      <i className="fas fa-file-alt mt-margin-small-right"></i>
                      Termo
                    </a>
                  )}

                  {this.state.status !== 4 && !!financeiroContasEntity && (
                    <ButtonControl color='secondary' to={`/admin/financeiro/contas/receber/${financeiroContasEntity.uk}/editar`} rel="noreferrer" target="_blank">
                      <i className="fas fa-dollar-sign mt-margin-small-right"></i>
                      Financeiro
                    </ButtonControl>
                  )}
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"Reserva"}
          subTitulo={"Gerenciamento completo de Agendamentos"}
        />

        <Container className="mt--7" fluid style={{ paddingBottom: 100 }}>
          {/* Table */}
          {this.renderContent()}
        </Container>
      </>
    );
  }

  renderContent() {
    const { financeiroContasEntity } = this.state;
    let ultimaParcela = null;
    let status = null;

    if (this.state.loading) return <BoxLoading />;

    if (financeiroContasEntity && 'parcelamentos' in financeiroContasEntity && financeiroContasEntity.parcelamentos.length > 0) {
      ultimaParcela = financeiroContasEntity.parcelamentos[financeiroContasEntity.parcelamentos.length - 1];
    }

    if ('status' in this.state) {
      status = this.state.status;
    }

    return (
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardBody>
              <h6 className="heading-small text-muted mb-4">
                Documento de Agendamento
              </h6>
              <Row>
                <Col lg="6" className="hover" onClick={() => this.props.history.push('/admin/agenda/' + this.props.match.params.uk + '/convidados')}>
                  <Card className="card-stats mb-4 mb-lg-0">
                    <CardBody>
                      <Row>
                      <Col className="col-auto" style={{ display: 'flex', alignItems: 'center'}}>
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-users"></i>
                          </div>
                        </Col>
                        <div className="col">
                          <CardTitle className="text-uppercase text-muted mb-0">
                            <span className="mt-margin-xsmall-right">Lista de Convidados </span>
                            <span className="h3 mb-0">({this.state.listaConvidados.length}</span>
                            <span>/</span>
                            <span className="h3 mb-0">{this.state.ambiente.capacidadePessoas})</span>
                          </CardTitle>
                          <p className="mt-margin-small-top mb-0 text-muted text-sm">
                            <span className="text-nowrap">Visualizar lista completa de Convidados</span>
                          </p>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" className="hover" onClick={() => this.props.history.push('/admin/agenda/' + this.props.match.params.uk + '/prestadores-servico')}>
                  <Card className="card-stats mb-4 mb-lg-0">
                    <CardBody>
                      <Row>
                        <Col className="col-auto" style={{ display: 'flex', alignItems: 'center'}}>
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-shopping-basket"></i>
                          </div>
                        </Col>
                        <div className="col">
                          <CardTitle className="text-uppercase text-muted mb-0">
                            <span className="mt-margin-xsmall-right">Lista de Prestadores de Serviço</span>
                            <span className="h3 mb-0">({this.state.listaPrestadoresServico.length})</span>
                          </CardTitle>
                          <p className="mt-margin-small-top mb-0 text-muted text-sm">
                            <span className="text-nowrap">Visualizar lista completa de Prestadores de Serviço</span>
                          </p>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <List>
                  {!!this.state.responsavel && (
                    <ListItem>
                      <ListItemText primary="Descrição do evento" secondary={this.state.descricao} />
                    </ListItem>
                  )}

                  {!!this.state.responsavel && (
                    <ListItem className="hover" onClick={() => {
                      this.props.history.push('/admin/associado/perfil/' + this.state.responsavel.uk)
                    }}>
                      <ListItemText primary="Responsável" secondary={this.state.responsavel.name} />
                    </ListItem>
                  )}

                  {!!this.state.autorizado && (
                    <ListItem>
                      <ListItemText primary="Responsável pela retirada da chave" secondary={this.state.autorizado} />
                    </ListItem>
                  )}
                  <ListItem>
                    <ListItemText primary="Ambiente" secondary={this.state.ambiente.nome} />
                  </ListItem>
                  {(!!ultimaParcela || !!status) && (
                    <ListItem>
                      <ListItemText primary="Status" secondary={getStatusReserva(!!ultimaParcela && ultimaParcela.length > 1 ? ultimaParcela.status : status)} />
                    </ListItem>
                  )}
                  <ListItem>
                    <ListItemText primary="Data de Início" secondary={moment(this.state.dtInicio).format('DD/MM/YYYY HH:mm')} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Valor Total" secondary={numeral(this.state.valorAluguel).format('$ 0,0.00')} />
                  </ListItem>

                  <ListItem>
                    <ListItemText primary="Reserva Pelo Aplicativo?" secondary={!!this.state.userDispositivo ? "Sim" : "Não"} />
                  </ListItem>

                  {!!this.state.userDispositivo && (
                    <ListItem>
                      <ListItemText primary="Dispositivo Utilizado" secondary={this.state.userDispositivo.nome} />
                    </ListItem>
                  )}

                  <ListItem>
                    <ListItemText primary="Data de Criação da Reserva" secondary={moment(this.state.dataCadastro).format('DD/MM/YYYY HH:mm')} />
                  </ListItem>

                  {!!this.state.observacao && (
                    <ListItem>
                      <ListItemText primary="Observação" secondary={this.state.observacao} secondaryTypographyProps={{ style: { whiteSpace: 'pre-line' } }}/>
                    </ListItem>
                  )}
                </List>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default withSnackbar(agendaDocumento);
