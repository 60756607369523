import React, { Component } from 'react';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import { FormControl, MenuItem, Select, TextField, CircularProgress } from '@material-ui/core';
import Config from 'Config';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import { jsonToUrlFilter } from 'utils/filter';

export default class FormFields extends Component {
  async componentDidMount() {
    const { handleChange } = this.props;
    handleChange({ loading: true })

    fetch(Config._HOST + '/api/ocorrencia/tipo', { method: 'GET' })
      .then(response => response.json())
      .then(response => {
        handleChange({
          dataTipos: response.data,
          loading: false,
        })
      }).catch(e => {
        handleChange({ loading: false })
      })

    fetch(Config._HOST + '/api/ocorrencia/status', { method: 'GET' })
      .then(response => response.json())
      .then(response => {
        handleChange({
          dataStatus: response.data,
          loading: false,
        })
      }).catch(e => {
        handleChange({ loading: false })
      })
  }

  searchUser = (search, field) => {
    if (!search) return;

    const { handleChange } = this.props;
    handleChange({ loadSelect: true });

    const params = {
      _limit: 100,
      name_like: search,
      profile: {
        id: [2, 3]
      }
    };

    fetch(Config._HOST + `/api/user?${jsonToUrlFilter(params)}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          [field]: 'data' in response ? response.data : [],
          loadSelect: false,
        })
      }).catch(e => {
        this.setState({ loadSelect: false });
      })
  }

  render() {
    const { state, handleChange } = this.props;

    return (
      <>
        <div className="mt-margin-left mt-margin-right">
          <Row>
            <Col lg="3">
              <FormControl variant="outlined">
                <label className="form-control-label">Tipo</label>
                <Select
                  name="tipo"
                  className="mt-select"
                  placeholder="Tipo"
                  onChange={value => { handleChange({ tipo: value.target.value }) }}
                  value={state.tipo ? state.tipo : 0}
                >
                  <MenuItem value={0}>Selecione uma opção</MenuItem>
                  {state.dataTipos.map(tipo => (
                    <MenuItem key={tipo.id} value={tipo.id}>{tipo.nome}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col lg="3">
              <FormControl variant="outlined">
                <label className="form-control-label">Status</label>
                <Select
                  name="status"
                  className="mt-select"
                  placeholder="Status"
                  onChange={value => { handleChange({ status: value.target.value }) }}
                  value={state.status ? state.status : 0}
                >
                  <MenuItem value={0}>Selecione uma opção</MenuItem>
                  {state.dataStatus.map(status => (
                    <MenuItem key={status.id} value={status.id}>{status.nome}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col lg="3">
              <FormControl>
                <label className="form-control-label">Data</label>
                <Input
                  type="datetime-local"
                  name="data"
                  placeholder="Data"
                  value={state.data}
                  onChange={value => {
                    if (moment(value.target.value.toUpperCase()).isValid())
                      handleChange({ data: value.target.value.toUpperCase() })
                  }}
                  className="form-control"
                />
              </FormControl>
            </Col>
          </Row>

          <Row>
            <Col lg="6">
              <FormGroup className="mt-margin-top">
                <label className="form-control-label">Descricao</label>
                <Input
                  className="form-control"
                  placeholder="Descricao"
                  type="text"
                  onChange={value => handleChange({ descricao: value.target.value.toUpperCase() })}
                  value={state.descricao}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="mt-margin-top">
                <label className="form-control-label">Relator</label>
                <Autocomplete
                  options={state.dataUsers}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, value) => handleChange({ "relator": value })}
                  onInputChange={(_, value) => this.searchUser(value, "dataUsers")}
                  inputMode="text"
                  key="relator"
                  loading={state.loadSelect}
                  value={state.relator ? state.relator : null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Buscar pelo nome"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {state.loadSelect ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup className="mt-margin-top">
                <label className="form-control-label">Envolvidos</label>
                <Autocomplete
                  options={state.dataUsers}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, value) => handleChange({ "envolvidos": value })}
                  onInputChange={(_, value) => {
                    console.log(value)
                    this.searchUser(value, "dataUsers");
                  }}
                  inputMode="text"
                  key="envolvidos"
                  loading={state.loadSelect}
                  value={state.envolvidos ? state.envolvidos : null}
                  multiple={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Buscar pelo nome"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {state.loadSelect ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
