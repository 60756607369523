/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Card, CardBody, CardHeader, Col, Container, Form, Row } from "reactstrap";
import { ButtonGroup } from "@material-ui/core";
import Config from "Config";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { withSnackbar } from "notistack";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { BoxLoading } from "react-loadingg";
import 'rodal/lib/rodal.css';
import api from "services/api";
import { handleDeleteFile, handleUploadArrayFile } from "utils/file";
import { jsonToUrlFilter } from "utils/filter";
import { req } from 'utils/req';
import FormButtonAction from "views/components/FormButtonAction";
import FormFields from "./Form/FormFields";
import ButtonControl from "views/components/ButtonControl";
import moment from "moment";

const HISTORY_BACK = '/admin/comunicados';
const ROUTE_API = '/api/comunicado';

class ComunicadoNovo extends React.Component {
  state = {
    files: [],
    filesRemovidos: [],
    dataCadastro: new Date(),
    dataPublicacao: moment().format('YYYY-MM-DDTHH:mm:ss'),
    loading: false
  };

  async componentDidMount() {
    const { id } = this.props.match.params;
    let user = JSON.parse(window.localStorage.getItem(Config._USER))

    if (!user) {
      window.localStorage.removeItem(Config._USER)
      this.props.history.replace('/auth')
      return;
    }

    if (id) await this.findBy();
  }

  findBy = async () => {
    this.setState({ loading: true })
    const { id } = this.props.match.params;

    let response = await api.get(`${ROUTE_API}/${id}`);
    this.setState({ ...response });
    let responseFiles = await api.get(`/api/bucket?${jsonToUrlFilter({ owner: response.uk })}`);
    this.setState({ files: responseFiles.data, loading: false })
  }

  create = async (id) => {
    let files = this.state.files;
    let temp = this.state;
    temp.files = [];

    if (!this.state.mensagem && files.length === 0) {
      this.props.enqueueSnackbar("Informe uma mensagem ou um anexo!");
      return;
    }

    this.setState({ loading: true });
    await handleDeleteFile(this.state.filesRemovidos);

    req(ROUTE_API, temp, id)
      .then(async (response) => {
        if(!response || ('status' in response && response.status !== 200)) throw new Error("Ocorreu um Erro!");
        if (files.length > 0) {
          const isUploaded = await handleUploadArrayFile(response.uk, 'comunicado', files)
          if(isUploaded !== 200) throw new Error("Falha ao salvar o arquivo.")
          this.props.enqueueSnackbar("Arquivo salvo com sucesso")
        }

        this.props.enqueueSnackbar(response.id ? "Atualizado com Sucesso!" : "Criado com Sucesso!");
        this.props.history.push(HISTORY_BACK);
        this.setState({ loading: false });
      }).catch((e) => {
        this.props.enqueueSnackbar(e.message);
        this.setState({ loading: false });
      });
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  }

  render() {
    const { loading } = this.state;

    return (
      <>
        {loading && (
          <div className="container-load-with-margin">
            <BoxLoading />
          </div>
        )}

        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={HISTORY_BACK}>
                    <i className="fas fa-arrow-left"></i> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={this.props.match.params.id ? "EDITAR COMUNICADOS" : "NOVO COMUNICADOS"}
          subTitulo={"Gerenciamento dos Comunicados"}
        />


        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {this.props.match.params.id ? "Atualizar Comunicados" : "Cadastrar Novo Comunicados"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormFields {...this} />
                    <FormButtonAction create={() => this.create(this.props.match.params.id)} cancel={HISTORY_BACK} history={this.props.history} isEdit={this.props.match.params.id} />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(ComunicadoNovo);
