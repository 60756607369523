/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Card, CardHeader, CardBody, Form, Container, Row, Col } from "reactstrap";
import Config from "Config";
import { withSnackbar } from "notistack";
import { ButtonGroup } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import FormFields from "./Form/FormFields";
import FormButtonAction from "views/components/FormButtonAction";
import { isValidCPF } from "utils/Validations";
import { req } from "utils/req";
import ButtonControl from "views/components/ButtonControl";

const HISTORY_BACK = '/admin/visitantes';
const ROUTE_API = '/api/visitantes';

class VisitanteCrud extends React.Component {
  state = {}

  async componentDidMount() {
    const { id } =this.props.match.params;
    id && await this.findBy(id);
  }

  findBy = async (id) => {
    this.setState({ loading: true })

    await fetch(Config._HOST + `${ROUTE_API}/${id}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          ...this.state,
          ...response,
        });
      }).catch(e => {
        this.setState({ loading: false })
      })
  }

  create = async id => {
    let temp = this.state;
    this.setState({ loading: true });

    if (!temp.nome || (temp.nome.trim() === "")) {
      this.props.enqueueSnackbar("Preencha o Nome Completo");
      return;
    }

    if(!isValidCPF(this.state.cpf)) {
      this.props.enqueueSnackbar("O campo CPF invalido!");
      return;
    }

    if (!temp.telefone || (temp.telefone.trim() === "")) {
      this.props.enqueueSnackbar("Preencha o Telefone");
      return;
    }

    const response = await req(ROUTE_API, temp, id);

    if(!!response) {
      this.props.enqueueSnackbar(id ? "Atualizado com sucesso!" : "Cadastrado com sucesso!")
      this.setState({ loading: false });
      this.props.history.push(HISTORY_BACK);
    }
    else {
      this.setState({ loading: false });
      this.props.enqueueSnackbar("Ops! Ocorreu algum erro, tente novamente mais tarde.");
    }
  }

  handleChange = (value) => {
    this.setState({ ...this.state, ...value });
  }

  render() {
    return (
      <>

        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={HISTORY_BACK}>
                    <i className="fas fa-arrow-left"></i> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={this.props.match.params.id ? "EDITAR VISITANTES" : "NOVO VISITANTES"}
          subTitulo={"Gerenciamento dos Visitantes"}
        />


        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {this.props.match.params.id ? "Atualizar Visitante" : "Cadastrar Novo Visitante"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormFields {...this} />
                    <FormButtonAction create={this.create} cancel={HISTORY_BACK} history={this.props.history} isEdit={this.props.match.params.id} />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(VisitanteCrud);
