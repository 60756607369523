import React from "react";
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col } from "reactstrap";
import Config from "Config";
import { withSnackbar } from "notistack";
import { ButtonGroup } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ButtonControl from "views/components/ButtonControl";

class FinanceiroDepartamentoCRUD extends React.Component {

  state = {
    valor: null,
  }

  componentDidMount() {
    let user = JSON.parse(window.localStorage.getItem(Config._USER))
    if (!user) {
      window.localStorage.removeItem(Config._USER)
      this.props.history.replace('/auth')
      return;
    }
    this.setState({
      _session: user,
      _uk: user._uk,
      _ukFunc: user._ukFunc,
      _func: user._func,
      _name: user._name,
    }, () => {
      if (this.props.match.params.uk) { // cenario para editar
        this.findBy()
      }
    })
  }

  findBy = () => {
    this.setState({
      loading: true,
    })
    fetch(Config._HOST + "/api/financeiro/departamento/" + this.props.match.params.uk)
      .then(response => response.json())
      .then(response => {
        this.setState({
          loading: false,
          ...response.data,
        })
      }).catch(e => {
        this.setState({
          loading: false
        })
      })
  }

  create() {
    let temp = this.state;
    if (!temp.nome || (temp.nome.trim() === "")) {
      this.props.enqueueSnackbar("Preencha o Nome Completo")
      return;
    }
    this.setState({
      loading: true,
    })
    let dataUser = JSON.stringify(this.state)
    fetch(Config._HOST + "/api/financeiro/departamento",
      {
        body: dataUser,
        method: this.props.match.params.uk ? 'PUT' : 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => response.json())
      .then(data => {
        this.props.enqueueSnackbar(data.mensagem)
        console.log("data", data)
        this.setState({
          loading: false
        }, () => {
          if (data.status === 200) {
            this.props.history.push("/admin/financeiro/departamento")
          }
        })
      }).catch(e => {
        this.setState({
          loading: false,
        })
        this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
      })
  }

  delete() {
    this.setState({
      loading: true,
    })
    let dataUser = JSON.stringify(this.state)
    fetch(Config._HOST + "/api/financeiro/departamento",
      {
        body: dataUser,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => response.json())
      .then(data => {
        this.props.enqueueSnackbar(data.mensagem)
        this.setState({
          loading: false
        }, () => {
          if (data.status === 200) {
            this.props.history.push("/admin/financeiro/departamento")
          }
        })
      }).catch(e => {
        this.setState({
          loading: false,
        })
        this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
      })
  }

  handleChange = (event, value, maskedValue) => {
    event.preventDefault();
  };

  render() {
    return (
      <>
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to='/admin/financeiro/departamento'>
                    <i className="fas fa-arrow-left" /> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"Departamento"}
          subTitulo={(this.props.match.params.uk ? "Atualizar departamento" : "Cadastrar novo departamento")} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {this.props.match.params.uk ? "Atualizar departamento" : "Cadastro de departamento"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Informações Gerais
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Nome
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder="Nome"
                              type="text"
                              onChange={(value) => {
                                this.setState({ nome: value.target.value.toUpperCase() })
                              }}
                              value={this.state.nome}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <hr className="my-4" />

                    <div className="pl-lg-4" style={{ marginTop: "10px" }}>
                      <Row>
                        {this.props.match.params.uk ? <Col lg="6">
                          <Button color="warning" style={{ width: "100%", marginBottom: 10 }} onClick={() => {
                            confirmAlert({
                              title: 'Deseja Excluir?',
                              message: '',
                              buttons: [
                                {
                                  label: 'Não',
                                },
                                {
                                  label: 'Sim',
                                  onClick: () => this.delete()
                                }
                              ]
                            })
                          }}>
                            <i className="fas fa-times" style={{ marginRight: 5 }}></i>
                            EXCLUIR</Button>
                        </Col> : <Col lg="6">
                          <Button color="warning" style={{ width: "100%" }} onClick={() => {
                            this.props.history.push("/admin/financeiro/departamento")
                          }}>
                            <i className="fas fa-arrow-left" style={{ marginRight: 5 }}></i>
                            CANCELAR</Button>
                        </Col>}
                        <Col lg="6">
                          <Button color="default" style={{ width: "100%" }} onClick={() => {
                            confirmAlert({
                              title: this.props.match.params.uk ? 'Deseja confirmar a atualização?' : 'Deseja confirmar o cadastro?',
                              message: '',
                              buttons: [
                                {
                                  label: 'Não',
                                },
                                {
                                  label: 'Sim',
                                  onClick: () => this.create()
                                }
                              ]
                            })
                          }
                          }>
                            <i className="fas fa-check" style={{ marginRight: 5 }}></i>
                            {this.props.match.params.uk ? "ATUALIZAR" : "CADASTRAR"}</Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(FinanceiroDepartamentoCRUD);
