/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import Config from "Config";

class HeaderButtonGroup extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      nome: ""
    }
  }

  componentDidMount() {
    let user = JSON.parse(window.localStorage.getItem(Config._USER))
    if (!user) {
      window.localStorage.removeItem(Config._USER)
      this.props.history.replace('/auth')
      return;
    }
    this.setState({
      _session: user,
      _uk: user._uk,
      _ukFunc: user._ukFunc,
      _func: user._func,
      _name: user._name,
    }, () => {
      if (this.props.callback) { this.props.callback(this.state) }
    })
  }

  render() {
    return (
      <>
        <div
          className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
          style={{
            minHeight: "10px",
            backgroundImage:
              "url(" + require("assets/img/capa.jpg").default + ")",
            backgroundSize: "cover",
            backgroundPosition: "center top"
          }}
        >
          <span className="mask bg-gradient-default opacity-8" />
          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col lg="12" md="12">
                {this.props.avatar}
                <h1 className="display-2 text-white">{this.props.titulo}</h1>
                <p className="text-white mt-0 mb-5">
                  {this.props.subTitulo}
                </p>
              </Col>
              <div style={{ marginLeft: 15 }}>
                {this.props.component}
              </div>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default withRouter(HeaderButtonGroup);
