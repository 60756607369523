import React, { Component } from "react";
import { withSnackbar } from "notistack";
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';


// reactstrap components
import { Card, CardHeader, Table, Container, Row } from "reactstrap";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { ButtonGroup } from "@material-ui/core";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import { jsonToUrlFilter } from "utils/filter";
import api from "services/api";
import ButtonExport from "utils/ButtonExport";
import ButtonControl from "views/components/ButtonControl";


class AgendaConvidados extends Component {

  state = {
    data: [],
    loading: false,
    listaConvidados: [],
    ambiente: { capacidadePessoas: 0 },
    usuario: {
      name: null,
      uk: null
    }
  }

  componentDidMount() {
    this.findBy(this.props.match.params.uk)
  }

  findBy = async (uk) => {
    this.setState({ loading: true });

    const response = await api.get("/api/documento-agenda/" + uk);
    const responseListaConvidados = await api.get(`/api/convidado/?documento_agenda_entity.uk=${uk}&_limit=10000&_order=asc&_sort=nome`);

    const table_header = [];
    table_header.push({ key: 'Descrição do Evento', value: !!response.data.descricao ? response.data.descricao : '' });

    if(!!response.data.autorizado) {
      table_header.push({ key: 'Responsável pela retirada da chave', value: response.data.autorizado });
    }

    table_header.push({ key: 'Sócio (a)', value: response.data.responsavel.name });
    table_header.push({ key: 'Telefone', value: response.data.responsavel.listaTelefone.length > 0 ? response.data.responsavel.listaTelefone[0].telefone : '' });
    table_header.push({ key: 'Data', value: moment(response.data.dtInicio).format('DD/MM/YYYY') });


    this.setState({
      ...response.data,
      listaConvidados: responseListaConvidados.data,
      loading: false,
      export: {
        _view: {
          title: `Lista de Convidados "${response.data.ambiente.nome}"`,
          head: 'Nome Completo,Telefone',
          body: 'nome,telefone',
          template: 2,
          table_header: JSON.stringify(table_header)
        },
        _order: 'asc',
        _sort: 'nome',
        _limit: 10000,
        documento_agenda_entity: {
          id: response.data.id
        }
      }
    });
  }

  render() {
    const { listaConvidados, ambiente, responsavel, loading, export: exportData } = this.state;
    const { uk } = this.props.match.params;

    return (
      <>
        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={`/admin/agenda/${uk}/visualizar`}>
                    <i className="fas fa-arrow-left" /> VOLTAR
                  </ButtonControl>
                  <ButtonControl
                    color='secondary'
                    to={`/admin/agenda/${uk}/convidados/novo`}
                    disabled={listaConvidados.length >= ambiente.capacidadePessoas}
                    title={listaConvidados.length >= ambiente.capacidadePessoas ? 'Lista excedeu o limite de convidados!' : null}
                  >
                    <i className="fas fa-plus"></i> NOVO
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"Convidados"} subTitulo={!!responsavel ? "Controle geral de convidados de " + responsavel.name : ''}
        />

        <Container className="mt--7" fluid style={{ paddingBottom: 100 }}>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 style={{ float: "left" }} className="mb-0 mt-margin-small-right">Convidados ({listaConvidados.length}/{ambiente.capacidadePessoas})</h3>

                  {exportData && !!listaConvidados.length && !loading && (
                    <>
                      <ButtonExport notification={this.props.enqueueSnackbar} name="Lista de Convidados" type="pdf" url={'/api/convidado/export/pdf?' + jsonToUrlFilter(this.state.export)} />
                      <ButtonExport notification={this.props.enqueueSnackbar} name="Lista de Convidados" type="excel" url={'/api/convidado/export/excel?' + jsonToUrlFilter(this.state.export)} />
                    </>
                  )}
                </CardHeader>
                {this.renderContent()}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }

  renderContent() {
    if (this.state.loading) return <BoxLoading />

    if (this.state.listaConvidados.length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Não existem convidados cadastrados</h3>
        </CardHeader>
      )
    }

    return (
      <div>
        <Table className="align-items-center table-flush table-with-button" responsive>
          <thead className="thead-light">
            <tr>
              <th>Nome</th>
              <th>Telefone</th>
              <th>DATA CADASTRO</th>
              <th>AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {this.state.listaConvidados.map(single => (
              <tr key={single.id}>
                <td title={single.nome}>{single.nome}</td>
                <td>{single.telefone ? single.telefone : "-"}</td>
                <td>{moment(single.dtCadastro).format('DD/MM/YYYY HH:mm')}</td>
                <td>
                  <div className="avatar-group">
                    <ButtonControl color='secondary' to={`/admin/agenda/${this.props.match.params.uk}/convidados/${single.uk}/editar`}>
                      <i className="fas fa-edit"></i>
                    </ButtonControl>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}

export default withSnackbar(AgendaConvidados);
