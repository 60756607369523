import { Component } from 'react';
import { Button, Card, CardBody, Col, Input, PopoverBody, Row, UncontrolledCollapse, UncontrolledPopover } from 'reactstrap';
import routes from 'routes';

export default class FormFields extends Component {

  render() {
    const { handleChecked, handleChangePermissaoWeb, handleChange, state, handleSelectAll, handleUnselectAll} = this.props;

    return (
      <>
        <Row>
          <Col lg="6">
            <Input name="nome" label="Nome" type="text" defaultValue={state.descricao} autofocus={true} onChange={(e)=>handleChange({descricao:e.target.value})}/>
          </Col>
          <Col lg="6">
          </Col>
        </Row>

        <div className="accordion mt-padding-small-top">
          {routes.map((route, index) => {
            if(!('children' in route)) return false
            return (
              <div key={route.nome}>
                <Row className="mt-margin-xsmall-top">
                <Button color="default" className="text-left" id={`option-${index}`}>
                  {route.nome}
                </Button>
                </Row>
                <UncontrolledCollapse toggler={`#option-${index}`} defaultClosed>
                  <Row>
                    <Col lg="3">
                      <Button color="primary" type="button" onClick={()=>handleSelectAll(route)}>
                        <i className="fas fa-check"></i> Selecionar Todos
                      </Button>
                    </Col>
                    <Col lg="3">
                      <Button color="gray" type="button" onClick={()=>handleUnselectAll(route)}>
                        <i className="fas fa-check"></i> Desmarcar Todos
                      </Button>
                    </Col>
                  </Row>
                  <Col lg="3">
                  </Col>
                  <Card color="secondary">
                    <CardBody>
                      <Row key={index}>
                        <label class="custom-toggle custom-toggle-success">
                          <input
                            type="checkbox" checked={handleChecked(route.layout+route.path)}
                            onChange={()=>handleChangePermissaoWeb(route.layout+route.path)}
                          />
                          <span class="custom-toggle-slider rounded-circle"></span>
                        </label>
                        <span id={`tooltip-${index}`}>
                          Página { route.nome }
                        </span>
                        <UncontrolledPopover className="accordion-tooltip" trigger="legacy" placement="right" target={`tooltip-${index}`}>
                          <PopoverBody>
                            { route.nome }
                          </PopoverBody>
                        </UncontrolledPopover>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      {route.children.map((propAction, key) => {
                        return (
                          <Row key={index + "-" + key}>
                            <label class="custom-toggle custom-toggle-success">
                              <input
                                type="checkbox" checked={handleChecked(route.layout+route.path+propAction.path)}
                                onChange={()=>handleChangePermissaoWeb(route.layout+route.path+propAction.path)}
                              />
                              <span class="custom-toggle-slider rounded-circle"></span>
                            </label>
                            <span id={`tooltip-${index + "-" + key}`}>
                              { propAction.nome }
                            </span>
                            <UncontrolledPopover className="accordion-tooltip" trigger="legacy" placement="right" target={`tooltip-${index + "-" + key}`}>
                              <PopoverBody>
                                {propAction.nome}
                              </PopoverBody>
                            </UncontrolledPopover>
                          </Row>
                        )
                      })}
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </div>
            )
          })}
        </div>
      </>
    );
  }
}
