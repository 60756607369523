/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Card, CardHeader, CardBody, Form, Container, Row, Col } from "reactstrap";
import Config from "Config";
import { withSnackbar } from "notistack";
import { ButtonGroup } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import FormFields from "./Form/FormFields";
import FormButtonAction from "views/components/FormButtonAction";
import { handleUploadArrayFile } from "utils/file";
import api from "services/api";
import { handleDeleteFile } from "utils/file";
import { jsonToUrlFilter } from "utils/filter";
import { BoxLoading } from "react-loadingg";
import ButtonControl from "views/components/ButtonControl";

const HISTORY_BACK = '/admin/deliberacao-diretoria';
const ROUTE_API = '/api/deliberacao-diretoria';

class DeliberacaoDiretoriaCrud extends Component {
  state = {
    tipoReuniao: "Ordinária",
    tipoOrgao: "Executivo",
    files: [],
    filesRemovidos: []
  }

  async componentDidMount() {
    let user = JSON.parse(window.localStorage.getItem(Config._USER));

    if (!user) {
      window.localStorage.removeItem(Config._USER);
      this.props.history.replace('/auth');
      return;
    }

    if (this.props.match.params.id)
      this.findBy(this.props.match.params.id);

    this.setState({ session: user });
  }

  findBy = async (id) => {
    this.setState({ loading: true })

    let response = await api.get(`${ROUTE_API}/${id}`);
    this.setState(response);
    let responseFiles = await api.get(`/api/bucket?${jsonToUrlFilter({ owner: response.uk })}`);
    this.setState({ files: responseFiles.data, loading: false })
  }

  create = async (id) => {
    let files = this.state.files;

    if (files.length === 0) {
      this.props.enqueueSnackbar("Envie um arquivo!");
      return;
    }

    if (!this.state.data || this.state.data.length === 0) {
      this.props.enqueueSnackbar("Campo data não preenchido!");
      return;
    }

    if (!this.state.observacao || this.state.observacao.length === 0) {
      this.props.enqueueSnackbar("Campo observação não preenchido!");
      return;
    }

    await handleDeleteFile(this.state.filesRemovidos);
    let temp = this.state;
    temp.files = [];

    this.setState({ loading: true });
    let response = null;

    if(id)
      response = await api.put(`${ROUTE_API}/${id}`, temp);
    else
      response = await api.post(`${ROUTE_API}/`, temp);

    if (files.length > 0) {
      this.props.enqueueSnackbar(await handleUploadArrayFile(response.uk, 'documento', files) === 200 ? "Upload Realizada com sucesso" : "Falha no upload do arquivo.")
    }

    this.props.enqueueSnackbar(id ? "Atualizado com sucesso!" : "Cadastrado com sucesso!");

    this.setState({ loading: false });
    this.props.history.push(HISTORY_BACK);
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  }

  render() {
    const { loading } = this.state;

    return (
      <>
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={HISTORY_BACK}>
                    <i className="fas fa-arrow-left"></i> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={this.props.match.params.id ? "EDITAR DELIBERAÇÃO DA DIRETORIA" : "NOVO DELIBERAÇÃO DA DIRETORIA"}
          subTitulo={"Gerenciamento da Deliberação da Diretoria"}
        />


        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {this.props.match.params.id ? "Atualizar Deliberação da diretoria" : "Cadastrar Novo Deliberação da diretoria"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <BoxLoading />
                  ) : (
                    <Form>
                      <FormFields {...this} />
                      <FormButtonAction create={() => this.create(this.props.match.params.id)} cancel={HISTORY_BACK} history={this.props.history} isEdit={this.props.match.params.id} />
                    </Form>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(DeliberacaoDiretoriaCrud);
