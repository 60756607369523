import React from "react";
import Config from "Config";
import { withSnackbar } from "notistack";
import 'react-big-calendar/lib/css/react-big-calendar.css';

import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { ButtonGroup } from "@material-ui/core";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import ButtonControl from "views/components/ButtonControl";
import { Card, CardHeader, Table, Container, Row } from "reactstrap";

// const { width, height } = { width: window.innerWidth, height: window.innerHeight }

class Ambiente extends React.Component {
  state = {
    data: [],
    dataComplete: [],
    loading: true
  };

  componentDidMount() {
    this.findAll()
  }

  findAll = () => {
    this.setState({ loading: true });

    fetch(Config._HOST + "/api/ambiente?_limit=9999&_sort=nome")
      .then(response => response.json())
      .then(response => {
        this.setState({
          loading: false,
          data: response.data,
          dataComplete: response.status === 200 ? response.data : []
        })
      }).catch(e => {
        this.setState({ loading: false })
      })
  }

  render() {
    return (
      <>
        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to='/admin/ambientes/novo'>
                    <i className="fas fa-plus"></i> NOVO
                  </ButtonControl>
                  <ButtonControl color='secondary' to='/admin/ambientes/setores'>
                    Setores
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"AMBIENTES"} subTitulo={"Controle geral de Ambientes"}
        />

        <Container className="mt--7" fluid style={{ paddingBottom: 100 }}>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Total ({this.state.data.length})</h3>
                </CardHeader>
                {this.renderContent()}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }

  renderContent() {
    if (this.state.loading) return <BoxLoading />

    if (this.state.data.length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Não existem ambientes cadastradas</h3>
        </CardHeader>
      );
    }

    return (
      <div>
        <Table className="align-items-center table-flush table-with-button" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">NOME</th>
              <th scope="col">DESCRIÇÃO</th>
              <th scope="col">LOCAÇÃO</th>
              <th scope="col">LOCAÇÃO PELO APP</th>
              <th scope="col">ATIVO</th>
              <th scope="col">AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map(single => {
              return (
                <tr key={single.id}>
                  <td>{single.nome}</td>
                  <td>{single.descricao ? single.descricao : "-"}</td>
                  <td>{!!single.permitirLocacao ? 'Sim' : "Não"}</td>
                  <td>{!!single.permitirLocacaoApp ? 'Sim' : "Não"}</td>
                  <td>{!!single.ativo ? 'Sim' : "Não"}</td>
                  <td>
                    <div className="avatar-group">
                      <ButtonControl color='secondary' to={`/admin/ambientes/${single.id}/editar`}>
                        <i className="fas fa-edit"></i>
                      </ButtonControl>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    )
  }
}

export default withSnackbar(Ambiente);
