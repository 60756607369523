import { FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

export default class Termos extends Component {
  render() {
    const { state, handleChange } = this.props;

    return (
      <div className="mt-margin-left mt-margin-right">
        <Row>
          <Col lg="8">
            <FormControl variant="outlined">
              <label className="form-control-label">Termo de Reserva e Utilização</label>
              <Autocomplete
                options={state.pdfs}
                getOptionLabel={option => option.nome}
                onChange={(_, value) => handleChange("termoReserva", value)}
                inputMode="search"
                value={state.termoReserva}
                multiple={false}
                renderOption={option => <span> {option.codigo} {option.nome} </span>}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Selecione um PDF"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {/* {state.loadCategorias ? <CircularProgress color="inherit" size={20} /> : null} */}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Col>
        </Row>
      </div>
    );
  }
}
