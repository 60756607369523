/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { ButtonGroup, CircularProgress, FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import Config from "Config";
import moment from 'moment';
import { withSnackbar } from "notistack";
import React from "react";
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Row } from "reactstrap";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import { jsonToUrlFilter } from "utils/filter";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'rodal/lib/rodal.css';
import ButtonControl from "views/components/ButtonControl";


const HISTORY_BACK = '/admin/manutencoes/ocorrencias';

class ManutencaoOcorrenciaNovo extends React.Component {
  state = {
    manutencoes: [],
    setores: [],
    ambientes: [],
    responsaveis: [],
    dataUsers: []
  }

  componentDidMount() {
    let user = JSON.parse(window.localStorage.getItem(Config._USER))

    if (!user) {
      window.localStorage.removeItem(Config._USER)
      this.props.history.replace('/auth')
      return;
    }

    fetch(Config._HOST + `/api/manutencao`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          manutencoes: response.data,
        })
      })

    fetch(Config._HOST + "/api/categoria")
      .then(response => response.json())
      .then(response => {
        this.setState({
          responsaveis: response.status === 200 ? response.data : [],
        })
      });

    fetch(Config._HOST + "/api/ambiente?_limit=9999")
      .then(response => response.json())
      .then(response => {
        this.setState({
          ambientes: response.data
        })
      });

    if (this.props.match.params.id) {
      this.findBy()
    }
  }

  findBy = () => {
    this.setState({ loading: true })
    const { id } = this.props.match.params;

    fetch(Config._HOST + `/api/manutencao/ocorrencia/${id}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          loading: false,
          ...response,
          manutencao: response.manutencao.uk,
          ambiente: response.setor.ambiente.uk,
          setor: response.setor.uk,
          setores: [response.setor]
        })
      })
      .catch(e => {
        this.setState({ loading: false })
      })
  }

  create = () => {
    let temp = this.state

    this.setState({ loading: true })
    const id = this.props.match.params.id !== undefined ? this.props.match.params.id : '';

    temp["manutencao"] = this.state.manutencoes.filter(single => single.uk === this.state.manutencao)
    temp["manutencao"] = temp["manutencao"] && temp["manutencao"].length ? temp["manutencao"][0] : temp["manutencao"]

    temp["ambiente"] = this.state.manutencoes.filter(single => single.uk === this.state.ambiente)
    temp["ambiente"] = temp["ambiente"] && temp["ambiente"].length ? temp["ambiente"][0] : temp["ambiente"]

    temp["setor"] = this.state.setores.filter(single => single.uk === this.state.setor)
    temp["setor"] = temp["setor"] && temp["setor"].length ? temp["setor"][0] : temp["setor"]

    temp["dt_inicio"] = moment(this.state.dt_inicio).format('YYYY-MM-DD') + "T" + moment(this.state.dt_inicio).format('HH:mm') + ":00";
    temp["dt_termino"] = moment(this.state.dt_termino).format('YYYY-MM-DD') + "T" + moment(this.state.dt_termino).format('HH:mm') + ":00";

    let dataUser = JSON.stringify(temp)

    fetch(Config._HOST + `/api/manutencao/ocorrencia/${id}`,
      {
        body: dataUser,
        method: id ? 'PUT' : 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => response.json())
      .then(data => {
        this.props.enqueueSnackbar(data ? (id ? "Atualizado com sucesso!" : "Cadastrado com sucesso!") : "Ops, ocorreu um erro!")
        this.setState({ loading: false }, () => {
          this.props.history.push(HISTORY_BACK)
        })
      })
      .catch(e => {
        this.setState({ loading: false })
        this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
      })
  }

  onChangeAmbiente = (value) => {
    this.setState({ ambiente: value.target.value.toUpperCase() });
    this.findSetoresAmbiente(value.target.value.toUpperCase());
  }

  findSetoresAmbiente = (ambiente) => {
    fetch(Config._HOST + `/api/manutencao/setor/ambiente/${ambiente}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          setores: response.data,
        })
      });
  }

  searchUser = (search, field) => {
    if (!search) return;
    this.setState({ loadSelect: true });

    const params = {
      _limit: 100,
      name_like: search,
      profile: {
        id: [2, 3]
      }
    };

    fetch(Config._HOST + `/api/user?${jsonToUrlFilter(params)}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          [field]: 'data' in response ? response.data : [],
          loadSelect: false,
        })
      }).catch(e => {
        this.setState({ loadSelect: false });
      })
  }

  render() {
    return (
      <>

        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={HISTORY_BACK}>
                    <i className="fas fa-arrow-left"></i> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={this.props.match.params.id ? "EDITAR OCORRÊNCIA" : "NOVA OCORRÊNCIA"}
          subTitulo={"Gerenciamento completo das Ocorrências"}
        />


        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {this.props.match.params.id ? "Atualizar Ocorrência" : "Cadastrar Nova Ocorrência"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>

                    <div className="mt-margin-left mt-padding-right">
                      <Row>
                        <Col lg="3">
                          <FormControl className="mt-margin-top" variant="outlined">
                            <label className="form-control-label">Status</label>
                            <Select
                              name="status"
                              className="mt-select"
                              placeholder="Status"
                              onChange={value => { this.setState({ status: value.target.value }) }}
                              value={this.state.status ? this.state.status : "Agendado"}
                            >
                              <MenuItem value="Agendado">Agendado</MenuItem>
                              <MenuItem value="Iniciado">Iniciado</MenuItem>
                              <MenuItem value="Atrasado">Atrasado</MenuItem>
                              <MenuItem value="Concluído">Concluído</MenuItem>
                            </Select>
                          </FormControl>
                        </Col>
                        <Col lg="3">
                          <FormControl className="mt-margin-top" variant="outlined">
                            <label className="form-control-label">Manutenção</label>
                            <Select
                              name="manutencao"
                              className="mt-select"
                              placeholder="Manutenção"
                              defaultValue={this.state.manutencao ? this.state.manutencao : 0}
                              onChange={value => { this.setState({ manutencao: value.target.value }) }}
                              value={this.state.manutencao ? this.state.manutencao : 0}
                            >
                              <MenuItem value="0">Selecione uma opção</MenuItem>
                              {this.state.manutencoes.map((value) => (
                                <MenuItem value={value.uk}>
                                  {value.categoria.nome} {value.descricao ? (' - ' + value.descricao) : ''}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Col>
                        <Col lg="3">
                          <FormControl className="mt-margin-top" variant="outlined">
                            <label className="form-control-label">Ambiente</label>
                            <Select
                              name="ambiente"
                              className="mt-select"
                              placeholder="Ambiente"
                              defaultValue={this.state.ambiente ? this.state.ambiente : 0}
                              onChange={value => { this.onChangeAmbiente(value) }}
                              value={this.state.ambiente ? this.state.ambiente : 0}
                            >
                              <MenuItem value="0">Selecione uma opção</MenuItem>
                              {this.state.ambientes.map((value, key) => (
                                <MenuItem key={key} value={value.uk}>
                                  {value.nome}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Col>
                        <Col lg="3">
                          <FormControl className="mt-margin-top" variant="outlined">
                            <label className="form-control-label">Setor</label>
                            <Select
                              name="setor"
                              className="mt-select"
                              placeholder="Setor"
                              defaultValue={this.state.setor ? this.state.setor : 0}
                              onChange={value => { this.setState({ setor: value.target.value }) }}
                              value={this.state.setor ? this.state.setor : 0}
                            >
                              <MenuItem value="0">Selecione uma opção</MenuItem>

                              {this.state.setores && this.state.setores.map((value, key) => (
                                <MenuItem key={key} value={value.uk}>
                                  {value.nome}
                                </MenuItem>
                              ))}

                            </Select>
                          </FormControl>
                        </Col>
                        <Col lg="12">
                          <FormControl className="mt-margin-top">
                            <Label>Descrição</Label>
                            <Input
                              name="descricao"
                              type="textarea"
                              rows="5"
                              placeholder="Descrição"
                              onChange={value => { this.setState({ descricao: value.target.value.toUpperCase() }) }}
                              value={this.state.descricao ? this.state.descricao : ''}
                            />
                          </FormControl>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="mt-margin-top">
                            <label className="form-control-label">Responsável</label>
                            <Autocomplete
                              options={this.state.dataUsers}
                              getOptionLabel={(option) => option.name}
                              onChange={(_, value) => this.setState({ "responsavel": value })}
                              onInputChange={(_, value) => this.searchUser(value, "dataUsers")}
                              inputMode="text"
                              key="responsavel"
                              loading={this.state.loadSelect}
                              value={this.state.responsavel ? this.state.responsavel : null}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Buscar pelo nome"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {this.state.loadSelect ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormControl className="mt-margin-top">
                            <label className="form-control-label">
                              Data Início
                            </label>
                            <Input
                              type="datetime-local"
                              format="YYYY-MM-DD"
                              name="dt_inicio"
                              placeholder="Data Início"
                              value={this.state.dt_inicio}
                              onChange={(value) => {
                                if (moment(value.target.value.toUpperCase()).isValid())
                                  this.setState({ dt_inicio: value.target.value.toUpperCase() })
                              }}
                              className="form-control"
                            />
                          </FormControl>
                        </Col>
                        <Col lg="3">
                          <FormControl className="mt-margin-top">
                            <label className="form-control-label">
                              Data Término
                            </label>
                            <Input
                              type="datetime-local"
                              format="YYYY-MM-DD"
                              name="dt_termino"
                              placeholder="Data Término"
                              value={this.state.dt_termino}
                              onChange={(value) => {
                                if (moment(value.target.value.toUpperCase()).isValid())
                                  this.setState({ dt_termino: value.target.value.toUpperCase() })
                              }}
                              className="form-control"
                            />
                          </FormControl>
                        </Col>
                        <Col lg="4">
                          <FormControl className="mt-margin-top">
                            <label className="form-control-label">Intervalo</label>
                            <Input
                              name="intervalo"
                              type="number"
                              placeholder="Intervalo"
                              onChange={value => { this.setState({ intervalo: value.target.value.toUpperCase() }) }}
                              value={this.state.intervalo ? this.state.intervalo : '1'}
                            />
                          </FormControl>
                        </Col>
                        <Col lg="4">
                          <FormControl className="mt-margin-top" variant="outlined">
                            <label className="form-control-label">Tipo Intervalo</label>
                            <Select
                              name="tipo_intervalo"
                              className="mt-select"
                              placeholder="Tipo Intervalo"
                              onChange={value => { this.setState({ tipo_intervalo: value.target.value }) }}
                              value={this.state.tipo_intervalo ? this.state.tipo_intervalo : "Meses"}
                            >
                              <MenuItem value="Horas">Horas</MenuItem>
                              <MenuItem value="Dia">Dia</MenuItem>
                              <MenuItem value="Meses">Meses</MenuItem>
                              <MenuItem value="Anos">Anos</MenuItem>
                            </Select>
                          </FormControl>
                        </Col>
                        <Col lg="4">
                          <FormControl className="mt-margin-top">
                            <Label>Contrato</Label>
                            <Input
                              name="contrato"
                              type="text"
                              placeholder="Contrato"
                              onChange={value => { this.setState({ contrato: value.target.value.toUpperCase() }) }}
                              value={this.state.contrato ? this.state.contrato : ''}
                            />
                          </FormControl>
                        </Col>
                        <Col lg="12">
                          <FormControl className="mt-margin-top">
                            <Label>Observações</Label>
                            <Input
                              name="observacoes"
                              type="textarea"
                              rows="5"
                              placeholder="Observações"
                              onChange={value => { this.setState({ observacoes: value.target.value.toUpperCase() }) }}
                              value={this.state.observacoes ? this.state.observacoes : ''}
                            />
                          </FormControl>
                        </Col>
                      </Row>
                    </div>

                    <hr className="my-4 mt-margin-large-top" />

                    <div className="mt-margin-left mt-padding-right" style={{ marginTop: "10px" }}>
                      <Row>
                        <Col lg="12">
                          <Button color="default" style={{ width: "100%" }} onClick={() => {
                            confirmAlert({
                              title: this.props.match.params.id
                                ? 'Deseja confirmar a atualização?'
                                : 'Deseja confirmar o cadastro?',
                              message: '',
                              buttons: [
                                { label: 'Não' },
                                { label: 'Sim', onClick: this.create }
                              ]
                            })
                          }
                          }>
                            <i className="fas fa-check" style={{ marginRight: 5 }} />
                            {this.props.match.params.id ? "ATUALIZAR" : "CADASTRAR"}
                          </Button>

                        </Col>
                      </Row>

                      <Row className="mt-margin-small-top">
                        <Col lg="12">
                          <Button color="warning" style={{ width: "100%" }} onClick={() => { this.props.history.push(HISTORY_BACK) }}>
                            CANCELAR
                          </Button>
                        </Col>
                      </Row>
                    </div>

                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(ManutencaoOcorrenciaNovo);
