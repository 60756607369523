import Config from 'Config';
import moment from "moment";
import React, { Component } from 'react';
import { CardHeader } from "reactstrap";

export default class TimeLine extends Component {
  render() {
    const user = JSON.parse(window.localStorage.getItem(Config._USER));

    return (
      <div>
        <CardHeader className="border-0">
          <h3 className="mb-0" style={{ textAlign: "center" }}>
            {this.props.respostas.length > 0 ? (
              <p>
                {this.props.msgTitulo} <i className="far fa-smile-beam"></i>
              </p>
            ) : (
              <p>
                {this.props.msgEmpty} <i className="far fa-frown"></i>
              </p>
            )}
          </h3>

          <div>
            {this.props.respostas.length > 0 && this.props.respostas.map(single => (
              <div style={{ margin: '20px' }}>
                {single.usuario.id === user.id ? (
                  <div style={{ margin: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <div style={{ background: '#ccdeff', width: 'fit-content', padding: '12px', borderRadius: '12px', whiteSpace: 'break-spaces' }}>{single.mensagem}</div>
                    </div>
                    <div style={{ fontSize: '14px', color: '#949494', textAlign: 'right', marginTop: '3px' }}>
                      {moment(single.data).format('DD/MM/YYYY') + ' às ' + moment(single.data).format('HH:mm')}
                    </div>
                  </div>
                ) : (
                  <div style={{ margin: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <div style={{ background: '#e1e1e1', width: 'fit-content', padding: '12px', borderRadius: '12px', whiteSpace: 'break-spaces' }}>{single.mensagem}</div>
                    </div>
                    <div style={{ fontSize: '14px', color: '#949494', marginTop: '3px' }}>
                      {moment(single.data).format('DD/MM/YYYY') + ' às ' + moment(single.data).format('HH:mm')}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

        </CardHeader>
      </div>
    );
  }
}
