import { CircularProgress, FormControl, MenuItem, Select } from '@material-ui/core';
import React, { Component } from 'react';
import { Col, FormGroup, Input, InputGroup, Row } from 'reactstrap';
import ModalEmail from './Modal/Email';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import Config from 'Config';
import cpfMask from 'utils/cpfMask';
import cnpjMask from 'utils/cnpjMask';

export default class InformacoesGerais extends Component {
  searchVeiculo = (search) => {
    if (!search) {
      return
    }

    const { handleChange } = this.props;
    handleChange({ loadSelect: true });

    fetch(Config._HOST + `/api/veiculo?placa_like=${search}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(response => {
        handleChange({
          dataVeiculos: response.data,
          loadSelect: false,
        })
      }).catch(e => {
        handleChange({ loadSelect: false })
      })
  }

  render() {
    const { state, handleChange, props, isDependente } = this.props;
    const { uk } = props.match.params;

    return (
      <div className="mt-margin-left mt-margin-right">
        <Row>
          {!(isDependente || state.isNaoSocio) && (
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">Número do Título</label>
                <Input
                  className="form-control"
                  placeholder="Número do Título"
                  type="number"
                  onChange={(value) => handleChange({ numeroTitulo: value.target.value.toUpperCase() })}
                  value={state.numeroTitulo ? state.numeroTitulo : ''}
                  disabled={!!uk}
                />
              </FormGroup>
            </Col>
          )}
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label">Nome Completo</label>
              <Input
                className="form-control"
                placeholder="Nome Completo"
                type="text"
                onChange={(value) => handleChange({ name: value.target.value.toUpperCase() })}
                value={state.name ? state.name : ''}
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label">CPF/CNPJ</label>
              <Input
                className="form-control"
                id="input-last-name"
                placeholder="CPF ou CNPJ"
                type="text"
                onChange={(value) => {
                  if (value.target.value.length <= 18) {
                    handleChange({
                      cpfOrcnpj: value.target.value.length <= 14 ? cpfMask(value.target.value) : cnpjMask(value.target.value)
                    })
                  }
                }}
                value={state.cpfOrcnpj ? state.cpfOrcnpj : ''}
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label">E-mail</label>
              <InputGroup>
                <Input
                  className="form-control"
                  placeholder="E-mail"
                  type="text"
                  onChange={(value) => handleChange({ email: value.target.value.toLowerCase() })}
                  value={state.email ? state.email : ''}
                />
                <div className="input-group-prepend">
                  <ModalEmail />
                </div>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label">Senha</label>
              <Input
                className="form-control"
                placeholder="Senha"
                type="text"
                onChange={(value) => handleChange({ senha: value.target.value })}
                value={state.senha ? state.senha : ''}
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label">Data de Nascimento</label>
              <Input
                type="date"
                format="YYYY-MM-DD"
                id="start_date"
                name="start_date"
                value={state._dtNascimento ? state._dtNascimento : ''}
                onChange={(value) => handleChange({ _dtNascimento: value.target.value.toUpperCase() })}
                className="form-control"
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <label className="form-control-label">Sexo</label>
                {state.sexo || !props.match.params.uk ? (
                  <Select
                    defaultValue={state.sexo ? state.sexo : 0}
                    placeholder={"Sexo"}
                    onChange={(value) => handleChange({ sexo: value.target.value })}
                    value={state.sexo ? state.sexo : 0}
                  >
                    <MenuItem value={0}>Selecione</MenuItem>
                    <MenuItem value={1}>Masculino</MenuItem>
                    <MenuItem value={2}>Feminino</MenuItem>
                    <MenuItem value={3}>Outro</MenuItem>
                  </Select>
                ) : <p />}
              </FormControl>
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <label className="form-control-label">Estado Civil</label>
                {state.estadoCivil || !props.match.params.uk ? (
                  <Select
                    defaultValue={state.estadoCivil ? state.estadoCivil : 0}
                    placeholder={"Estado Civil"}
                    onChange={(value) => handleChange({ estadoCivil: value.target.value })}
                    value={state.estadoCivil ? state.estadoCivil : 0}
                  >
                    <MenuItem value={0}>Selecione</MenuItem>
                    <MenuItem value={1}>Solteiro</MenuItem>
                    <MenuItem value={2}>Relação Estável</MenuItem>
                    <MenuItem value={3}>Casado</MenuItem>
                    <MenuItem value={4}>Divorciado</MenuItem>
                    <MenuItem value={5}>Separado</MenuItem>
                    <MenuItem value={6}>Viúvo</MenuItem>
                  </Select>
                ) : <p />}
              </FormControl>
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label">RG</label>
              <Input
                className="form-control"
                placeholder="RG"
                type="text"
                onChange={(value) => handleChange({ rg: value.target.value.toUpperCase() })}
                value={state.rg ? state.rg : ''}
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label">Orgão Expedidor</label>
              <Input
                className="form-control"
                placeholder="Orgão Expedidor"
                type="text"
                onChange={(value) => handleChange({ orgaoExpedidor: value.target.value.toUpperCase() })}
                value={state.orgaoExpedidor ? state.orgaoExpedidor : ''}
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label">Data Expedição</label>
              <Input
                type="date"
                id="start_date"
                name="start_date"
                value={state._dtExpedicao ? state._dtExpedicao : ''}
                onChange={(value) => handleChange({ _dtExpedicao: value.target.value.toUpperCase() })}
                className="form-control"
              />
            </FormGroup>
          </Col>
        </Row>
        {!state.isNaoSocio && (
          <Row>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label">
                  Veículos
                </label>
                <Autocomplete
                  options={state.dataVeiculos}
                  getOptionLabel={(option) => option.placa}
                  onChange={(_, value) => handleChange({ veiculo: value })}
                  onInputChange={(_, value) => this.searchVeiculo(value)}
                  inputMode="text"
                  key="veiculo"
                  loading={state.loadSelect}
                  value={state.veiculo}
                  multiple={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Buscar pela placa"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {state.loadSelect ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}
