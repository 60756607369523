export default class Config {
  static _HOST = process.env.REACT_APP_API_URL;
  static _USER = "_USERKEY";
  static _TOKEN = "_TOKEN";
  static _USERKEY_CONTACT = "_USERKEY_CONTACT";
  static FOTO_CAPA = 'foto-capa'
  static ANEXO = "anexo";
}

export const getToken = () => {
  let _TOKEN = window.localStorage.getItem(Config._TOKEN);
  if (_TOKEN) return JSON.parse(window.localStorage.getItem(Config._TOKEN));
  return null
}

export const getUser = () => {
  let _USER = window.localStorage.getItem(Config._USER);
  if (_USER) return JSON.parse(window.localStorage.getItem(Config._USER));
  return null
}

export const authHeader = () => {
  let token = getToken();
  let headers = {}
  headers["Accept"] = 'application/json'
  headers["Content-Type"] = 'application/json'
  if (token) headers["Authorization"] = `Bearer ${token}`;
  return headers
}

export const authHeaderBucket = () => {
  let token = getToken();
  let headers = {}
  if (token) headers["Authorization"] = `Bearer ${token}`;
  return headers
}

export const statusPagamento = (single) => {
  return (single && single.length) ? !(single.filter(v => v.status === 0).length > 0) : false
}

export const existIn = (key, values) => {
  if (!values || !key || !values.length) return;
  for (let single of values) {
    if (single === key) {
      return true
    }
  }
  return false
}

export const _isEmpty = (valorCampo) => {
  if (valorCampo == null) {
    return true
  }
  return String(valorCampo).trim().length <= 0
}

export const IncodeText = (text, size) => {
  if (_isEmpty(text)) {
    return
  }
  let str = ""
  for (let i = 0; i <= text.length - 1; i++) {
    str = str + text[i];
    if (i >= size) {
      break;
    }
  }
  return str + (str.length >= size ? "..." : '');
}

// export const ICON = "http://www.clubeatiradores.com.br/img/logoHeader.png"
export const ICON = require("assets/img/" + process.env.REACT_APP_LOGO).default

export const DataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(',')
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++)
    ia[i] = byteString.charCodeAt(i)

  return new Blob([ia], { type: mimeString })
}

export const TranslaterDayOfMonth = (day) => {
  switch (day) {
    case 1: return "SEGUNDA-FEIRA";
    case 2: return "TERÇA-FEIRA";
    case 3: return "QUARTA-FEIRA";
    case 4: return "QUINTA-FEIRA";
    case 5: return "SEXTA-FEIRA";
    case 6: return "SÁBADO";
    case 7: return "DOMINGO";
    default: return "";
  }
}

export const parsePercentual = (data) => {
  let total = 0
  for (let single of data) {
    total = total + single;
  }
  let temp = []
  for (let single of data) {
    temp.push(
      single * 100 / total
    )
  }
  return temp;
}

export const parsePercentualByIndex = (index, data) => {
  let total = 0
  for (let single of data) {
    total = total + single;
  }
  let temp = []
  for (let single of data) {
    temp.push(
      single * 100 / total
    )
  }
  return temp[index];
}

export const CACHE_KEY_AGENDA = "CACHE_KEY_AGENDA"

export let createFilter = (chave, compart, dataComplete) => {
  if (!chave || chave.length === 0 || chave.trim() === "") {
    return dataComplete
  }
  let temp = []
  for (let single of compart) {
    for (let conten of dataComplete) {
      if (conten[single] && conten[single].toLowerCase().match(chave.toLowerCase())) {
        temp.push(conten)
      }
    }
  }
  return temp
}

export const isEmpty = (valor, valorDefault) => {
  if (!valor || valor.length === 0) {
    return valorDefault
  }
  return valor ? valor : ""
}

export const existValue = (valor) => {
  return !(!valor || valor.length === 0)
}

export const validateEmail = (email) => {
  const expression = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return expression.test(String(email).toLowerCase())
}

export const b64toBlob = (dataURI) => {
  var byteString = atob(dataURI.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
}

export const checkPerfilPossuiAcesso = (json, menu) => {
  return json.some(item => item.nome === menu);
}

export const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export const onChangeCheckbox = (value, campo, that) => {
  const { checked, data, checkAll } = that.state;
  if (campo === 'select-all') {
    if (checkAll) {
      const list = checked;
      data.map(single => {
        list.push(single.uk);
        return true;
      })
      that.setState({
        checkAll: false,
        checked: list
      })
    } else {
      const list = [];
      that.setState({
        checkAll: true,
        checked: list
      })
    }
  } else {
    value = !checked.some(key => key === campo);
    if (value) {
      const list = checked;
      list.push(campo);
      that.setState({
        checked: list
      })
    } else {
      that.setState({
        checked: checked.filter(key => key !== campo)
      })
    }
  }
}

export const getDescricaoModoManutencao = (modo) => {
  if (modo === "1") {
    return "Pontual";
  } else {
    return "Recorrente";
  }
};

export const getDescricaoTipoIntervalo = (intervalo) => {
  if (intervalo === "1") {
    return "Dia";
  } else if (intervalo === "2") {
    return "Mês";
  } else {
    return "Ano";
  }
};

export const replaceSpecialChars = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // Remove acentos
    .replace('&', 'e')
    .replace(/([^\w]+|\s+)/g, ' ')
    .replace(/\\-\\-+/g, '')	// Substitui multiplos hífens por um único hífen
    .replace(/(^-+|-+$)/, '') // Remove hífens extras do final ou do inicio da string
    .toUpperCase()
}


export const findNode = (id, currentNode) => {
  var result;

  if (currentNode === undefined) return null;

  if (currentNode && typeof currentNode === 'object' && 'id' in currentNode && id === currentNode.id) {
    return currentNode;
  } else {
    if (Array.isArray(currentNode)) {
      for (let i = 0; i < currentNode.length; i++) {
        const currentChild = currentNode[i];
        result = findNode(id, currentChild);

        if (result !== false) {
          return result;
        }
      };
    }
    else if (typeof currentNode === 'object') {
      for (const key in currentNode) {
        result = findNode(id, currentNode[key]);

        if (result !== false) {
          return result;
        }
      }
    }

    return false;
  }
}
