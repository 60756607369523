import React from "react";

const DescricaoStatusPagamento = ({ status }) => {
  switch (parseInt(status, 10)) {
    case 0:
      return <span><i className="far fa-clock mt-margin-xsmall-right" style={{ color: 'darkblue' }}></i>{'Aguardando Pagamento'}</span>;
    case 1:
      return <span><i className="fas fa-check-circle mt-margin-xsmall-right" style={{ color: 'green' }}></i>{'Pagamento Confirmado'}</span>;
    case 2:
      return <span><i className="fas fa-times-circle mt-margin-xsmall-right" style={{ color: 'red' }}></i>{'Boleto Cancelado'}</span>;
    case 3:
      return <span><i className="fas fa-exclamation-triangle mt-margin-xsmall-right" style={{ color: 'darkorange' }}></i>{'Boleto Vencido'}</span>;
    case 4:
      return <span><i className="fas fa-gavel mt-margin-xsmall-right" style={{ color: 'orange' }}></i>{'Em Acordo'}</span>;
    case 5:
      return <span><i className="fas fa-layer-group mt-margin-xsmall-right" style={{ color: 'orange' }}></i>{'Em Cobrança Mensal'}</span>;
    default:
      return '';
  }
};

export { DescricaoStatusPagamento };
