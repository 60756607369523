/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";

// reactstrap components
import { Card, Table, Container, Row } from "reactstrap";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { ButtonGroup } from "@material-ui/core";
import Config from "Config";
import api from "services/api";
import moment from "moment";
import { jsonToUrlFilter } from "utils/filter";
import { estadoCivil } from "utils/estadoCivil";
import { BoxLoading } from "react-loadingg";
import ButtonControl from "views/components/ButtonControl";


class Certificados extends Component {
  state = {
    loading: false
  };

  async componentDidMount() {
    this.setState({ loading: true });
    const response = await api.get(`/api/user/${this.props.match.params.uk}`);

    const tituloPatrimonial = {
      nome: response.data.name,
      data: moment().format("DD \\d\\e MMMM \\d\\e YYYY")
    };

    const fichaSocio = {
      nome: response.data.name,
      titulo: !!response.data.numeroTitulo ? response.data.numeroTitulo : "",
      cpf_cnpj: !!response.data.cpfOrcnpj ? response.data.cpfOrcnpj : "",
      rg: !!response.data.rg ? response.data.rg : "",
      categoria: !!response.data.associadoUk ? "Dependente" : "Proprietário",
      estado_civil: !!response.data.estadoCivil ? estadoCivil.find(item => item.id === response.data.estadoCivil).descricao : "",
      logradouro: response.data.listaEnderecos.length > 0 ? response.data.listaEnderecos[0].logradouro : "",
      numero: response.data.listaEnderecos.length > 0 ? response.data.listaEnderecos[0].numero : "",
      complemento: response.data.listaEnderecos.length > 0 ? response.data.listaEnderecos[0].complemento : "",
      bairro: response.data.listaEnderecos.length > 0 ? response.data.listaEnderecos[0].bairro : "",
      cidade: response.data.listaEnderecos.length > 0 ? response.data.listaEnderecos[0].cidade : "",
      uf: response.data.listaEnderecos.length > 0 ? response.data.listaEnderecos[0].estado : "",
      cep: response.data.listaEnderecos.length > 0 ? response.data.listaEnderecos[0].cep : "",
      data_emissao: moment().format("DD/MM/YYYY"),
      data_nascimento: !!response.data.dtNascimento ? moment(response.data.dtNascimento).format("DD/MM/YYYY") : "",
      data_associacao: !!response.data.dtAssociacao ? moment(response.data.dtAssociacao).format("DD/MM/YYYY") : ""
    };

    this.setState({ tituloPatrimonial, fichaSocio, loading: false });
  }

  renderContent() {
    const { tituloPatrimonial, fichaSocio, loading } = this.state;

    return (
      <div>
        <Table className="align-items-center table-flush table-with-button" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">NOME</th>
              <th scope="col">AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {loading ? <tr><td><BoxLoading /></td></tr> : (
              <>
                <tr>
                  <td>
                    <i className="fas fa-file-alt mt-margin-right" style={{ fontSize: "20px" }} />
                    Ficha de Sócio
                  </td>
                  <td>
                    <div className="avatar-group">
                      <a className="btn" href={`${Config._HOST}/api/pdf/emitir/6?${jsonToUrlFilter(fichaSocio)}`} target="_blank" rel="noopener noreferrer">
                        <i className="fas fa-eye" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <i className="fas fa-file-alt mt-margin-right" style={{ fontSize: "20px" }} />
                    Certificado de Título Patrimonial
                  </td>
                  <td>
                    <div className="avatar-group">
                      <a
                        className="btn"
                        href={`${Config._HOST}/api/pdf/emitir/5?${jsonToUrlFilter(tituloPatrimonial)}`}
                        target="_blank" rel="noopener noreferrer"
                      >
                        <i className="fas fa-eye" />
                      </a>
                    </div>
                  </td>
                </tr>

                {/* <tr>
                  <td>
                    <i className="fas fa-file-alt mt-margin-right" style={{fontSize: "20px"}} />
                    Certificado de Atirador
                  </td>
                  <td>
                    <div className="avatar-group">
                      <a className="btn" href={`/admin/associado/${params.uk}`} target="_blank" rel="noopener noreferrer">
                        <i className="fas fa-eye" />
                      </a>
                    </div>
                  </td>
                </tr> */}
              </>
            )}
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    const { uk } = this.props.match.params;

    return (
      <>
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={`/admin/associado/${uk}`}>
                    <i className="fas fa-arrow-left"></i> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          } titulo={"Certificados"} subTitulo={"Módulo de Certificados"} />

        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                {this.renderContent()}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Certificados;
