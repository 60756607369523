/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { CardHeader, Button, Table, Container, Row, Card } from "reactstrap";
import Config from "Config";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { ButtonGroup } from "@material-ui/core";
import { withSnackbar } from "notistack";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import ButtonControl from "views/components/ButtonControl";

class controleAcesso extends React.Component {
  state = {
    data: [],
  };

  componentDidMount() {
    this.findAll()
  }

  findAll = () => {
    this.setState({ loading: true })

    fetch(Config._HOST + '/api/controle-acesso')
      .then(response => response.json())
      .then(response => {
        this.setState({
          data: response.data,
          loading: false,
        })
      }).catch(e => {
        this.setState({ loading: false })
      })
  }

  handleRemove = (id) => {

    confirmAlert({
      title: 'Deseja Deletar o cadastro?',
      message: '',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim', onClick: () => {
            this.setState({ loading: true })

            fetch(Config._HOST + `/api/controle-acesso/${id}`,
              {
                method: 'DELETE',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                }
              },
            )
              .then(response => response.json())
              .then(data => {
                if (data) {
                  const dataUpdated = this.state.data.filter(row => row.id !== id);
                  this.setState({ data: dataUpdated });
                }

                this.props.enqueueSnackbar(data ? "Deletado com sucesso!" : 'Ops, ocorreu um erro!')
                this.setState({ loading: false })
              })
              .catch(e => {
                this.setState({ loading: false })
                this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
              })

          }
        }
      ]
    })

  }

  renderContent() {
    if (this.state.loading) {
      return <BoxLoading />
    }

    if (this.state.data.length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Não Existem Controle de Acesso cadastrados</h3>
        </CardHeader>
      );
    }

    return (
      <div>
        <Table className="align-items-center table-flush table-with-button" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">PESSOA</th>
              <th>TIPO DE PESSOA</th>
              <th>TIPO DE ABERTURA</th>
              <th>ZONA</th>
              <th>PLACA DO VEÍCULO</th>
              <th>DATA</th>
              <th>AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map(single => {
              console.log(single);
              return (
                <tr key={single.id}>
                  <td>
                    {single.usuario ? single.usuario.name
                      : single.fornecedor ? single.fornecedor.nome
                        : single.visitante ? single.visitante.nome
                          : '-'}
                  </td>
                  <td>
                    {single.usuario ? "Usuário"
                      : single.fornecedor ? "Fornecedor / Prestador"
                        : single.visitante ? "Visitante"
                          : '-'}
                  </td>
                  <td>{single.tipoAbertura ? single.tipoAbertura.nome : '-'}</td>
                  <td>{single.zona ? single.zona.nome : '-'}</td>
                  <td>{single.placaVeiculo ? single.placaVeiculo : '-'}</td>
                  <td>{single.dataHora ? (
                    moment(single.dataHora).format('DD/MM/YYYY') + " às " +
                    moment(single.dataHora).format('HH:mm')
                  ) : "-"}</td>
                  <td>
                    <ButtonControl color='secondary' to={`/admin/controle-acesso/${single.uk}`}>
                      <i className="fas fa-edit"></i>
                    </ButtonControl>
                    <Button color="secondary" onClick={() => this.handleRemove(single.id)}>
                      <i className="fas fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    return (
      <>

        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <Button onClick={() => this.props.history.push('/admin/controle-acesso/novo')}>
                    <i className="fas fa-plus"></i> NOVO
                  </Button>
                  <Button onClick={() => this.props.history.push('/admin/controle-acesso/tipo-aberturas')}>
                    <i className="fas fa-unlock-alt"></i> Tipo de Aberturas
                  </Button>
                  <Button onClick={() => this.props.history.push('/admin/controle-acesso/zonas')}>
                    <i className="fas fa-warehouse"></i> Zonas
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"Controle de Acesso"} subTitulo={"Módulo de Gestão para Controle de Acesso"}
        />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Total de Controle de Acesso ({this.state.data.length})</h3>
                </CardHeader>

                {this.renderContent()}

              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(controleAcesso);
