/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Card, CardHeader, Table, Container, Row, Button } from "reactstrap";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import { Link } from "react-router-dom";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { ButtonGroup } from "@material-ui/core";
import { withSnackbar } from "notistack";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { findCategorias } from "utils/find";
import { jsonToUrlFilter } from "utils/filter";
import ButtonControl from "views/components/ButtonControl";

class FinanceiroCategoriaList extends React.Component {
  state = {
    data: [],
    filter: {
      _limit: 999,
      _order: 'ASC',
      _sort: "codigo",
    }
  };

  componentDidMount() {
    this.findAll()
  }

  findAll = async () => {
    this.setState({ loading: true });
    const response = await findCategorias(jsonToUrlFilter(this.state.filter));
    this.setState({ data: response.data, loading: false });
  }

  renderContent() {
    if (this.state.loading) return <BoxLoading />;

    if (this.state.data.length === 0) {
      return <CardHeader className="border-0">
        <h3 className="mb-0">Não existem Categorias cadastradas</h3>
      </CardHeader>
    }
    return <div>
      <Table className="align-items-center table-flush table-with-button" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">CÓDIGO</th>
            <th scope="col">NOME</th>
            <th scope="col">STATUS</th>
            <th scope="col">AÇÃO</th>
          </tr>
        </thead>
        <tbody>
          {this.state.data.map(single => (
            <tr>
              <td>{single.codigo ? single.codigo : "-"}</td>
              <td>{single.nome ? single.nome : "-"}</td>
              <td>{single.ativo ? "ATIVO" : 'INATIVO'}</td>
              <td>
                <div className="avatar-group">
                  <ButtonControl color='secondary' to={`/admin/financeiro/categorias/${single.uk}/editar`}>
                    <i className="fas fa-edit" />
                  </ButtonControl>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  }


  renderContentIten() {
    let data = []
    for (let single of this.state.data) {
      data.push(<tr>
        <td>
          <span className="mb-0 text-sm">
            {single.nome}
          </span>
        </td>
        <td>
          <span className="mb-0 text-sm">
            {single.ativo ? "ATIVO" : "INATIVO"}
          </span>
        </td>
        <td className="text-right">
          asd
          <Link to={`/admin/financeiro/categoria/${single.uk}/editar`}>
            <Button
              color="orange"
            >
              <i className="fas fa-edit" />
            </Button>
          </Link>
        </td>
      </tr>)
    }
    return data
  }

  render() {
    return (
      <>
        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to='/admin/financeiro'>
                    <i className="fas fa-arrow-left" /> VOLTAR
                  </ButtonControl>
                  <ButtonControl color='secondary' to='/admin/financeiro/categoria/novo'>
                    <i className="fas fa-plus"></i> NOVO
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"Categorias"} subTitulo={"Módulo de gestão para Categoria"} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">

                <CardHeader className="border-0">
                  <h3 className="mb-0">Total Categoria ({this.state.data.length})</h3>
                </CardHeader>
                {this.renderContent()}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(FinanceiroCategoriaList);
