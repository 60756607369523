import api from "services/api";
import Config from "Config";
import { ButtonGroup } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { handleUploadfile, sendFile } from "utils/file";
import moment from 'moment';
import { withSnackbar } from "notistack";
import React from "react";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import BoxLoading from "react-loadingg/lib/BoxLoading";
import ReactSignatureCanvas from "react-signature-canvas";
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row } from "reactstrap";
import ButtonControl from "views/components/ButtonControl";


class agendaCheck extends React.Component {

  state = {
    statusCheckIn: false,
    statusCheckOut: false,
    checkIn: false,
    checkOut: false
  }

  sigPad = {}

  async componentDidMount() {
    const user = JSON.parse(window.localStorage.getItem(Config._USER))

    if (!user) {
      window.localStorage.removeItem(Config._USER)
      this.props.history.replace('/auth')
      return;
    }

    if (this.props.match.params.uk) {
      await this.findBy(this.props.match.params.uk)
    }

    if (!this.state.checkIn) {
      this.setState({ checkIn: moment().format('YYYY-MM-DD\\THH:mm:00'), checkInBy: user });
    }
    else if (this.state.checkIn && !this.state.checkOut) {
      this.setState({ checkOut: moment().format('YYYY-MM-DD\\THH:mm:00'), checkOutBy: user });
    }
  }

  findBy = async (uk) => {
    this.setState({ loading: true });
    const response = await api.get("/api/documento-agenda/" + uk)

    if (response.status === 200) {
      if (response.data.checkIn) this.setState({ statusCheckIn: true })
      if (response.data.checkOut) this.setState({ statusCheckOut: true })
      this.setState({ ...this.state, ...response.data, loading: false })
    }
  }

  create = async () => {
    let temp = this.state;

    if (!this.state.checkIn) {
      this.props.enqueueSnackbar("Informe hora do check-in")
      return;
    }

    if (!this.state.statusCheckIn && this.state.checkIn && this.sigPad.isEmpty()) {
      this.props.enqueueSnackbar("Assinatura do responsável é necessário!")
      return;
    }

    if (!this.state.statusCheckOut && this.state.checkOut && this.sigPad.isEmpty()) {
      this.props.enqueueSnackbar("Assinatura do responsável é necessário!")
      return;
    }

    if (temp.listaConvidados) {
      temp.listaConvidados = [];

      this.state.listaConvidados.forEach(item => {
        temp.listaConvidados.push({ id: item.id });
      })
    }

    if (temp.ambiente) {
      temp.ambiente = { id: temp.ambiente.id, uk: temp.ambiente.uk };
    }
    if (temp.financeiroContasEntity) {
      temp.financeiroContasEntity = { id: temp.financeiroContasEntity.id, uk: temp.financeiroContasEntity.uk };
    }

    delete temp.parcelamentos;

    const assinatura = this.sigPad.toDataURL('image/png');

    this.setState({ loading: true });
    let agendaData = JSON.stringify({ ...temp })

    fetch(Config._HOST + "/api/documento-agenda",
      {
        body: agendaData,
        method: this.props.match.params.uk ? 'PUT' : 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => response.json())
      .then(data => {
        this.props.enqueueSnackbar(data.mensagem)
        this.setState({ loading: false }, () => {
          const { uk } = this.props.match.params;

          if (data.status === 200) {
            if (this.sigPad != null) {
              const dataSend = new FormData();

              if (sendFile(dataSend, assinatura, this.state.statusCheckIn ? "checkout" : "checkin")) {
                handleUploadfile(data.data.uk, 'agenda', dataSend)
              }
            }

            if (uk) {
              this.props.history.push(`/admin/agenda/${uk}/visualizar`);
              return;
            }
          }
        })
      }).catch(e => {
        this.setState({ loading: false });
        this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
      })
  }

  clear = () => {
    this.sigPad.clear()
  }

  render() {
    const { uk } = this.props.match.params;

    return (
      <>
        {this.state.loading && (
          <div className="container-load-with-margin">
            <BoxLoading />
          </div>
        )}

        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={`/admin/agenda/${uk}/visualizar`}>
                    <i className="fas fa-arrow-left" /> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"Agenda"}
          subTitulo={"Gerenciamento completo de Agendamentos"}
        />

        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {this.props.match.params.uk ? "Atualizar Agenda" : "Cadastro de Agenda"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="mt-margin-left mt-margin-right">
                      {this.props.match.params.uk ? (
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="input-username">Check-In</label>
                              <Input
                                type="datetime-local"
                                value={this.state.checkIn}
                                onChange={(value) => {
                                  if (moment(value.target.value.toUpperCase()).isValid()) {
                                    this.setState({ checkIn: value.target.value.toUpperCase() })
                                  }
                                }}
                                className="form-control"
                                disabled={this.state.statusCheckIn ? true : false}
                              />
                            </FormGroup>
                          </Col>

                          {!!this.state.statusCheckIn && (
                            <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="input-username">Check-Out</label>
                                <Input
                                  type="datetime-local"
                                  value={this.state.checkOut}
                                  onChange={(value) => {
                                    if (moment(value.target.value.toUpperCase()).isValid()) {
                                      this.setState({ checkOut: value.target.value.toUpperCase() })
                                    }
                                  }}
                                  className="form-control"
                                  disabled={!this.state.statusCheckOut ? false : true}
                                />
                              </FormGroup>
                            </Col>
                          )}
                        </Row>
                      ) : ''}
                    </div>

                    {!this.state.statusCheckIn || !this.state.statusCheckOut ? (
                      <div className="mt-margin-left mt-margin-right mt-margin-medium-top">
                        <>
                          <h6 className="heading-small text-muted mb-2">
                            Assinatura
                          </h6>
                          <div className="mt-margin-left mt-margin-right mt-margin-top">
                            <ReactSignatureCanvas canvasProps={{ className: 'sigCanvas sigPad' }} ref={(ref) => { this.sigPad = ref }} />
                            <Button className="mt-margin-small-top" style={{ float: 'right' }} onClick={this.clear}>
                              <i className="fas fa-eraser mt-margin-small-right"></i>
                              Limpar Assinatura
                            </Button>
                          </div>
                        </>
                      </div>
                    ) : ""}

                    <div className={`mt-margin-left mt-margin-right ${!this.state.statusCheckIn || !this.state.statusCheckOut ? "mt-margin-large-top" : ""}`}>
                      <h6 className="heading-small text-muted mt-padding-top mt-margin-remove">
                        Observação
                      </h6>

                      <div className="mt-margin-left mt-margin-right">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <textarea className="form-control-alternative"
                                id="input-username"
                                style={{
                                  padding: '10px',
                                  width: '100%',
                                  marginTop: '10px',
                                  borderRadius: '10px',
                                  fontSize: '14px',
                                  height: '150px'
                                }}
                                type="text"
                                onChange={(value) => {
                                  this.setState({ observacao: value.target.value.toUpperCase() })
                                }}
                                value={this.state.observacao}
                                disabled={this.state.statusCheckIn && this.state.statusCheckOut}
                              ></textarea>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div className="mt-margin-left mt-margin-right mt-margin-top">
                      <Row>
                        {!!this.state.statusCheckIn && (
                          <Col lg="6">
                            <h6 className="heading-small text-muted mb-2">
                              Assinatura Check-In
                            </h6>
                            {this.state.statusCheckIn ? (
                              <img
                                style={{ width: "100%", borderRight: 'solid 1px #e9e9e9' }}
                                alt="check-in"
                                src={Config._HOST + "/api/bucket/owner/" + this.props.match.params.uk + "/key/checkin"}
                              />
                            ) : ""}
                          </Col>
                        )}

                        {!!this.state.statusCheckIn && !!this.state.statusCheckOut && (
                          <Col lg="6">
                            <h6 className="heading-small text-muted mb-2">
                              Assinatura Check-Out
                            </h6>
                            {this.state.statusCheckOut ? (
                              <img
                                style={{ width: "100%" }}
                                alt="check-in"
                                src={Config._HOST + "/api/bucket/owner/" + this.props.match.params.uk + "/key/checkout"}
                              />
                            ) : ""}
                          </Col>
                        )}
                      </Row>
                    </div>

                    {(!this.state.statusCheckIn || !this.state.statusCheckOut) && (
                      <>
                        <hr className="my-4" />
                        <div className="mt-margin-left mt-margin-right mt-margin-top" style={{ marginTop: "10px" }}>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <Button color="warning" style={{ width: "100%" }} onClick={() => this.props.history.push(`/admin/agenda/${uk}/editar`)}>
                                  <i className="fas fa-arrow-left" style={{ marginRight: 5 }}></i>
                                  CANCELAR
                                </Button>
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <FormGroup>
                                <Button color="default" style={{ width: "100%" }} onClick={() => {
                                  confirmAlert({
                                    title: this.props.match.params.uk ? 'Deseja confirmar a atualização?' : 'Deseja confirmar o cadastro?',
                                    message: '',
                                    buttons: [
                                      {
                                        label: 'Não',
                                      },
                                      {
                                        label: 'Sim',
                                        onClick: () => this.create()
                                      }
                                    ]
                                  })
                                }
                                }>
                                  <i className="fas fa-check" style={{ marginRight: 5 }}></i>
                                  {this.props.match.params.uk ? "ATUALIZAR" : "CADASTRAR"}</Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(agendaCheck);
