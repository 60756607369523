import { FormControl, MenuItem, Select } from '@material-ui/core';
import React, { Component } from 'react';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import Dropzone from 'views/components/Dropzone';

export default class OutrasInformacoes extends Component {
  render() {
    const { state, handleChange, isDependente } = this.props;

    return (
      <div className="mt-margin-left mt-margin-right">
        <Row>
          {!state.isNaoSocio && (
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-username">
                  Data de Associação
                </label>
                <Input
                  type="date"
                  format="YYYY-MM-DD"
                  id="start_date"
                  name="start_date"
                  value={state._dtAssociacao ? state._dtAssociacao : ''}
                  onChange={(value) => handleChange({ _dtAssociacao: value.target.value.toUpperCase() })}
                  className="form-control"
                />
              </FormGroup>
            </Col>
          )}

          <Col lg="4">
            <FormGroup>
              <FormControl variant="outlined">
                <label className="form-control-label" htmlFor="input-last-name">
                  Status de Acesso
                </label>
                {(state.statusAcesso || !this.props.match.params.uk) && (
                  <Select
                    placeholder={"Status de Acesso"}
                    value={state.statusAcesso ? state.statusAcesso : 1}
                    onChange={(value) => handleChange({ statusAcesso: value.target.value })}
                  >
                    {(!isDependente || state.statusAcessoAtual === 1) && <MenuItem value={1}>Ativo</MenuItem>}
                    {(!isDependente || state.statusAcessoAtual === 6) && <MenuItem value={6}>Irregular (Após 5 dias)</MenuItem>}
                    {(!isDependente || state.statusAcessoAtual === 7) && <MenuItem value={7}>Inadimplente (Após 30 dias)</MenuItem>}
                    {(!isDependente || state.statusAcessoAtual === 10) &&<MenuItem value={10}>Bloqueado (Após 60 dias)</MenuItem>}
                    {(!isDependente || state.statusAcessoAtual === 2) && <MenuItem value={2}>Inativo (Após 180 dias)</MenuItem>}
                    {/* <MenuItem value={4}>Público</MenuItem> */}
                    <MenuItem value={8}>Desligado</MenuItem>
                    <MenuItem value={5}>Suspenso</MenuItem>
                    <MenuItem value={3}>Falecido</MenuItem>
                    {(!isDependente || state.statusAcessoAtual === 9) && !state.isNaoSocio && <MenuItem value={9}>Licenciado (Por 6 meses)</MenuItem>}
                  </Select>
                )}
              </FormControl>
            </FormGroup>
          </Col>

          {!(isDependente || state.isNaoSocio) && (
            <Col lg="4">
              <FormGroup className="mt-margin-bottom">
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <label className="form-control-label" htmlFor="input-last-name">
                    Tipo de Associado
                  </label>

                  {state.statusAcesso && (
                    <Select
                      value={state.categoria ? state.categoria : "0"}
                      onChange={(value) => handleChange({ categoria: value.target.value })}
                    >
                      <MenuItem value={"0"}>Selecione</MenuItem>
                      {state.categoriaComplete && state.categoriaComplete.map(single => {
                        return <MenuItem key={single.id} value={single.id}>{single.nome}</MenuItem>
                      })}
                    </Select>
                  )}
                </FormControl>
              </FormGroup>
            </Col>
          )}
        </Row>

        {state.statusAcesso !== 1 && state.statusAcesso !== undefined && (
          <Row>
            <Col lg="12">
              <FormGroup className="mt-margin-bottom">
                <label className="form-control-label" htmlFor="observacoes">
                  Observações
                </label>
                <Input
                  type="textarea"
                  id="observacoes"
                  name="observacoes"
                  placeholder="Observações"
                  value={state.observacoes ? state.observacoes : ''}
                  rows="5"
                  onChange={(value) => handleChange({ observacoes: value.target.value.toUpperCase() })}
                  className="form-control"
                />
              </FormGroup>
            </Col>
          </Row>
        )}

        <hr className="my-4 mt-margin-large-top"></hr>
        <h6 className="heading-small text-muted mb-4">Anexo</h6>
        <div className="pl-lg-4">
          <Row>
            <Col lg="12">
              <Dropzone {...this.props} handleChange={this.props.handleChangeDropzone} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
