import { ButtonGroup, FormControl, MenuItem, Select } from "@material-ui/core";
import HeaderButtonGroup from 'views/components/Headers/HeaderButtonGroup';
import Config from 'Config';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Component } from 'react';
import BoxLoading from "react-loadingg/lib/BoxLoading";
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import { Button, Card, CardHeader, Col, Container, FormGroup, Row } from 'reactstrap';
import api from 'services/api';
import { jsonToUrlFilter } from 'utils/filter';
import {v4 as uuidv4} from 'uuid';
import ButtonControl from "views/components/ButtonControl";


const MonthEvents = (props) => {
  if (props.data) {
    return props.data.map(item => {
      let arr = item.dtInicio.split("T")[0].split("-");

      if (
        arr[0] === props.year &&
        arr[1] === props.month &&
        arr[2] === props.day
      ) {
        return (
          <div key={item.uk}>
            <ReactTooltip id={`tooltip-${item.uk}`} place="top" type='dark' effect='solid'>
              {!!item.bloqueio && (
                <span className={!item.observacao ? "mt-margin-remove" : ""}>
                  <strong>"AMBIENTE BLOQUEADO"</strong>
                  <hr style={{ background: 'white', margin: 0 }} />
                </span>
              )}
              {!!item.responsavel && <p className={!item.observacao ? "mt-margin-remove" : ""}><strong>Responsável:</strong> {item.responsavel.name}</p>}
              {!!item.observacao && <p className="mt-margin-remove"><strong>Observação:</strong> {item.observacao}</p>}
            </ReactTooltip>
            <Link key={item.uk} className="ev" to={!item.bloqueio ? `/admin/agenda/${item.uk}/visualizar` : '#'} rel="noopener noreferrer" data-tip data-for={`tooltip-${item.uk}`}
              style={{
                backgroundColor: item.ambiente.corFundo,
                color: item.ambiente.corTexto,
                opacity: item.status === 2 ? 0.5 : 1,
                boxShadow: item.status !== 2 ? '0px 0px 3px #bcbcbc' : 'none'
              }}
            >
              <div className="ev-desc">
                {!!item.bloqueio && <i className="fas fa-lock mt-margin-xsmall-right"></i>}
                {!!item.dataCadastro && parseInt(moment.duration(moment().diff(moment(item.dataCadastro))).asHours()) <= 24 && (
                  <i className="fas fa-bell mt-margin-xsmall-right"></i>
                )}
                {[0, 3].indexOf(item.status) >= 0 && (
                  <i className="fas fa-clock mt-margin-xsmall-right"></i>
                )}
                {item.ambiente.nome}
              </div>
            </Link>
          </div>
        );
      }

      return "";
    });
  }

  return "";
}

const MonthDays = (props) => {
  // first day
  const first = moment(props.date).date(1);

  // week format
  const weekF = moment(props.date).format("YYYYMM");

  // init arr
  let arr = [];

  for (let i = 0; i < 42; i++) {
    // day
    let day = moment(props.date)
      .date(1)
      .subtract(first.weekday() - i, "days");

    // day format
    let dayF = moment(day.format("YYYYMM"));

    // add class by type
    let cls = dayF.isBefore(weekF)
      ? "days-before"
      : dayF.isAfter(weekF)
        ? "days-after"
        : props.date.clone().isSame(day, "day") && moment(props.date).format("MMYYYY") === moment().format("MMYYYY") ? "today" : "";

    // push
    arr.push(
      <li key={i + day.format("DD/MM/YYYY")} className={cls}>
        <div className="date">{day.format("DD")}</div>
        <div className="">{day.format("DD/MM/YYYY")}</div>

        {!(dayF.isBefore(weekF) || dayF.isAfter(weekF)) && (
          <MonthEvents
            data={props.data}
            day={day.clone().format("DD")}
            month={day.clone().format("MM")}
            year={day.clone().format("YYYY")}
          />
        )}
      </li>
    );
  }
  return <ul className="days">{arr}</ul>;
};

class AgendaCalendario extends Component {
  state = {
    data: [],
    dataBloqueio: [],
    ambiente: "todos",
    status: "todos",
    dataAmbiente: [],
    loading: false,
    requests: 0,
    month: moment()
  }

  async componentDidMount() {
    let user = JSON.parse(window.localStorage.getItem(Config._USER));

    if (!user) {
      window.localStorage.removeItem(Config._USER)
      this.props.history.replace('/auth')
      return;
    }

    this.setState({ loading: true });
    await this.findAmbiente();
    await this.findAll(this.state.month, true);
    this.setState({ loading: false });
  }

  findAmbiente = async () => {
    const response = await api.get("/api/ambiente?_limit=9999&ativo=true&permitir_locacao=true&_sort=nome");
    this.setState({ dataAmbiente: response.data });
  }

  findAll = async (month, getBloqueio = false) => {
    let filterData = {
      dt_inicio_like: month.format('YYYY-MM'),
      _limit: 99999,
      _order: 'desc',
      _sort: 'dt_inicio',
    }

    if (this.state.status === 'todos') {
      filterData.status = [0, 1, 3, 4];
    }
    else if (this.state.status === 'aguardando') {
      filterData.status = [0, 3];
    }
    else if (this.state.status === 'confirmado') {
      filterData.status = [1, 4];
    }
    else if (this.state.status === 'cancelado') {
      filterData.status = [2];
    }

    if (this.state.ambiente !== 'todos') {
      filterData.ambiente = { id: this.state.ambiente };
    }

    const response = await api.get(`/api/documento-agenda?${jsonToUrlFilter(filterData)}`);
    let dataBloqueio = [];

    if(this.state.status !== 'cancelado') {
      filterData = {
        data_inicio_like: month.format('YYYY'),
        _limit: 99999,
        _order: 'desc',
        _sort: 'data_inicio',
      }

      if (this.state.ambiente !== 'todos') {
        filterData.ambientes = { id: this.state.ambiente };
      }

      const responseBloqueio = await api.get(`/api/agenda/bloqueio?${jsonToUrlFilter(filterData)}`);

      if(!!responseBloqueio && 'data' in responseBloqueio) {
        responseBloqueio.data.forEach(item => {

          item.ambientes.forEach(ambiente => {
            if (this.state.ambiente === 'todos' || this.state.ambiente === ambiente.id) {
              const data_inicio = moment(item.data_inicio);
              const data_fim = moment(item.data_fim).format('YYYYMMDD');

              while (parseInt(data_inicio.format('YYYYMMDD')) <= parseInt(data_fim)) {
                const hasBloqueio = this.state.dataBloqueio.find(itemBloqueio => itemBloqueio.dtInicio === data_inicio.format('YYYY-MM-DD') && itemBloqueio.ambiente.id === ambiente.id);

                if(!hasBloqueio) {
                  let isBloqueado = false;

                  if(data_inicio.day() === 0) isBloqueado = !!item.domingo;
                  else if(data_inicio.day() === 1) isBloqueado = !!item.segunda;
                  else if(data_inicio.day() === 2) isBloqueado = !!item.terca;
                  else if(data_inicio.day() === 3) isBloqueado = !!item.quarta;
                  else if(data_inicio.day() === 4) isBloqueado = !!item.quinta;
                  else if(data_inicio.day() === 5) isBloqueado = !!item.sexta;
                  else if(data_inicio.day() === 6) isBloqueado = !!item.sabado;

                  if(isBloqueado) {
                    dataBloqueio.push({
                      uk: uuidv4(),
                      dtInicio: data_inicio.format('YYYY-MM-DD'),
                      observacao: item.descricao,
                      ambiente,
                      status: 1,
                      bloqueio: true
                    });
                  }
                }

                data_inicio.add(1, 'days');
              }
            }
          });
        });
      }
    }

    if (response && this.state.month.month() === month.month()) {
      const responseData = response.data.filter(item => !this.state.data.find(itemReserva => item.id === itemReserva));

      this.setState({ data: [...responseData, ...dataBloqueio] })
    }
  }

  changeDate = async (newMonth, monthOld) => {
    this.setState({ month: newMonth, loading: true, requests: this.state.requests + 1 });
    setTimeout(async () => {
      if (this.state.month.month() === newMonth.month()) await this.findAll(newMonth, !monthOld ? true : newMonth.year() !== monthOld.year());
      this.setState({ loading: this.state.requests - 1 > 0, requests: this.state.requests - 1 });
    }, 1000*2);
  }

  next = () => {
    const { month } = this.state;
    const newMonth = month.clone().add(1, "month");
    this.changeDate(newMonth, month)
  };

  prev = () => {
    const { month } = this.state;
    const newMonth = month.clone().subtract(1, "month");
    this.changeDate(newMonth, month)
  };

  today = () => {
    const today = moment();
    this.changeDate(today)
  };

  render() {
    const { month, data } = this.state;
    let m = moment().day();

    return (
      <>
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to='/admin/agenda/novo'>
                    <i className="fas fa-plus"></i> NOVO
                  </ButtonControl>
                  <ButtonControl color='secondary' to='/admin/agenda/listagem'>
                    <i className="fas fa-list"></i> Listagem
                  </ButtonControl>
                  <ButtonControl color='secondary' to='/admin/agenda/bloqueio'>
                    <i className="fas fa-lock"></i> Bloqueio
                  </ButtonControl>
                  <ButtonControl color='secondary' to='/admin/agenda/ultimas-reservas'>
                    <i className="fas fa-bell"></i> Últimas Reservas
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"Reservas"}
          subTitulo={"Gerenciamento completo de Agendamentos"}
        />

        <Container className="mt--7" fluid style={{ paddingBottom: 100 }}>

          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <FormControl variant="outlined">
                          <label className="form-control-label">Ambiente</label>
                          <Select
                            value={this.state.ambiente}
                            onChange={value => {
                              this.setState({ ambiente: value.target.value }, async () => {
                                this.setState({ loading: true })
                                await this.findAll(this.state.month);
                                this.setState({ loading: false })
                              })
                            }}
                            defaultValue={this.state.ambiente}
                            disabled={this.state.loading}
                          >
                            <MenuItem value='todos'>Todos</MenuItem>
                            {this.state.dataAmbiente.map(single => {
                              return <MenuItem key={single.id} value={single.id}>{single.nome}</MenuItem>
                            })}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <FormControl variant="outlined">
                          <label className="form-control-label">Status</label>
                          <Select
                            value={this.state.status}
                            onChange={(value) => {
                              this.setState({ status: value.target.value }, async () => {
                                this.setState({ loading: true })
                                await this.findAll(this.state.month);
                                this.setState({ loading: false })
                              })
                            }}
                            defaultValue={this.state.status}
                            disabled={this.state.loading}
                          >
                            <MenuItem value='todos'>Todos</MenuItem>
                            <MenuItem value='aguardando'>Aguardando</MenuItem>
                            <MenuItem value='confirmado'>Confirmado</MenuItem>
                            <MenuItem value='cancelado'>Cancelado</MenuItem>
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
            </div>
          </Row>

          {/* Table */}
          <Row>
            <Col>
              <Card className="shadow">
                <div className="calendar">
                  <Row className="header">
                    <Col lg="4" className="mt-text-left">
                      <Button type="button" onClick={this.today}>Hoje</Button>
                      <Button type="button" onClick={this.prev}>{"<"}</Button>
                      <Button type="button" onClick={this.next}>{">"}</Button>
                    </Col>
                    <Col lg="4" className="calendar-title">
                      {month.format("MMMM YYYY")}
                    </Col>
                    <Col lg="4" className="mt-text-right" style={{ paddingTop: "8px" }}>
                      {moment().format("dddd")} {moment().format("LL")}
                    </Col>
                  </Row>
                  {this.state.loading && (
                    <div className="container-load-with-margin" style={{ position: "absolute", width: "98%", height: "90%" }}>
                      <BoxLoading style={{ margin: "auto", position: "sticky", inset: "0px" }} />
                    </div>
                  )}
                  <ul className="weekdays">
                    {moment.weekdays(true).map((item, i) => (
                      <li key={i.toString()} className={m === i && month.format("MMYYYY") === moment().format("MMYYYY") ? "today" : ""}>
                        {item}
                      </li>
                    ))}
                  </ul>
                  <MonthDays date={month} data={data} />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AgendaCalendario;
