/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Card, CardHeader, CardBody, Form, Container, Row, Col } from "reactstrap";
import Config from "Config";
import { withSnackbar } from "notistack";
import { ButtonGroup } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import FormFields from "./Form/FormFields";
import FormButtonAction from "views/components/FormButtonAction";
import ButtonControl from "views/components/ButtonControl";

const HISTORY_BACK = '/admin/usuarios';
const ROUTE_API = '/api/user';

class UsuarioCrud extends React.Component {

  state = {
    dataProfile: [],
    statusAcesso: 1,
    profile: 1
  }

  componentDidMount() {
    let user = JSON.parse(window.localStorage.getItem(Config._USER))
    if (!user) {
      window.localStorage.removeItem(Config._USER)
      this.props.history.replace('/auth')
      return;
    }

    if (this.props.match.params.uk) {
      this.findBy(this.props.match.params.uk)
    }

    this.setState({ session: user })
  }

  findBy = id => {
    this.setState({ loading: true })

    fetch(Config._HOST + `${ROUTE_API}/${id}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          ...response.data,
          profile: response.data.profile.id
        })
      }).catch(e => {
        this.setState({ loading: false })
      })
  }

  create(id) {
    let temp = this.state;

    if (!this.state.name || this.state.name === 0) {
      this.props.enqueueSnackbar("Selecione um Nome")
      return;
    }
    if (!this.state.email || this.state.email === 0) {
      this.props.enqueueSnackbar("Selecione um Email")
      return;
    }
    if (!id && (!this.state.senha || this.state.senha === 0)) {
      this.props.enqueueSnackbar("Selecione um Senha")
      return;
    }

    this.setState({ loading: true });
    temp.profile = { id: temp.profile };

    let data = JSON.stringify(temp);
    fetch(Config._HOST + ROUTE_API,
      {
        body: data,
        method: id ? 'PUT' : 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => response.json())
      .then(data => {
        this.props.enqueueSnackbar(data.mensagem)
        this.setState({ loading: false }, () => {
          this.props.history.push(HISTORY_BACK)
        })
      }).catch(e => {
        this.setState({ loading: false })
        this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
      })
  }

  handleChange = (value) => {
    this.setState({ ...this.state, ...value });
  }

  render() {
    return (
      <>

        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={HISTORY_BACK}>
                    <i className="fas fa-arrow-left"></i> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={this.props.match.params.uk ? "EDITAR USUÁRIO" : "NOVO USUÁRIO"}
          subTitulo={"Gerenciamento dos Usuários"}
        />


        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {this.props.match.params.uk ? "Atualizar Usuário" : "Cadastrar Novo Usuário"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormFields {...this} />
                    <FormButtonAction create={() => this.create(this.props.match.params.uk)} cancel={HISTORY_BACK} history={this.props.history} isEdit={this.props.match.params.uk} />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(UsuarioCrud);
