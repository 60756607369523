import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import Config from "Config";
import { withSnackbar } from "notistack";
import { ButtonGroup, FormControl, MenuItem, Select } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ButtonControl from "views/components/ButtonControl";

class Categoria extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      tituloPatrimonial: "1",
      regraAcesso: "1",
      contaComoAssociado: "1",
      habilitarCategoriaCadastroPublico: "1",
      categoria: "1",
      dresponsavelFinanceiro: "1",
      nome: "",

      // dataCategorias: [],
      dataResponsavelFinanceiro: [],
    }
  }

  componentDidMount() {
    let user = JSON.parse(window.localStorage.getItem(Config._USER))
    if (!user) {
      window.localStorage.removeItem(Config._USER)
      this.props.history.push('/auth')
      return;
    }

    this.findAllResponsavelFinanceiro()

    if (this.props.match.params.uk) {
      this.findBy(this.props.match.params.uk)
    }

    this.setState({
      session: user,
    }, () => {

    })
  }

  findBy = (uk) => {
    this.setState({
      loading: true,
    })
    fetch(Config._HOST + "/api/categoria/" + uk)
      .then(response => response.json())
      .then(response => {
        if (response.status === 200) {
          let responsavelFinanceiro = response.data.responsavelFinanceiro[0]
          this.setState({ ...response.data, dresponsavelFinanceiro: responsavelFinanceiro })
        }
      }).catch(e => {
        this.setState({
          loading: false
        })
      })
  }

  findAllResponsavelFinanceiro = () => {
    this.setState({
      loading: true,
    })
    fetch(Config._HOST + "/api/user")
      .then(response => response.json())
      .then(response => {
        this.setState({
          dataResponsavelFinanceiro: response.status === 200 ? response.data : [],
          loading: false,
        })
      }).catch(e => {
        this.setState({
          loading: false
        })
      })
  }

  // findAllCategorias = () => {
  //   this.setState({
  //     loading: true,
  //   })
  //   fetch(Config._HOST + "/api/financeiro/categoria")
  //   .then(response => response.json())
  //   .then(response => {
  //     this.setState({
  //       dataCategorias: response.status === 200 ? response.data: [],
  //       loading: false,
  //     })
  //   }).catch(e => {
  //     this.setState({
  //       loading: false
  //     })
  //   })
  // }

  delete() {
    this.setState({
      loading: true,
    })
    let dataUser = JSON.stringify(this.state)
    fetch(Config._HOST + "/api/categoria",
      {
        body: dataUser,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => response.json())
      .then(data => {
        this.props.enqueueSnackbar(data.mensagem)
        this.setState({
          loading: false
        }, () => {
          if (data.status === 200) {
            this.props.history.push("/admin/categoria")
          }
        })
      }).catch(e => {
        this.setState({
          loading: false,
        })
        this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
      })
  }

  create() {
    let temp = this.state;
    if (!temp.nome) {
      this.props.enqueueSnackbar("Cadastre um nome para essa categoria")
      return;
    }

    this.setState({ loading: true })
    let tempState = this.state;

    if (this.state.dresponsavelFinanceiro !== "1") {
      tempState['responsavelFinanceiro'] = [this.state.dresponsavelFinanceiro]
    }

    let dataUser = JSON.stringify(tempState)
    fetch(Config._HOST + "/api/categoria",
      {
        body: dataUser,
        method: this.props.match.params.uk ? 'PUT' : 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => response.json())
      .then(data => {
        this.props.enqueueSnackbar(data.mensagem)
        this.setState({
          loading: false
        }, () => {
          if (data.status === 200) {
            this.props.history.push("/admin/categoria")
          }
        })
      }).catch(e => {
        this.setState({
          loading: false,
        })
        this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
      })
  }

  render() {
    return (
      <>
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to='/admin/categoria'>
                    <i className="fas fa-arrow-left" /> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"Categorias"}
          subTitulo={(this.props.match.params.uk ? "Atualizar Categoria" : "Cadastrar nova Categoria")} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {this.props.match.params.uk ? "Atualizar Categoria" : "Cadastro de Categoria"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Informações Gerais
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Nome
                            </label>
                            <Input
                              className="form-control"
                              id="input-username"
                              placeholder="Nome Completo"
                              type="text"
                              onChange={(value) => {
                                this.setState({ nome: value.target.value.toUpperCase() })
                              }}
                              value={this.state.nome}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                Possui título patrimonial
                              </label>
                              <Select
                                style={{
                                  background: "#fff",
                                  height: "3rem",
                                  fontSize: "1rem",
                                  color: "#8898aa",
                                  border: 0
                                }}
                                value={this.state.tituloPatrimonial}
                                onChange={(value) => {
                                  this.setState({ tituloPatrimonial: value.target.value })
                                }}
                                defaultValue={this.state.tituloPatrimonial}
                              >
                                <MenuItem value="1">Não</MenuItem>
                                <MenuItem value="2">Sim</MenuItem>
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                Ignora as regras de acesso
                              </label>
                              <Select
                                style={{
                                  background: "#fff",
                                  height: "3rem",
                                  fontSize: "1rem",
                                  color: "#8898aa",
                                  border: 0
                                }}
                                value={this.state.regraAcesso}
                                onChange={(value) => {
                                  this.setState({ regraAcesso: value.target.value })
                                }}
                                defaultValue={this.state.regraAcesso}
                              >
                                <MenuItem value="1">Não</MenuItem>
                                <MenuItem value="2">Sim</MenuItem>
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Col>
                        {/* <Col lg="12">
                          <FormGroup>
                          <FormControl variant="outlined" style={{width: '100%'}}>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Conta como associado
                            </label>
                            <Select
                              style={{
                                background:"#fff",
                                height: "3rem",
                                fontSize: "1rem",
                                color: "#8898aa",
                                border:0
                              }}
                              value={this.state.contaComoAssociado}
                              onChange={(value) => {
                                this.setState({ contaComoAssociado: value.target.value })
                              }}
                              defaultValue={this.state.contaComoAssociado}
                            >
                              <MenuItem value="1">Não</MenuItem>
                              <MenuItem value="2">Sim</MenuItem>
                            </Select>
                          </FormControl>
                          </FormGroup>
                        </Col> */}
                        {/* <Col lg="12">
                          <FormGroup>
                          <FormControl variant="outlined" style={{width: '100%'}}>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Habilitar categoria no cadastro público
                            </label>
                            <Select
                              style={{
                                background:"#fff",
                                height: "3rem",
                                fontSize: "1rem",
                                color: "#8898aa",
                                border:0
                              }}
                              value={this.state.habilitarCategoriaCadastroPublico}
                              onChange={(value) => {
                                this.setState({ habilitarCategoriaCadastroPublico: value.target.value })
                              }}
                              defaultValue={this.state.habilitarCategoriaCadastroPublico}
                            >
                              <MenuItem value="1">Não</MenuItem>
                              <MenuItem value="2">Sim</MenuItem>
                            </Select>
                          </FormControl>
                          </FormGroup>
                        </Col> */}
                        {/* <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Responsável Financeiro
                            </label>
                            <Autocomplete
                                id="combo-box-demo"
                                options={this.state.dataResponsavelFinanceiro}
                                getOptionLabel={(option) => option.name}
                                onChange={(value, newValue) => {
                                  this.setState({dresponsavelFinanceiro: newValue})
                                }}
                                value={this.state.dresponsavelFinanceiro}
                                defaultChecked={this.state.dresponsavelFinanceiro}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                      <input
                                      style={{
                                        width: '100%',
                                        borderRadius: '4px',
                                        padding: '11px',
                                        border: '1px solid #cdcdcd',
                                        boxShadow: '5px 1px -1px #cdcdcd',
                                        fontSize:'15px',
                                        boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
                                        border: 0,
                                        transition: 'box-shadow .15s ease'
                                      }}
                                      placeholder={"Responsável Financeiro"}
                                      type="text" {...params.inputProps} />
                                    </div>
                                )}
                              />
                          </FormGroup>
                        </Col> */}
                      </Row>
                    </div>

                    <hr className="my-4" />

                    <div className="pl-lg-4" style={{ marginTop: "10px" }}>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Button color="warning" style={{ width: "100%" }} onClick={() => {
                              this.props.history.push('/admin/categoria')
                            }}>
                              <i className="fas fa-arrow-left" style={{ marginRight: 5 }}></i>
                              CANCELAR</Button>
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Button color="default" style={{ width: "100%" }} onClick={() => {
                              confirmAlert({
                                title: this.props.match.params.uk ? 'Deseja confirmar a atualização?' : 'Deseja confirmar o cadastro?',
                                message: '',
                                buttons: [
                                  {
                                    label: 'Não',
                                  },
                                  {
                                    label: 'Sim',
                                    onClick: () => this.create()
                                  }
                                ]
                              })
                            }
                            }>
                              <i className="fas fa-check" style={{ marginRight: 5 }}></i>
                              {this.props.match.params.uk ? "ATUALIZAR" : "CADASTRAR"}</Button>
                          </FormGroup>
                        </Col>
                        {this.props.match.params.uk ? <Col lg="12">
                          <FormGroup>
                            <Button color="danger" style={{ width: "100%" }} onClick={() => {
                              confirmAlert({
                                title: 'Deseja excluir?',
                                message: '',
                                buttons: [
                                  {
                                    label: 'Não',
                                  },
                                  {
                                    label: 'Sim',
                                    onClick: () => this.delete()
                                  }
                                ]
                              })
                            }
                            }>
                              EXCLUIR</Button>
                          </FormGroup>
                        </Col> : <div />}
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(Categoria);
