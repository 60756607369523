import { CircularProgress, FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import React, { Component } from 'react';
import { Col, Row, Input, FormGroup, Table, Button } from 'reactstrap';
import IntlCurrencyInput from "react-intl-currency-input";
import Dropzone from "views/components/Dropzone";
import ButtonControl from 'views/components/ButtonControl';

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export default class FormFields extends Component {

  renderTotalParcelamento() {
    const { renderTotalParcelamentoItem, state } = this.props;

    return (
      <div>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              {state.recorrencia === 'Parcelado' && (
                <th scope="col">Parcelas</th>
              )}
              <th scope="col">Valor</th>
              <th scope="col" style={{ width: '280px' }}>Status</th>
              <th scope="col">Data Vencimento</th>
            </tr>
          </thead>
          <tbody>
            {renderTotalParcelamentoItem()}
          </tbody>
        </Table>
      </div>
    )
  }

  renderTotalParcelamentoEdit() {
    const { uk, type } = this.props.props.match.params;
    const { state, handleChange, changeStatus, changeData, showInfo, handleGerarBoleto } = this.props;
    const disabled = !!uk;

    const total = state.parcelamentos.sort((a, b) => {
      if (a.parcela < b.parcela)
        return -1;
      if (a.parcela > b.parcela)
        return 1;

      return 0;
    })

    return (
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col" style={{ width: 100 }}>Parcelas</th>
            <th scope="col">Valor</th>
            <th scope="col">Data Vencimento</th>
            <th scope="col" style={{ width: 280 }}>Status</th>
            {state.tipoPagamento === 1 && type === 'receber' && (
              <th scope="col" style={{ paddingLeft: 0 }}>Link Boleto</th>
            )}
          </tr>
        </thead>
        <tbody>
          {total.map((single, index) => {
            return (
              <tr key={single.id}>
                <td>{single.parcela}ª Parcela</td>
                <td style={{ width: 150 }}>
                  <IntlCurrencyInput
                    className="form-control"
                    currency="BRL"
                    style={{ width: 150 }}
                    config={currencyConfig}
                    value={single.valorPagamento}
                    disabled
                  />
                </td>
                <td style={{ paddingLeft: 0, width: 150 }}>
                  <Input
                    type="date"
                    format="YYYY-MM-DD"
                    value={moment(single.dtVencimento).format('YYYY-MM-DD')}
                    onChange={(value) => {
                      if (!moment(value.target.value).isValid()) return;
                      state.parcelamentos[index]['dtVencimento'] = value.target.value;
                      handleChange('parcelamentos', state.parcelamentos);
                    }}
                    onBlur={(value) => {
                      changeData(value.target.value, single.uk);
                    }}
                    className="form-control"
                    disabled={disabled}
                  />
                </td>
                <td>
                  <Col lg="3">
                    <FormControl variant="outlined" style={{ padding: "10px 0", width: '280px' }}>
                      <Row>
                        <Col lg="10" className='mt-padding-remove'>
                          <Select
                            style={{ width: '100%' }}
                            className="mt-select"
                            value={state[`status${single.uk}`] >= 0 ? state[`status${single.uk}`] : single.status}
                            disabled={[1,2,4,5].indexOf(single.status) > -1 || [1,2,4,5].indexOf(state[`status${single.uk}`]) > -1}
                            onChange={value => changeStatus(value.target.value, single.uk, single.valorPagamento)}
                          >
                            <MenuItem value={0}>Aguardando pagamento</MenuItem>
                            <MenuItem value={1}>Confirmado</MenuItem>
                            <MenuItem value={2}>Cancelado</MenuItem>
                            <MenuItem value={3}>Vencido</MenuItem>
                            <MenuItem value={4}>Em Acordo</MenuItem>
                            <MenuItem value={5}>Em Cobrança Mensal</MenuItem>
                          </Select>

                        </Col>
                        <Col lg="2" className='mt-padding-remove'>
                          {(single.status === 1 || single.observacao) && (
                            <Button color="link" onClick={() => showInfo(single)}>
                              <i className="fas fa-info-circle" style={{ color: 'black' }}></i>
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </FormControl>
                  </Col>
                </td>
                {type === 'receber' && (
                  <td style={{ paddingLeft: 0 }}>
                    {state.tipoPagamento === 1 && !!single.linkBoleto ? (
                      <a style={{ color: "#00000080" }} target="_blank" rel="noopener noreferrer" href={single.linkBoleto}>
                        <i className="fas fa-barcode mt-margin-xsmall-right"></i>
                        Link Boleto
                      </a>
                    ) : state.tipoPagamento === 1 && single.status === 0 ? (
                      <Button onClick={() => !state.gerarBoletoLoading && handleGerarBoleto(single.id)}>
                        {state.gerarBoletoLoading ? (
                          <i className="fas fa-sync fa-solid fa-spin mt-margin-xsmall-right"></i>
                        ) : (
                          <>
                            <i className="fas fa-sync mt-margin-xsmall-right"></i>
                            Gerar Boleto
                          </>
                        )}
                      </Button>
                    ) : (
                      <span style={{ color: "#aaaaaa" }}>Não disponivel</span>
                    )}
                  </td>
                )}
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }

  render() {
    const { uk, type } = this.props.props.match.params;
    const { state, handleChange, handleChangeValorTotal, searchFornecedor, searchAssociado, handleChangeParcelas } = this.props;
    const disabled = !!uk;

    return (
      <>
        <h6 className="heading-small text-muted mb-4">Informações Gerais</h6>
        <div className="pl-lg-4">
          <Row>
            {state.tipoBusca === "Fornecedor" ? (
              <>
                <Col lg="11">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-username">
                      Fornecedor / Prestador {
                        uk
                          && state.dataFornecedor && state.dataFornecedor.nome
                          ? ": " + state.dataFornecedor.nome
                          : ""
                      }
                    </label>
                    <Autocomplete
                      disabled={disabled}
                      options={state.dataFornecedor}
                      getOptionLabel={option => `(${option.cnpjCpf}) ${option.nome}`}
                      onChange={(_, value) => handleChange("fornecedor", value)}
                      onInputChange={(_, value) => searchFornecedor(value)}
                      inputMode="text"
                      key="fornecedor"
                      loading={state.loadFornecedor}
                      value={state.fornecedor}
                      renderOption={(option) => (
                        <span>({option.cnpjCpf}) {option.nome}</span>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Buscar pelo nome do Fornecedor"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {state.loadFornecedor ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col lg="1">
                  <ButtonControl
                    className={`btn ${!!uk ? 'disabled' : ''}`}
                    to={"/admin/fornecedores/novo"}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginTop: "40px", backgroundColor: "#3b5999" }}
                  >
                    <i className="fa fa-plus" aria-hidden="true" style={{ fontSize: "18px", padding: "4px" }} />
                  </ButtonControl>
                </Col>
              </>
            ) : (
              <>
                {!uk ? (
                  <Col lg="2">
                    <FormGroup>
                      <FormControl variant="outlined">
                        <label className="form-control-label">Selecionar Todos</label>
                        <Select
                          name="todosClientes"
                          className="mt-select"
                          placeholder="Selecionar Todos"
                          onChange={value => { handleChange("todosClientes", value.target.value) }}
                          value={state.todosClientes ? state.todosClientes : false}
                          disabled={disabled}
                        >
                          <MenuItem value={false}>Não</MenuItem>
                          <MenuItem value={true}>Sim</MenuItem>
                        </Select>
                      </FormControl>
                    </FormGroup>
                  </Col>
                ) : ''}

                {!state.todosClientes ? (
                  <Col lg={uk ? "12" : "10"}>
                    <FormGroup style={{ position: 'relative', top: -4 }}>
                      <label className="form-control-label" htmlFor="input-username">
                        Associado
                      </label>
                      <Autocomplete
                        disabled={disabled}
                        options={state.associados}
                        getOptionLabel={option => `(${!!option.numeroTitulo ? option.numeroTitulo : option.cpfOrcnpj}) ${option.name}`}
                        onChange={(_, value) => handleChange("clientes", value)}
                        onInputChange={(_, value) => searchAssociado(value)}
                        multiple={uk ? false : true}
                        inputMode="text"
                        key="clientes"
                        loading={state.loadAssociado}
                        value={uk ? state.clientes[0] : state.clientes}
                        renderOption={(option) => (
                          <span>{`(${!!option.numeroTitulo ? option.numeroTitulo : option.cpfOrcnpj}) ${option.name}`}</span>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Buscar Associado"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {state.loadAssociado ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                ) : (
                  <>
                    <Col lg="10" style={{ display: 'flex', alignItems: 'center' }}>
                      <FormGroup className="mt-margin-medium-top" style={{ marginLeft: 50 }}>
                        <Input
                          id="enviar-para-proprietario"
                          className="form-check-input"
                          type="checkbox"
                          onChange={value => handleChange("enviaParaProprietario", !state.enviaParaProprietario)}
                          checked={state.enviaParaProprietario}
                          value={state.enviaParaProprietario}
                          disabled={disabled}
                        />
                        <label for="enviar-para-proprietario" className="form-control-label">Enviar para Proprietário</label>
                      </FormGroup>
                      <FormGroup className="mt-margin-medium-top" style={{ marginLeft: 50 }}>
                        <Input
                          id="enviar-para-remido"
                          className="form-check-input"
                          type="checkbox"
                          onChange={value => handleChange("enviaParaRemido", !state.enviaParaRemido)}
                          checked={state.enviaParaRemido}
                          value={state.enviaParaRemido}
                          disabled={disabled}
                        />
                        <label for="enviar-para-remido" className="form-control-label">Enviar para Remido</label>
                      </FormGroup>
                      <FormGroup className="mt-margin-medium-top" style={{ marginLeft: 50 }}>
                        <Input
                          id="enviar-para-benemerito"
                          className="form-check-input"
                          type="checkbox"
                          onChange={value => handleChange("enviaParaBenemerito", !state.enviaParaBenemerito)}
                          checked={state.enviaParaBenemerito}
                          value={state.enviaParaBenemerito}
                          disabled={disabled}
                        />
                        <label for="enviar-para-benemerito" className="form-control-label">Enviar para Benemérito</label>
                      </FormGroup>
                    </Col>
                  </>
                )}
              </>
            )}

            <Col lg="12">
              <FormGroup>
                <label className="form-control-label">Descrição</label>
                <Input
                  className="form-control"
                  placeholder="Descrição"
                  type="text"
                  onChange={value => handleChange("descricao", value.target.value.toUpperCase())}
                  value={state.descricao}
                  disabled={disabled}
                  maxLength={120}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormControl className="mt-margin-top" variant="outlined">
                <label className="form-control-label">Categoria</label>
                <Autocomplete
                  options={state.categorias}
                  getOptionLabel={(option) => `(${option.codigo}) ${option.nome}`}
                  onChange={(_, value) => handleChange("ct", value)}
                  inputMode="text"
                  key="categorias"
                  value={state.ct}
                  multiple={false}
                  disabled={disabled}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span> ({option.codigo}) {option.nome} </span>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Selecione uma categoria"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {state.loadClientes ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Col>
            <Col lg="6">
              <FormControl className="mt-margin-top" variant="outlined">
                <label className="form-control-label">Departamento</label>
                <Select
                  className="mt-select"
                  value={state.departamento ? state.departamento : 0}
                  onChange={(value) => {
                    handleChange("departamento", value.target.value)
                  }}
                  disabled={disabled}
                >
                  <MenuItem value={0}>Selecione um Departamento</MenuItem>
                  {state.departamentos.map(single => {
                    return <MenuItem key={single.id} value={single.id}>{single.nome}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Col>
          </Row>
        </div >

        <hr className="my-4 mt-margin-large-top" />
        <h6 className="heading-small text-muted mb-4">Financeiro</h6>
        <div className="pl-lg-4">
          <Row>
            {type === 'receber' && (
              <Col lg="3">
                <FormControl variant="outlined" style={{ padding: "10px 0" }}>
                  <label className="form-control-label">Emitir boleto via</label>
                  <Select
                    className="mt-select"
                    value={state.emitirBoletoVia}
                    onChange={value => {
                      handleChange("emitirBoletoVia", value.target.value)

                      if(value.target.value === 'Viacredi') {
                        handleChange("caixa", 10)
                        handleChange("agruparCobrancaMensal", true)
                      }
                    }}
                    defaultValue={state.emitirBoletoVia}
                    disabled={disabled}
                  >
                    <MenuItem value='Viacredi'>Viacredi</MenuItem>
                    <MenuItem value='Superlogica'>Superlogica</MenuItem>
                  </Select>
                </FormControl>
              </Col>
            )}
            <Col lg="3">
              <FormControl variant="outlined" style={{ padding: "10px 0" }}>
                <label className="form-control-label">Conta</label>
                <Select
                  className="mt-select"
                  value={state.caixa}
                  onChange={value => handleChange("caixa", value.target.value)}
                  defaultValue={state.caixa}
                  disabled={disabled || state.emitirBoletoVia === 'Viacredi'}
                >
                  <MenuItem value={0}>Selecione uma Conta</MenuItem>
                  {state.contas.map(single => {
                    return <MenuItem key={single.id} value={single.id}>{single.nome}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Col>
            {state.emitirBoletoVia === 'Viacredi' && (
              <>
                <Col lg="3">
                  <FormControl variant="outlined" style={{ padding: "10px 0" }}>
                    <label className="form-control-label">Agrupar na cobrança mensal?</label>
                    <Select
                      className="mt-select"
                      value={!!state.agruparCobrancaMensal}
                      onChange={value => handleChange("agruparCobrancaMensal", value.target.value)}
                      defaultValue={!!state.agruparCobrancaMensal}
                      disabled={disabled}
                    >
                      <MenuItem value={true}>Sim</MenuItem>
                      <MenuItem value={false}>Não</MenuItem>
                    </Select>
                  </FormControl>
                </Col>

                {!!state.agruparCobrancaMensal && (
                  <Col className='col-lg-3 mt-padding-bottom' style={{ color: '#979797' }}>
                    <FormControl variant="outlined" style={{ padding: "10px 0", flexDirection: 'row' }}>
                      <i className="fas fa-info-circle" style={{ color: '#3f3f3f', paddingRight: 5, paddingTop: 5 }}></i>
                      Será emitido um único boleto no final do mês com essa cobrança
                    </FormControl>
                  </Col>
                )}
              </>
            )}
          </Row>
          <Row>
            <Col lg="3">
              <FormControl variant="outlined" style={{ padding: "10px 0" }}>
                <label className="form-control-label">Recorrência</label>
                <Select
                  className="mt-select"
                  value={state.recorrencia ? state.recorrencia : "Nenhuma"}
                  onChange={value => {
                    handleChange("recorrencia", value.target.value)

                    if (value.target.value !== 'Parcelado') {
                      handleChange("numeroParcelas", 1)
                      handleChange("dtVencimento", moment().add(2, 'days').format('YYYY-MM-DD'));
                      handleChange("status", 0);
                      handleChange("valorPagamento", state.vlTotal);
                    }
                  }}
                  disabled={disabled}
                >
                  <MenuItem value="Nenhuma">Nenhuma</MenuItem>
                  <MenuItem value="Parcelado">Parcelado</MenuItem>
                  <MenuItem value="Fixa Mensal">Fixa Mensal</MenuItem>
                </Select>
              </FormControl>
            </Col>
            <Col lg="3">
              <FormControl variant="outlined" style={{ padding: "10px 0" }}>
                <label className="form-control-label">
                  Forma de Pagamento
                </label>
                <Select
                  className="mt-select"
                  value={state.tipoPagamento}
                  onChange={value => handleChange("tipoPagamento", value.target.value)}
                  disabled={disabled}
                >
                  {type === 'receber' ? (
                    <MenuItem value={1}>Boleto</MenuItem>
                  ) : state.tipoPagamentos.map(value => (
                    <MenuItem key={value.id} value={value.id}>{value.descricao}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col lg="3">
              <FormGroup>
                <label className="form-control-label">Valor Total</label>
                <IntlCurrencyInput
                  className="form-control"
                  currency="BRL"
                  disabled={uk}
                  value={state.vlTotal}
                  config={currencyConfig}
                  onChange={async (event, value) => {
                    await handleChangeValorTotal(event, value);
                    await handleChangeParcelas();
                  }}
                />
              </FormGroup>
            </Col>

            {state.recorrencia === 'Parcelado' && (
              <Col lg="3">
                <FormGroup>
                  <label className="form-control-label">Número de Parcelas</label>
                  <Input
                    type="number"
                    className="form-control"
                    disabled={uk}
                    min={1}
                    value={state.numeroParcelas}
                    onChange={async value => {
                      await handleChange("numeroParcelas", value.target.value);
                      await handleChangeParcelas();
                    }}
                  />
                </FormGroup>
              </Col>
            )}

            {(state.recorrencia === 'Fixa Mensal' && !uk) && (
              <Col lg="3">
                <FormGroup>
                  <label className="form-control-label">Vencimento da primeira parcela</label>
                  <Input
                    type="date"
                    format="YYYY-MM-DD"
                    value={!!state.dataPrimeiraParcela ? moment(state.dataPrimeiraParcela).format('YYYY-MM-DD') : null}
                    onChange={value => {
                      if (!moment(value.target.value).isValid()) return;
                      handleChange("dataPrimeiraParcela", value.target.value)
                    }}
                    className="form-control"
                    disabled={disabled}
                  />
                </FormGroup>
              </Col>
            )}

          </Row>

          {type === 'receber' && state.tipoPagamento === 1 && (
            <Row className="mt-margin-bottom">
              <Col lg="3">
                <FormGroup>
                  <label className="form-control-label">Desconto (%)</label>
                  <Input
                    className="form-control"
                    placeholder="Desconto"
                    type="number"
                    onChange={value => handleChange("desconto", value.target.value.toUpperCase())}
                    value={state.desconto}
                    disabled={disabled}
                  />
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup>
                  <label className="form-control-label">Multa (%)</label>
                  <Input
                    className="form-control"
                    placeholder="Multa"
                    type="number"
                    onChange={value => handleChange("multa", value.target.value.toUpperCase())}
                    value={state.multa}
                    disabled={disabled}
                  />
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup>
                  <label className="form-control-label">Juros (%)</label>
                  <Input
                    className="form-control"
                    placeholder="Juros"
                    type="number"
                    onChange={value => handleChange("juros", value.target.value.toUpperCase())}
                    value={state.juros}
                    disabled={disabled}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}

          {(state.recorrencia !== 'Fixa Mensal' || !!uk) && (
            <div className='mt-margin-medium-top'>
              {uk ? this.renderTotalParcelamentoEdit() : this.renderTotalParcelamento()}
            </div>
          )}
        </div>

        {(state.recorrencia !== 'Fixa Mensal' || !!uk) && (
          <>
            <hr className="my-4 mt-margin-large-top" />
            <h6 className="heading-small text-muted mb-4">Anexo</h6>
            <div className="pl-lg-4">
              <Row>
                <Col lg="12">
                  <Dropzone {...this.props} />
                </Col>
              </Row>
            </div>
          </>
        )}
      </>
    );
  }
}
