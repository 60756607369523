import React, { Component } from 'react';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import { FormControl, MenuItem, Select, TextField, CircularProgress } from '@material-ui/core';
import Config from 'Config';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IntlCurrencyInput from "react-intl-currency-input";
import moment from 'moment';

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export default class FormFields extends Component {

  searchFornecedor = (search) => {
    if (!search) return;

    const { handleChange } = this.props;
    handleChange({ loadSelect: true });

    fetch(Config._HOST + `/api/fornecedor?nome_like=${search}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(response => {
        handleChange({
          dataFornecedores: response.data,
          loadSelect: false,
        })
      }).catch(e => {
        handleChange({
          loadSelect: false
        })
      })
  }

  render() {
    const { state, handleChange, handleChangeValorTotal } = this.props;

    return (
      <>
        <div className="mt-margin-left mt-margin-right">
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Descricao</label>
                <Input
                  className="form-control"
                  placeholder="Descricao"
                  type="text"
                  onChange={value => handleChange({ descricao: value.target.value.toUpperCase() })}
                  value={state.descricao}
                />
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <label className="form-control-label">Quantidade</label>
                <Input
                  className="form-control"
                  placeholder="Quantidade"
                  type="number"
                  onChange={value => handleChange({ quantidade: value.target.value.toUpperCase() })}
                  value={state.quantidade}
                />
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <label className="form-control-label">Preço</label>
                <IntlCurrencyInput
                  className="form-control"
                  currency="BRL"
                  value={state.preco}
                  config={currencyConfig}
                  onChange={handleChangeValorTotal} />
              </FormGroup>
            </Col>

            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Fornecedor</label>
                <Autocomplete
                  options={state.dataFornecedores}
                  getOptionLabel={(option) => option.nome}
                  onChange={(_, value) => handleChange({ "fornecedor": value })}
                  onInputChange={(_, value) => this.searchFornecedor(value)}
                  inputMode="text"
                  key="fornecedor"
                  loading={state.loadSelect}
                  value={state.fornecedor ? state.fornecedor : null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Buscar pelo nome"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {state.loadSelect ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormGroup>
            </Col>

            <Col lg="3">
              <FormGroup>
                <FormControl variant="outlined">
                  <label className="form-control-label">Condição de Pagamento</label>
                  <Select
                    name="condicaoPagamento"
                    className="mt-select"
                    placeholder="Condição de Pagamento"
                    onChange={value => handleChange({ 'condicaoPagamento': value.target.value })}
                    value={state.condicaoPagamento}
                  >
                    <MenuItem value="Boleto">Boleto</MenuItem>
                    <MenuItem value="Conta Bancaria">Conta Bancaria</MenuItem>
                    <MenuItem value="Pix">Pix</MenuItem>
                  </Select>
                </FormControl>
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <FormControl>
                  <label className="form-control-label">Data de Compra</label>
                  <Input
                    type="date"
                    name="dataCompra"
                    value={state.dataCompra}
                    onChange={value => {
                      if (moment(value.target.value.toUpperCase()).isValid())
                        handleChange({ dataCompra: value.target.value.toUpperCase() })
                    }}
                    className="form-control"
                  />
                </FormControl>
              </FormGroup>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
