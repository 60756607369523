/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Card, CardHeader, Table, Container, Row, Button } from "reactstrap";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import { Link } from "react-router-dom";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { ButtonGroup } from "@material-ui/core";
import { withSnackbar } from "notistack";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import api from "services/api";
import ButtonControl from "views/components/ButtonControl";

class FinanceiroDepartamentoList extends React.Component {

  state = {
    data: [],
    dataComplete: [],
  };

  componentDidMount() {
    this.findAll()
  }

  findAll = async () => {
    this.setState({ loading: true });
    const response = await api.get("/api/financeiro/caixa?_limit=1000");

    if (response) {
      this.setState({
        ...response,
        loading: false,
      })
    }
    else {
      this.setState({ loading: false })
    }
  }

  renderContent() {
    const { data, loading } = this.state;

    if (loading) return <BoxLoading />

    if (data.length === 0) {
      return <CardHeader className="border-0">
        <h3 className="mb-0">Não existem caixas cadastrados</h3>
      </CardHeader>
    }

    return (
      <div>
        <Table className="align-items-center table-flush table-with-button" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">NOME</th>
              <th scope="col">TIPO</th>
              <th scope="col">PRINCIPAL</th>
              <th scope="col">ATIVO</th>
              <th scope="col">AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {data.map(single => {
              return (
                <tr>
                  <td>{single.nome ? single.nome : "-"}</td>
                  <td>{single.tipo ? single.tipo : "-"}</td>
                  <td>{single.principal ? "Sim" : "Não"}</td>
                  <td>{single.ativo ? "Sim" : "Não"}</td>
                  <td>
                    <div className="avatar-group">
                      <ButtonControl color='secondary' to={`/admin/financeiro/caixas/${single.id}`}>
                        <i className="fas fa-edit"></i>
                      </ButtonControl>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    )
  }


  renderContentIten() {
    let data = []
    for (let single of this.state.data) {
      data.push(<tr>
        <td>
          <span className="mb-0 text-sm">
            {single.nome}
          </span>
        </td>
        <td>
          <span className="mb-0 text-sm">
            {single.descricao}
          </span>
        </td>
        <td className="text-right">
          <Link to={"/admin/financeiro/categoria/" + single.uk}>
            <Button
              color="orange"
            >
              <i className="fas fa-edit" />
            </Button>
          </Link>
        </td>
      </tr>)
    }
    return data
  }

  render() {
    return (
      <>
        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color="secondary" to="/admin/financeiro">
                    <i className="fas fa-arrow-left" /> VOLTAR
                  </ButtonControl>
                  <ButtonControl color='secondary' to='/admin/financeiro/caixa/novo'>
                    <i className="fas fa-plus" /> NOVO
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"Conta"}
          subTitulo={"Módulo de gestão para Contas"}
        />

        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">

                <CardHeader className="border-0">
                  <h3 className="mb-0">Total Caixas ({this.state.data.length})</h3>
                </CardHeader>
                {this.renderContent()}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(FinanceiroDepartamentoList);
