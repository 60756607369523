import { CircularProgress, FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Editor } from '@tinymce/tinymce-react';
import 'assets/css/react-form-builder2.css';
import { ReactFormBuilder } from 'views/components/react-form-builder';
import { Component } from 'react';
import { Col, FormGroup, Input, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import Dropzone from 'views/components/Dropzone';
import IntlCurrencyInput from "react-intl-currency-input";
import Preview from './Preview';
import moment from 'moment';

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};


var items = [
  { key: 'TextInput', name: 'Texto' },
  { key: 'TextArea', name: 'Texto grande' },
  { key: 'DatePicker', name: 'Data' },
  { key: 'EmailInput', name: 'E-mail' },
  { key: 'NumberInput', name: 'Número', icon: "fas fa-sort-numeric-up-alt" },
  { key: 'PhoneNumber', name: 'Telefone' },
  { key: 'Dropdown', name: 'Única seleção' },
  { key: 'Tags', name: 'Múltipla seleção', icon: "fas fa-check-double" },
  { key: 'Checkboxes', name: 'Múltipla escolha' },
  { key: 'RadioButtons', name: 'Única escolha' },
  { key: 'Range', name: 'Intervalo' },
  // { key: 'Header', name: 'Título' },
  // { key: 'LineBreak', name: 'Divisor' },
  { key: 'Image', name: 'Imagem' },
  { key: 'Rating', name: 'Avaliação' },
  { key: 'HyperLink', name: 'Link' },
  // { key: 'FileUpload', name: 'Arquivo' },
];

export default class FormFields extends Component {

  state = {
    activeTab: '1'
  };

  toggle = this.toggle.bind(this);

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    const { handleChange, state } = this.props;

    return (
      <div>
        <Nav tabs>

          <NavItem>
            <NavLink className={this.state.activeTab === '1' && 'active'} onClick={() => { this.toggle('1'); }}>
              Configurações
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink className={this.state.activeTab === '2' && 'active'} onClick={() => { this.toggle('2'); }}>
              Cabeçalho
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink className={this.state.activeTab === '3' && 'active'} onClick={() => { this.toggle('3'); }}>
              Formulário
            </NavLink>
          </NavItem>

          {!!state.taxa_inscricao && (
            <NavItem>
              <NavLink className={this.state.activeTab === '4' && 'active'} onClick={() => { this.toggle('4'); }}>
                Taxa de Inscrição
              </NavLink>
            </NavItem>
          )}

          {!!state.mensalidade && (
            <NavItem>
              <NavLink className={this.state.activeTab === '5' && 'active'} onClick={() => { this.toggle('5'); }}>
                Mensalidade
              </NavLink>
            </NavItem>
          )}

        </Nav>
        <TabContent activeTab={this.state.activeTab}>

          <TabPane tabId="1">
            <Row className='mt-margin-top'>
              <Col lg="3">
                <div className="custom-control custom-control-alternative custom-checkbox mt-margin-xsmall-left" style={{ marginTop: 50 }}>
                  <input
                    className="custom-control-input"
                    id="ativo"
                    type="checkbox"
                    onChange={value => handleChange({ ativo: !state.ativo })}
                    checked={state.ativo}
                    value={state.ativo}
                  />
                  <label className="custom-control-label" htmlFor="ativo">
                    Inscrição ativa
                  </label>
                </div>
              </Col>
              <Col lg="3">
                <FormGroup>
                  <label className="form-control-label">Data Início</label>
                  <Input
                    type="date"
                    format="YYYY-MM-DD"
                    className="form-control"
                    value={!!state.data_inicio ? state.data_inicio : ''}
                    onChange={value => {
                      const data_inicio = value.target.value;

                      if(!!state.data_fim && moment(state.data_fim).isBefore(data_inicio))
                        handleChange({ data_inicio, data_fim: null })
                      else
                        handleChange({ data_inicio })
                    }}
                  />
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup>
                  <label className="form-control-label">Data Fim</label>
                  <Input
                    type="date"
                    format="YYYY-MM-DD"
                    className="form-control"
                    min={!!state.data_inicio ? state.data_inicio : null}
                    value={state.data_fim ? state.data_fim : ''}
                    onChange={value => handleChange({ data_fim: value.target.value })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <div className="custom-control custom-control-alternative custom-checkbox mt-margin-xsmall-left mt-margin-medium-top">
                  <input
                    className="custom-control-input"
                    id="taxa_inscricao"
                    type="checkbox"
                    onChange={value => handleChange({ taxa_inscricao: !state.taxa_inscricao })}
                    checked={state.taxa_inscricao}
                    value={state.taxa_inscricao}
                  />
                  <label className="custom-control-label" htmlFor="taxa_inscricao">
                    Ativar Taxa de Inscrição
                  </label>
                </div>
              </Col>
              <Col lg="3">
                <div className="custom-control custom-control-alternative custom-checkbox mt-margin-xsmall-left mt-margin-medium-top">
                  <input
                    className="custom-control-input"
                    id="mensalidade"
                    type="checkbox"
                    onChange={value => {
                      handleChange({ mensalidade: !state.mensalidade });
                    }}
                    checked={state.mensalidade}
                    value={state.mensalidade}
                  />
                  <label className="custom-control-label" htmlFor="mensalidade">
                    Ativar Mensalidade
                  </label>
                </div>
              </Col>
              <Col lg="3">
                <div className="custom-control custom-control-alternative custom-checkbox mt-margin-xsmall-left mt-margin-medium-top">
                  <input
                    className="custom-control-input"
                    id="requer_aprovacao"
                    type="checkbox"
                    onChange={value => handleChange({ requer_aprovacao: !state.requer_aprovacao })}
                    checked={state.requer_aprovacao}
                    value={state.requer_aprovacao}
                    disabled
                  />
                  <label className="custom-control-label" htmlFor="requer_aprovacao">
                    Inscrição Requer Aprovação
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <div className="custom-control custom-control-alternative custom-checkbox mt-margin-xsmall-left mt-margin-medium-top">
                  <input
                    className="custom-control-input"
                    id="hibilitado_para_socio"
                    type="checkbox"
                    onChange={value => handleChange({ hibilitado_para_socio: !state.hibilitado_para_socio })}
                    checked={state.hibilitado_para_socio}
                    value={state.hibilitado_para_socio}
                  />
                  <label className="custom-control-label" htmlFor="hibilitado_para_socio">
                    Habilitar para sócio
                  </label>
                </div>
              </Col>
              <Col lg="3">
                <div className="custom-control custom-control-alternative custom-checkbox mt-margin-xsmall-left mt-margin-medium-top">
                  <input
                    className="custom-control-input"
                    id="hibilitado_para_nao_socio"
                    type="checkbox"
                    onChange={value => handleChange({ hibilitado_para_nao_socio: !state.hibilitado_para_nao_socio })}
                    checked={state.hibilitado_para_nao_socio}
                    value={state.hibilitado_para_nao_socio}
                  />
                  <label className="custom-control-label" htmlFor="hibilitado_para_nao_socio">
                    Habilitar para não sócio
                  </label>
                </div>
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="2">
            <Row className='mt-margin-medium-top'>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label">Título</label>
                  <Input
                    className="form-control"
                    placeholder="Título"
                    type="text"
                    onChange={value => handleChange({ titulo: value.target.value.toUpperCase() })}
                    value={!!('titulo' in state) ? state.titulo : ''}
                  />
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup>
                  <label className="form-control-label">Cor Primária</label>
                  <Input
                    className="form-control"
                    placeholder="Cor"
                    type="color"
                    onChange={value => handleChange({ cor_primaria: value.target.value.toUpperCase() })}
                    value={state.cor_primaria}
                  />
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup>
                  <label className="form-control-label">Cor de Contraste</label>
                  <Input
                    className="form-control"
                    placeholder="Cor"
                    type="color"
                    onChange={value => handleChange({ cor_contraste: value.target.value.toUpperCase() })}
                    value={state.cor_contraste}
                  />
                </FormGroup>
              </Col>
              <Col lg="12" className='mt-margin-medium-top'>
                <label className="form-control-label">Imagem</label>
                <Dropzone {...this.props} handleChange={this.props.handleChangeDropzone} maxFiles={1} acceptedFiles={['.jpg', '.jpeg', '.png']} />
              </Col>
              <Col lg="12" className='mt-margin-top'>
                <label className="form-control-label">Descrição</label>
                <Editor
                  apiKey="215zb0qgsla41aau558l9p9s2osddsg0n9az2i3ayglpvi6p"
                  value={state.descricao}
                  onEditorChange={(content) => handleChange({ descricao: content })}
                  init={{
                    height: 500,
                    selector: 'textarea',
                    language: 'pt_BR',
                    menubar: 'edit view insert format table',
                    // plugins: [
                    //   'advlist autolink lists link image charmap',
                    //   'searchreplace visualblocks code fullscreen',
                    //   'insertdatetime media table paste code help wordcount'
                    // ],
                    plugins: [
                      'paste importcss searchreplace autolink',
                      'directionality code visualblocks visualchars fullscreen',
                      'image link media template table charmap hr',
                      'nonbreaking anchor toc insertdatetime advlist lists wordcount',
                      'imagetools textpattern noneditable help charmap quickbars emoticons'
                    ],
                    toolbar: 'undo redo | fontselect formatselect fontsizeselect | ' +
                      'bold italic underline strikethrough forecolor backcolor removeformat |' +
                      'alignleft aligncenter alignright alignjustify |' +
                      'bullist numlist | outdent indent | table image link |' +
                      'charmap fullscreen preview | template ',
                    fontsize_formats: '8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 19px 20px ' +
                      '21px 22px 23px 24px 25px 26px 26px 27px 28px 29px 30px ' +
                      '31px 32px 33px 34px 35px 36px 36px 37px 38px 39px 40px'
                  }}
                />
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="3">
            {!state.loading && (
              <div className='mt-margin-top'>
                <Preview {...this.props} />
                <ReactFormBuilder toolbarItems={items} data={state.form} />
              </div>
            )}
          </TabPane>

          <TabPane tabId="4">
            <Row>
              <Col lg="12">
                <FormGroup className='mt-margin-medium-top'>
                  <label className="form-control-label">Descrição da cobrança</label>
                  <Input
                    className="form-control"
                    placeholder="Descrição da cobrança"
                    type="text"
                    onChange={value => handleChange({ taxa_inscricao_descricao: value.target.value.toUpperCase() })}
                    value={state.taxa_inscricao_descricao}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormControl className="mt-margin-top" variant="outlined">
                  <label className="form-control-label">Categoria</label>
                  <Autocomplete
                    options={state.categorias}
                    getOptionLabel={(option) => `(${option.codigo}) ${option.nome}`}
                    onChange={(_, value) => handleChange({ taxa_inscricao_categoria: value })}
                    inputMode="text"
                    key="categorias"
                    value={state.taxa_inscricao_categoria}
                    renderOption={option => <span> ({option.codigo}) {option.nome} </span>}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Selecione uma categoria"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {state.loadClientes ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Col>
              <Col lg="3">
                <FormControl className="mt-margin-top" variant="outlined">
                  <label className="form-control-label">Departamento</label>
                  <Select
                    className="mt-select"
                    value={state.taxa_inscricao_departamento ? state.taxa_inscricao_departamento.id : 0}
                    onChange={(value) => {
                      handleChange({ taxa_inscricao_departamento: { id: value.target.value } })
                    }}
                  >
                    <MenuItem value={0}>Selecione um Departamento</MenuItem>
                    {state.departamentos.map(single => {
                      return <MenuItem key={single.id} value={single.id}>{single.nome}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Col>
              <Col lg="3">
                <FormControl className="mt-margin-top" variant="outlined">
                  <label className="form-control-label">Conta</label>
                  <Select
                    className="mt-select"
                    value={state.taxa_inscricao_conta ? state.taxa_inscricao_conta.id : 0}
                    onChange={value => handleChange({ taxa_inscricao_conta: { id: value.target.value } })}
                  >
                    <MenuItem value={0}>Selecione uma Conta</MenuItem>
                    {state.contas.map(single => {
                      return <MenuItem key={single.id} value={single.id}>{single.nome}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Col>
              <Col lg="3">
                <FormGroup className="mt-margin-top">
                  <label className="form-control-label">Valor para sócio</label>
                  <IntlCurrencyInput
                    className="form-control"
                    currency="BRL"
                    value={state.taxa_inscricao_valor}
                    config={currencyConfig}
                    onChange={(event, value) => {
                      handleChange({ taxa_inscricao_valor: value })
                    }}
                  />
                </FormGroup>
              </Col>

              {state.hibilitado_para_nao_socio && (
                <Col lg="3">
                  <FormGroup className="mt-margin-top">
                    <label className="form-control-label">Valor para não sócio</label>
                    <IntlCurrencyInput
                      className="form-control"
                      currency="BRL"
                      value={state.taxa_inscricao_valor_nao_socio}
                      config={currencyConfig}
                      onChange={(event, value) => {
                        handleChange({ taxa_inscricao_valor_nao_socio: value })
                      }}
                    />
                  </FormGroup>
                </Col>
              )}

              <Col lg="3">
                <FormGroup className="mt-margin-top">
                  <label className="form-control-label">Multa (%)</label>
                  <Input
                    className="form-control"
                    placeholder="Multa"
                    type="number"
                    onChange={value => handleChange({ taxa_inscricao_multa: value.target.value.toUpperCase() })}
                    value={state.taxa_inscricao_multa}
                  />
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup className="mt-margin-top">
                  <label className="form-control-label">Juros (%)</label>
                  <Input
                    className="form-control"
                    placeholder="Juros"
                    type="number"
                    onChange={value => handleChange({ taxa_inscricao_juros: value.target.value.toUpperCase() })}
                    value={state.taxa_inscricao_juros}
                  />
                </FormGroup>
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="5">
            <Row>
              <Col lg="12">
                <FormGroup className='mt-margin-medium-top'>
                  <label className="form-control-label">Descrição da cobrança</label>
                  <Input
                    className="form-control"
                    placeholder="Descrição da cobrança"
                    type="text"
                    onChange={value => handleChange({ mensalidade_descricao: value.target.value.toUpperCase() })}
                    value={state.mensalidade_descricao}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormControl className="mt-margin-top" variant="outlined">
                  <label className="form-control-label">Categoria</label>
                  <Autocomplete
                    options={state.categorias}
                    getOptionLabel={(option) => `(${option.codigo}) ${option.nome}`}
                    onChange={(_, value) => handleChange({ mensalidade_categoria: value })}
                    inputMode="text"
                    key="categorias"
                    value={state.mensalidade_categoria}
                    renderOption={option => <span> ({option.codigo}) {option.nome} </span>}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Selecione uma categoria"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {state.loadClientes ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Col>
              <Col lg="3">
                <FormControl className="mt-margin-top" variant="outlined">
                  <label className="form-control-label">Departamento</label>
                  <Select
                    className="mt-select"
                    value={state.mensalidade_departamento ? state.mensalidade_departamento.id : 0}
                    onChange={(value) => {
                      handleChange({ mensalidade_departamento: { id: value.target.value } })
                    }}
                  >
                    <MenuItem value={0}>Selecione um Departamento</MenuItem>
                    {state.departamentos.map(single => {
                      return <MenuItem key={single.id} value={single.id}>{single.nome}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Col>
              <Col lg="3">
                <FormControl className="mt-margin-top" variant="outlined">
                  <label className="form-control-label">Conta</label>
                  <Select
                    className="mt-select"
                    value={state.mensalidade_conta ? state.mensalidade_conta.id : 0}
                    onChange={value => handleChange({ mensalidade_conta: { id: value.target.value } })}
                  >
                    <MenuItem value={0}>Selecione uma Conta</MenuItem>
                    {state.contas.map(single => {
                      return <MenuItem key={single.id} value={single.id}>{single.nome}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Col>
              <Col lg="3">
                <FormGroup className="mt-margin-top">
                  <label className="form-control-label">Valor para sócio</label>
                  <IntlCurrencyInput
                    className="form-control"
                    currency="BRL"
                    value={state.mensalidade_valor}
                    config={currencyConfig}
                    onChange={(event, value) => {
                      handleChange({ mensalidade_valor: value })
                    }}
                  />
                </FormGroup>
              </Col>
              {state.hibilitado_para_nao_socio && (
                <Col lg="3">
                  <FormGroup className="mt-margin-top">
                    <label className="form-control-label">Valor para não sócio</label>
                    <IntlCurrencyInput
                      className="form-control"
                      currency="BRL"
                      value={state.mensalidade_valor_nao_socio}
                      config={currencyConfig}
                      onChange={(event, value) => {
                        handleChange({ mensalidade_valor_nao_socio: value })
                      }}
                    />
                  </FormGroup>
                </Col>
              )}
              <Col lg="3">
                <FormGroup className="mt-margin-top">
                  <label className="form-control-label">Multa (%)</label>
                  <Input
                    className="form-control"
                    placeholder="Multa"
                    type="number"
                    onChange={value => handleChange({ mensalidade_multa: value.target.value.toUpperCase() })}
                    value={state.mensalidade_multa}
                  />
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup className="mt-margin-top">
                  <label className="form-control-label">Juros (%)</label>
                  <Input
                    className="form-control"
                    placeholder="Juros"
                    type="number"
                    onChange={value => handleChange({ mensalidade_juros: value.target.value.toUpperCase() })}
                    value={state.mensalidade_juros}
                  />
                </FormGroup>
              </Col>
            </Row>
          </TabPane>

        </TabContent>
      </div>
    );
  }
}
