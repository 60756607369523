import { FormControl, MenuItem, Select } from '@material-ui/core';
import React, { Component } from 'react';
import { Col, FormGroup, Input, Row } from 'reactstrap';

export default class InformacoesGerais extends Component {
  render() {
    const { state, handleChange } = this.props;

    return (
      <div className="mt-margin-left mt-margin-right">
        <Row>
          <Col lg="2">
            <FormGroup>
              <label className="form-control-label">Cor de Fundo</label>
              <Input className="form-control"
                placeholder="Cor de Fundo"
                type="color"
                value={state.corFundo}
                onChange={(value) => handleChange("corFundo", value.target.value.toUpperCase())}
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <label className="form-control-label">Cor de Texto</label>
              <Input className="form-control"
                placeholder="Cor de Texto"
                type="color"
                value={state.corTexto}
                onChange={(value) => handleChange("corTexto", value.target.value.toUpperCase())}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <label className="form-control-label">Nome</label>
              <Input
                className="form-control"
                placeholder="Nome Completo"
                type="text"
                onChange={(value) => handleChange("nome", value.target.value.toUpperCase())}
                value={state.nome ? state.nome : ''}
              />
            </FormGroup>
          </Col>

          <Col lg="12">
            <FormGroup>
              <label className="form-control-label">Descrição</label>
              <Input
                className="form-control"
                placeholder="Descrição"
                type="text"
                onChange={(value) => handleChange("descricao", value.target.value.toUpperCase())}
                value={state.descricao ? state.descricao : ''}
              />
            </FormGroup>
          </Col>

          <Col lg="12">
            <FormGroup>
              <label className="form-control-label">Lista Itens</label>
              <Input className="form-control"
                placeholder="Lista Itens"
                type="textarea"
                rows="5"
                value={state.listaItens ? state.listaItens : ''}
                onChange={(value) => handleChange("listaItens", value.target.value.toUpperCase())}
              />
            </FormGroup>
          </Col>

          <Col lg="3">
            <FormControl variant="outlined">
              <label className="form-control-label">Ativo</label>
              <Select
                value={state.ativo ? state.ativo : false}
                onChange={(value) => handleChange("ativo", value.target.value)}
              >
                <MenuItem value={false}>Não</MenuItem>
                <MenuItem value={true}>Sim</MenuItem>
              </Select>
            </FormControl>
          </Col>

          {state.ativo && (
            <Col lg="3">
              <FormControl variant="outlined">
                <label className="form-control-label">Permitir Locação</label>
                <Select
                  value={state.permitirLocacao ? state.permitirLocacao : false}
                  onChange={(value) => handleChange("permitirLocacao", value.target.value)}
                >
                  <MenuItem value={false}>Não</MenuItem>
                  <MenuItem value={true}>Sim</MenuItem>
                </Select>
              </FormControl>
            </Col>
          )}

        </Row>

        {state.ativo && state.permitirLocacao && (
          <Row>
            <Col lg="3">
              <FormControl variant="outlined" className='mt-margin-small-top'>
                <label className="form-control-label">Permitir Locação no Aplicativo</label>
                <Select
                  value={state.permitirLocacaoApp ? state.permitirLocacaoApp : false}
                  onChange={(value) => handleChange("permitirLocacaoApp", value.target.value)}
                >
                  <MenuItem value={false}>Não</MenuItem>
                  <MenuItem value={true}>Sim</MenuItem>
                </Select>
              </FormControl>
            </Col>

            <Col lg="3">
              <FormControl variant="outlined" className='mt-margin-small-top'>
                <label className="form-control-label">Permite Aluguéis Simultâneo</label>
                <Select
                  value={state.permitirAluguelSimultaneo ? state.permitirAluguelSimultaneo : false}
                  onChange={(value) => handleChange("permitirAluguelSimultaneo", value.target.value)}
                >
                  <MenuItem value={false}>Não</MenuItem>
                  <MenuItem value={true}>Sim</MenuItem>
                </Select>
              </FormControl>
            </Col>

            <Col lg="3">
              <FormGroup>
                <label className="form-control-label">Capacidade de pessoas</label>
                <Input className="form-control"
                  placeholder="Capacidade de pessoas"
                  type="number"
                  value={state.capacidadePessoas ? state.capacidadePessoas : ''}
                  onChange={(value) => handleChange("capacidadePessoas", value.target.value.toUpperCase())}
                />
              </FormGroup>
            </Col>
          </Row>
        )}

      </div>
    );
  }
}
