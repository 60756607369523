/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { CardHeader, Button, Table, Container, Row, Card } from "reactstrap";
import Config from "Config";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { ButtonGroup } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { confirmAlert } from "react-confirm-alert";
import ButtonControl from "views/components/ButtonControl";

import 'react-confirm-alert/src/react-confirm-alert.css';

class Categorias extends React.Component {
  state = {
    data: [],
  };

  componentDidMount() {
    this.findAll()
  }

  findAll = () => {
    this.setState({ loading: true })

    fetch(Config._HOST + '/api/manutencao/categoria')
      .then(response => response.json())
      .then(response => {
        this.setState({
          data: response.status === 200 ? response.data : [],
          loading: false,
        })
      }).catch(e => {
        this.setState({ loading: false })
      })
  }

  handleRemove = (uk) => {
    confirmAlert({
      title: 'Deseja Deletar o cadastro?',
      message: '',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim', onClick: () => {
            this.setState({ loading: true })

            fetch(Config._HOST + `/api/manutencao/categoria`,
              {
                method: 'DELETE',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ uk: uk })
              },
            )
              .then(response => response.json())
              .then(data => {
                if (data.status === 200) {
                  const dataUpdated = this.state.data.filter(row => row.uk !== uk);
                  this.setState({ data: dataUpdated });
                }

                this.props.enqueueSnackbar(data.mensagem ? data.mensagem : 'Ops, ocorreu um erro!')
                this.setState({ loading: false })
              })
              .catch(e => {
                this.setState({ loading: false })
                this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
              })

          }
        }
      ]
    })

  }

  renderContent() {
    if (this.state.loading) {
      return <BoxLoading />
    }

    if (this.state.data.length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Não existem categorias cadastradas</h3>
        </CardHeader>
      );
    }

    return (
      <div>
        <Table className="align-items-center table-flush table-with-button" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">NOME</th>
              <th scope="col" className="text-right mt-padding-medium-right">AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map(single => {
              return (
                <tr key={single.uk}>
                  <th>{single.nome ? single.nome : "-"}</th>
                  <td>
                    <div className="text-right">
                      <Button color="orange" onClick={() => this.props.history.push(`/admin/manutencoes/categorias/${single.uk}`)}>
                        <i className="fas fa-edit"></i>
                      </Button>
                      <Button color="orange" onClick={() => this.handleRemove(single.uk)}>
                        <i className="fas fa-trash"></i>
                      </Button>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    return (
      <>

        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to='/admin/manutencoes'>
                    <i className="fas fa-arrow-left" /> VOLTAR
                  </ButtonControl>
                  <ButtonControl color='secondary' to='/admin/manutencoes/categorias/novo'>
                    <i className="fas fa-plus"></i> NOVO
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"CATEGORIAS DA MANUTENÇÃO"}
          subTitulo={"Módulo de Gestão para categorias"}
        />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Total categorias ({this.state.data.length})</h3>
                </CardHeader>

                {this.renderContent()}

              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(Categorias);
