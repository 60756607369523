import axios from 'axios';
import { getToken } from 'Config';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Request
api.interceptors.request.use(
  config => {
    const token = getToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  }, error => {
    return null;
  }, { synchronous: true }
);

// Response
api.interceptors.response.use(
  response => {
    return response.data;
  }, error => {
    return error.response;
  }, { synchronous: true }
);

export default api;
