/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { CardHeader, Button, Table, Container, Row, Card, Col, FormGroup, Input, ListGroup, ListGroupItem } from "reactstrap";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { ButtonGroup, FormControl, MenuItem, Select } from "@material-ui/core";
import { withSnackbar } from "notistack";
import 'react-confirm-alert/src/react-confirm-alert.css';
import api from "services/api";
import Filter from "views/components/Filter";
import { jsonToUrlFilter } from "utils/filter";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import Config from "Config";
import ButtonExport from "utils/ButtonExport";
import { DescricaoStatusInscricao } from "utils/StatusInscricao";
import ButtonControl from "views/components/ButtonControl";

class InscricoesInscritos extends Component {
  state = {
    data: [],
    loading: false,
    loadingMore: false,
    previewVisible: false,
    modal: [],
    filtrar_data: 'data_cadastro',
    filter: {
      nome_like: '',
      inscricao: { id: this.props.match.params.id },
      status: ['Aguardando Aprovação'],
      usuario: ['not_null','null'],
      _page: 1,
      _limit: 10000,
      _order: 'desc',
      _sort: "data_cadastro"
    },
  };

  stateFilterLimpar = { ...this.state.filter };

  async componentDidMount() {
    await this.getInscricao();
    await this.findInscritos();
  }

  getInscricao = async () => {
    const { id } = this.props.match.params;
    const inscricao = await api.get(`/api/inscricoes/${id}`);
    this.setState({ inscricao });
  }

  getInscritos = async (filter, data, removeData) => {
    const response = await api.get(`/api/inscricoes/inscritos?${jsonToUrlFilter(filter)}`);

    if (response) {
      if (removeData) {
        this.setState({
          ...response,
          loading: false,
          loadingMore: false,
        })
      }
      else {
        this.setState({
          ...response,
          data: [...data, ...response.data],
          loading: false,
          loadingMore: false
        })
      }
    }
    else {
      this.setState({ loading: false, loadingMore: false })
    }
  }

  findInscritos = async (removeData = true) => {
    const { data, filter, loading } = this.state;
    if (loading) return;

    await this.setState({ loading: removeData, loadingMore: !removeData });

    if (removeData && filter._page > 1) {
      await this.setState({ filter: { ...filter, _page: 1 } })
    }

    let tmpFilter = { ...filter };

    if (tmpFilter["nome_like"].length === 0)
      delete tmpFilter['nome_like'];

    if(`${this.state.filtrar_data}>=` in tmpFilter && (tmpFilter[`${this.state.filtrar_data}>=`] === null || tmpFilter[`${this.state.filtrar_data}>=`].length === 0)) {
      delete tmpFilter[`${this.state.filtrar_data}>=`];
    }

    if(`${this.state.filtrar_data}<=` in tmpFilter && (tmpFilter[`${this.state.filtrar_data}<=`] === null || tmpFilter[`${this.state.filtrar_data}<=`].length === 0)) {
      delete tmpFilter[`${this.state.filtrar_data}<=`];
    }

    this.getInscritos(tmpFilter, data, removeData);
  }

  loadMore = () => {
    const { filter } = this.state;

    this.setState({
      filter: {
        ...filter,
        _page: this.state.filter._page + 1
      }
    }, () => {
      this.findInscritos(false);
    });
  }

  handleLimpar = () => {
    this.setState({ filter: this.stateFilterLimpar }, () => {
      this.findInscritos();
    });
  }

  handleAprovar = async inscricao => {
    if(!inscricao.usuario) {
      this.props.enqueueSnackbar("Inscrição com falta de informação para gerar o boleto! Refazer inscrição.");
      return
    }
    else if(inscricao.usuario.listaEnderecos.length === 0) {
      this.props.enqueueSnackbar("Inscrito não tem endereço cadastrado!");
      return
    }

    const endereco = inscricao.usuario.listaEnderecos[0];

    if(!endereco.cep) {
      this.props.enqueueSnackbar("Inscrito não tem 'CEP' cadastrado!");
      return;
    }
    else if(!endereco.estado) {
      this.props.enqueueSnackbar("Inscrito não tem 'Estado' cadastrado!");
      return;
    }
    else if(!endereco.cidade) {
      this.props.enqueueSnackbar("Inscrito não tem 'Cidade' cadastrado!");
      return;
    }
    else if(!endereco.bairro) {
      this.props.enqueueSnackbar("Inscrito não tem 'Bairro' cadastrado!");
      return;
    }
    else if(!endereco.logradouro) {
      this.props.enqueueSnackbar("Inscrito não tem 'Logradouro' cadastrado!");
      return;
    }
    else if(!endereco.numero) {
      this.props.enqueueSnackbar("Inscrito não tem 'Número' cadastrado!");
      return;
    }
    else if(!endereco.complemento) {
      this.props.enqueueSnackbar("Inscrito não tem 'Complemento' cadastrado!");
      return;
    }

    this.setState({ modal: {} }, () => {
      confirmAlert({
        closeOnEscape: false,
        closeOnClickOutside: false,
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body">
              <h1>Deseja mesmo 'Aprovar'?</h1>
              Nome do Inscrito: {inscricao.tipo_socio === 'Não Sócio' ? (!!inscricao.nome ? inscricao.nome : inscricao.usuario.name) : inscricao.usuario.name}
              <div className="react-confirm-alert-button-group">
                <button id="react-confirm-alert-loading" style={{ display: 'none' }}>
                  <i className="fas fa-spinner fa-pulse"></i>
                </button>
                <button onClick={onClose}>Não</button>
                <button
                  onClick={async () => {
                    let user = JSON.parse(window.localStorage.getItem(Config._USER));
                    this.setState({ loading: true });
                    onClose();

                    let data = {
                      status: inscricao.inscricao.taxa_inscricao ? 'Aguardando Pagamento' : 'Confirmado',
                      aprovado_by: { id: user.id },
                      aprovado_at: moment().format('YYYY-MM-DD\\THH:mm:ss'),
                    }

                    const response = await api.put(`/api/inscricoes/inscritos/${inscricao.id}`, data);

                    if(!response || ('data' in response && 'error' in response.data && 'status' in response.data)) {
                      this.props.enqueueSnackbar(response.mensagem);
                      this.setState({ loading: false });
                    }
                    else {
                      this.props.enqueueSnackbar("Inscrição Aprovada!");
                      this.setState({ loading: false });
                      setTimeout(() => {
                        this.findInscritos();
                      }, 300);
                    }
                  }}
                >
                  Sim
                </button>
              </div>
            </div>
          );
        }
      })
    });
  }

  handleCancelar = (inscricao, status) => {
    this.setState({ modal: {} }, () => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body">
              <h1>Deseja mesmo '{status === 'Reprovado' ? "Reprovar" : "Cancelar"}'?</h1>

              Esta operação não poderá ser desfeita.
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label">Observação</label>
                  <Input
                    type="textarea"
                    className="form-control"
                    rows={5}
                    onChange={value => this.setState({ modal: { ...this.state.modal, observacao: value.target.value } })}
                  />
                </FormGroup>
              </Col>

              <div className="react-confirm-alert-button-group">
                <button onClick={onClose}>Não</button>
                <button
                  onClick={async () => {
                    const { modal } = this.state;

                    if(!modal.observacao) {
                      this.props.enqueueSnackbar("Preencha o campo 'Observação'.");
                      return;
                    }

                    this.setState({ loading: true });
                    onClose();
                    let user = JSON.parse(window.localStorage.getItem(Config._USER));

                    const data = {
                      status,
                      observacao: modal.observacao,
                      cancelado_by: { id: user.id },
                      cancelado_at: moment().format('YYYY-MM-DD\\THH:mm:ss'),
                    };

                    const response = await api.put(`/api/inscricoes/inscritos/${inscricao.id}`, data);

                    if(!response || ('data' in response && 'error' in response.data && 'status' in response.data)) {
                      this.props.enqueueSnackbar(response.mensagem);
                      this.setState({ loading: false });
                    }
                    else {
                      this.props.enqueueSnackbar(`Inscrição ${status === 'Reprovado' ? "reprovada" : "cancelada"}!`);
                      this.setState({ loading: false });
                      setTimeout(() => {
                        this.findInscritos();
                      }, 300);
                    }
                  }}
                >
                  Sim
                </button>
              </div>
            </div>
          );
        }
      })
    });
  }

  exportData = () => {
    const { filter, inscricao } = this.state;

    return {
      ...filter,
      _limit: 10000,
      _view: {
        title: `Inscrições "${inscricao.titulo}"`,
        head: 'Data Cadastro,Nome,Status' + (inscricao.taxa_inscricao ? ',Status Pagamento' : ''),
        body: 'data_cadastro:date,nome,status' + (inscricao.taxa_inscricao ? ',financeiro_taxa_inscricao.parcelamentos.status:statusPagamento' : ''),
        template: 2,
      },
    }
  }

  renderContent() {
    const { data } = this.state;

    if (data.length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Não Existem inscrições cadastrados</h3>
        </CardHeader>
      );
    }

    return (
      <Table className="align-items-center table-flush table-with-button" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">DATA CADASTRO</th>
            <th scope="col">NOME</th>
            <th scope="col">SÓCIO</th>
            <th scope="col">STATUS</th>
            <th scope="col">BOLETO</th>
            <th scope="col" className="text-center mt-padding-medium-right">AÇÃO</th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => {
            return (
              <tr key={item.id}>
                <th>{moment(item.data_cadastro).format('DD/MM/YYYY HH:mm')}</th>
                <th style={{ height: 75.5 }}>{item.tipo_socio === 'Não Sócio' ? (!!item.nome ? item.nome : item.usuario.name) : item.usuario.name}</th>
                <th style={{ height: 75.5 }}>{item.tipo_socio === 'Não Sócio' ? "Não" : "Sim"}</th>
                <th>{DescricaoStatusInscricao(item.status)}</th>
                <th>
                  {item.financeiro_taxa_inscricao !== null && item.financeiro_taxa_inscricao.parcelamentos !== null && (
                    <a href={item.financeiro_taxa_inscricao.parcelamentos[0].linkBoleto} target='_blank' rel="noopener noreferrer" style={{ color: '#525f7f' }}>
                      <i class="fas fa-barcode mt-margin-xsmall-right"></i>
                      Boleto
                    </a>
                  )}
                </th>
                <td>
                  <div className="text-right">
                    {item.status === 'Aguardando Aprovação' && (
                      <Button color="success" onClick={() => this.handleAprovar(item) }>
                        <i className="fas fa-check-circle mt-margin-xsmall-right"></i>Aprovar
                      </Button>
                    )}
                    {['Cancelado', 'Reprovado'].indexOf(item.status) === -1 && (
                      <Button color="danger" onClick={() => this.handleCancelar(item, item.status === 'Aguardando Aprovação' ? "Reprovado" : "Cancelado")}>
                        <i className="fas fa-times-circle mt-margin-xsmall-right"></i>
                        {item.status === 'Aguardando Aprovação' ? "Reprovar" : "Cancelar"}
                      </Button>
                    )}
                    <Button color="custom" onClick={() => this.setState({ previewVisible: true, modal: item })}>
                      <i className="fas fa-file-alt mt-margin-xsmall-right"></i>Mais Informações
                    </Button>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    );
  }

  maisInformacoes = () => {
    const { modal, previewVisible } = this.state;
    let modalClass = 'modal';

    if (previewVisible) modalClass += ' show d-block';

    return (
      <>
        {previewVisible && (
          <div className={modalClass} style={{ background: '#000000a3' }}>
            <div className="modal-dialog modal-lg" style={{ marginTop: '3rem' }}>
              <div className="modal-content">
                <div className='mt-padding mt-margin-medium-bottom'>
                  <div className="modal-footer mt-padding-remove" style={{ position: 'fixed', right: '-10px', top: '-45px' }}>
                    <button type="button" className="btn" data-dismiss="modal" onClick={() => this.setState({ previewVisible: false })} style={{ background: 'transparent', color: 'white' }}>
                      <i className='fas fa-times'/>
                    </button>
                  </div>
                  <div className="mt-margin-top">

                    <h4 style={{ color: '#8f8f8f' }}>Informações da inscrição</h4>
                    <ListGroup className="mt-margin-xsmall-top mt-margin-bottom">

                      {!!modal.usuario ? (
                        <>
                          <ListGroupItem>
                            <Row style={{ alignItems: 'center' }}>
                              <Col lg='12'>
                                <strong className="mt-margin-small-right">Nome do Inscrito: </strong>
                                {modal.nome}
                              </Col>
                            </Row>
                          </ListGroupItem>

                          <ListGroupItem>
                            <Row style={{ alignItems: 'center' }}>
                              <Col lg='12'>
                                <ButtonControl
                                  color='link'
                                  to={`/admin/${modal.tipo_socio === 'Não Sócio' ? "nao-socios" : "associado"}/${!!modal.usuario.associadoUk ? `${modal.usuario.associadoUk}/dependentes/${modal.usuario.uk}` : modal.usuario.uk}/editar`}
                                  target='_blank'
                                  rel="noopener noreferrer"
                                  className="mt-padding-remove"
                                  style={{ color: '#525f7f' }}
                                  forceShowChild={true}
                                >
                                  <strong className="mt-margin-small-right">Nome do Responsável: </strong>
                                  {modal.usuario.name}
                                  <i className="fas fa-share-square mt-margin-small-left"></i>
                                </ButtonControl>
                              </Col>
                            </Row>
                          </ListGroupItem>

                          {!!modal.tipo_socio && (
                            <ListGroupItem>
                              <Row style={{ alignItems: 'center' }}>
                                <Col lg='12'>
                                  <strong className="mt-margin-small-right">Tipo de sócio: </strong>
                                  {modal.tipo_socio}
                                </Col>
                              </Row>
                            </ListGroupItem>
                          )}
                        </>
                      ) : (
                        <>
                          {!!modal.nome && (
                            <ListGroupItem>
                              <Row style={{ alignItems: 'center' }}>
                                <Col lg='12'>
                                  <strong className="mt-margin-small-right">Nome: </strong>
                                  {modal.nome}
                                </Col>
                              </Row>
                            </ListGroupItem>
                          )}

                          {!!modal.cpf && (
                            <ListGroupItem>
                              <Row style={{ alignItems: 'center' }}>
                                <Col lg='12'>
                                  <strong className="mt-margin-small-right">CPF: </strong>
                                  {modal.cpf}
                                </Col>
                              </Row>
                            </ListGroupItem>
                          )}

                          {!!modal.telefone && (
                            <ListGroupItem>
                              <Row style={{ alignItems: 'center' }}>
                                <Col lg='12'>
                                  <strong className="mt-margin-small-right">Telefone: </strong>
                                  {modal.telefone}
                                </Col>
                              </Row>
                            </ListGroupItem>
                          )}

                          {!!modal.email && (
                            <ListGroupItem>
                              <Row style={{ alignItems: 'center' }}>
                                <Col lg='12'>
                                  <strong className="mt-margin-small-right">E-mail: </strong>
                                  {modal.email}
                                </Col>
                              </Row>
                            </ListGroupItem>
                          )}
                        </>
                      )}

                      {!!modal.data_cadastro && (
                        <ListGroupItem>
                          <Row style={{ alignItems: 'center' }}>
                            <Col lg='12'>
                              <strong className="mt-margin-small-right">Data Cadastro: </strong>
                              {moment(modal.data_cadastro).format('DD/MM/YYYY HH:mm:ss')}
                            </Col>
                          </Row>
                        </ListGroupItem>
                      )}

                      {!!modal.data_primeira_mensalidade && (
                        <ListGroupItem>
                          <Row style={{ alignItems: 'center' }}>
                            <Col lg='12'>
                              <strong className="mt-margin-small-right">Data Primeira Parcela: </strong>
                              {moment(modal.data_primeira_mensalidade).format('DD/MM/YYYY')}
                            </Col>
                          </Row>
                        </ListGroupItem>
                      )}

                    </ListGroup>

                    {!!modal.aprovado_at && (
                      <>
                        <h4 className="mt-margin-medium-top" style={{ color: '#8f8f8f' }}>Informações da aprovação</h4>
                        <ListGroup className="mt-margin-xsmall-top mt-margin-bottom">

                          {!!modal.financeiro_taxa_inscricao && (
                            <ListGroupItem>
                              <Row style={{ alignItems: 'center' }}>
                                <Col lg='12'>
                                  <ButtonControl
                                    color="link"
                                    to={`/admin/financeiro/contas/receber/${modal.financeiro_taxa_inscricao.uk}/editar`}
                                    target='_blank'
                                    rel="noopener noreferrer"
                                    className="mt-padding-remove"
                                    style={{ color: '#525f7f' }}
                                    forceShowChild={true}
                                  >
                                    <strong>
                                      Taxa de Inscrição
                                      <i className="fas fa-share-square mt-margin-small-left"></i>
                                    </strong>
                                  </ButtonControl>
                                </Col>
                              </Row>
                            </ListGroupItem>
                          )}

                          {!!modal.aprovado_at && (
                            <ListGroupItem>
                              <Row style={{ alignItems: 'center' }}>
                                <Col lg='12'>
                                  <strong className="mt-margin-small-right">Data Aprovação: </strong>
                                  {moment(modal.aprovado_at).format('DD/MM/YYYY HH:mm:ss')}
                                </Col>
                              </Row>
                            </ListGroupItem>
                          )}

                          {!!modal.aprovado_by && (
                            <ListGroupItem>
                              <Row style={{ alignItems: 'center' }}>
                                <Col lg='12'>
                                  <strong className="mt-margin-small-right">Aprovado por: </strong>
                                  {modal.aprovado_by.name}
                                </Col>
                              </Row>
                            </ListGroupItem>
                          )}
                        </ListGroup>
                      </>
                    )}

                    {!!modal.cancelado_at && (
                      <>
                        <h4 className="mt-margin-medium-top" style={{ color: '#8f8f8f' }}>Informações do cancelamento</h4>
                        <ListGroup className="mt-margin-xsmall-top mt-margin-bottom">
                          {!!modal.cancelado_at && (
                            <ListGroupItem>
                              <Row style={{ alignItems: 'center' }}>
                                <Col lg='12'>
                                  <strong className="mt-margin-small-right">Data Cancelamento: </strong>
                                  {moment(modal.cancelado_at).format('DD/MM/YYYY HH:mm:ss')}
                                </Col>
                              </Row>
                            </ListGroupItem>
                          )}

                          {!!modal.cancelado_by && (
                            <ListGroupItem>
                              <Row style={{ alignItems: 'center' }}>
                                <Col lg='12'>
                                  <strong className="mt-margin-small-right">Cancelado por: </strong>
                                  {modal.cancelado_by.name}
                                </Col>
                              </Row>
                            </ListGroupItem>
                          )}

                          {!!modal.observacao && (
                            <ListGroupItem>
                              <Row style={{ alignItems: 'center' }}>
                                <Col lg='12'>
                                  <strong className="mt-margin-small-right">Observação: </strong>
                                  {modal.observacao}
                                </Col>
                              </Row>
                            </ListGroupItem>
                          )}

                        </ListGroup>
                      </>
                    )}

                    <h4 className="mt-margin-medium-top" style={{ color: '#8f8f8f' }}>Respostas do formulário</h4>
                    {modal.respostas.map(item => (
                      <ListGroup className="mt-margin-xsmall-top">
                        <ListGroupItem>
                          <Row style={{ alignItems: 'center' }}>
                            <Col lg='8' style={{ borderRight: 'solid 1px #e9e9e9' }}>
                              <strong>{item.label}</strong>
                            </Col>
                            <Col lg='4' className="mt-text-center">
                              {item.value}
                            </Col>
                          </Row>
                        </ListGroupItem>
                      </ListGroup>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  render() {
    const { loading, filter, inscricao } = this.state;


    return (
      <>

        {loading && (
          <div className="container-load">
            <BoxLoading style={{ margin: 'auto', position: 'absolute', inset: 0, left: '-20%' }} />
          </div>
        )}

        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to='/admin/inscricoes'>
                    <i className="fas fa-arrow-left"></i> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={(!!inscricao ? inscricao.titulo : '')}
          subTitulo={"Módulo de Gestão de inscrições"}
        />

        {this.maisInformacoes()}

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0" style={{ float: "left" }}>Total ({this.state.data.length})</h3>

                  {!!this.state.data.length && !loading && (
                    <>
                      <ButtonExport notification={this.props.enqueueSnackbar} name="Lista de Prestadores de Serviço" type="pdf" url={'/api/inscricoes/inscritos/export/pdf?' + jsonToUrlFilter(this.exportData())} />
                      <ButtonExport notification={this.props.enqueueSnackbar} name="Lista de Prestadores de Serviço" type="excel" url={'/api/inscricoes/inscritos/export/excel?' + jsonToUrlFilter(this.exportData())} />
                    </>
                  )}

                  <Filter handleLimpar={this.handleLimpar} handlePesquisar={this.findInscritos}>
                    <Row>
                      <Col xs="4">
                        <FormGroup>
                          <label className="form-control-label">Nome</label>
                          <Input
                            type="text"
                            className="form-control"
                            value={filter.nome_like}
                            onChange={value => this.setState({ filter: { ...filter, nome_like: value.target.value } })}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">Tipo de Inscrito</label>
                          <FormControl variant="outlined" style={{ width: '100%' }}>
                            <Select
                              value={filter.usuario}
                              onChange={value => this.setState({ filter: { ...filter, usuario: value.target.value } })}
                              multiple
                            >
                              <MenuItem value="not_null">Sócio</MenuItem>
                              <MenuItem value="null">Não Sócio</MenuItem>
                            </Select>
                          </FormControl>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">Status</label>
                          <FormControl variant="outlined" style={{ width: '100%' }}>
                            <Select
                              value={filter.status}
                              multiple
                              onChange={value => {
                                this.setState({ filter: { ...filter, status: value.target.value } })
                              }}
                            >
                              <MenuItem value="Confirmado">Confirmado</MenuItem>
                              <MenuItem value="Aguardando Pagamento">Aguardando Pagamento</MenuItem>
                              <MenuItem value="Aguardando Aprovação">Aguardando Aprovação</MenuItem>
                              <MenuItem value="Cancelado">Cancelado</MenuItem>
                              <MenuItem value="Reprovado">Reprovado</MenuItem>
                            </Select>
                          </FormControl>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">Filtrar data</label>
                          <FormControl variant="outlined" style={{ width: '100%' }}>
                            <Select
                              value={this.state.filtrar_data}
                              onChange={value => {
                                this.setState({ filtrar_data: value.target.value })
                              }}
                            >
                              <MenuItem value="data_cadastro">Data Cadastro</MenuItem>
                              <MenuItem value="aprovado_at">Data Aprovação</MenuItem>
                            </Select>
                          </FormControl>
                        </FormGroup>
                      </Col>
                      <Col lg="3" className="mt-margin-bottom mt-margin-small-top">
                        <label className="form-control-label">Data Inicio</label>
                        <input format="YYYY-MM-DD" type="date" className="form-control" value={filter[`${this.state.filtrar_data}>=`]} onChange={value => this.setState({ filter: { ...filter, [`${this.state.filtrar_data}>=`]: value.target.value } })} />
                      </Col>
                      <span className="mt-margin-bottom mt-margin-medium-top" style={{ display: 'flex', alignItems: 'center' }}>Até</span>
                      <Col lg="3" className="mt-margin-bottom mt-margin-small-top">
                        <label className="form-control-label">Data Fim</label>
                        <input format="YYYY-MM-DD" type="date" className="form-control" value={filter[`${this.state.filtrar_data}<=`]} onChange={value => this.setState({ filter: { ...filter, [`${this.state.filtrar_data}<=`]: value.target.value } })} />
                      </Col>
                    </Row>
                  </Filter>
                </CardHeader>

                {this.renderContent()}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(InscricoesInscritos);
