/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { Fragment } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import Config from "Config";
import { ICON } from "Config";
import routes from "routes";
import hasPermission from "utils/UserPermission";

class Sidebar extends React.Component {
  state = {
    collapseOpen: false,
    user: null
  }

  constructor(props) {
    super();

    if (JSON.parse(window.localStorage.getItem(Config._USER))) {
      this.activeRoute.bind(this);

      if (JSON.parse(window.localStorage.getItem(Config._USER))) {
        this.state = {
          collapseOpen: false,
          user: JSON.parse(window.localStorage.getItem(Config._USER))
        }
      }
      else {
        this.state = {
          collapseOpen: false
        }
      }
    }
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    });
  };

  render() {
    /* const isNotConfig = routes.map(item => {
              if (!item.isConfig) return item
            })
    const isConfig = routes.map(item => {
              if (item.isConfig) return item
            }) */
    const { logo } = this.props;
    let navbarBrandProps;

    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank"
      };
    }

    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>

          {/* Logo */}
          {!!logo && (
            <NavbarBrand className="pt-0" {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={ICON}
              />
            </NavbarBrand>
          )}

          {/* User */}
          <Nav className="align-items-center d-md-none">
            <UncontrolledDropdown nav>
              {/* <DropdownToggle nav className="nav-link-icon">
                <i className="ni ni-bell-55" />
              </DropdownToggle> */}
              <DropdownMenu
                aria-labelledby="navbar-default_dropdown_1"
                className="dropdown-menu-arrow"
                right
              >
                <DropdownItem>Action</DropdownItem>
                <DropdownItem>Another action</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Something else here</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle" style={{ backgroundColor: 'transparent' }}>
                    <img
                      alt="..."
                      src={ICON}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {this.state._name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/dashboard" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>DashBoard</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Perfil</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="/auth/login" onClick={e => {
                  e.preventDefault();
                  window.localStorage.removeItem(Config._USER);
                  window.localStorage.removeItem(Config._TOKEN);
                  this.props.history.replace('/auth');
                }}>
                  <i className="ni ni-user-run" />
                  <span>Sair</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>

          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {!!logo && (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                )}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>

            {/* Form */}
            <hr className="my-3" />
            <h6 className="navbar-heading text-muted">CLUBES ASSOCIADOS</h6>

            {/* Navigation */}
            <Nav navbar>
              {routes.map(item => (
                <Fragment key={item.layout + item.path}>
                  {item.inMenu && !item.isConfig && hasPermission(item.layout + item.path) && (
                    <NavItem>
                      <NavLink activeclassname="inactive" to={item.layout + item.path} tag={Link}>
                        {item.icon}
                        {item.nome}
                      </NavLink>
                    </NavItem>
                  )}
                </Fragment>
              ))}
            </Nav>


            <hr className="my-3" />
            <h6 className="navbar-heading text-muted">CONFIGURAÇÕES</h6>

            <Nav navbar>
              {routes.map(item => (
                <Fragment key={item.layout + item.path}>
                  {item.inMenu && item.isConfig && hasPermission(item.layout + item.path) && (
                    <NavItem>
                      <NavLink activeclassname="inactive" to={item.layout + item.path} tag={Link}>
                        {item.icon}
                        {item.nome}
                      </NavLink>
                    </NavItem>
                  )}
                </Fragment>
              ))}
            </Nav>

            <hr className="my-3" />
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}]
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
};

export default Sidebar;
