/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { CardHeader, Button, Table, Container, Row, Card } from "reactstrap";
import Config from "Config";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import { getDescricaoModoManutencao, getDescricaoTipoIntervalo } from "Config";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { ButtonGroup } from "@material-ui/core";
import { withSnackbar } from "notistack";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from "react-confirm-alert";
import ButtonControl from "views/components/ButtonControl";

class Manutencoes extends React.Component {
  state = {
    data: [],
  };

  componentDidMount() {
    this.findAll()
  }

  findAll = () => {
    this.setState({ loading: true })

    fetch(Config._HOST + '/api/manutencao')
      .then(response => response.json())
      .then(response => {
        this.setState({
          data: response.status === 200 ? response.data : [],
          loading: false,
        })
      }).catch(e => {
        this.setState({ loading: false })
      })
  }

  handleRemove = (uk) => {
    confirmAlert({
      title: 'Deseja Deletar o cadastro?',
      message: '',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim', onClick: () => {
            this.setState({ loading: true })

            fetch(Config._HOST + `/api/manutencoes/${uk}`,
              {
                method: 'DELETE',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              },
            )
              .then(data => {
                this.setState({ loading: false })
                const dataUpdated = this.state.data.filter(row => row.id !== uk);
                this.setState({ data: dataUpdated });
                this.props.enqueueSnackbar('Excluido')
              })
              .catch(e => {
                this.setState({ loading: false })
                this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
              })

          }
        }
      ]
    })

  }

  renderContent() {
    if (this.state.loading) {
      return <BoxLoading />
    }

    if (this.state.data.length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Não existem Manutenções cadastradas</h3>
        </CardHeader>
      );
    }

    return (
      <div>
        <Table className="align-items-center table-flush table-with-button" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">TIPO</th>
              <th scope="col">CATEGORIA</th>
              <th scope="col">MODO</th>
              <th scope="col">INTERVALO</th>
              <th scope="col">TIPO INTERVALO</th>
              <th scope="col">DESCRIÇÃO</th>
              <th scope="col">AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map(single => {
              return (
                <tr key={single.id}>
                  <th>{single.tipo ? single.tipo.nome : "-"}</th>
                  <td>{single.categoria ? single.categoria.nome : "-"}</td>
                  <td>{single.modo ? getDescricaoModoManutencao(single.modo) : "-"}</td>
                  <td>{single.intervalo ? single.intervalo : "-"}</td>
                  <td>{single.tipo_intervalo ? getDescricaoTipoIntervalo(single.tipo_intervalo) : "-"}</td>
                  <td>{single.descricao ? single.descricao : "-"}</td>
                  <td>
                    <ButtonControl color='secondary' to={`/admin/manutencoes/${single.uk}`}>
                      <i className="fas fa-edit"></i>
                    </ButtonControl>
                    <Button color="orange" onClick={() => this.handleRemove(single.uk)}>
                      <i className="fas fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    return (
      <>

        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl
                    color='secondary' to='/admin/manutencoes/novo'>
                    <i className="fas fa-plus"></i> NOVO
                  </ButtonControl>
                  <ButtonControl
                    color='secondary' to='/admin/manutencoes/ocorrencias'>
                    Ocorrências
                  </ButtonControl>
                  <ButtonControl
                    color='secondary' to='/admin/manutencoes/tipos'>
                    Tipos
                  </ButtonControl>
                  <ButtonControl
                    color='secondary' to='/admin/manutencoes/categorias'>
                    Categorias
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"MANUTENÇÕES"} subTitulo={"Módulo de Gestão para Manutenções"}
        />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Total Manutenções ({this.state.data.length})</h3>
                </CardHeader>
                {this.renderContent()}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(Manutencoes);
