/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";

// reactstrap components
import Config from 'Config';
import { BoxLoading } from 'react-loadingg';
import { withSnackbar } from "notistack";
import api from "services/api";
import { Button, Card, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Col} from "reactstrap";

class Login extends Component {
  state = {
    loading: false,
    email: '',
    senha: '',
    recuperar_senha: false
  }

  handleLogin = async e => {
    e.preventDefault();

    if (!this.state.email || this.state.email === '') {
      this.props.enqueueSnackbar("O e-mail deve ser preenchido!")
      return;
    }

    if (!this.state.senha || this.state.senha === '') {
      this.props.enqueueSnackbar("A senha deve ser preenchida!")
      return;
    }

    this.setState({ loading: true });
    const response = await api.post("/api/auth/login", this.state);

    if (!!response && 'data' in response && 'mensagem' in response.data) {
      this.props.enqueueSnackbar(response.data.mensagem);
    }
    else if (!!response) {
      if (response.data.usuario.profile.id === 2 || response.data.usuario.profile.id === 3) {
        this.props.enqueueSnackbar("Seu usuário possui somente permissão para acessar o app!");
      }
      else {
        window.localStorage.setItem(Config._USER, JSON.stringify(response.data.usuario));
        window.localStorage.setItem(Config._TOKEN, JSON.stringify(response.data.token));
        this.props.history.replace('/admin');
      }
    }
    else {
      this.props.enqueueSnackbar("Ops, Ocorreu um erro!");
    }

    this.setState({ loading: false });
  }

  handleRedefinirSenha = e => {
    e.preventDefault()
    if(!this.state.enviado){
      if(!this.state.email){
        this.props.enqueueSnackbar("Informe um e-mail");
        return;
      }

      this.setState({ loading: true })
      api.post(`/api/user/senha/${this.state.email}`)
      .then(response => {
        console.log(response.mensagem)
        this.props.enqueueSnackbar(response.mensagem);
        this.setState({ recuperar_senha: !this.state.recuperar_senha, loading: false })
      })
      .catch(error => {
        this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
        console.log(error.response)
        this.setState({ loading: false })
      });
    }
  }

  changeRecuperarSenha = e => {
    e.preventDefault();
    this.setState({ recuperar_senha: !this.state.recuperar_senha });
  }

  render() {
    const { loading, recuperar_senha } = this.state;

    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 pt-lg-5 pb-lg-4">
              <div className="text-center text-muted mb-4">
                <small>Entre com sua Credencial</small>
              </div>
              <Form role="form" onSubmit={e => recuperar_senha ? this.handleRedefinirSenha(e) : this.handleLogin(e)}>

                <FormGroup className="mb-3">
                  <InputGroup className="input-group">
                    <InputGroupAddon className="mt-border-remove" addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input value={this.state.email} onChange={value => this.setState({ email: value.target.value.toLowerCase() })} placeholder="Email" type="email" autoComplete="new-email" />
                  </InputGroup>
                </FormGroup>

                {!recuperar_senha && (
                  <FormGroup>
                    <InputGroup className="input-group">
                      <InputGroupAddon className="mt-border-remove" addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input value={this.state.senha} onChange={value => this.setState({ senha: value.target.value })} placeholder="Senha" type="password" autoComplete="new-password" />
                    </InputGroup>
                  </FormGroup>
                )}

                {loading && <BoxLoading style={{ zIndex: 99999, margin: 'auto', position: 'absolute', inset: 0 }} />}

                <div className="text-center">
                  {recuperar_senha ? (
                    <>
                      <div className="text-center">
                        <a href="/" onClick={this.changeRecuperarSenha} className="mt-link">
                          Fazer login
                        </a>
                      </div>
                      <div className="text-center">
                        <Button className="my-4" color="primary" type="submit" disabled={loading}>
                          Recuperar Senha
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-center">
                        <a href="/" onClick={this.changeRecuperarSenha} className="mt-link">
                          Recuperar Senha
                        </a>
                      </div>
                      <div className="text-center">
                        <Button className="my-4" color="primary" type="submit" disabled={loading}>
                          Fazer login
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default withSnackbar(Login);
