import { b64toBlob } from "Config";
import api from "services/api";

export default class file {
}

export const handleDeleteFile = async filesRemovidos => {
  for (const key in filesRemovidos) {
    return await deleteFile(filesRemovidos[key]);
  }
}

export const deleteFile = async file => {
  if ("id" in file) {
    const response = await api.delete(`/api/bucket/${file.id}`);
    return response.mensagem;
  }
}

export const handleUploadArrayFile = async (owner, key, files) => {
  const sendData = new FormData();
  for (let i = 0; i < files.length; i++) {
    if (!("id" in files[i])) {
      sendData.append("files", files[i].file);
    }
  }

  const response = await api.post(`/api/bucket/owner/${owner}/${key}`, sendData);
  return response.status;
}

export const handleUploadfile = async (owner, key, data) => {
  const response = await api.post(`/api/bucket/owner/${owner}/${key}`, data);
  return response.status;
}

export const sendFile = (dataSend, dataB64, key) => {
  let sendFile = false;

  if (dataB64) {
    dataSend.append('file', b64toBlob(dataB64), key);
    sendFile = true;
  }

  return sendFile ? dataSend : null;
}
