import Config from "Config";
import { ICON } from "Config";
/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";

class AdminNavbar extends React.Component {

  state = {
    _name: "", _src: "",
    // options: [],
    // dataResponsavelFinanceiro: [],
  }

  componentDidMount() {
    let user = JSON.parse(window.localStorage.getItem(Config._USER))
    if (!user) {
      window.localStorage.removeItem(Config._USER)
      this.props.history.replace('/auth')
      return;
    }
    this.setState({ user: user })
  }

  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <div />
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={ICON}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {this.state._name}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem to={`/admin/usuarios/${!!this.state.user && this.state.user.uk}/editar`} tag={Link}>
                    <i className="fas fa-user fa-fw" />
                    <span>Meu Perfil</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href="/auth/login" onClick={e => {
                    e.preventDefault();
                    window.localStorage.removeItem(Config._USER);
                    window.localStorage.removeItem(Config._TOKEN);
                    this.props.history.replace('/auth');
                  }}>
                    <i className="fas fa-sign-out-alt fa-fw" />
                    <span>Sair</span>
                  </DropdownItem>
                </DropdownMenu>


              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
