/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Button, Card, CardHeader, CardBody, Form, Input, Container, Row, Col } from "reactstrap";
import Config from "Config";
import { withSnackbar } from "notistack";
import { ButtonGroup, FormControl } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import ButtonControl from "views/components/ButtonControl";
import { confirmAlert } from 'react-confirm-alert'; // Import

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'rodal/lib/rodal.css';

const HISTORY_BACK = '/admin/manutencoes/categorias';

class ManutencaoCategoriaNovo extends React.Component {

  componentDidMount() {
    let user = JSON.parse(window.localStorage.getItem(Config._USER))

    if (!user) {
      window.localStorage.removeItem(Config._USER)
      this.props.history.replace('/auth')
      return;
    }

    this.setState({
      uk: this.props.match.params.uk,
      _session: user,
      _uk: user._uk,
      _ukFunc: user._ukFunc,
      _func: user._func,
      _name: user._name,
    }, () => {
      if (this.props.match.params.uk) { // cenario para editar
        this.findBy()
      }
    })
  }

  findBy = () => {
    this.setState({ loading: true })
    const { uk } = this.props.match.params;

    fetch(Config._HOST + `/api/manutencao/categoria/${uk}`)
      .then(response => response.json())
      .then(response => {
        console.log(response)
        this.setState({
          loading: false,
          ...response.data,
        })
      })
      .catch(e => {
        this.setState({ loading: false })
      })
  }

  create = () => {
    this.setState({ loading: true })
    const uk = this.props.match.params.uk !== undefined ? this.props.match.params.uk : '';
    let dataUser = JSON.stringify(this.state)

    fetch(Config._HOST + `/api/manutencao/categoria`,
      {
        body: dataUser,
        method: uk ? 'PUT' : 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => response.json())
      .then(data => {
        this.setState({ loading: false })
        this.props.enqueueSnackbar(data.mensagem)
        this.props.history.push(HISTORY_BACK)
      })
      .catch(e => {
        this.setState({ loading: false })
        this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
      })
  }

  render() {
    console.log(this.state)
    return (
      <>

        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={HISTORY_BACK}>
                    <i className="fas fa-arrow-left"></i> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={this.props.match.params.uk ? "EDITAR CATEGORIA DA MANUTENÇÃO" : "NOVA CATEGORIA DA MANUTENÇÃO"}
          subTitulo={"Gerenciamento completo das Categorias"}
        />


        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {this.props.match.params.uk ? "Atualizar Categoria" : "Cadastrar Nova Categoria"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>

                    <div className="mt-margin-left mt-padding-right">
                      <Row>
                        <Col lg="12">
                          <FormControl className="mt-margin-top" style={{ width: '100%' }}>
                            <label className="form-control-label">Nome</label>
                            <Input
                              name="nome"
                              type="textarea"
                              placeholder="Nome"
                              rows="5"
                              onChange={(value) => { this.setState({ nome: value.target.value.toUpperCase() }) }}
                              value={this.state.nome ? this.state.nome : ''}
                            />
                          </FormControl>
                        </Col>
                      </Row>
                    </div>

                    <hr className="my-4 mt-margin-large-top" />

                    <div className="mt-margin-left mt-padding-right" style={{ marginTop: "10px" }}>
                      <Row>
                        <Col lg="12">
                          <Button color="default" style={{ width: "100%" }} onClick={() => {
                            confirmAlert({
                              title: this.props.match.params.uk
                                ? 'Deseja confirmar a atualização?'
                                : 'Deseja confirmar o cadastro?',
                              message: '',
                              buttons: [
                                { label: 'Não' },
                                { label: 'Sim', onClick: this.create }
                              ]
                            })
                          }
                          }>
                            <i className="fas fa-check" style={{ marginRight: 5 }} />
                            {this.props.match.params.uk ? "ATUALIZAR" : "CADASTRAR"}
                          </Button>

                        </Col>
                      </Row>

                      <Row className="mt-margin-small-top">
                        <Col lg="12">
                          <Button color="warning" style={{ width: "100%" }} onClick={() => { this.props.history.push(HISTORY_BACK) }}>
                            CANCELAR
                          </Button>
                        </Col>
                      </Row>
                    </div>

                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(ManutencaoCategoriaNovo);
