import React, { Component } from 'react';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { getUser } from 'Config';
import api from 'services/api';

export default class FormFields extends Component {

  async componentDidMount() {
    const { handleChange } = this.props;
    handleChange({ loading: true })

    const response = await api.get('/api/profile')
    if (!!response && response.data.length > 0) {
      handleChange({
        dataProfile: response.data.filter(profile => [2, 3].indexOf(profile.id) === -1),
        loading: false,
      })
    }
  }

  render() {
    const { state, handleChange } = this.props;
    const { profile } = getUser();

    return (
      <>
        <div className="mt-margin-left mt-margin-right">
          <Row>
            {profile.id === 1 && (
              <>
                <Col lg="3">
                  <FormGroup>
                    <FormControl variant="outlined">
                      <label className="form-control-label">Perfil</label>
                      <Select
                        name="profile"
                        className="mt-select"
                        placeholder="Perfil"
                        onChange={value => { handleChange({ profile: value.target.value }) }}
                        value={state.profile}
                      >
                        {state.dataProfile.map(profile => (
                          <MenuItem key={profile.id} value={profile.id}>{profile.descricao}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormGroup>
                </Col>
                {'id' in state && profile.id !== state.id && (
                  <Col lg="3">
                    <FormGroup>
                      <FormControl variant="outlined">
                        <label className="form-control-label">Status</label>
                        <Select
                          name="profile"
                          className="mt-select"
                          placeholder="Perfil"
                          onChange={value => { handleChange({ statusAcesso: value.target.value }) }}
                          value={state.statusAcesso}
                        >
                          <MenuItem value={1}>Ativo</MenuItem>
                          <MenuItem value={8}>Inativo</MenuItem>
                        </Select>
                      </FormControl>
                    </FormGroup>
                  </Col>
                )}
              </>
            )}
          </Row>
          <Row>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">Nome Completo</label>
                <Input
                  className="form-control"
                  placeholder="Nome Completo"
                  type="text"
                  onChange={value => handleChange({ name: value.target.value.toUpperCase() })}
                  value={state.name}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">E-mail</label>
                <Input
                  className="form-control"
                  placeholder="E-mail"
                  type="text"
                  onChange={value => handleChange({ email: value.target.value.toLowerCase() })}
                  value={state.email}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">Senha</label>
                <Input
                  className="form-control"
                  placeholder="Senha"
                  type="text"
                  onChange={value => handleChange({ senha: value.target.value })}
                  value={state.senha}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
