import * as Page from "./views";


const routes = [

  // Dashboard
  {
    layout: "/admin",
    path: '/dashboard',
    icon: <i className="fas fa-home"></i>,
    component: Page['Dashboard'],
    exact: true,
    inMenu: true,
    nome: "Home",
    isConfig: false
  },



  // Associado
  {
    layout: "/admin",
    path: '/associado',
    icon: <i className="fas fa-users"></i>,
    component: Page['Associados'],
    exact: true,
    inMenu: true,
    nome: "Associados",
    isConfig: false,
    children: [
      {
        path: '/novo',
        component: Page['AssociadoCrud'],
        exact: true,
        nome: "Associados Novo",
      },
      {
        path: '/:uk/editar',
        component: Page['AssociadoCrud'],
        exact: true,
        nome: "Associados Editar",
      },


      // Carteirinhas
      {
        layout: "/admin",
        path: '/carteirinhas',
        component: Page['AssociadoCarteirinhas'],
        exact: true,
        nome: "Associados Carteirinhas"
      },


      // Histórico do Perfil
      {
        path: '/perfil/:uk',
        component: Page['AssociadoPerfil'],
        exact: true,
        nome: "Associado Perfil",
      },


      // Histórico de reservas
      {
        path: '/historico-reservas/:uk',
        component: Page['HistoricoReservas'],
        exact: true,
        nome: "Associados Histórico Reservas",
      },


      // Histórico financeiro
      {
        path: '/historico-financeiro/:uk',
        component: Page['HistoricoFinanceiro'],
        exact: true,
        nome: "Associados Histórico Financeiro",
      },


      // Certificados
      {
        path: '/certificados/:uk',
        component: Page['Certificados'],
        exact: true,
        nome: "Associados Certificados",
      },


      // Dependente
      {
        path: '/:uk/dependentes',
        component: Page['Dependentes'],
        exact: true,
        nome: "Associados Dependentes",
      },
      {
        path: '/:ukAssociado/dependentes/novo',
        component: Page['AssociadoCrud'],
        exact: true,
        nome: "Associados Agregado Novo",
      },
      {
        path: '/:ukAssociado/dependentes/:uk/editar',
        component: Page['AssociadoCrud'],
        exact: true,
        nome: "Associados Agregado Editar",
      },

    ]
  },


  // Não sócio
  {
    layout: "/admin",
    path: '/nao-socios',
    icon: <i className="fas fa-users"></i>,
    component: Page['Associados'],
    exact: true,
    inMenu: true,
    nome: "Não Sócios",
    isConfig: false,
    children: [
      {
        path: '/novo',
        component: Page['AssociadoCrud'],
        exact: true,
        nome: "Novo não Sócio",
      },
      {
        path: '/:uk/editar',
        component: Page['AssociadoCrud'],
        exact: true,
        nome: "Editar não Sócio",
      },


      // Histórico financeiro
      {
        path: '/historico-financeiro/:uk',
        component: Page['HistoricoFinanceiro'],
        exact: true,
        nome: "Histórico Financeiro não Sócio",
      },
    ]
  },



  // Agenda
  {
    layout: "/admin",
    path: '/agenda',
    icon: <i className="fas fa-calendar-alt"></i>,
    component: Page['AgendaCalendario'],
    exact: true,
    inMenu: true,
    nome: "Reservas",
    isConfig: false,
    children: [
      {
        path: '/novo',
        component: Page['AgendaCrud'],
        exact: true,
        nome: "Reservas Novo",
      },
      {
        path: '/:uk/editar',
        component: Page['AgendaCrud'],
        exact: true,
        nome: "Reservas Editar",
      },
      {
        path: '/:uk/visualizar',
        component: Page['AgendaDocumento'],
        exact: true,
        nome: "Reservas Visualizar",
      },


      // Agenda - Lista
      {
        path: '/listagem',
        component: Page['AgendaList'],
        exact: true,
        nome: "Reservas Listar",
      },


      // Agenda - Últimas Reservas
      {
        path: '/ultimas-reservas',
        component: Page['UltimasReservas'],
        exact: true,
        nome: "Reservas Últimas Reservas",
      },


      // Agenda - Bloqueio
      {
        path: '/bloqueio',
        component: Page['AgendaBloqueio'],
        exact: true,
        nome: "Reservas Bloqueio",
      },
      {
        path: '/bloqueio/novo',
        component: Page['AgendaBloqueioCrud'],
        exact: true,
        nome: "Reservas Bloqueio Novo",
      },
      {
        path: '/bloqueio/:id/editar',
        component: Page['AgendaBloqueioCrud'],
        exact: true,
        nome: "Reservas Bloqueio Editar",
      },


      // Agenda - Convidados
      {
        path: '/:uk/convidados',
        component: Page['AgendaConvidados'],
        exact: true,
        nome: "Reservas Convidados",
      },
      {
        path: '/:uk/convidados/novo',
        component: Page['AgendaConvidadosCrud'],
        exact: true,
        nome: "Reservas Convidados Novo",
      },
      {
        path: '/:uk/convidados/:ukConvidado/editar',
        component: Page['AgendaConvidadosCrud'],
        exact: true,
        nome: "Reservas Convidados Editar",
      },


      // Agenda - Prestadores de Serviço
      {
        path: '/:uk/prestadores-servico',
        component: Page['AgendaPrestadoresServico'],
        exact: true,
        nome: "Reservas Prestadores de Serviço",
      },
      {
        path: '/:uk/prestadores-servico/novo',
        component: Page['AgendaPrestadoresServicoCrud'],
        exact: true,
        nome: "Reservas Prestadores de Serviço Novo",
      },
      {
        path: '/:uk/prestadores-servico/:id/editar',
        component: Page['AgendaPrestadoresServicoCrud'],
        exact: true,
        nome: "Reservas Prestadores de Serviço Editar",
      },


      // Agenda - Check-in e check-out
      {
        path: '/check/:uk',
        component: Page['AgendaCheck'],
        exact: true,
        nome: "Reservas Check-in/Check-out",
      },
    ]
  },


  // Financeiro
  {
    layout: "/admin",
    path: '/financeiro',
    icon: <i className="fas fa-dollar-sign"></i>,
    component: Page['FinanceiroDashboad'],
    exact: true,
    inMenu: true,
    nome: "Financeiro",
    isConfig: false,
    children: [
      {
        path: '/contas/:type',
        component: Page['FinanceiroList'],
        exact: true,
        nome: "Financeiro Contas",
      },
      {
        path: '/contas/:type/novo',
        component: Page['FinanceiroCRUD'],
        exact: true,
        nome: "Financeiro Contas Novo",
      },
      {
        path: '/contas/:type/:uk/editar',
        component: Page['FinanceiroCRUD'],
        exact: true,
        nome: "Financeiro Contas Editar",
      },


      // Caixa
      {
        path: '/caixa',
        component: Page['FinanceiroContaList'],
        exact: true,
        nome: "Financeiro Caixa",
      },
      {
        path: '/caixa/novo',
        component: Page['FinanceiroContaCRUD'],
        exact: true,
        nome: "Financeiro Caixa Novo",
      },
      {
        path: '/caixas/:ukCaixa/editar',
        component: Page['FinanceiroContaCRUD'],
        exact: true,
        nome: "Financeiro Caixa Editar",
      },


      // Departamento
      {
        path: '/departamento',
        component: Page['FinanceiroDepartamentoList'],
        exact: true,
        nome: "Financeiro Departamento",
      },
      {
        path: '/departamento/novo',
        component: Page['FinanceiroDepartamentoCRUD'],
        exact: true,
        nome: "Financeiro Departamento Novo",
      },
      {
        path: '/departamentos/:uk/editar',
        component: Page['FinanceiroDepartamentoCRUD'],
        nome: "Financeiro Departamento Editar",
      },


      // Categoria
      {
        path: '/categoria',
        component: Page['FinanceiroCategoriaList'],
        exact: true,
        nome: "Financeiro Categoria",
      },
      {
        path: '/categoria/novo',
        component: Page['FinanceiroCategoriaCRUD'],
        exact: true,
        nome: "Financeiro Categoria Novo",
      },
      {
        path: '/categorias/:uk/editar',
        component: Page['FinanceiroCategoriaCRUD'],
        exact: true,
        nome: "Financeiro Categoria Editar",
      },


      // Recorrência
      {
        path: '/recorrencia/:type',
        component: Page['FinanceiroRecorrenciaList'],
        exact: true,
        nome: "Financeiro Recorrência",
      },
      {
        path: '/recorrencia/:type/:id/editar',
        component: Page['FinanceiroRecorrenciaCRUD'],
        nome: "Financeiro Recorrência Editar",
      },


      // Acordo
      {
        path: '/acordo',
        component: Page['FinanceiroAcordoList'],
        exact: true,
        nome: "Financeiro Acordo",
      },
      {
        path: '/acordo/novo',
        component: Page['FinanceiroAcordoCRUD'],
        exact: true,
        nome: "Financeiro Acordo Novo",
      },
      {
        path: '/acordo/:id/editar',
        component: Page['FinanceiroAcordoCRUD'],
        exact: true,
        nome: "Financeiro Acordo Editar",
      },

    ]
  },



  // Compras
  {
    layout: "/admin",
    path: '/compras',
    icon: <i className="fas fa-shopping-cart"></i>,
    component: Page['Compras'],
    exact: true,
    inMenu: true,
    nome: "Compras",
    isConfig: false,
    children: [
      {
        path: '/novo',
        component: Page['CompraCrud'],
        exact: true,
        nome: "Compras Novo",
      },
      {
        path: '/:id/editar',
        component: Page['CompraCrud'],
        exact: true,
        nome: "Compras Editar",
      },
    ]
  },



  // Comunicados
  {
    layout: "/admin",
    path: '/comunicados',
    icon: <i className="fas fa-comment"></i>,
    component: Page['Comunicados'],
    exact: true,
    inMenu: true,
    nome: "Comunicados",
    isConfig: false,
    children: [
      {
        path: '/novo',
        component: Page['ComunicadoCrud'],
        exact: true,
        nome: "Comunicados Novo",
      },
      {
        path: '/:id/editar',
        component: Page['ComunicadoCrud'],
        exact: true,
        nome: "Comunicados Editar",
      },
    ]
  },



  // Inscrições
  {
    layout: "/admin",
    path: '/inscricoes',
    icon: <i className="fas fa-user-edit"></i>,
    component: Page['Inscricoes'],
    exact: true,
    inMenu: true,
    nome: "Inscrições",
    isConfig: false,
    children: [
      {
        path: '/novo',
        component: Page['InscricaoCrud'],
        exact: true,
        nome: "Inscrições Novo",
      },
      {
        path: '/:id/editar',
        component: Page['InscricaoCrud'],
        exact: true,
        nome: "Inscrições Editar",
      },

      // Inscritos
      {
        path: '/:id/inscritos',
        component: Page['InscricoesInscritos'],
        exact: true,
        nome: "Inscrições Inscritos",
      },
    ]
  },



  // Visitantes
  {
    layout: "/admin",
    path: '/visitantes',
    icon: <i className="fas fa-user-friends"></i>,
    component: Page['Visitantes'],
    exact: true,
    inMenu: true,
    nome: "Visitantes",
    isConfig: false,
    children: [
      {
        path: '/novo',
        component: Page['VisitanteCrud'],
        exact: true,
        nome: "Visitantes Novo",
      },
      {
        path: '/:id/editar',
        component: Page['VisitanteCrud'],
        exact: true,
        nome: "Visitantes Editar",
      },
    ]
  },

  // Usuários
  {
    layout: "/admin",
    path: '/usuarios',
    icon: <i className="fas fa-users"></i>,
    component: Page['Usuarios'],
    exact: true,
    inMenu: true,
    nome: "Usuários",
    isConfig: true,
    children: [
      {
        path: '/novo',
        component: Page['UsuarioCrud'],
        exact: true,
        nome: "Usuários Novo",
      },
      {
        path: '/:uk/editar',
        component: Page['UsuarioCrud'],
        exact: true,
        nome: "Usuários Editar",
      },
      {
        path: '/perfis',
        component: Page['Perfis'],
        exact: true,
        nome: "Perfis",
      },
      {
        path: '/perfis/novo',
        component: Page['PerfisCrud'],
        exact: true,
        nome: "Perfis Novo",
      },
      {
        path: '/perfis/:id/editar',
        component: Page['PerfisCrud'],
        exact: true,
        nome: "Perfis Editar",
      },
    ]
  },

  // Manutenções
  {
    layout: "/admin",
    path: '/manutencoes',
    icon: <i className="fas fa-tools"></i>,
    component: Page['Manutencoes'],
    exact: true,
    inMenu: true,
    nome: "Manutenções",
    isConfig: true,
    children: [
      {
        path: '/novo',
        component: Page['ManutencaoNovo'],
        exact: true,
        nome: "Manutenções Novo",
      },
      {
        path: '/:uk/editar',
        component: Page['ManutencaoNovo'],
        exact: true,
        nome: "Manutenções Editar",
      },


      // Categorias
      {
        path: '/categorias',
        component: Page['ManutencaoCategorias'],
        exact: true,
        nome: "Manutenções Categorias",
      },
      {
        path: '/categorias/novo',
        component: Page['ManutencaoCategoriaNovo'],
        exact: true,
        nome: "Manutenções Categorias Novo",
      },
      {
        path: '/categorias/:uk/editar',
        component: Page['ManutencaoCategoriaNovo'],
        exact: true,
        nome: "Manutenções Categorias Editar",
      },


      // Tipos
      {
        path: '/tipos',
        component: Page['ManutencaoTipos'],
        exact: true,
        nome: "Manutenções Tipos",
      },
      {
        path: '/tipos/novo',
        component: Page['ManutencaoTipoNovo'],
        exact: true,
        nome: "Manutenções Tipos Novo",
      },
      {
        path: '/tipos/:uk/editar',
        component: Page['ManutencaoTipoNovo'],
        exact: true,
        nome: "Manutenções Tipos Editar",
      },


      // Ocorrencias
      {
        path: '/ocorrencias',
        component: Page['ManutencaoOcorrencias'],
        exact: true,
        nome: "Manutenções Ocorrências",
      },
      {
        path: '/ocorrencias/novo',
        component: Page['ManutencaoOcorrenciaNovo'],
        exact: true,
        nome: "Manutenções Ocorrências Novo",
      },
      {
        path: '/ocorrencias/:id/editar',
        component: Page['ManutencaoOcorrenciaNovo'],
        exact: true,
        nome: "Manutenções Ocorrências Editar",
      },
    ]
  },

  // Ambientes
  {
    layout: "/admin",
    path: '/ambientes',
    icon: <i className="fas fa-globe-americas"></i>,
    component: Page['Ambientes'],
    exact: true,
    inMenu: true,
    nome: "Ambientes",
    isConfig: true,
    children: [
      {
        path: '/novo',
        component: Page['AmbienteCrud'],
        exact: true,
        nome: "Ambientes Novo",
      },
      {
        path: '/:id/editar',
        component: Page['AmbienteCrud'],
        exact: true,
        nome: "Ambientes Editar",
      },


      // Setores
      {
        path: '/setores',
        component: Page['AmbienteSetores'],
        exact: true,
        nome: "Ambientes Setores",
      },
      {
        path: '/setores/novo',
        component: Page['AmbienteSetorNovo'],
        exact: true,
        nome: "Ambientes Setores Novo",
      },
      {
        path: '/setores/:uk/editar',
        component: Page['AmbienteSetorNovo'],
        nome: "Ambientes Setores Editar",
      },
    ]
  },





  // Categoria
  {
    layout: "/admin",
    path: '/categoria',
    component: Page['CategoriaLista'],
    exact: true,
    inMenu: false,
    nome: "Categoria",
    isConfig: false,
    children: [
      {
        path: '/novo',
        component: Page['Categoria'],
        exact: true,
        nome: "Categoria Novo",
      },
      {
        path: '/:uk/editar',
        component: Page['Categoria'],
        exact: true,
        nome: "Categoria Editar",
      },
    ]
  },


  // Fornecedores
  {
    layout: "/admin",
    path: '/fornecedores',
    component: Page['Fornecedores'],
    exact: true,
    inMenu: false,
    nome: "Fornecedores",
    isConfig: false,
    children: [
      {
        path: '/novo',
        component: Page['FornecedorCrud'],
        exact: true,
        nome: "Fornecedores Novo",
      },
      {
        path: '/:id/editar',
        component: Page['FornecedorCrud'],
        exact: true,
        nome: "Fornecedores Editar",
      },
    ]
  },






  // Fale Conosco
  {
    layout: "/admin",
    path: '/fale-conosco',
    icon: <i className="fas fa-comment-alt"></i>,
    component: Page['FaleConosco'],
    exact: true,
    inMenu: true,
    nome: "Fale Conosco",
    isConfig: false,
    children: [
      {
        path: '/novo',
        component: Page['FaleConoscoCrud'],
        exact: true,
        nome: "Fale Conosco Novo",
      },
      {
        path: '/:id/visualizar',
        component: Page['FaleConoscoView'],
        exact: true,
        nome: "Fale Conosco Visualizar",
      },
      {
        path: '/:id/editar',
        component: Page['FaleConoscoCrud'],
        exact: true,
        nome: "Fale Conosco Editar",
      },
    ]
  },



  // Documentos
  {
    layout: "/admin",
    path: '/documentos',
    icon: <i className="fas fa-file-alt"></i>,
    component: Page['Documentos'],
    exact: true,
    inMenu: true,
    nome: "Documentos",
    isConfig: true,
    children: [
      {
        path: '/novo',
        component: Page['DocumentosCrud'],
        exact: true,
        nome: "Documentos Novo",
      },
      {
        path: '/:id/editar',
        component: Page['DocumentosCrud'],
        nome: "Documentos Editar",
      },
    ]
  },



  // Deliberação da diretoria
  {
    layout: "/admin",
    path: '/deliberacao-diretoria',
    icon: <i className="fas fa-pencil-alt"></i>,
    component: Page['DeliberacaoDiretoria'],
    exact: true,
    inMenu: true,
    nome: "Deliberação da Diretoria",
    isConfig: true,
    children: [
      {
        path: '/novo',
        component: Page['DeliberacaoDiretoriaCrud'],
        exact: true,
        nome: "Deliberação da Diretoria Novo",
      },
      {
        path: '/:id/editar',
        component: Page['DeliberacaoDiretoriaCrud'],
        nome: "Deliberação da Diretoria Editar",
      },
    ]
  },



  // Veículos
  {
    layout: "/admin",
    path: '/veiculos',
    component: Page['Veiculos'],
    exact: true,
    inMenu: false,
    nome: "Veículos",
    isConfig: false,
    children: [
      {
        path: '/novo',
        component: Page['VeiculoCrud'],
        exact: true,
        nome: "Veículos Novo",
      },
      {
        path: '/:id/editar',
        component: Page['VeiculoCrud'],
        exact: true,
        nome: "Veículos Editar",
      },
    ]
  },


  // Tipo de Abertura
  {
    layout: "/admin",
    path: '/controle-acesso/tipo-aberturas',
    component: Page['TipoAberturas'],
    exact: true,
    inMenu: false,
    nome: "Controle de Acesso (Tipo aberturas)",
    isConfig: false,
    children: [
      {
        path: '/novo',
        component: Page['TipoAberturaCrud'],
        exact: true,
        nome: "Controle de Acesso (Tipo aberturas) Novo",
      },
      {
        path: '/:id/editar',
        component: Page['TipoAberturaCrud'],
        exact: true,
        nome: "Controle de Acesso (Tipo aberturas) Editar",
      },
    ]
  },



  // Zona
  {
    layout: "/admin",
    path: '/controle-acesso/zonas',
    component: Page['Zonas'],
    exact: true,
    inMenu: false,
    nome: "Controle de Acesso (Zonas)",
    isConfig: false,
    children: [
      {
        path: '/novo',
        component: Page['ZonaCrud'],
        exact: true,
        nome: "Controle de Acesso (Zonas) Novo",
      },
      {
        path: '/:id/editar',
        component: Page['ZonaCrud'],
        exact: true,
        nome: "Controle de Acesso (Zonas) Editar",
      },
    ]
  },



  // Controle
  {
    layout: "/admin",
    path: '/controle-acesso',
    component: Page['ControleAcessos'],
    exact: true,
    inMenu: false,
    nome: "Controle de Acesso",
    isConfig: false,
    children: [
      {
        path: '/novo',
        component: Page['ControleAcessoCrud'],
        exact: true,
        nome: "Controle de Acesso Novo",
      },
      {
        path: '/:id/editar',
        component: Page['ControleAcessoCrud'],
        exact: true,
        nome: "Controle de Acesso Editar",
      },
    ]
  },

  // PDF
  {
    layout: "/admin",
    path: '/pdf',
    icon: <i className="fas fa-tags"></i>,
    component: Page['Pdfs'],
    exact: true,
    inMenu: true,
    nome: "PDF",
    isConfig: true,
    children: [
      {
        path: '/novo',
        component: Page['PdfCrud'],
        exact: true,
        nome: "PDF Novo",
      },
      {
        path: '/:id/editar',
        component: Page['PdfCrud'],
        exact: true,
        nome: "PDF Editar",
      },
    ]
  }

];

export default routes;
