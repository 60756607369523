import { ButtonGroup } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { withSnackbar } from "notistack";
import { Component } from "react";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { BoxLoading } from "react-loadingg";
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row } from "reactstrap";
import api from "services/api";
import phoneMask from "utils/phoneMask";
import ButtonControl from "views/components/ButtonControl";

const ROUTE_API = '/api/documento-agenda/prestadores-servico';

class AgendaPrestadoresServicoCrud extends Component {

  state = {
    loading: true,
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.findDocumentoAgenda(this.props.match.params.uk);

    if (this.props.match.params.id) {
      await this.findBy(this.props.match.params.id);
    }

    this.setState({ loading: false });
  }

  findDocumentoAgenda = async (uk) => {
    const response = await api.get("/api/documento-agenda/" + uk);
    this.setState({ documento_agenda_entity: { id: response.data.id } });
  }

  findBy = async (id) => {
    const response = await api.get(ROUTE_API + '/' + id);
    this.setState({ ...response, documento_agenda_entity: { id: response.documento_agenda_entity } })
  }

  delete() {
    this.setState({ loading: true });
    const response = api.delete(ROUTE_API + '/' + this.props.match.params.id);

    if(response) {
      this.setState({ loading: false });
      this.props.enqueueSnackbar('Deletado com sucesso!');
      this.props.history.push('/admin/agenda/' + this.props.match.params.uk + '/prestadores-servico');
    }
    else {
      this.setState({ loading: false })
      this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
    }
  }

  create = async () => {
    let data = this.state;

    if (!this.state.nome_entregador || this.state.nome_entregador === "") {
      this.props.enqueueSnackbar("Campo 'Nome Entregador' obrigatório!")
      return;
    }
    if (!this.state.nome_empresa || this.state.nome_empresa === "") {
      this.props.enqueueSnackbar("Campo 'Nome Empresa' obrigatório!")
      return;
    }
    if (!this.state.telefone || this.state.telefone.length < 14) {
      this.props.enqueueSnackbar("Campo 'Telefone' obrigatório!")
      return;
    }

    this.setState({ loading: true });
    const id = this.props.match.params.id !== undefined ? this.props.match.params.id : '';
    const response = !!id ? await api.put(`${ROUTE_API}/${id}`, data) : await api.post(`${ROUTE_API}`, data);

    if(!response || ('status' in response && response.status !== 200)) {
      this.props.enqueueSnackbar("Ops, ocorreu um erro!");
    }
    else {
      this.props.enqueueSnackbar(id ? "Atualizado com sucesso!" : "Cadastrado com sucesso!");
      this.props.history.push('/admin/agenda/' + this.props.match.params.uk + '/prestadores-servico');
    }

    this.setState({ loading: false });
  }

  render() {
    const { uk } = this.props.match.params;

    return (
      <>
        {this.state.loading && (
          <div className="container-load-with-margin">
            <BoxLoading />
          </div>
        )}

        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={`/admin/agenda/${uk}/prestadores-servico`}>
                    <i className="fas fa-arrow-left" /> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"Prestadores de Serviço"}
          subTitulo={""}
        />
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {this.props.match.params.id ? "Atualizar" : "Cadastro"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label className="form-control-label">Nome Entregador</label>
                            <Input
                              className="form-control"
                              placeholder="Nome Entregador"
                              type="text"
                              onChange={value => this.setState({ nome_entregador: value.target.value.toUpperCase() })}
                              value={this.state.nome_entregador}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label className="form-control-label">Nome Empresa</label>
                            <Input
                              className="form-control"
                              placeholder="Nome Empresa"
                              type="text"
                              onChange={value => this.setState({ nome_empresa: value.target.value.toUpperCase() })}
                              value={this.state.nome_empresa}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label className="form-control-label">Telefone</label>
                            <Input
                              className="form-control"
                              placeholder="Telefone"
                              type="text"
                              onChange={value => this.setState({ telefone: phoneMask(value.target.value) })}
                              value={this.state.telefone}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <FormGroup>
                            <label className="form-control-label">Observação</label>
                            <Input
                              className="form-control"
                              placeholder="Observação"
                              type="textarea"
                              rows="5"
                              onChange={value => this.setState({ observacao: value.target.value.toUpperCase() })}
                              value={this.state.observacao}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <hr className="my-4" />

                    <div className="pl-lg-4" style={{ marginTop: "10px" }}>
                      <Row>
                        {this.props.match.params.id && (
                          <Col lg="6">
                            <FormGroup>
                              <Button color="danger" style={{ width: "100%" }} onClick={() => {
                                confirmAlert({
                                  title: 'Deseja excluir?',
                                  message: '',
                                  buttons: [
                                    {
                                      label: 'Não',
                                    },
                                    {
                                      label: 'Sim',
                                      onClick: () => this.delete()
                                    }
                                  ]
                                })
                              }
                              }>
                                <i className="fas fa-trash" style={{ marginRight: 5 }}></i>
                                EXCLUIR
                              </Button>
                            </FormGroup>
                          </Col>
                        )}

                        <Col lg={this.props.match.params.id ? 6 : 12}>
                          <FormGroup>
                            <Button color="default" style={{ width: "100%" }} onClick={() => {
                              confirmAlert({
                                title: this.props.match.params.id ? 'Deseja confirmar a atualização?' : 'Deseja confirmar o cadastro?',
                                message: '',
                                buttons: [
                                  {
                                    label: 'Não',
                                  },
                                  {
                                    label: 'Sim',
                                    onClick: () => this.create()
                                  }
                                ]
                              })
                            }
                            }>
                              <i className="fas fa-check" style={{ marginRight: 5 }}></i>
                              {this.props.match.params.id ? "ATUALIZAR" : "CADASTRAR"}</Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(AgendaPrestadoresServicoCrud);
