import React, { Component } from 'react';
import { Col, FormGroup, Row, Input } from 'reactstrap';

export default class FormFields extends Component {

  render() {
    const { state, handleChange } = this.props;

    return (
      <>
        <div className="mt-margin-left mt-margin-right">
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Nome</label>
                <Input
                  className="form-control"
                  placeholder="Nome"
                  type="text"
                  onChange={value => handleChange({ nome: value.target.value.toUpperCase() })}
                  value={state.nome}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
