import moment from 'moment';
import React, { Component } from 'react';
import { Button } from 'reactstrap';
import api from 'services/api';

class ButtonExport extends Component {
  state = {
    loading: false,
    types: {
      pdf: {
        mimeType: `application/pdf`,
        extension: 'pdf',
        buttonText: 'PDF',
        icon: 'fas fa-file-pdf'
      },
      excel: {
        mimeType: `application/octet-stream`,
        extension: 'xlsx',
        buttonText: 'Excel',
        icon: 'fas fa-file-excel'
      }
    }
  }

  handleExport = async () => {
    const { type, name, notification } = this.props;
    const { types } = this.state;
    this.setState({ loading: true });
    await api.get(this.props.url, { responseType: 'arraybuffer' }).then(response => {
      if('status' in response && response.status === 400) throw new Error('');

      const blob = new Blob([response], { type: types[type].mimeType });
      const url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = `${!!name ? name : 'Relatório'} - ${moment().format('DD-MM-YYYY HH_mm_ss')}.${types[type].extension}`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.setState({ loading: false });
    }).catch(error => {
      notification("Ops, ocorreu um erro ao gerar o relatório!")
      this.setState({ loading: false });
    });
  }

  render() {
    const { type } = this.props;
    const { types, loading } = this.state;

    return (
      <Button
        size="sm"
        color="export"
        className="mt-margin-small-left mt-btn-default"
        onClick={this.handleExport}
        disabled={loading}
      >
        <i className={types[type].icon} /> {types[type].buttonText}
        {loading && <i className="fas fa-spinner fa-spin"></i>}
      </Button>
    );
  }
}

export default ButtonExport;
