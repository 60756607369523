/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";

// reactstrap components
import { CardHeader, Table, Container, Row, Card } from "reactstrap";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { ButtonGroup } from "@material-ui/core";
import { withSnackbar } from "notistack";
import api from "services/api";
import moment from "moment";
import ButtonControl from "views/components/ButtonControl";

import 'react-confirm-alert/src/react-confirm-alert.css';

class Inscricoes extends Component {
  state = {
    data: [],
  };

  componentDidMount() {
    this.findAll();
  }

  findAll = async () => {
    this.setState({ loading: true });
    const response = await api.get('/api/inscricoes?_limit=999&_order=desc&_sort=ativo');
    this.setState({ data: response.data, loading: false });
  }

  renderContent() {
    if (this.state.loading) return <BoxLoading />

    if (this.state.data.length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Não Existem inscrições cadastrados</h3>
        </CardHeader>
      );
    }

    return (
      <Table className="align-items-center table-flush table-with-button" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">TÍTULO</th>
            <th scope="col">MENSALIDADE</th>
            <th scope="col">STATUS</th>
            <th scope="col" className="text-right mt-padding-medium-right">AÇÃO</th>
          </tr>
        </thead>
        <tbody>
          {this.state.data.map(single => {
            const incricaoIsStart = moment(moment(moment().format('YYYY-MM-DD'))).isSameOrAfter(single.data_inicio);
            const incricaoIsEnd = moment(moment(moment().format('YYYY-MM-DD'))).isAfter(single.data_fim);
            const isAtivo = single.ativo && ((!!single.data_inicio ? incricaoIsStart : true) && (!!single.data_fim ? !incricaoIsEnd : true)) ;

            return (
              <tr key={single.id}>
                <th>{single.titulo}</th>
                <th>{single.mensalidade ? 'Sim' : 'Não'}</th>
                <th style={{color: isAtivo ? '#008000' : '#ababab'}}>{isAtivo ? 'Ativo' : 'Inativo'}</th>
                <td>
                  <div className="text-right">
                    <ButtonControl color='secondary' to={`/admin/inscricoes/${single.id}/inscritos`}>
                      <i className="fas fa-users"></i>
                    </ButtonControl>
                    <a href={window.location.origin + '/inscricoes/' + single.uk} className="btn btn-secondary" target="_blank" rel="noopener noreferrer">
                      <i className="fas fa-share-square"></i>
                    </a>
                    <ButtonControl color='secondary' to={`/admin/inscricoes/${single.id}/editar`}>
                      <i className="fas fa-edit"></i>
                    </ButtonControl>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    );
  }

  render() {
    return (
      <>

        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl
                    color='secondary' to='/admin/inscricoes/novo'>
                    <i className="fas fa-plus"></i> NOVO
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"INSCRIÇÕES"} subTitulo={"Módulo de Gestão de inscrições"}
        />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Total ({this.state.data.length})</h3>
                </CardHeader>

                {this.renderContent()}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(Inscricoes);
