/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Component } from "react";

// reactstrap components
import { ButtonGroup } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import Config from "Config";
import moment from "moment";
import { withSnackbar } from "notistack";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import BoxLoading from "react-loadingg/lib/BoxLoading";
import { Badge, Button, Card, CardHeader, Container, Row, Table } from "reactstrap";
import api from "services/api";
import { jsonToUrlFilter } from "utils/filter";
import ButtonControl from "views/components/ButtonControl";

class AgendaBloqueio extends Component {
  state = {
    data: [],
  };

  async componentDidMount() {
    await this.findAll()
  }

  findAll = async () => {
    this.setState({ loading: true });
    const filterData = {
      _limit: 999,
      "data_fim>=": moment().format('YYYY-MM-DD'),
      _order: 'asc',
      _sort: 'data_inicio',
    }
    const response = await api.get(`/api/agenda/bloqueio?${jsonToUrlFilter(filterData)}`);
    this.setState({ data: response.data, loading: false });
  }

  handleRemove = (id) => {
    confirmAlert({
      title: 'Deseja Deletar o cadastro?',
      message: '',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim', onClick: () => {
            this.setState({ loading: true })

            fetch(Config._HOST + `/api/agenda/bloqueio/${id}`,
              {
                method: 'DELETE',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                }
              },
            )
              .then(response => response.json())
              .then(data => {
                if (data) {
                  const dataUpdated = this.state.data.filter(row => row.id !== id);
                  this.setState({ data: dataUpdated });
                }

                this.props.enqueueSnackbar(data ? "Deletado com sucesso!" : 'Ops, ocorreu um erro!')
                this.setState({ loading: false })
              })
              .catch(e => {
                this.setState({ loading: false })
                this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
              })

          }
        }
      ]
    })

  }

  renderContent() {
    if (this.state.loading) return <BoxLoading />

    if (this.state.data.length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Não Existem bloqueios cadastrados</h3>
        </CardHeader>
      );
    }

    return (
      <div>
        <Table className="align-items-center table-flush table-with-button" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">DESCRIÇÃO</th>
              <th scope="col">DATA INÍCIO</th>
              <th scope="col">DATA FIM</th>
              <th scope="col">AMBIENTE</th>
              <th scope="col">DIAS DA SEMANA</th>
              <th scope="col" className="text-right mt-padding-medium-right">AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map(item => (
              <tr key={item.id}>
                <th>{item.descricao}</th>
                <th>{moment(item.data_inicio).format('DD/MM/yyyy')}</th>
                <th>{moment(item.data_fim).format('DD/MM/yyyy')}</th>
                <th>
                  {item.ambientes.length > 0 && <Badge color="info" className="mt-margin-xsmall-right">{item.ambientes[0].nome}</Badge>}
                  {item.ambientes.length-1 > 0 && <Badge color="info" className="mt-margin-xsmall-right">+{item.ambientes.length-1}</Badge>}
                </th>
                {/* <th>{item.ambientes.map(ambiente => <Badge color="info" className="mt-margin-xsmall-right">{ambiente.nome}</Badge>)}</th> */}
                <th>
                  <Badge color={!!item.segunda ? "info" : "secondary" } className="mt-margin-xsmall-right">Segunda</Badge>
                  <Badge color={!!item.terca ? "info" : "secondary" } className="mt-margin-xsmall-right">Terça</Badge>
                  <Badge color={!!item.quarta ? "info" : "secondary" } className="mt-margin-xsmall-right">Quarta</Badge>
                  <Badge color={!!item.quinta ? "info" : "secondary" } className="mt-margin-xsmall-right">Quinta</Badge>
                  <Badge color={!!item.sexta ? "info" : "secondary" } className="mt-margin-xsmall-right">Sexta</Badge>
                  <Badge color={!!item.sabado ? "info" : "secondary" } className="mt-margin-xsmall-right">Sábado</Badge>
                  <Badge color={!!item.domingo ? "info" : "secondary" } className="mt-margin-xsmall-right">Domingo</Badge>
                </th>
                <td>
                  <div className="text-right">
                    <ButtonControl color='secondary' to={`/admin/agenda/bloqueio/${item.id}/editar`}>
                      <i className="fas fa-edit"></i>
                    </ButtonControl>
                    <Button color="secondary" onClick={() => this.handleRemove(item.id)}>
                      <i className="fas fa-trash"></i>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    return (
      <>

        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to='/admin/agenda'>
                    <i className="fas fa-arrow-left"></i> VOLTAR
                  </ButtonControl>
                  <ButtonControl color='secondary' to='/admin/agenda/bloqueio/novo'>
                    <i className="fas fa-plus"></i> NOVO
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"Bloqueio da Agenda"}
          subTitulo={"Módulo de Gestão de bloqueio da agenda"}
        />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Total ({this.state.data.length})</h3>
                </CardHeader>

                {this.renderContent()}

              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(AgendaBloqueio);
