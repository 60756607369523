/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Component } from "react";

// reactstrap components
import { Card, CardBody, CardHeader, Col, Container, Form, Row } from "reactstrap";
import { ButtonGroup } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { withSnackbar } from "notistack";

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import 'rodal/lib/rodal.css';
import api from "services/api";
import FormButtonAction from "views/components/FormButtonAction";
import FormFields from "./Form/FormFields";
import { BoxLoading } from "react-loadingg";
import ButtonControl from "views/components/ButtonControl";

const HISTORY_BACK = '/admin/agenda/bloqueio';
const ROUTE_API = '/api/agenda/bloqueio';

class AgendaBloqueioCrud extends Component {

  state = {
    ambientes: [],
    dataAmbientes: [],
    diasSemana: ['segunda', 'terca', 'quarta', 'quinta', 'sexta', 'sabado', 'domingo']
  }

  async componentDidMount() {
    this.setState({ loading: true });
    if (this.props.match.params.id) await this.findBy();
    await this.findAmbientes();
    this.setState({ loading: false });
  }

  findBy = async () => {
    const { id } = this.props.match.params;
    const response = await api.get(`${ROUTE_API}/${id}`);
    let diasSemana = [];

    if(response.segunda) diasSemana.push('segunda');
    if(response.terca) diasSemana.push('terca');
    if(response.quarta) diasSemana.push('quarta');
    if(response.quinta) diasSemana.push('quinta');
    if(response.sexta) diasSemana.push('sexta');
    if(response.sabado) diasSemana.push('sabado');
    if(response.domingo) diasSemana.push('domingo');

    this.setState({ ...response, diasSemana, ambientes: response.ambientes.map(item => item.id) });
  }

  findAmbientes = async () => {
    const response = await api.get("/api/ambiente?_limit=9999&ativo=true&permitir_locacao=true&_sort=nome");
    this.setState({ dataAmbientes: response.data });
  }

  create = async () => {
    this.setState({ loading: true });
    const { id } = this.props.match.params;
    const data = {
      ...this.state,
      segunda: this.state.diasSemana.indexOf('segunda') >= 0,
      terca: this.state.diasSemana.indexOf('terca') >= 0,
      quarta: this.state.diasSemana.indexOf('quarta') >= 0,
      quinta: this.state.diasSemana.indexOf('quinta') >= 0,
      sexta: this.state.diasSemana.indexOf('sexta') >= 0,
      sabado: this.state.diasSemana.indexOf('sabado') >= 0,
      domingo: this.state.diasSemana.indexOf('domingo') >= 0,
      ambientes: this.state.ambientes.map(item => {
        return { id: item };
      })
    }

    const response = !!id ? await api.put(`${ROUTE_API}/${id ? id : ''}`, data) : await api.post(ROUTE_API, data);

    if(!!response) {
      this.props.enqueueSnackbar(!!id ? "Atualizado com sucesso!" : "Cadastrado com sucesso!");
      this.props.history.push(HISTORY_BACK);
    }
    else {
      this.props.enqueueSnackbar("Ops, ocorreu um erro!");
    }

    this.setState({ loading: false });
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  }

  render() {
    return (
      <>
        {this.state.loading && (
          <div className="container-load-with-margin">
            <BoxLoading />
          </div>
        )}

        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={HISTORY_BACK}>
                    <i className="fas fa-arrow-left"></i> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={this.props.match.params.id ? "EDITAR BLOQUEIO" : "NOVO BLOQUEIO"}
          subTitulo={"Gerenciamento dos Bloqueio"}
        />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {this.props.match.params.id ? "Atualizar Bloqueio" : "Cadastrar Novo Bloqueio"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormFields {...this} />
                    <FormButtonAction create={this.create} cancel={HISTORY_BACK} history={this.props.history} isEdit={this.props.match.params.id} />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(AgendaBloqueioCrud);
