import { CircularProgress, FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import numeral from 'numeral';
import { Component } from 'react';
import IntlCurrencyInput from "react-intl-currency-input";
import { Alert, Col, FormGroup, Input, Row, Table } from 'reactstrap';

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export default class FormFields extends Component {

  render() {
    const { id } = this.props.props.match.params;
    const { state, handleChange, handleChangeParcelasAcordo, searchUsuarios, getParcelaAtraso, disabled } = this.props;
    let valorTotal = 0;
    let multaTotal = 0;
    let jurosTotal = 0;

    const valorTotalParcela = parseFloat((state.parcelas_acordo.reduce((accumulator, item) => accumulator + item.valorPagamento, 0)).toFixed(2));

    return (
      <>
        <Col lg="12">
          <FormGroup style={{ position: 'relative', top: -4 }}>
            <label className="form-control-label" htmlFor="input-username">
              Associado
            </label>
            <Autocomplete
              options={state.usuarios}
              getOptionLabel={option => `(${option.numeroTitulo}) ${option.name}`}
              onChange={(_, value) => {
                getParcelaAtraso(value)
              }}
              onInputChange={(_, value) => searchUsuarios(value)}
              inputMode="text"
              loading={!!state.loadUsuarios}
              value={state.usuario}
              renderOption={(option) => (
                <span>({option.numeroTitulo}) {option.name}</span>
              )}
              disabled={disabled}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Buscar Associado"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {!!state.loadUsuarios ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </FormGroup>
        </Col>

        {!!state.usuario && (
          <>
            <Col lg="12 mt-text-center mt-margin-medium-y">
              <i className="fas fa-angle-double-down"></i>
            </Col>

            <h6 className="heading-small text-muted mb-4">Contas Atrasadas</h6>

            <Table className="align-items-center table-flush table-with-button" responsive>
              <thead className="thead-light">
                <tr>
                  {!id && (
                    <th scope="col">
                      <div className="custom-control custom-checkbox" style={{ top: -10 }}>
                        <input
                          className="custom-control-input"
                          id="select-all"
                          type="checkbox"
                          checked={state.parcelas_pendentes.length === state.parcelas_origem.length}
                          disabled={disabled}
                          onChange={async (value) => {
                            valorTotal = 0;
                            multaTotal = 0;
                            jurosTotal = 0;

                            if(value.target.checked) {
                              for (const key in state.parcelas_pendentes) {
                                const parcela = state.parcelas_pendentes[key];

                                const valor = parcela.financeiroContasEntity.vlTotal;
                                let multa = parcela.financeiroContasEntity.multa;
                                let juros = parcela.financeiroContasEntity.juros;
                                let qtdDiasAtraso = state.data_acordo_realizado.diff(moment(parcela.dtVencimento), 'days');
                                multa = valor * (multa/100);
                                juros = parseFloat((((juros/30)*valor)/100).toFixed(2)) * qtdDiasAtraso;

                                valorTotal += valor;
                                multaTotal += multa;
                                jurosTotal += juros;
                              }

                              await handleChange({ parcelas_origem: state.parcelas_pendentes });
                            }
                            else {
                              await handleChange({ parcelas_origem: [] });
                            }

                            await handleChangeParcelasAcordo(valorTotal, multaTotal, jurosTotal);
                          }}
                        />
                        <label className="custom-control-label" htmlFor="select-all" />
                      </div>
                    </th>
                  )}
                  <th scope="col">CATEGORIA</th>
                  <th scope="col">VALOR PARCELA</th>
                  <th scope="col">MULTA</th>
                  <th scope="col">JUROS</th>
                  <th scope="col">VALOR TOTAL</th>
                  <th scope="col">VENCIMENTO</th>
                  <th scope="col">FINANCEIRO</th>
                </tr>
              </thead>
              <tbody>
                {state.parcelas_pendentes.map(parcela => {
                  const valor = parcela.valorPagamento;
                  let multa = parcela.financeiroContasEntity.multa;
                  let juros = parcela.financeiroContasEntity.juros;
                  let qtdDiasAtraso = state.data_acordo_realizado.diff(moment(parcela.dtVencimento), 'days');
                  multa = valor * (multa/100);
                  juros = parseFloat((((juros/30)*valor)/100).toFixed(2));
                  juros = parseFloat((juros * qtdDiasAtraso).toFixed(2));

                  if(state.parcelas_origem.some(value => value.id === parcela.id)) {
                    valorTotal += valor;
                    multaTotal += multa;
                    jurosTotal += juros;
                  }

                  return (
                    <tr key={parcela.id}>
                      {!id && (
                        <td>
                          <div className="custom-control custom-checkbox" style={{ top: -12 }}>
                            <input
                              className="custom-control-input"
                              id={parcela.uk}
                              type="checkbox"
                              checked={(state.parcelas_origem.some(value => value.id === parcela.id))}
                              disabled={disabled}
                              onChange={async value => {
                                if(value.target.checked) {
                                  await handleChange({ parcelas_origem: [ ...state.parcelas_origem, parcela ] })
                                  await handleChangeParcelasAcordo(valorTotal + valor, multaTotal + multa, jurosTotal + juros);
                                }
                                else {
                                  await handleChange({ parcelas_origem: state.parcelas_origem.filter(item => item.id !== parcela.id) })
                                  await handleChangeParcelasAcordo(valorTotal - valor, multaTotal - multa, jurosTotal - juros);
                                }
                              }}
                            />
                            <label className="custom-control-label" htmlFor={parcela.uk} />
                          </div>
                        </td>
                      )}

                      <td>
                        {parcela.financeiroContasEntity.ct && parcela.financeiroContasEntity.ct.nome}
                      </td>
                      <td>{numeral(valor).format('$ 0,0.00')}</td>
                      <td>{numeral(multa).format('$ 0,0.00')}</td>
                      <td>{numeral(juros).format('$ 0,0.00')}</td>
                      <td>{numeral(valor + multa + juros).format('$ 0,0.00')}</td>
                      <td>{parcela.dtVencimento ? moment(parcela.dtVencimento).format('DD/MM/YYYY') : '-'}</td>
                      <td>
                        <a href={`/admin/financeiro/contas/receber/${parcela.financeiroContasEntity.uk}/editar`} target='_blank' rel="noopener noreferrer" style={{ color: 'black' }}>
                          <i className="fas fa-eye mt-margin-xsmall-right"></i> Visualizar
                        </a>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              <tfoot>
                <th scope="col" colSpan="2">Total:</th>
                <th scope="col">{numeral(valorTotal).format('$ 0,0.00')}</th>
                <th scope="col">{numeral(multaTotal).format('$ 0,0.00')}</th>
                <th scope="col">{numeral(jurosTotal).format('$ 0,0.00')}</th>
                <th scope="col">{numeral(valorTotal + multaTotal + jurosTotal).format('$ 0,0.00')}</th>
                <th scope="col"></th>
                {!id && <th scope="col"></th>}
              </tfoot>
            </Table>

            {!!state.parcelas_origem.length && (
              <>
                <Col lg="12 mt-text-center mt-margin-medium-y">
                  <i className="fas fa-angle-double-down"></i>
                </Col>

                <hr className="my-4 mt-margin-large-top" />
                <Row>
                  <Col lg="4">
                    <FormControl variant="outlined" style={{ padding: "10px 0" }}>
                      <label className="form-control-label">
                        Forma de recebimento
                      </label>
                      <Select
                        className="mt-select"
                        value={state.tipo_pagamento}
                        onChange={value => handleChange({ "tipo_pagamento": value.target.value })}
                        disabled
                      >
                        <MenuItem value={1}>Boleto</MenuItem>
                      </Select>
                    </FormControl>
                  </Col>

                  <Col lg="4">
                    <FormControl variant="outlined" style={{ padding: "10px 0" }}>
                      <label className="form-control-label">Conta</label>
                      <Select
                        className="mt-select"
                        value={state.conta}
                        onChange={value => handleChange({ "conta": value.target.value })}
                        defaultValue={state.conta}
                        disabled={disabled}
                      >
                        {state.contas.map(parcela => {
                          return <MenuItem key={parcela.id} value={parcela.id}>{parcela.nome}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  </Col>

                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">Data de geração do acordo</label>
                      <Input
                        type="date"
                        format="YYYY-MM-DD"
                        className="form-control"
                        value={!!state.data_acordo ? moment(state.data_acordo).format('YYYY-MM-DD') : null}
                        disabled={disabled}
                        onChange={value => {
                          if (!moment(value.target.value).isValid()) return;
                          handleChange({ "data_acordo": value.target.value })
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {!disabled && (
                    <>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">Vencimento da primeira parcela</label>
                          <Input
                            type="date"
                            format="YYYY-MM-DD"
                            value={state.vencimento_primeira_parcela}
                            className="form-control"
                            disabled={disabled}
                            onChange={value => {
                              handleChange({ "vencimento_primeira_parcela": value.target.value });
                            }}
                            onBlur={valor => {
                              handleChangeParcelasAcordo(valorTotal, multaTotal, jurosTotal);
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">Entrada</label>
                          <IntlCurrencyInput
                            className="form-control"
                            currency="BRL"
                            value={state.entrada}
                            config={currencyConfig}
                            disabled={disabled}
                            onChange={async (event, value) => {
                              await handleChange({ "entrada": value });
                              await handleChangeParcelasAcordo(valorTotal, multaTotal, jurosTotal);
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">Número de Parcelas</label>
                          <Input
                            type="number"
                            value={state.numero_parcelas}
                            className="form-control"
                            disabled={disabled}
                            onChange={async value => {
                              await handleChange({ "numero_parcelas": value.target.value });
                              await handleChangeParcelasAcordo(valorTotal, multaTotal, jurosTotal);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </>
                  )}
                </Row>

                <Row className="mt-margin-bottom">
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">Desconto (%)</label>
                      <Input
                        className="form-control"
                        placeholder="Desconto"
                        type="number"
                        onChange={value => handleChange({ "desconto": value.target.value })}
                        value={state.desconto}
                        disabled={disabled}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">Multa (%)</label>
                      <Input
                        className="form-control"
                        placeholder="Multa"
                        type="number"
                        onChange={value => handleChange({ "multa": value.target.value })}
                        value={state.multa}
                        disabled={disabled}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">Juros (%)</label>
                      <Input
                        className="form-control"
                        placeholder="Juros"
                        type="number"
                        onChange={value => handleChange({ "juros": value.target.value })}
                        value={state.juros}
                        disabled={disabled}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                {!!state.conta && !!state.data_acordo && state.numero_parcelas > 0 && !!state.vencimento_primeira_parcela && !!state.parcelas_acordo.length && (
                  <>
                    <Col lg="12 mt-text-center mt-margin-medium-y">
                      <i className="fas fa-angle-double-down"></i>
                    </Col>

                    <Table borderless>
                      <thead>
                        <tr>
                          <th>Vencimento</th>
                          <th>Valor</th>
                          <th>Observação</th>
                          {!!id && <th>Financeiro</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {state.parcelas_acordo.map((item, index) => {
                          return (
                            <tr key={item.parcela}>
                              <th scope="row">
                                <Input
                                  type="date"
                                  format="YYYY-MM-DD"
                                  className="form-control"
                                  value={moment(item.dtVencimento).format('YYYY-MM-DD')}
                                  disabled={disabled}
                                  onChange={value => {
                                    const parcelas_acordo = state.parcelas_acordo;
                                    parcelas_acordo[index].dtVencimento = moment(value.target.value).format('YYYY-MM-DDT00:00:00');
                                    handleChange({ parcelas_acordo });
                                  }}
                                />
                              </th>
                              <td>
                                <IntlCurrencyInput
                                  className="form-control"
                                  currency="BRL"
                                  config={currencyConfig}
                                  value={item.valorPagamento}
                                  disabled={disabled}
                                  onChange={(event, value) => {
                                    const parcelas_acordo = state.parcelas_acordo;
                                    parcelas_acordo[index].valorPagamento = value;
                                    handleChange({ parcelas_acordo });
                                  }}
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  className="form-control"
                                  value={item.descricao}
                                  disabled
                                />
                              </td>

                              {!!id && (
                                <td>
                                  <a className="btn btn-link" href={`/admin/financeiro/contas/receber/${item.financeiroContasEntity.uk}/editar`} target='_blank' rel="noopener noreferrer" style={{ color: 'black', background: 'transparent', padding: '10px 0' }}>
                                    <i className="fas fa-eye mt-margin-xsmall-right"></i> Visualizar
                                  </a>
                                </td>
                              )}
                            </tr>
                          )
                        }
                        )}
                      </tbody>
                    </Table>
                    {parseFloat(valorTotal + multaTotal + jurosTotal).toFixed(2) - valorTotalParcela !== 0 && (
                      <Alert color="danger" className='mt-margin mt-margin-medium-top'>
                        Devido {numeral(valorTotal + multaTotal + jurosTotal).format('$ 0,0.00')}
                        {" "} - Parcelas {valorTotalParcela}
                        {" "} = Diferença {numeral(valorTotal + multaTotal + jurosTotal - valorTotalParcela).format('$ 0,0.00')}.
                      </Alert>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  }
}
