function phoneMask(value) {
  var valueReplace = value.replace(/\D/g, "");
  valueReplace = valueReplace.replace(/^0/, "");

  if (valueReplace.length > 10)
    valueReplace = valueReplace.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  else if (valueReplace.length > 5)
    valueReplace = valueReplace.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
  else if (valueReplace.length > 2)
    valueReplace = valueReplace.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  else
    valueReplace = valueReplace.replace(/^(\d*)/, "($1");

  valueReplace = ['(', '-'].indexOf(valueReplace[valueReplace.length-1]) !== -1 ? valueReplace.slice(0, -1) : valueReplace;
  return valueReplace;
}


export default phoneMask;
