import { FormControl, MenuItem, Select } from '@material-ui/core';
import React, { Component } from 'react';
import { Button, Card, CardBody, Col, FormGroup, Input, Row } from 'reactstrap';
import InputGroup from 'reactstrap/lib/InputGroup';

export default class Telefones extends Component {
  render() {
    const {
      state,
      addTelefone,
      removeTelefone,
      handleInputChangeTelefone,
      handleInputChangeTelefoneTipo
    } = this.props;

    return (
      <div className="mt-margin-left mt-margin-right">
        <Row>
          <Col lg="6">
            <Button onClick={addTelefone}>
              <i className="fas fa-plus" /> Adicionar
            </Button>
          </Col>
        </Row>

        <Card className="mt-margin-top">
          <CardBody>
            {state.listaTelefone && state.listaTelefone.map((single, index) => {
              return (
                <Row key={single.id}>
                  <Col lg="8">
                    <FormGroup className="mt-margin-bottom">
                      <label className="form-control-label">
                        Telefone
                        <span
                          onClick={() => removeTelefone(index, single)}
                          style={{marginLeft: 25, color: "#d00000", cursor: "pointer"}}
                          className="form-control-label"
                        >
                          Excluir
                        </span>
                      </label>
                      <InputGroup>
                        <Input
                          className="form-control"
                          onChange={event => handleInputChangeTelefone(event.target.value, single, index)}
                          id={single.id}
                          value={single.telefone ? single.telefone : ''}
                          placeholder="Telefone"
                          type="text"
                        />
                        <div className="input-group-prepend">
                          {parseInt(single.tipo) === 0 ? (
                            <a href={single.telefone !== null ? (`https://wa.me/55${single.telefone.replace(/[^0-9]+/g,'')}`) : ''}
                              onClick={event => single.telefone === null ? event.preventDefault() : ''}
                              className="btn btn-outline-secondary"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fab fa-whatsapp"/>
                            </a>
                          ) : (
                            <a href={single.telefone !== null ? (`tel:55${single.telefone.replace(/[^0-9]+/g,'')}`) : ''}
                              onClick={event => single.telefone === null ? event.preventDefault() : ''}
                              className="btn btn-outline-secondary"
                            >
                              <i className="fas fa-phone-alt"/>
                            </a>
                          )}
                        </div>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <FormControl variant="outlined">
                        <label className="form-control-label">Tipo Telefone</label>
                        <Select
                          value={single.tipo}
                          key={single.id}
                          onChange={event => handleInputChangeTelefoneTipo(event.target.value, single, index)}
                        >
                          <MenuItem value={0}>WhatsApp</MenuItem>
                          <MenuItem value={1}>Comercial</MenuItem>
                          <MenuItem value={2}>Residencial</MenuItem>
                          <MenuItem value={3}>Principal</MenuItem>
                          <MenuItem value={4}>Emergência</MenuItem>
                          <MenuItem value={5}>Outros</MenuItem>
                        </Select>
                      </FormControl>
                    </FormGroup>
                  </Col>
                </Row>
              )
            })}
          </CardBody>
        </Card>
      </div>
    );
  }
}
