/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import AdminFooter from "views/components/Footers/AdminFooter";
import AdminNavbar from "views/components/Navbars/AdminNavbar";
import Sidebar from "views/components/Sidebar/Sidebar.js";
import React, { Component, Fragment } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import routes from "routes.js";
import hasPermission from "utils/UserPermission";
import Config from "Config";
import { getUser } from "Config";
import api from "services/api";


const Routes = () => {
  return (
    <>
      {routes.map(prop => {
        return (
          <Fragment key={prop.layout + prop.path}>
            {hasPermission(prop.layout + prop.path) && (
              <Route
                key={prop.layout + prop.path}
                path={prop.layout + prop.path}
                component={prop.component}
                exact={prop.exact}
              />
            )}
          </Fragment>
        )
      })}

      {routes.map(prop => {
        if (prop.children) {
          return prop.children.map(propAction => {
            return (
              <Fragment key={prop.layout + prop.path + propAction.path}>
                {hasPermission(prop.layout + prop.path + propAction.path) && (
                  <Route
                    key={prop.layout + prop.path + propAction.path}
                    path={prop.layout + prop.path + propAction.path}
                    component={propAction.component}
                    exact={propAction.exact}
                  />
                )}
              </Fragment>
            );
          });
        }

        return null;
      })}
    </>
  );
};

class AdminLayout extends Component {
  state = {
    loading: false
  }

  async componentDidMount() {
    const user = getUser();
    this.setState({ loading: true });
    const response = await api.get(`/api/user/${user.uk}`);

    if(!!response) {
      if(JSON.stringify(user.profile.permissaoWeb) !== JSON.stringify(response.data.profile.permissaoWeb)){
        window.localStorage.setItem(Config._USER, JSON.stringify(response.data));
      }
    }

    this.setState({ loading: false });
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (this.props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "";
  };

  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/admin/dashboard",
            imgSrc: require("assets/img/" + process.env.REACT_APP_LOGO).default,
            imgAlt: "..."
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar {...this.props} brandText={this.getBrandText(this.props.location.pathname)} />
          <Switch>
            <Redirect from="/admin" to="/admin/dashboard" exact />
            <Routes />
            <Redirect from="*" to="/admin/dashboard" />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default AdminLayout;
