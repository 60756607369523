import { ButtonGroup } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import Config from "Config";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import { Card, CardBody, CardHeader, Col, Container, Form, Row } from "reactstrap";
import api from "services/api";
import { jsonToUrlFilter } from "utils/filter";
import FormButtonAction from "views/components/FormButtonAction";
import FormFields from "./Form";
import { getAccessWithStatus } from "utils/StatusAcesso";
import ButtonControl from "views/components/ButtonControl";

class FinanceiroRecorrenciaCRUD extends Component {

  state = {
    valor: null,
    categoria: null,
    tipoBusca: this.props.match.params.type === 'receber' ? 'Clientes' : 'Fornecedor',
    departamento: 0,
    status: [],
    conta: 0,
    categorias: [],
    departamentos: [],
    contas: [],
    associados: [],
    fornecedores: [],
    tipo_pagamentos: [],
    clientes: [],
    desconto: 0,
    multa: 0,
    juros: 0,
    recorrencia: "Fixa Mensal",
    tipo_pagamento: 1,
    todosClientes: false,
    enviaParaBenemerito: false,
    enviaParaRemido: false,
    loading: false,
    filter: {
      _limit: 999,
      _order: 'ASC',
      _sort: "nome",
      ativo: true
    }
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.findCategorias();
    await this.findTipoPagamentos();
    await this.findAllDepartamento();
    await this.findAllCaixa();
    await this.findContaRecorrencia();
    this.setState({ loading: false })
  }

  findContaRecorrencia = async () => {
    const { id } = this.props.match.params;
    const response = await api.get(`/api/financeiro/recorrencias/${id}`);

    if (response) {
      let temp = response;
      temp.conta = temp.conta.id;
      temp.departamento = temp.departamento.id;
      temp.tipo_pagamento = temp.tipo_pagamento.id;

      this.setState({
        ...this.state,
        ...temp,
      })
    }
  }

  findAllDepartamento = async () => {
    const response = await api.get("/api/financeiro/departamento");

    if (response) {
      this.setState({
        departamentos: response.status === 200 ? response.data : []
      })
    }
  }

  findCategorias = async () => {
    const { type } = this.props.match.params;

    const filter = {
      _limit: 999,
      _order: 'ASC',
      _sort: "nome",
      ativo: true,
      codigo_like: type === 'receber' ? '1.%' : '2.%'
    }

    const response = await api.get(`/api/financeiro/categoria?${jsonToUrlFilter(filter)}`);
    this.setState({ categorias: response.data });
  }

  findTipoPagamentos = async () => {
    const filter = {
      _limit: 999,
      _order: 'ASC',
      _sort: "id",
    }

    const response = await api.get(`/api/tipo-pagamentos?${jsonToUrlFilter(filter)}`);
    this.setState({ tipo_pagamentos: response.data });
  }

  searchFornecedor = async (search) => {
    if (!search) return;

    this.setState({ loadFornecedor: true });
    const response = await api.get(`/api/fornecedor?nome_like=${search}`);
    const response2 = await api.get(`/api/fornecedor?cnpj_cpf_like=${search}`);

    if (response && response2) {
      this.setState({
        fornecedores: [...response.data, ...response2.data],
        loadFornecedor: false,
      })
    }
    else {
      this.setState({ loadFornecedor: false })
    }
  }

  searchAssociado = async (search) => {
    if (!search) return;

    const filter = {
      _limit: 50,
      _order: 'ASC',
      _sort: "name",
      profile: { id: 2 },
      status_acesso: getAccessWithStatus("App")
    };

    this.setState({ loadAssociado: true });
    const response = await api.get(`/api/user?name_like=${search}&${jsonToUrlFilter(filter)}`);
    const response2 = await api.get(`/api/user?numero_titulo_like=${search}&${jsonToUrlFilter(filter)}`);

    if (response && response2) {
      this.setState({
        associados: [...response.data, ...response2.data],
        loadAssociado: false,
      })
    }
    else {
      this.setState({ loadAssociado: false })
    }
  }

  findAllCaixa = async () => {
    const response = await api.get('/api/financeiro/caixa?ativo=true');

    if (response) {
      this.setState({ contas: response.data })
    }
  }

  handleChangeValorTotal = (event, value) => {
    event.preventDefault();
    this.setState({ valor: value })
  }

  delete = () => {
    this.setState({ loading: true });
    let dataUser = JSON.stringify({ uk: this.state.uk });

    fetch(Config._HOST + "/api/financeiro/contas",
      {
        body: dataUser,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => response.json())
      .then(data => {
        this.props.enqueueSnackbar(data.mensagem);

        this.setState({ loading: false }, () => {
          if (data.status === 200) {
            this.props.history.push("/admin/financeiro/contas-a-receber");
          }
        })
      }).catch(e => {
        this.setState({ loading: false });
        this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
      })
  }

  create = async () => {
    const { id, type } = this.props.match.params;
    let temp = this.state;
    temp.files = [];

    // if (!uk) {
    //   if (temp.tipoBusca === 'Fornecedor') {
    //     if (!temp.fornecedor) {
    //       this.props.enqueueSnackbar("Selecione um fornecedor");
    //       return;
    //     }
    //   }
    //   else {
    //     if (!temp.todosClientes && !temp.clientes.length) {
    //       this.props.enqueueSnackbar("Selecione um associado");
    //       return;
    //     }
    //   }
    // }

    if (!temp.categoria) {
      this.props.enqueueSnackbar("Selecione uma categoria")
      return;
    }

    if (!temp.departamento) {
      this.props.enqueueSnackbar("Selecione um departamento")
      return;
    }

    if (temp.conta === 0) {
      this.props.enqueueSnackbar("Selecione uma conta")
      return;
    }

    if (!temp.valor || temp.valor < 0) {
      this.props.enqueueSnackbar("Preencha um valor total")
      return;
    }

    if (!temp.tipo_pagamento) {
      this.props.enqueueSnackbar("Preencha a Forma de Pagamento")
      return;
    }

    this.setState({ loading: true });

    const response = await api.put(`/api/financeiro/recorrencias/${id}`, {
      ...temp,
      tipo: type,
      departamento: { id: temp.departamento },
      conta: { id: temp.conta },
      tipo_pagamento: { id: temp.tipo_pagamento }
    });

    if(!response || ('data' in response && 'error' in response.data && 'status' in response.data)) {
      this.setState({ loading: false });
      this.props.enqueueSnackbar("Ops, ocorreu um erro!");
      return;
    }

    this.setState({ loading: false });
    this.props.enqueueSnackbar("Recorrência atualizada com sucesso!");
    this.props.history.push(`/admin/financeiro/recorrencia/${type}`);
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  }

  render() {
    const { tipo_pagamento, loading } = this.state;
    const { uk, type } = this.props.match.params;
    const disabled = uk && tipo_pagamento === "BOLETO" ? true : false;

    return (
      <>
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={`/admin/financeiro/contas/${type}`}>
                    <i className="fas fa-arrow-left" /> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={`Contas a ${type === 'receber' ? 'Receber' : 'Pagar'}`}
          subTitulo={(uk ? `Atualizar contas a ${type === 'receber' ? 'Receber' : 'Pagar'}` : `Cadastrar nova conta a ${type === 'receber' ? 'Receber' : 'Pagar'}`)}
        />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {uk ? `Atualizar contas a ${type === 'receber' ? 'Receber' : 'Pagar'}` : `Cadastro de conta a ${type === 'receber' ? 'Receber' : 'Pagar'}`}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <BoxLoading />
                  ) : (
                    <Form>
                      <FormFields {...this} disabled={disabled} />
                      <FormButtonAction create={this.create} cancel={`/admin/financeiro/recorrencia/${type}`} history={this.props.history} isEdit />
                    </Form>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(FinanceiroRecorrenciaCRUD);
