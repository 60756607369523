import { ButtonGroup } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import Config from "Config";
import { withSnackbar } from "notistack";
import React from "react";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { BoxLoading } from "react-loadingg";
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row } from "reactstrap";
import api from "services/api";
import phoneMask from "utils/phoneMask";
import ButtonControl from "views/components/ButtonControl";


class AgendaConvidadosCrud extends React.Component {

  state = {
    loading: true,
    dataAmbiente: [],
    dataResponsavelFinanceiro: [],
    ambiente: "0",
    dataResponsavel: [],
    dresponsavel: "1",
    status: "0",
  }

  async componentDidMount() {
    this.setState({ loading: true });
    let user = JSON.parse(window.localStorage.getItem(Config._USER))
    if (!user) {
      window.localStorage.removeItem(Config._USER)
      this.props.history.replace('/auth')
      return;
    }

    if (this.props.match.params.ukConvidado) {
      await this.findBy(this.props.match.params.ukConvidado)
    }

    this.setState({ session: user, loading: false });
  }

  findBy = async (uk) => {
    const response = await api.get("/api/convidado/" + uk)

    if (response.status === 200) {
      this.setState({
        ...response.data,
        ccaixa: (response.data.caixa && response.data.caixa[0] ? response.data.caixa[0].id : null),
        ccategoria: (response.data.categoria && response.data.categoria[0] ? response.data.categoria[0].id : null)
      })
    }
  }

  delete() {
    this.setState({ loading: true });

    let dataUser = JSON.stringify(this.state)
    fetch(Config._HOST + "/api/convidado/" + this.props.match.params.ukConvidado,
      {
        body: dataUser,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => response.json())
      .then(data => {
        this.props.enqueueSnackbar(data.mensagem)
        this.setState({
          loading: false
        }, () => {
          if (data.status === 200) {
            this.props.history.push('/admin/agenda/' + this.props.match.params.uk + '/convidados')
          }
        })
      }).catch(e => {
        this.setState({ loading: false })
        this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
      })
  }

  create() {
    let temp = this.state;

    if (!this.state.nome || this.state.nome === "") {
      this.props.enqueueSnackbar("Campo 'Nome' obrigatório!")
      return;
    }
    if (!this.state.telefone || this.state.telefone.length < 14) {
      this.props.enqueueSnackbar("Campo 'Telefone' obrigatório!")
      return;
    }

    if (this.props.match.params.ukConvidado) {
      temp['documentoAgendaEntity'] = { id: temp['documentoAgendaEntity'] };
    }
    else {
      temp["ukDocumentoAgenda"] = this.props.match.params.uk;
    }

    this.setState({ loading: true });

    let agendaData = JSON.stringify(temp);
    fetch(Config._HOST + "/api/convidado",
      {
        body: agendaData,
        method: this.props.match.params.ukConvidado ? 'PUT' : 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => response.json())
      .then(data => {
        this.props.enqueueSnackbar(data.mensagem)
        this.setState({ loading: false }, () => {
          if (data.status === 200) {
            this.props.history.push('/admin/agenda/' + this.props.match.params.uk + '/convidados')
          }
        })
      }).catch(e => {
        this.setState({ loading: false })
        this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
      })
  }

  render() {
    const { uk } = this.props.match.params;

    return (
      <>
        {this.state.loading && (
          <div className="container-load-with-margin">
            <BoxLoading />
          </div>
        )}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={`/admin/agenda/${uk}/convidados`}>
                    <i className="fas fa-arrow-left" /> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"Convidado"}
          subTitulo={""}
        />
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {this.props.match.params.ukConvidado ? "Atualizar" : "Cadastro"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">Nome Completo</label>
                            <Input
                              className="form-control"
                              placeholder="Nome Completo"
                              type="text"
                              onChange={value => this.setState({ nome: value.target.value.toUpperCase() })}
                              value={this.state.nome}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">Telefone</label>
                            <Input
                              className="form-control"
                              placeholder="Telefone"
                              type="text"
                              onChange={value => this.setState({ telefone: phoneMask(value.target.value) })}
                              value={this.state.telefone}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label className="form-control-label">Observação</label>
                            <Input
                              className="form-control"
                              placeholder="Observação"
                              type="textarea"
                              rows="5"
                              onChange={value => this.setState({ observacao: value.target.value.toUpperCase() })}
                              value={this.state.observacao}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <hr className="my-4" />

                    <div className="pl-lg-4" style={{ marginTop: "10px" }}>
                      <Row>
                        {this.props.match.params.ukConvidado && (
                          <Col lg="6">
                            <FormGroup>
                              <Button color="danger" style={{ width: "100%" }} onClick={() => {
                                confirmAlert({
                                  title: 'Deseja excluir?',
                                  message: '',
                                  buttons: [
                                    {
                                      label: 'Não',
                                    },
                                    {
                                      label: 'Sim',
                                      onClick: () => this.delete()
                                    }
                                  ]
                                })
                              }
                              }>
                                <i className="fas fa-trash" style={{ marginRight: 5 }}></i> EXCLUIR
                              </Button>
                            </FormGroup>
                          </Col>
                        )}

                        <Col lg={this.props.match.params.ukConvidado ? 6 : 12}>
                          <FormGroup>
                            <Button color="default" style={{ width: "100%" }} onClick={() => {
                              confirmAlert({
                                title: this.props.match.params.ukConvidado ? 'Deseja confirmar a atualização?' : 'Deseja confirmar o cadastro?',
                                message: '',
                                buttons: [
                                  {
                                    label: 'Não',
                                  },
                                  {
                                    label: 'Sim',
                                    onClick: () => this.create()
                                  }
                                ]
                              })
                            }
                            }>
                              <i className="fas fa-check" style={{ marginRight: 5 }}></i>
                              {this.props.match.params.ukConvidado ? "ATUALIZAR" : "CADASTRAR"}</Button>
                          </FormGroup>
                        </Col>

                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(AgendaConvidadosCrud);
