import React, { Component } from 'react';
import InformacoesGerais from './InformacoesGerais';
import Financeiro from './Financeiro';
import Termos from './Termos';

export default class FormFields extends Component {
  render() {
    return (
      <>
        <h6 className="heading-small text-muted mb-4">Informações Gerais</h6>
        <InformacoesGerais {...this.props} />

        {this.props.state.permitirLocacao && (
          <>
            <hr className="my-4 mt-margin-large-top" />
            <h6 className="heading-small text-muted mb-4">Termos</h6>
            <Termos {...this.props} />
          </>
        )}

        <hr className="my-4 mt-margin-large-top" />
        <h6 className="heading-small text-muted mb-4">Financeiro</h6>
        <Financeiro {...this.props} />
      </>
    );
  }
}
