/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Bar, Pie } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";

import 'react-confirm-alert/src/react-confirm-alert.css';

// core components
import {
  chartOptions,
  parseOptions
} from "assets/variables/charts.js";

// var colors = {
//   gray: {
//     100: "#f6f9fc",
//     200: "#e9ecef",
//     300: "#dee2e6",
//     400: "#ced4da",
//     500: "#adb5bd",
//     600: "#8898aa",
//     700: "#525f7f",
//     800: "#32325d",
//     900: "#212529"
//   },
//   theme: {
//     default: "#172b4d",
//     primary: "#5e72e4",
//     secondary: "#f4f5f7",
//     info: "#11cdef",
//     success: "#2dce89",
//     danger: "#f5365c",
//     warning: "#fb6340"
//   },
//   black: "#12263F",
//   white: "#FFFFFF",
//   transparent: "transparent"
// };

import Header from "views/components/Headers/Header.js";
import Config, { parsePercentual } from "Config";
import moment from 'moment';
import numeral from 'numeral';

numeral.register('locale', 'pt-BR', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  currency: {
    symbol: 'R$'
  }
});

numeral.locale('pt-BR');

// yAxes
Chart.scaleService.updateScaleDefaults("linear", {
  gridLines: {
    borderDash: [2],
    borderDashOffset: [2],
    color: '#fff',
    drawBorder: false,
    drawTicks: false,
    lineWidth: 0,
    zeroLineWidth: 0,
    zeroLineColor: '#fff',
    zeroLineBorderDash: [2],
    zeroLineBorderDashOffset: [2]
  },
  ticks: {
    beginAtZero: true,
    padding: 10,
    fontColor: '#fff',
    callback: function (value) {
      if (!(value % 10)) {
        return value;
      }
    }
  }
});

class Dashboard extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    chart: [],
    chartAtendimentos: [],
    data: [785, 58, 3, 1],
  };

  componentDidMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
    let user = JSON.parse(window.localStorage.getItem(Config._USER))
    if (!user) {
      window.localStorage.removeItem(Config._USER)
      this.props.history.replace('/auth')
      return;
    }
  }

  parseMonth(data) {
    let temp = []
    if (data &&
      data.length > 0) {
      let size = data.length
      for (let i = 1; i <= size; i++) {
        temp.push(
          moment(
            data[(size - i)].date).format('MM/YY')
        )
      }
    }
    return temp
  }

  parseData(data) {
    let temp = []
    if (data &&
      data.length > 0) {
      let size = data.length
      for (let i = 1; i <= size; i++) {
        temp.push(
          data[(size - i)].valor
        )
      }
    }
    return temp
  }

  parse(data) {
    return {
      labels: this.parseMonth(data),
      datasets: [
        {
          label: "RECEBIDOS",
          data: this.parseData(data)
        }
      ]
    };
  };

  render() {
    const bubble = {
      labels: [
        "Proprietário",
        "Remido",
        "Fornecedor",
        "Benemérito",
      ],
      datasets: [
        {
          data: parsePercentual(this.state.data),
          backgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#cdcdcd'
          ],
          hoverBackgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#cdcdcd'
          ]
        },
      ]
    };

    return (
      <>
        <Header {...this.props} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="bg-gradient-primary shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-light ls-1 mb-1">
                        Quantidade de cadastros por categoria
                      </h6>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart">
                    <Bar
                      height='110'
                      data={
                        {
                          labels: [
                            'Ativo',
                            'Desligado',
                            'Inativo',
                            'Inadimplente',
                            'Falecido',
                            'Licenciado',
                            'Irregular'
                          ],
                          datasets: [
                            {
                              label: 'Proprietário',
                              backgroundColor: '#FF6384',
                              borderColor: '#FF6384',
                              borderWidth: 1,
                              data: [65, 59, 80, 81, 2, 4, 19]
                            },
                            {
                              label: 'Remido',
                              backgroundColor: '#37A2EB',
                              borderColor: '#37A2EB',
                              borderWidth: 1,
                              data: [45, 79, 10, 41, 99, 80, 88]
                            },
                            {
                              label: 'Fornecedor',
                              backgroundColor: '#FFCE56',
                              borderColor: '#FFCE56',
                              borderWidth: 1,
                              data: [45, 79, 10, 41, 99, 80, 88]
                            },
                            {
                              label: 'Benemérito',
                              backgroundColor: '#CDCDCD',
                              borderColor: '#CDCDCD',
                              borderWidth: 1,
                              data: [45, 79, 10, 41, 99, 80, 88]
                            }
                          ]
                        }
                      }
                      options={{
                        responsive: true,
                        // legend: {
                        //     display: false,
                        // },
                        type: 'bar',
                        legend: {
                          display: true,
                          position: "top",
                          labels: {
                            usePointStyle: true,
                            padding: 5
                          }
                        }
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-light ls-1 mb-1">
                        PORCENTAGEM POR CATEGORIA
                      </h6>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Pie
                      data={bubble}
                      redraw
                      options={{
                        responsive: true,
                        legend: {
                          display: true,
                          position: "left",
                        }
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Dashboard;
