/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { CardHeader, Media, Container } from "reactstrap";
// core components
import Config from "Config";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { Avatar, ButtonGroup, Modal, Typography } from "@material-ui/core";
import moment from 'moment';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import { withSnackbar } from "notistack";
import ButtonControl from "views/components/ButtonControl";

class AssociadoPerfil extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      eventosLoad: false,
      eventos: []
    }
  }

  componentDidMount() {
    let user = JSON.parse(window.localStorage.getItem(Config._USER))
    if (!user) {
      window.localStorage.removeItem(Config._USER)
      this.props.history.replace('/auth')
      return;
    }
    this.findEvents()
    this.findBy()
  }

  findEvents = () => {
    this.setState({
      eventosLoad: true,
    })
    fetch(Config._HOST + "/api/events/" + this.props.match.params.uk)
      .then(response => response.json())
      .then(response => {
        this.setState({
          eventosLoad: false,
          eventos: response.data
        })
      }).catch(e => {
        this.setState({
          eventosLoad: false
        })
      })
  }

  findBy = () => {
    this.setState({
      loading: true,
    })
    fetch(Config._HOST + "/api/user/" + this.props.match.params.uk)
      .then(response => response.json())
      .then(response => {
        this.setState({
          loading: false,
          ...response.data
        })
      }).catch(e => {
        this.setState({
          loading: false
        })
      })
  }

  renderContent() {
    return <CardHeader className="border-0">
      <h3 className="mb-0" style={{ textAlign: "center" }}>
        {this.state.eventosLoad ? <BoxLoading /> : this.state.eventos.length > 0 ?
          <p>Linha do Tempo de Eventos <i className="far fa-smile-beam"></i> </p> : <p>Não existem registros de eventos até o momento. <i className="far fa-frown"></i></p>}
      </h3>
      {!this.state.eventosLoad && this.state.eventos.length > 0 ?
        <Timeline align="alternate">
          {this.state.eventos.map(single => {
            return <TimelineItem>
              <TimelineOppositeContent>
                <Typography color="textSecondary">{moment(single.dtEvent).format('DD/MM/YYYY')
                  + " às " + moment(single.dtEvent).format('HH:mm')}</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography>{single.descricao}</Typography>
              </TimelineContent>
            </TimelineItem>
          })}
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="default" variant="outlined">
                <ArrowUpwardIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent />
          </TimelineItem>
        </Timeline>
        : <p />}

    </CardHeader>
  }

  render() {
    const { uk } = this.props.match.params;

    return (
      <>
        {/* <Header /> */}
        <HeaderButtonGroup
          avatar={
            <Media className="align-items-center">
              <Avatar
                style={{
                  width: '200px',
                  height: '200px',
                }}
                alt={this.props.match.params.name}
                src={Config._HOST + `/api/bucket/owner/${uk}/key/foto-capa`} />
            </Media>
          }
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to='/admin/associado/'>
                    <i className="fas fa-arrow-left"></i> VOLTAR
                  </ButtonControl>
                  <ButtonControl color='secondary' to={`/admin/associado/${uk}`}>
                    <i className="fas fa-edit" />
                  </ButtonControl>
                  <ButtonControl color='secondary' to={`/admin/associado/${uk}/dependentes`}>
                    DEPENDENTES
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          } titulo={this.state.name} subTitulo={"Página de controle do Associado"} />
        <Container className="mt--12" fluid>
          {this.renderContent()}
        </Container>
        {this.state.loading ? <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={true}
        >
          <BoxLoading color={"#fff"} />
        </Modal> : <p />}
      </>
    );
  }
}

export default withSnackbar(AssociadoPerfil);
