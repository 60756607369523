const estadoCivil = [
  { id: 1, descricao: 'Solteiro' },
  { id: 2, descricao: 'Relação Estável' },
  { id: 3, descricao: 'Casado' },
  { id: 4, descricao: 'Divorciado' },
  { id: 5, descricao: 'Separado' },
  { id: 6, descricao: 'Viúvo' }
];

export { estadoCivil };
