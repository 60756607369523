import React, { Component } from 'react';

class PoliticaDePrivacidade extends Component {
  render() {
    return (
      <div id="politica-de-privacidade">
        <header>
          <h1>Política de Privacidade</h1>
        </header>

        <section id="informacoes-gerais">
          <h2>Informações Gerais</h2>
          <p><strong>Nome da Empresa:</strong> Clube de Caça e Tiro Vasconcelos Drumond</p>
          <p><strong>Endereço Físico:</strong> R. Uruguai, 546 - Centro, Itajaí - SC, 88302-202, Brasil</p>
          <p>
            <strong>E-mail para Contato:</strong>
            <a href="mailto:administrativo@clubeatiradores.com.br">administrativo@clubeatiradores.com.br</a>
          </p>
          <p><strong>Telefone para Contato:</strong> (47) 3348-0710</p>
        </section>

        <section id="coleta-de-informacoes">
          <h2>Coleta de Informações</h2>
          <p><strong>Tipo de Informações Pessoais:</strong> Não coletamos nenhuma informação em nosso aplicativo, somente
            disponibilizamos as informações coletadas na secretaria do clube.</p>
          <p><strong>Como são Coletadas:</strong> Todas as informações dos usuários são coletadas na secretaria do clube e
            disponibilizadas no aplicativo.</p>
          <p><strong>Coleta Automática:</strong> Não realizamos coleta automática de informações, como endereço IP, cookies ou
            dados de navegação.</p>
        </section>

        <section id="uso-de-informacoes">
          <h2>Uso de Informações</h2>
          <p><strong>Como as Informações São Usadas:</strong> Para identificação e contato.</p>
          <p><strong>Compartilhamento com Terceiros:</strong> Não compartilhamos informações com terceiros.</p>
        </section>

        <section id="cookies">
          <h2>Cookies</h2>
          <p><strong>Uso de Cookies:</strong> Nosso site não coleta cookies, mas coletamos em nosso aplicativo para poder
            trazer informações respectivas do usuário que fez o login.</p>
          <p><strong>Gerenciamento de Preferências:</strong> Ao utilizar nossa plataforma, o usuário aceita as preferências de
            cookies.</p>
        </section>

        <section id="seguranca">
          <h2>Segurança</h2>
          <p><strong>Medidas de Segurança:</strong> Utilizamos criptografia em dados sensíveis.</p>
          <p><strong>Armazenamento e Retenção:</strong> Armazenamos os dados até 6 anos após o desligamento do sócio com o
            clube.</p>
        </section>

        <section id="direitos-do-usuario">
          <h2>Direitos do Usuário</h2>
          <p><strong>Acesso, Correção e Exclusão:</strong> Os usuários podem acessar, corrigir ou excluir suas informações
            através do aplicativo, meios de contato ou diretamente através da secretaria.</p>
        </section>

        <section id="consentimento">
          <h2>Consentimento</h2>
          <p><strong>Fornecimento de Consentimento:</strong> Os usuários fornecem consentimento para a coleta e processamento
            de suas informações pessoais.</p>
          <p><strong>Retirada do Consentimento:</strong> A retirada do consentimento pode ser feita através do aplicativo,
            meios de contato ou diretamente através da secretaria.</p>
        </section>

        <section id="contato">
          <h2>Contato</h2>
          <p><strong>Dúvidas ou Preocupações:</strong> Em caso de dúvidas ou preocupações sobre a política de privacidade, os
            usuários podem entrar em contato através do aplicativo, meios de contato ou diretamente através da secretaria.</p>
        </section>
      </div>
    );
  }
}

export default PoliticaDePrivacidade;
