import { Component } from 'react';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import cpfMask from 'utils/cpfMask';
import phoneMask from 'utils/phoneMask';

export default class FormFields extends Component {
  render() {
    const { state, handleChange } = this.props;

    return (
      <>
        <div className="mt-margin-left mt-margin-right">
          <Row>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">Nome Completo</label>
                <Input
                  className="form-control"
                  placeholder="Nome Completo"
                  type="text"
                  onChange={value => handleChange({ nome: value.target.value.toUpperCase() })}
                  value={state.nome}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">CPF</label>
                <Input
                  className="form-control"
                  placeholder="CPF"
                  type="text"
                  onChange={value => handleChange({ cpf: cpfMask(value.target.value) })}
                  value={state.cpf}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">Telefone</label>
                <Input
                  className="form-control"
                  placeholder="Telefone"
                  type="text"
                  onChange={value => handleChange({ telefone: phoneMask(value.target.value) })}
                  value={state.telefone}
                />
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label">Observação</label>
                <Input
                  className="form-control"
                  placeholder="Observação"
                  type="textarea"
                  rows="5"
                  onChange={value => handleChange({ observacao: value.target.value.toUpperCase() })}
                  value={state.observacao}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
