/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/plugins/nucleo/css/nucleo.css";
import "assets/scss/argon-dashboard-react.scss";
import { authHeader, authHeaderBucket, getToken, getUser } from "Config";
import fetchIntercept from 'fetch-intercept';
import AdminLayout from "views/layouts/Admin";
import AuthLayout from "views/layouts/Auth";
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import FazerInscricao from "views/pages/Inscricoes/FazerInscricao";
import CadastroVisitante from "views/pages/Visitantes/CadastroVisitante";
import PoliticaDePrivacidade from "views/pages/PoliticaDePrivacidade";

fetchIntercept.register({
  request: function (url, config) {
    if (!config) config = {};

    if (config.body && config.body instanceof FormData)
      config['headers'] = authHeaderBucket();
    else
      config['headers'] = authHeader();

    return [url, config];
  },

  requestError: function (error) {
    return Promise.reject(error);
  },

  response: function (response) {
    if (response && response.status === 403) {
      // window.localStorage.removeItem(Config._USER)
      // window.location.reload()
    }
    return response;
  },

  responseError: function (error) {
    return Promise.reject(error);
  }
});

let PrivateRoute = ({ render, cookies, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        !!getToken() && !!getUser() ? (
          React.createElement(render, props)
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

let AuthRoute = ({ render, cookies, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        !getToken() && !getUser() ? (
          React.createElement(render, props)
        ) : (
          <Redirect
            to={{
              pathname: "/admin/dashboard",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

ReactDOM.render(
  <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
    <BrowserRouter>
      <Switch>
        <PrivateRoute path="/admin" render={props => <AdminLayout {...props} />} />
        <AuthRoute path='/auth' render={props => <AuthLayout {...props} />} />

        <Route path="/inscricoes/:uk" render={props => <FazerInscricao {...props} />} />
        <Route path="/visitante/:uk" render={props => <CadastroVisitante {...props} />} />
        <Route path="/politica-de-privacidade" render={props => <PoliticaDePrivacidade {...props} />} />

        <Redirect from='*' to='/admin/dashboard' />
      </Switch>
    </BrowserRouter>
  </SnackbarProvider>,
  document.getElementById("root")
);
