import { CircularProgress, FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import React, { Component } from 'react';
import IntlCurrencyInput from "react-intl-currency-input";
import { Col, FormGroup, Input, Row } from 'reactstrap';

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export default class FormFields extends Component {

  render() {
    const { uk, type } = this.props.props.match.params;
    const { state, handleChange, handleChangeValorTotal, searchFornecedor, searchAssociado } = this.props;

    return (
      <>
        <h6 className="heading-small text-muted mb-4">Informações Gerais</h6>
        <div className="pl-lg-4">
          <Row>
            {state.tipoBusca === "Fornecedor" ? (
              <>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-username">
                      Fornecedor / Prestador
                    </label>
                    <Autocomplete
                      options={state.fornecedores}
                      getOptionLabel={option => `(${option.cnpjCpf}) ${option.nome}`}
                      onChange={(_, value) => handleChange("fornecedor", value)}
                      onInputChange={(_, value) => searchFornecedor(value)}
                      inputMode="text"
                      key="fornecedor"
                      loading={state.loadFornecedor}
                      value={state.fornecedor}
                      renderOption={(option) => (
                        <span>({option.cnpjCpf}) {option.nome}</span>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Buscar pelo nome do Fornecedor"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {state.loadFornecedor ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </>
            ) : (
              <>
                {!uk ? (
                  <Col lg="2">
                    <FormGroup>
                      <FormControl variant="outlined">
                        <label className="form-control-label">Selecionar Todos</label>
                        <Select
                          name="todosClientes"
                          className="mt-select"
                          placeholder="Selecionar Todos"
                          onChange={value => { handleChange("todosClientes", value.target.value) }}
                          value={state.todosClientes ? state.todosClientes : false}
                        >
                          <MenuItem value={false}>Não</MenuItem>
                          <MenuItem value={true}>Sim</MenuItem>
                        </Select>
                      </FormControl>
                    </FormGroup>
                  </Col>
                ) : ''}

                {!state.todosClientes ? (
                  <Col lg={uk ? "12" : "10"}>
                    <FormGroup style={{ position: 'relative', top: -4 }}>
                      <label className="form-control-label" htmlFor="input-username">
                        Associado
                      </label>
                      <Autocomplete
                        options={state.associados}
                        getOptionLabel={option => `(${option.numeroTitulo}) ${option.name}`}
                        onChange={(_, value) => handleChange("usuarios", value)}
                        onInputChange={(_, value) => searchAssociado(value)}
                        inputMode="text"
                        value={state.usuarios}
                        multiple
                        renderOption={(option) => (
                          <span>({option.numeroTitulo}) {option.name}</span>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Buscar Associado"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {state.loadAssociado ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                ) : (
                  <>
                    <Col lg="10" style={{ display: 'flex', alignItems: 'center' }}>
                      <FormGroup className="mt-margin-medium-top" style={{ marginLeft: 50 }}>
                        <Input
                          id="enviar-para-remido"
                          className="form-check-input"
                          type="checkbox"
                          onChange={value => handleChange("enviaParaRemido", !state.enviaParaRemido)}
                          checked={state.enviaParaRemido}
                          value={state.enviaParaRemido}
                        />
                        <label for="enviar-para-remido" className="form-control-label">Enviar Para Remido</label>
                      </FormGroup>
                      <FormGroup className="mt-margin-medium-top" style={{ marginLeft: 50 }}>
                        <Input
                          id="enviar-para-benemerito"
                          className="form-check-input"
                          type="checkbox"
                          onChange={value => handleChange("enviaParaBenemerito", !state.enviaParaBenemerito)}
                          checked={state.enviaParaBenemerito}
                          value={state.enviaParaBenemerito}
                        />
                        <label for="enviar-para-benemerito" className="form-control-label">Enviar Para Benemérito</label>
                      </FormGroup>
                    </Col>
                  </>
                )}
              </>
            )}

            <Col lg="12">
              <FormGroup>
                <label className="form-control-label">Descrição</label>
                <Input
                  className="form-control"
                  placeholder="Descrição"
                  type="text"
                  onChange={value => handleChange("descricao", value.target.value.toUpperCase())}
                  value={state.descricao}
                  maxLength={120}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormControl className="mt-margin-top" variant="outlined">
                <label className="form-control-label">Categoria</label>
                <Autocomplete
                  options={state.categorias}
                  getOptionLabel={(option) => `(${option.codigo}) ${option.nome}`}
                  onChange={(_, value) => handleChange("categoria", value)}
                  inputMode="text"
                  key="categorias"
                  value={state.categoria}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span> ({option.codigo}) {option.nome} </span>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Selecione uma categoria"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {state.loadClientes ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Col>
            <Col lg="6">
              <FormControl className="mt-margin-top" variant="outlined">
                <label className="form-control-label">Departamento</label>
                <Select
                  className="mt-select"
                  value={state.departamento ? state.departamento : 0}
                  onChange={(value) => {
                    handleChange("departamento", value.target.value)
                  }}
                >
                  <MenuItem value={0}>Selecione um Departamento</MenuItem>
                  {state.departamentos.map(single => {
                    return <MenuItem key={single.id} value={single.id}>{single.nome}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Col>
          </Row>
        </div >

        <hr className="my-4 mt-margin-large-top" />
        <h6 className="heading-small text-muted mb-4">Financeiro</h6>
        <div className="pl-lg-4">
          <Row>
            <Col lg="3">
              <FormControl variant="outlined" style={{ padding: "10px 0" }}>
                <label className="form-control-label">Conta</label>
                <Select
                  className="mt-select"
                  value={state.conta}
                  onChange={value => handleChange("conta", value.target.value)}
                  defaultValue={state.conta}
                >
                  <MenuItem value={0}>Selecione uma Conta</MenuItem>
                  {state.contas.map(single => {
                    return <MenuItem key={single.id} value={single.id}>{single.nome}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <FormControl variant="outlined" style={{ padding: "10px 0" }}>
                <label className="form-control-label">Recorrência</label>
                <Select className="mt-select" value={"Fixa Mensal"} disabled>
                  <MenuItem value="Fixa Mensal">Fixa Mensal</MenuItem>
                </Select>
              </FormControl>
            </Col>
            <Col lg="3">
              <FormControl variant="outlined" style={{ padding: "10px 0" }}>
                <label className="form-control-label">
                  Forma de Pagamento
                </label>
                <Select
                  className="mt-select"
                  value={state.tipo_pagamento}
                  onChange={value => handleChange("tipo_pagamento", value.target.value)}
                >
                  {state.tipo_pagamentos.map(value => (
                    <MenuItem key={value.id} value={value.id}>{value.descricao}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col lg="3">
              <FormGroup>
                <label className="form-control-label">Valor Total</label>
                <IntlCurrencyInput
                  className="form-control"
                  currency="BRL"
                  value={state.valor}
                  config={currencyConfig}
                  onChange={(event, value) => {
                    handleChangeValorTotal(event, value);
                  }}
                />
              </FormGroup>
            </Col>

            <Col lg="3">
              <FormGroup>
                <label className="form-control-label">Data da primeira parcela</label>
                <Input
                  type="date"
                  format="YYYY-MM-DD"
                  value={!!state.data_primeira_parcela ? moment(state.data_primeira_parcela).format('YYYY-MM-DD') : null}
                  onChange={value => {
                    if (!moment(value.target.value).isValid()) return;
                    handleChange("data_primeira_parcela", value.target.value)
                  }}
                  className="form-control"
                />
              </FormGroup>
            </Col>
          </Row>

          {type === 'receber' && state.tipo_pagamento === 1 && (
            <Row className="mt-margin-bottom">
              <Col lg="3">
                <FormGroup>
                  <label className="form-control-label">Desconto (%)</label>
                  <Input
                    className="form-control"
                    placeholder="Desconto"
                    type="number"
                    onChange={value => handleChange("desconto", value.target.value.toUpperCase())}
                    value={state.desconto}
                  />
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup>
                  <label className="form-control-label">Multa (%)</label>
                  <Input
                    className="form-control"
                    placeholder="Multa"
                    type="number"
                    onChange={value => handleChange("multa", value.target.value.toUpperCase())}
                    value={state.multa}
                  />
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup>
                  <label className="form-control-label">Juros (%)</label>
                  <Input
                    className="form-control"
                    placeholder="Juros"
                    type="number"
                    onChange={value => handleChange("juros", value.target.value.toUpperCase())}
                    value={state.juros}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
        </div>
      </>
    );
  }
}
