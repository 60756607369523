/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { ButtonGroup, FormControl, FormGroup, MenuItem, Select, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Filter from "views/components/Filter";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { onChangeCheckbox } from "Config";
import moment from "moment";
import { withSnackbar } from "notistack";
import numeral from 'numeral';
import React, { Component } from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import BoxLoading from "react-loadingg/lib/BoxLoading";
import { Button, Card, CardHeader, Col, Container, Row, Table } from "reactstrap";
import api from "services/api";
import { jsonToUrlFilter } from "utils/filter";
import { DescricaoStatusPagamento } from "utils/StatusPagamento";
import ButtonControl from "views/components/ButtonControl";

class HistoricoFinanceiro extends Component {

  state = {
    data: [],
    categorias: [],
    associados: [],
    fornecedores: [],
    checked: [],
    checkAll: true,
    loading: false,
    loadingMore: false,
    categoria: '',
    cliente: '',
    fornecedor: '',
    filter: {
      data_inicio: moment().startOf('month').format('YYYY-MM-DD'),
      data_fim: moment().endOf('month').format('YYYY-MM-DD'),
      status: [0],
      financeiro_contas_entity: {
        type: 'receber',
        cliente: { uk: this.props.match.params.uk }
      },
      _page: 1,
      _limit: 50,
      _order: 'desc',
      _sort: "dt_vencimento",
    },
  };

  async componentDidMount() {
    this.defaultFilter = { ...this.state.filter };
    await this.findContas();
    await this.findCategorias();
    await this.findAssociados();
    await this.findFornecedores();
  }

  findContas = async (removeData = true) => {
    const { data, filter, loading } = this.state;
    if (loading) return;

    await this.setState({ loading: removeData, loadingMore: !removeData });

    if (removeData && filter._page > 1) {
      await this.setState({ filter: { ...filter, _page: 1 } })
    }

    let tmpFilter = { ...filter };

    tmpFilter[`${filter._sort}>=`] = filter.data_inicio;
    tmpFilter[`${filter._sort}<=`] = filter.data_fim;
    delete tmpFilter['data_inicio'];
    delete tmpFilter['data_fim'];

    const response = await api.get(`api/documento-agenda/parcelamentos?${jsonToUrlFilter(tmpFilter)}`);

    if (response) {
      if (removeData) {
        this.setState({
          ...response,
          loading: false,
          loadingMore: false
        })
      }
      else {
        this.setState({
          ...response,
          data: [...data, ...response.data],
          loading: false,
          loadingMore: false
        })
      }
    }
    else {
      this.setState({ loading: false, loadingMore: false })
    }
  }

  findCategorias = async () => {
    const filter = {
      _limit: 999,
      _order: 'ASC',
      _sort: "nome",
      ativo: true
    }

    const response = await api.get(`/api/financeiro/categoria?${jsonToUrlFilter(filter)}`);
    this.setState({ categorias: response.data });
  }

  findAssociados = async () => {
    const filter = {
      _limit: 99999,
      _order: 'ASC',
      _sort: "name",
      profile: { id: [2] },
      status_acesso: [1, 5, 6, 7, 9]
    }

    const response = await api.get(`/api/user?${jsonToUrlFilter(filter)}`);
    this.setState({ associados: response.data });
  }

  findFornecedores = async () => {
    const filter = {
      _limit: 99999,
      _order: 'ASC',
      _sort: "nome"
    }

    const response = await api.get(`/api/fornecedor?${jsonToUrlFilter(filter)}`);
    this.setState({ fornecedores: response.data });
  }

  loadMore = () => {
    const { filter } = this.state;

    this.setState({
      filter: {
        ...filter,
        _page: this.state.filter._page + 1
      }
    }, () => {
      this.findContas(false);
    });
  }

  handleLimpar = () => {
    this.setState({
      categoria: '',
      cliente: '',
      fornecedor: '',
      filter: {
        ...this.defaultFilter
      },
    }, () => {
      this.findContas();
    });
  }

  renderContent() {
    const { type } = this.props.match.params;
    const { data, length, total_pages, filter, loading } = this.state;

    if (loading) return <BoxLoading />;

    if (length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Não existem Contas a {type === 'receber' ? 'Receber' : 'Pagar'}</h3>
        </CardHeader>
      )
    }

    return (
      <div>
        <Table className="align-items-center table-flush table-with-button" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">
                <div className="custom-control custom-checkbox" style={{ top: -10 }}>
                  <input className="custom-control-input" id="select-all" type="checkbox" onChange={(value) => onChangeCheckbox(value, "select-all", this)} checked={!this.state.checkAll} />
                  <label className="custom-control-label" htmlFor="select-all" />
                </div>
              </th>
              <th scope="col">SÓCIO</th>
              <th scope="col">CATEGORIA</th>
              <th scope="col">VALOR PARCELA</th>
              <th scope="col">VALOR TOTAL</th>
              <th scope="col">VENCIMENTO</th>
              <th scope="col">EMISSÃO DO BOLETO VIA</th>
              <th scope="col">STATUS</th>
              <th scope="col">BOLETO</th>
              <th scope="col">VALOR PAGO</th>
              <th scope="col">PAGO DIA</th>
              <th scope="col">CRÉDITADO NA CONTA</th>
              <th scope="col">JUROS/MULTA</th>
              <th scope="col">AÇÃO</th>
            </tr>
          </thead>
          <tbody>
          {data.map(single => {
              return (
                <tr key={single.id}>
                  <td>
                    <div className="custom-control custom-checkbox" style={{ top: -12 }}>
                      <input className="custom-control-input" id={single.uk} type="checkbox" checked={(this.state.checked.some(value => value === single.uk))} onChange={(value) => onChangeCheckbox(value, single.uk, this)} />
                      <label className="custom-control-label" htmlFor={single.uk} />
                    </div>
                  </td>
                  <td>
                    {single.financeiroContasEntity.cliente && single.financeiroContasEntity.cliente.name ? (
                      <a href={`/admin/financeiro/contas/${type}/${single.financeiroContasEntity.uk}/editar`} target='_blank' rel="noopener noreferrer" style={{ color: "#525f7f" }}>
                        {single.financeiroContasEntity.cliente.name}
                      </a>
                    ) : single.financeiroContasEntity.fornecedor && single.financeiroContasEntity.fornecedor.nome ? (
                      <a href={`/admin/financeiro/contas/${type}/${single.financeiroContasEntity.uk}/editar`} target='_blank' rel="noopener noreferrer" style={{ color: "#525f7f" }}>
                        {single.financeiroContasEntity.fornecedor.nome}
                      </a>
                    ) : "-"}
                  </td>
                  <td>
                    {single.financeiroContasEntity.ct && single.financeiroContasEntity.ct.nome}
                  </td>
                  <td>
                    {numeral(single.valorPagamento).format('$ 0,0.00')}
                    <span style={{ fontSize: '11px', marginLeft: '5px' }}>{single.financeiroContasEntity.numeroParcelas > 1 && ` (Parcela ${single.parcela} de ${single.financeiroContasEntity.numeroParcelas})`}</span>
                  </td>
                  <td>{numeral(single.financeiroContasEntity.vlTotal).format('$ 0,0.00')}</td>

                  <td>{single.dtVencimento ? moment(single.dtVencimento).format('DD/MM/YYYY') : '-'}</td>
                  <td>{single.emitirBoletoVia && single.emitirBoletoVia.toUpperCase()}</td>
                  <td><DescricaoStatusPagamento status={single.status} /></td>
                  <th>
                    {single.linkBoleto !== null && (
                      <a href={single.linkBoleto} target='_blank' rel="noopener noreferrer" style={{ color: '#525f7f' }}>
                        <i className="fas fa-barcode mt-margin-xsmall-right"></i>
                        Boleto
                      </a>
                    )}
                  </th>
                  <td>{numeral(single.valorPagamentoRecebido).format('$ 0,0.00')}</td>
                  <td>{single.dtRecebimento ? moment(single.dtRecebimento).format('DD/MM/YYYY') : '-'}</td>
                  <td>{single.dtCreditoConta ? moment(single.dtCreditoConta).format('DD/MM/YYYY') : '-'}</td>
                  <td>{(single.valorPagamentoRecebido && parseFloat(single.valorPagamentoRecebido.toFixed(2)) > parseFloat(single.valorPagamento.toFixed(2))) ? numeral(single.valorPagamentoRecebido - single.valorPagamento).format('$ 0,0.00') : '-'}</td>
                  <td>
                    <div className="avatar-group">
                      <ButtonControl color='secondary' to={`/admin/financeiro/contas/${type}/${single.financeiroContasEntity.uk}/editar`} target='_blank' rel="noopener noreferrer">
                        <i style={{ fontSize: 20 }} className="fa fa-eye" aria-hidden="true" />
                      </ButtonControl>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>

        {filter._page < total_pages && (
          <Button style={{ backgroundColor: "#3f51b5", color: "white", width: '100%', height: "45px" }} disabled={this.state.loadingMore} variant="contained" onClick={this.loadMore}>
            BUSCAR MAIS {this.state.loadingMore && <BoxLoading />}
          </Button>
        )}
      </div >
    )
  }

  render() {
    const { filter, total_data, categorias, categoria } = this.state;
    const { uk } = this.props.match.params;

    return (
      <>
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={`/admin/associado/${uk}`}>
                    <i className="fas fa-arrow-left"></i> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo='HISTÓRICO FINANCEIRO'
          subTitulo='Histórico Financeiro do associado'
        />
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 style={{ float: "left" }} className="mb-0">Total a Receber ({total_data ? total_data : 0})</h3>
                  <Filter handleLimpar={this.handleLimpar} handlePesquisar={this.findContas}>
                    <Row>
                      <Col lg="2">
                        <FormControl variant="outlined">
                          <Select
                            value={filter._sort}
                            onChange={value => this.setState({ filter: { ...filter, _sort: value.target.value } })}
                          >
                            <MenuItem value="dt_vencimento">Data Vencimento</MenuItem>
                            <MenuItem value="dt_recebimento">Data Recebimento</MenuItem>
                          </Select>
                        </FormControl>
                      </Col>
                      <Col lg="2">
                        <input format="YYYY-MM-DD" type="date" className="form-control" value={filter.data_inicio} onChange={value => this.setState({ filter: { ...filter, data_inicio: value.target.value } })} />
                      </Col>
                      <span style={{ display: 'flex', alignItems: 'center' }}>Até</span>
                      <Col lg="2">
                        <input format="YYYY-MM-DD" type="date" className="form-control" value={filter.data_fim} onChange={value => this.setState({ filter: { ...filter, data_fim: value.target.value } })} />
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <FormControl variant="outlined" style={{ width: '100%' }}>
                            <Select
                              value={filter.status}
                              onChange={value => this.setState({ filter: { ...filter, status: value.target.value } })}
                              multiple={true}
                            >
                              <MenuItem value={0}>Aguardando Pagamento</MenuItem>
                              <MenuItem value={1}>Confirmado</MenuItem>
                              <MenuItem value={2}>Cancelado</MenuItem>
                              <MenuItem value={3}>Vencido</MenuItem>
                              <MenuItem value={4}>Em Acordo</MenuItem>
                              <MenuItem value={5}>Agrupado na Cobrança Mensal</MenuItem>
                            </Select>
                          </FormControl>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormControl variant="outlined">
                          <Autocomplete
                            options={categorias}
                            getOptionLabel={option => `(${option.codigo}) ${option.nome}`}
                            onChange={(_, item) => {
                              if (item) {
                                this.setState({ filter: { ...filter, financeiro_contas_entity: { ...filter.financeiro_contas_entity, ct: { id: item.id } } } })
                              }
                              else {
                                const financeiro_contas_entity = Object.keys(filter.financeiro_contas_entity).filter(key => key !== 'ct').reduce((result, current) => {
                                  result[current] = filter.financeiro_contas_entity[current];
                                  return result;
                                }, {});

                                this.setState({ filter: { ...filter, financeiro_contas_entity } });
                              }
                            }}
                            inputMode="text"
                            multiple={false}
                            value={filter.financeiro_contas_entity.ct}
                            inputValue={categoria}
                            onInputChange={(_, newInputValue) => {
                              this.setState({ categoria: newInputValue });
                            }}
                            renderOption={(option) => (
                              <span>({option.codigo}) {option.nome}</span>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Selecione uma categoria"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  </Filter>
                </CardHeader>
                {this.renderContent()}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(HistoricoFinanceiro);
