import { FormControl, MenuItem, Select } from '@material-ui/core';
import { ReactFormGenerator } from 'views/components/react-form-builder';
import Config from 'Config';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { BoxLoading } from 'react-loadingg';
import { Button, Col, Container, Row } from 'reactstrap';
import api from 'services/api';
import cpfMask from 'utils/cpfMask';
import phoneMask from 'utils/phoneMask';
import { isValidCPF } from 'utils/Validations';
import { estados } from 'utils/estados';
import { cepMask } from 'utils/cepMask';


const SOCIO_PROPRIETARIO = "Sócio Proprietário";
const SOCIO_DEPENDENTE = "Sócio Dependente";
const NAO_SOCIO = "Não Sócio";


class FazerInscricao extends Component {
  state = {
    form: [],
    showFinalizado: false,
    showInscricaoRealizada: false,
    showEnviadoComSucesso: false,
    showEnviadoComErro: false,
    showCpfRestrito: false,
    showTituloRestrito: false,
    loading: false,
    dependentes: [],
    dependente: null,
    socio: null,
    numero_titulo: '',
    nome_titular: '',
    tipo_socio: null,
    nome_inscrito: '',
    cpf: '',
    telefone: '',
    email: '',
    usuario: null,
    data_nascimento: null
  }

  async componentDidMount() {
    document.body.style.backgroundColor = "#f6f6f6";
    const titulo = (new URLSearchParams(window.location.search)).get("titulo");

    if(!!titulo) {
      await this.setState({ numero_titulo: titulo, socio: true, loading: true });
      await this.handleFindUser();
      await this.setState({ loading: false })
    }

    !!this.props.match.params.uk && this.findBy();
  }

  findBy = async () => {
    this.setState({ loading: true })
    const { uk } = this.props.match.params;
    const response = await api.get(`/api/inscricoes/form/${uk}`);

    if(!!response) {
      const hasTitulo = !!(new URLSearchParams(window.location.search)).get("titulo");
      this.setState({
        ...response,
        loading: false,
        socio: !!response.hibilitado_para_socio ? true : !!response.hibilitado_para_nao_socio && !hasTitulo ? false : null
      });
    }
    else {
      this.setState({ showFinalizado: true, loading: false });
    }
  }

  onSubmit = async dataForm => {
    if(dataForm.length >= 0) {
      this.setState({ loading: true });

      let { menor_18anos, data_nascimento, nome_inscrito, nome_responsavel, cpf, telefone, email, cep, estado, cidade, bairro, logradouro, numero, complemento, dependente, usuario, tipo_socio } = this.state;
      let responseUser = true;

      if(tipo_socio === NAO_SOCIO) {
        responseUser = false;

        const data = {
          nome: menor_18anos ? nome_responsavel : nome_inscrito,
          data_nascimento: menor_18anos ? null : data_nascimento,
          cpf,
          email,
          telefone,
          cep, estado, cidade, bairro, logradouro, numero, complemento
        }

        if(!!usuario && 'id' in usuario) {
          responseUser = await api.put(`/api/user/nao-socio/${usuario.id}`, data);
        }
        else {
          responseUser = await api.post('/api/user/nao-socio', data);
        }

        if(responseUser.status === 200) {
          usuario = responseUser.data;
        }
        else {
          if('data' in responseUser && 'mensagem' in responseUser.data) {
            this.props.enqueueSnackbar(responseUser.data.mensagem);
          }
          responseUser = false
        }
      }
      
      if(responseUser) {
        const response = await api.post('/api/inscricoes/inscritos', {
          respostas: JSON.stringify(dataForm),
          inscricao: { id: this.state.id },
          tipo_socio: this.state.tipo_socio,
          nome: nome_inscrito,
          cpf: !!cpf ? cpf : null,
          telefone: !!telefone ? telefone : null,
          email: !!email ? email : null,
          usuario: !!dependente ? { id: dependente } : !!usuario ? usuario : null,
        });
  
        if(!response || ('data' in response && 'error' in response.data && 'status' in response.data))
          this.setState({ showEnviadoComErro: true });
        else
          this.setState({ showEnviadoComSucesso: true });
      }
      else {
        this.props.enqueueSnackbar("Ops, ocorreu um erro! Entrar em contato com a secretaria.");
      }

      this.setState({ loading: false });
    }
    else {
      this.props.enqueueSnackbar("Ops, ocorreu um erro!");
    }
  }

  handleFindUser = async () => {
    const { socio, numero_titulo } = this.state;

    if(!!socio && !!numero_titulo) {
      this.setState({ loading: true });
      const dependenteId = parseInt((new URLSearchParams(window.location.search)).get("dependente"));
      const response = await api.get(`/api/user/${numero_titulo}/acesso-publico/inscricao`);
      const { uk } = this.props.match.params;

      if(!!response) {
        if(!response.ativo) {
          this.setState({ loading: false, uk, showTituloRestrito: true });
        }
        else if(dependenteId) {
          const hasInscricao = await api.get(`api/inscricoes/${uk}/socio/${dependenteId}`);
          const dependente = response.dependentes.find(item => item.id === dependenteId);

          if(!dependente) {
            this.props.enqueueSnackbar("Sócio não encontrado! Entrar em contato com a secretaria.");
            this.setState({ loading: false, uk });
          }

          if(!dependente.ativo) {
            this.setState({ loading: false, uk, showTituloRestrito: true });
          }

          if(hasInscricao) {
            this.setState({ showInscricaoRealizada: true });
          }

          this.setState({
            nome_titular: response.name,
            usuario: { id: response.id },
            tipo_socio: SOCIO_DEPENDENTE,
            nome_inscrito: dependente.name,
            dependentes: response.dependentes,
            dependente: dependente.id
          });
        }
        else {
          const { uk } = this.props.match.params;
          let dependentes = response.dependentes;

          for (const key in response.dependentes) {
            const dependente = response.dependentes[key];
            const hasInscricao = await api.get(`api/inscricoes/${uk}/socio/${dependente.id}`);
            if(hasInscricao) dependentes = dependentes.filter(item => item.id !== dependente.id);
          }

          const hasInscricao = await api.get(`api/inscricoes/${uk}/socio/${response.id}`);

          if(hasInscricao && dependentes.length === 0) {
            this.setState({ showInscricaoRealizada: true });
          }

          if(hasInscricao) {
            this.setState({
              desativa_socio_proprietario: true,
              nome_titular: response.name,
              usuario: { id: response.id },
              tipo_socio: SOCIO_DEPENDENTE,
              nome_inscrito: '',
              dependentes: dependentes,
              dependente: null
            });
          }
          else {
            this.setState({
              nome_titular: response.name,
              usuario: { id: response.id },
              tipo_socio: SOCIO_PROPRIETARIO,
              nome_inscrito: response.name,
              dependentes: dependentes,
              dependente: null
            });
          }
        }
      }
      else {
        this.props.enqueueSnackbar("Sócio não encontrado! Entrar em contato com a secretaria.");

        if(!!socio) {
          this.setState({
            uk,
            dependentes: [],
            dependente: null,
            nome_titular: '',
            tipo_socio: null,
            nome_inscrito: '',
            cpf: '',
            telefone: '',
            email: '',
            usuario: null
          });
        }
        else {
          this.setState({ usuario: null });
        }
      }

      this.setState({ loading: false });
    }
  }

  handleFindNaoSocio = async () => {
    const { tipo_socio, cpf, menor_18anos } = this.state;

    if(tipo_socio === this.state.tipo_socio && !!cpf) {
      const { uk } = this.props.match.params;
      const response = await api.get(`/api/user/nao-socio/${cpf}/acesso-publico/inscricao`);

      if(!!response) {
        if(!menor_18anos) {
          const hasInscricao = await api.get(`api/inscricoes/${uk}/socio/${response.id}`);
  
          if(hasInscricao) {
            this.setState({ showInscricaoRealizada: true });
          }
        }

        if(!response.ativo) {
          this.setState({ showCpfRestrito: true });
        }
        else {
          this.setState({
            nome_titular: response.name,
            usuario: { id: response.id },
            tipo_socio: NAO_SOCIO,
            nome_inscrito: menor_18anos ? '' : response.name ,
            nome_responsavel: menor_18anos ? response.name : '',
            in_database: true
          });
        }

      }
      else {
        this.setState({
          uk,
          dependentes: [],
          dependente: null,
          nome_titular: '',
          tipo_socio: NAO_SOCIO,
          nome_inscrito: '',
          telefone: '',
          email: '',
          usuario: null,
          in_database: false
        });
      }
    }
  }

  changeTipoSocio = value => {
    this.setState({
      socio: value,
      dependente: null,
      numero_titulo: '',
      nome_titular: '',
      tipo_socio: !value ? NAO_SOCIO : null,
      nome_inscrito: '',
      cpf: '',
      telefone: '',
      email: '',
      usuario: null
    })
  }

  changemenor_18anos = value => {
    this.setState({
      ...this.state,
      menor_18anos: value
    })
  }

  handleFindAddress = async (cep) => {
    if(cep.replace("-", "").length === 8) {
      const response = await api.get(`https://viacep.com.br/ws/${cep}/json/`);

      const estado = !!response.uf && estados.find(item => item.sigla === response.uf).id;
      const cidade = response.localidade;
      const logradouro = response.logradouro;
      const bairro = response.bairro;

      this.setState({ estado, cidade, logradouro, bairro });
    }
  }

  validatePhone = phone => !!phone && new RegExp('^\\([0-9]{2}\\) ((3[0-9]{3}-[0-9]{4})|(9[0-9]{4}-[0-9]{4}))$').test(phone);
  validateEmail = email => !!email && new RegExp('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$').test(email);

  render() {
    const { loading, showFinalizado, showInscricaoRealizada, showEnviadoComSucesso, showEnviadoComErro, showCpfRestrito, showTituloRestrito, cor_primaria, cor_contraste } = this.state;
    const hasTitulo = !!(new URLSearchParams(window.location.search)).get("titulo");
    const isDependente = !!(new URLSearchParams(window.location.search)).get("dependente");
    const incricaoIsStart = moment(moment(moment().format('YYYY-MM-DD'))).isSameOrAfter(this.state.data_inicio);
    const incricaoIsEnd = moment(moment(moment().format('YYYY-MM-DD'))).isAfter(this.state.data_fim);

    return (
      <>
        {loading && (
          <div className="container-load">
            <BoxLoading />
          </div>
        )}

        <div className='mt-inscricao'>
          <Container className='mt-padding-top mt-padding-large-bottom'>
            <Row>
              <Col lg="12">
                <div className="header-image" style={{ backgroundImage: `url(${Config._HOST + "/api/bucket/owner/" + this.state.uk + "/key/inscricoes"})`}} />
                {this.state.descricao && (
                  <div className="header rfb-item" style={{ borderTop: `solid 12px ${cor_primaria}` }}>
                    <h1 className="display-4 mt-margin-small-bottom">{this.state.titulo}</h1>
                    <div className='header-descricao' dangerouslySetInnerHTML={{ __html: this.state.descricao }} />
                    <hr className='mt-margin-remove'/>
                    <div style={{ color: '#d93025', fontSize: 14, marginTop: 15 }}>* Obrigatório</div>
                  </div>
                )}

                {loading ? (
                  <>
                    {!this.state.descricao && (
                      <Row className='rfb-item mt-margin-remove mt-sended'>
                        <Col lg='12' className='mt-margin-top' />
                      </Row>
                    )}
                  </>
                ) : showFinalizado ? (
                  <Row className='rfb-item mt-margin-remove mt-sended'>
                    <Col lg='12' className='mt-text-center'>
                      <i className="fas fa-exclamation-triangle mt-margin-small-right" style={{ color: '#ebeb13', fontSize: 80 }}></i>
                    </Col>
                    <Col lg='12' className='mt-margin-top'>
                      <h2 className='mt-text-center'>
                        Inscrição não está mais disponivel!
                      </h2>
                    </Col>
                  </Row>
                ) : showCpfRestrito ? (
                  <Row className='rfb-item mt-margin-remove mt-sended'>
                    <Col lg='12' className='mt-text-center'>
                      <i className="fas fa-exclamation-triangle mt-margin-small-right" style={{ color: '#ebeb13', fontSize: 80 }}></i>
                    </Col>
                    <Col lg='12' className='mt-margin-top'>
                      <h2 className='mt-text-center'>
                        Tivemos um problema com o seu CPF!
                        <br />
                        Entrar em contato com a secretaria.
                      </h2>
                    </Col>
                  </Row>
                ) : showTituloRestrito ? (
                  <Row className='rfb-item mt-margin-remove mt-sended'>
                    <Col lg='12' className='mt-text-center'>
                      <i className="fas fa-exclamation-triangle mt-margin-small-right" style={{ color: '#ebeb13', fontSize: 80 }}></i>
                    </Col>
                    <Col lg='12' className='mt-margin-top'>
                      <h2 className='mt-text-center'>
                        Tivemos um problema com o seu título!
                        <br />
                        Entrar em contato com a secretaria.
                      </h2>
                      {!(hasTitulo || isDependente) && (
                        <Col lg='12' className='mt-text-center mt-margin-medium-top'>
                          <Button color="custom" block={true} onClick={() => window.location.reload()} style={{ background: cor_primaria, color: cor_contraste }}>
                            <i className="fas fa-arrow-left mt-margin-xsmall-right"></i>
                            Tentar Novamente
                          </Button>
                        </Col>
                      )}
                    </Col>
                  </Row>
                ) : showInscricaoRealizada ? (
                  <Row className='rfb-item mt-margin-remove mt-sended'>
                    <Col lg='12' className='mt-text-center'>
                      <i className="fas fa-check-circle mt-margin-small-right" style={{ color: 'green', fontSize: 80 }}></i>
                    </Col>
                    <Col lg='12' className='mt-margin-top'>
                      <h2 className='mt-text-center'>
                        {this.state.dependentes.length === 0 ? 'Inscrições já realizada!' : 'Inscrição já realizada!'}
                      </h2>
                    </Col>
                  </Row>
                ) : showEnviadoComSucesso ? (
                  <Row className='rfb-item mt-margin-remove mt-sended'>
                    <Col lg='12' className='mt-text-center'>
                      <i className="fas fa-check-circle mt-margin-small-right" style={{ color: 'green', fontSize: 80 }}></i>
                    </Col>
                    <Col lg='12' className='mt-margin-top'>
                      <h2 className='mt-text-center'>
                        Inscrição enviado com sucesso!
                      </h2>
                    </Col>
                    <Col lg='12' className='mt-text-center mt-margin-medium-top'>
                      <Button color="custom" block={true} onClick={() => window.location.reload()} style={{ background: cor_primaria, color: cor_contraste }}>
                        <i className="fas fa-arrow-left mt-margin-xsmall-right"></i>
                        Realizar nova Inscrição
                      </Button>
                    </Col>
                  </Row>
                ) : showEnviadoComErro ? (
                  <Row className='rfb-item mt-margin-remove mt-sended'>
                    <Col lg='12' className='mt-text-center'>
                      <i className="fas fa-times-circle mt-margin-small-right" style={{ color: '#d52b2b', fontSize: 80 }}></i>
                    </Col>
                    <Col lg='12' className='mt-margin-top'>
                      <h2 className='mt-text-center'>
                        Ops, ocorreu um erro.
                      </h2>
                      <h2 className='mt-text-center'>
                        Não conseguimos enviar sua Inscrição!
                      </h2>
                    </Col>
                    <Col lg='12' className='mt-text-center mt-margin-medium-top'>
                      <Button color="custom" block={true} onClick={() => window.location.reload()} style={{ background: cor_primaria, color: cor_contraste }}>
                        <i className="fas fa-arrow-left mt-margin-xsmall-right"></i>
                        Tentar Novamente
                      </Button>
                    </Col>
                  </Row>
                ) : !this.state.ativo || (!!this.state.data_inicio && !incricaoIsStart) || (!!this.state.data_fim && incricaoIsEnd) ? (
                  <Row className='rfb-item mt-margin-remove mt-sended'>
                    <Col lg='12' className='mt-text-center'>
                      <i className="fas fa-exclamation-triangle mt-margin-small-right" style={{ color: '#f7f730', fontSize: 80 }}></i>
                    </Col>
                    <Col lg='12' className='mt-margin-top'>
                      <h2 className='mt-text-center'>
                        {!!this.state.data_inicio && !incricaoIsStart ? (
                          "As inscrições irão começar dia " + moment(this.state.data_inicio).format('DD/MM/YYYY')
                        ) : (
                          "Infelizmente as inscrições foram finalizadas!"
                        )}
                      </h2>
                    </Col>
                  </Row>
                ) : (
                  <>
                    {!hasTitulo && !!this.state.hibilitado_para_nao_socio && !!this.state.hibilitado_para_socio && (
                      <div className="SortableItem rfb-item">
                        <div className="form-group">
                          <label className="form-label" style={{ display: 'block' }}>
                            <span>Sócio do clube? </span>
                            <span className="label-required">*</span>
                          </label>
                          <div className="custom-control custom-radio option-inline" style={{ display: 'inline-block', marginRight: 10 }}>
                            <input
                              id="socio"
                              className="custom-control-input"
                              type="radio"
                              name="socio"
                              checked={this.state.socio === true}
                              onChange={() => this.changeTipoSocio(true)}
                            />
                            <label className="custom-control-label" htmlFor="socio">Sim</label>
                          </div>
                          <div className="custom-control custom-radio option-inline" style={{ display: 'inline-block', marginRight: 10 }}>
                            <input
                              id="nao_socio"
                              className="custom-control-input"
                              type="radio"
                              name="socio"
                              checked={this.state.socio === false}
                              onChange={() => this.changeTipoSocio(false)}
                            />
                            <label className="custom-control-label" htmlFor="nao_socio">Não</label>
                          </div>
                        </div>
                      </div>
                    )}

                    {this.state.socio !== null && (
                      <>
                        {this.state.socio ? (
                          <>
                            <div className="SortableItem rfb-item">
                              <div className="form-group">
                                <label className="">
                                  <span>Número Título: </span>
                                  <span className="label-required">*</span>
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.numero_titulo}
                                    onChange={data => this.setState({ numero_titulo: data.target.value })}
                                    disabled={hasTitulo}
                                  />
                                  {!hasTitulo && (
                                    <div className="input-group-append">
                                      <button className="btn btn-primary" type="button" onClick={this.handleFindUser}>
                                        <i className="fas fa-search mt-margin-xsmall-right"></i>
                                        Buscar
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            {!!this.state.usuario && (
                              <>
                                {this.state.dependentes.length > 0 && (
                                  <div className="SortableItem rfb-item">
                                    <div className="form-group">
                                      <label className="">
                                        <span>Nome Titular: </span>
                                        <span className="label-required">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={this.state.nome_titular}
                                        onChange={data => this.setState({ nome_titular: data.target.value })}
                                        disabled={!!this.state.socio}
                                      />
                                    </div>
                                  </div>
                                )}

                                {!isDependente && this.state.dependentes.length > 0 && !this.state.desativa_socio_proprietario && (
                                  <div className="SortableItem rfb-item">
                                    <div className="form-group">
                                      <label className="form-label" style={{ display: 'block' }}>
                                        <span>Inscrição para o tipo de sócio: </span>
                                        <span className="label-required">*</span>
                                      </label>

                                      <div className="custom-control custom-radio option-inline" style={{ display: 'inline-block', marginRight: 10 }}>
                                        <input
                                          id="tipo_socio_proprietario"
                                          className="custom-control-input"
                                          type="radio"
                                          name="tipo_socio"
                                          value={SOCIO_PROPRIETARIO}
                                          checked={this.state.tipo_socio === SOCIO_PROPRIETARIO}
                                          onChange={() => this.setState({ tipo_socio: SOCIO_PROPRIETARIO, nome_inscrito: this.state.nome_titular, dependente: null })}
                                          disabled={isDependente}
                                        />
                                        <label className="custom-control-label" htmlFor="tipo_socio_proprietario">{SOCIO_PROPRIETARIO}</label>
                                      </div>

                                      <div className="custom-control custom-radio option-inline" style={{ display: 'inline-block', marginRight: 10 }}>
                                        <input
                                          id="tipo_socio_dependente"
                                          className="custom-control-input"
                                          type="radio"
                                          name="tipo_socio"
                                          value={SOCIO_DEPENDENTE}
                                          checked={this.state.tipo_socio === SOCIO_DEPENDENTE}
                                          onChange={() => this.setState({ tipo_socio: SOCIO_DEPENDENTE, nome_inscrito: '', dependente: null })}
                                          disabled={isDependente}
                                        />
                                        <label className="custom-control-label" htmlFor="tipo_socio_dependente">{SOCIO_DEPENDENTE}</label>
                                      </div>

                                    </div>
                                  </div>
                                )}

                                {this.state.tipo_socio !== null && (
                                  <div className="SortableItem rfb-item">
                                    <div className="form-group">
                                      <label className="">
                                        <span>Nome Inscrito: </span>
                                        <span className="label-required">*</span>
                                      </label>
                                      {this.state.tipo_socio === SOCIO_PROPRIETARIO ? (
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={this.state.nome_inscrito}
                                          onChange={data => this.setState({ nome_inscrito: data.target.value.toUpperCase() })}
                                          disabled={this.state.tipo_socio === SOCIO_PROPRIETARIO}
                                        />
                                      ) : (
                                        <FormControl variant="outlined" style={{ width: '100%' }}>
                                          <Select
                                            value={this.state.dependente}
                                            onChange={value => {
                                              const dependente = this.state.dependentes.find(dependente => parseInt(dependente.id) === parseInt(value.target.value));
                                              this.setState({ dependente: value.target.value, nome_inscrito: dependente.name });
                                            }}
                                            disabled={isDependente}
                                          >
                                            {this.state.dependentes.filter(dependente => dependente.ativo).map(dependente => (
                                              <MenuItem key={dependente.id} value={dependente.id}>{dependente.name}</MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="SortableItem rfb-item">
                              <div className="form-group">
                                <label className="">
                                  <span>Data de Nascimento do Inscrito: </span>
                                  <span className="label-required">*</span>
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  value={this.state.data_nascimento}
                                  onChange={data => {
                                    const data_nascimento = data.target.value;

                                    if(moment(data_nascimento).isValid()) {
                                      const idade = (moment()).diff(moment(data.target.value), 'years');
                                      const menor_18anos = idade < 18;

                                      this.setState({
                                        menor_18anos,
                                        data_nascimento
                                      })
                                    }
                                    else {
                                      this.setState({
                                        data_nascimento
                                      })}
                                    }
                                  }
                                />
                              </div>
                            </div>

                            {moment(this.state.data_nascimento).isValid() && (
                              <>
                                <div className="SortableItem rfb-item">
                                  <div className="form-group">
                                    <label className="">
                                      <span>CPF do {this.state.menor_18anos ? 'Responsável' : 'Inscrito'}: </span>
                                      <span className="label-required">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.cpf}
                                      onChange={data => {
                                        const cpf = cpfMask(data.target.value);

                                        if(isValidCPF(cpf)) {
                                          this.setState({ cpf, cpfValido: true }, () => {
                                            if(cpf.length === 14) {
                                              this.handleFindNaoSocio();
                                            }
                                          });
                                        }
                                        else {
                                          this.setState({ cpf, cpfValido: false });

                                          if(cpf.length === 14) {
                                            this.props.enqueueSnackbar("CPF invalido!");
                                          }                                          
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                                
                                {this.state.cpfValido && (
                                  <>
                                    {this.state.menor_18anos && (
                                      <div className="SortableItem rfb-item">
                                        <div className="form-group">
                                          <label className="">
                                            <span>Nome Responsável: </span>
                                            <span className="label-required">*</span>
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={this.state.nome_responsavel}
                                            onChange={data => this.setState({ nome_responsavel: data.target.value.toUpperCase() })}
                                            // disabled={this.state.tipo_socio === SOCIO_PROPRIETARIO || ('in_database' in this.state && this.state.in_database === true)}
                                          />
                                        </div>
                                      </div>
                                    )}

                                    <div className="SortableItem rfb-item">
                                      <div className="form-group">
                                        <label className="">
                                          <span>Nome Inscrito: </span>
                                          <span className="label-required">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={this.state.nome_inscrito}
                                          onChange={data => this.setState({ nome_inscrito: data.target.value.toUpperCase() })}
                                          // disabled={(this.state.tipo_socio === SOCIO_PROPRIETARIO && !this.state.menor_18anos) || ('in_database' in this.state && this.state.in_database === true && !this.state.menor_18anos)}
                                        />
                                      </div>
                                    </div>

                                    <div className="SortableItem rfb-item">
                                      <div className="form-group">
                                        <label className="">
                                          <span>Telefone do {this.state.menor_18anos ? 'Responsável' : 'Inscrito'}: </span>
                                          <span className="label-required">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={this.state.telefone}
                                          onChange={data => this.setState({ telefone: phoneMask(data.target.value) })}
                                        />
                                      </div>
                                    </div>

                                    <div className="SortableItem rfb-item">
                                      <div className="form-group">
                                        <label className="">
                                          <span>E-mail do {this.state.menor_18anos ? 'Responsável' : 'Inscrito'}: </span>
                                          <span className="label-required">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={this.state.email}
                                          onChange={data => this.setState({ email: data.target.value })}
                                        />
                                      </div>
                                    </div>

                                    <div className="SortableItem rfb-item">
                                      <div className="form-group">
                                        <label className="">
                                          <span>CEP: </span>
                                          <span className="label-required">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="CEP"
                                          className="form-control"
                                          value={this.state.cep ? this.state.cep : ''}
                                          onChange={e => {
                                            const cep = cepMask(e.target.value);
                                            this.handleFindAddress(cep)                                    

                                            if(cep.replace("-", "").length <= 8) {
                                              this.setState({ cep });
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="SortableItem rfb-item">
                                      <div className="form-group">
                                        <label className="">
                                          <span>Estado: </span>
                                          <span className="label-required">*</span>
                                        </label>
                                        <FormControl variant="outlined" style={{ width: '100%' }}>
                                          <Select
                                            value={this.state.estado ? this.state.estado : 24}
                                            onChange={e => {
                                              const estado = e.target.value.toUpperCase();
                                              this.setState({ estado });
                                            }}
                                          >
                                            <MenuItem value="0">Estado</MenuItem>
                                            {estados.map(item => <MenuItem value={item.id}>{item.sigla} - {item.name}</MenuItem>)}
                                          </Select>
                                        </FormControl>
                                      </div>
                                    </div>

                                    <div className="SortableItem rfb-item">
                                      <div className="form-group">
                                        <label className="">
                                          <span>Cidade: </span>
                                          <span className="label-required">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Cidade"
                                          className="form-control"
                                          value={this.state.cidade ? this.state.cidade : ''}
                                          onChange={e => {
                                            const cidade = e.target.value.toUpperCase();
                                            this.setState({ cidade });
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="SortableItem rfb-item">
                                      <div className="form-group">
                                        <label className="">
                                          <span>Bairro: </span>
                                          <span className="label-required">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Bairro"
                                          className="form-control"
                                          value={this.state.bairro ? this.state.bairro : ''}
                                          onChange={e => {
                                            const bairro = e.target.value.toUpperCase();
                                            this.setState({ bairro });
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="SortableItem rfb-item">
                                      <div className="form-group">
                                        <label className="">
                                          <span>Logradouro: </span>
                                          <span className="label-required">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Logradouro"
                                          className="form-control"
                                          value={this.state.logradouro ? this.state.logradouro : ''}
                                          onChange={e => {
                                            const logradouro = e.target.value.toUpperCase();
                                            this.setState({ logradouro });
                                          }}
                                        />
                                      </div>
                                    </div>
                                    
                                    <div className="SortableItem rfb-item">
                                      <div className="form-group">
                                        <label className="">
                                          <span>Número: </span>
                                          <span className="label-required">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Número"
                                          className="form-control"
                                          value={this.state.numero ? this.state.numero : ''}
                                          onChange={e => {
                                            const numero = e.target.value.toUpperCase();
                                            this.setState({ numero });
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="SortableItem rfb-item">
                                      <div className="form-group">
                                        <label className="">
                                          <span>Complemento: </span>
                                          <span className="label-required">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Complemento"
                                          className="form-control"
                                          value={this.state.complemento ? this.state.complemento : ''}
                                          onChange={e => {
                                            const complemento = e.target.value.toUpperCase();
                                            this.setState({ complemento });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )}

                          </>
                        )}

                        {(
                          (!!this.state.socio && !!this.state.usuario && !!this.state.nome_titular && !!this.state.tipo_socio && !!this.state.nome_inscrito) ||
                          (!this.state.socio 
                            && !!this.state.nome_inscrito 
                            && this.validatePhone(this.state.telefone) 
                            && this.validateEmail(this.state.email) 
                            && !!this.state.cep 
                            && !!this.state.estado 
                            && !!this.state.cidade
                            && !!this.state.bairro
                            && !!this.state.logradouro 
                            && !!this.state.numero 
                            && !!this.state.complemento                          
                          )
                        ) && (
                          <ReactFormGenerator
                            back_name="Cancelar"
                            action_name="Enviar"
                            onSubmit={this.onSubmit}
                            form_method="POST"
                            data={this.state.form}
                            button_color={cor_primaria}
                            button_text_color={cor_contraste}
                          />
                        )}
                      </>
                    )}

                  </>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default withSnackbar(FazerInscricao);
