/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Card, CardHeader, Table, Container, Row, Button } from "reactstrap";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { ButtonGroup, FormControl, MenuItem, Select } from "@material-ui/core";
import { withSnackbar } from "notistack";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Col from "reactstrap/lib/Col";
import Filter from "views/components/Filter";
import api from "services/api";
import { jsonToUrlFilter } from "utils/filter";
import moment from 'moment';
import { getStatusReserva } from "utils/StatusReserva";
import ButtonControl from "views/components/ButtonControl";

class UltimasReservas extends Component {
  state = {
    data: [],
    loading: false,
    loadingMore: false,
    filter: {
      _page: 1,
      _limit: 20,
      _order: 'desc',
      _sort: 'data_cadastro',
      user_dispositivo: ['not_null'],
      'data_cadastro>=': moment().subtract(1, 'days').format('YYYY-MM-DD'),
      'data_cadastro<=': moment().subtract(1, 'days').format('YYYY-MM-DD')
    }
  };

  componentDidMount() {
    this.findReservas();
  }

  findReservas = async (removeData = true) => {
    const { data, filter, loading } = this.state;
    if (loading) return;

    await this.setState({ loading: removeData, loadingMore: !removeData });

    if (removeData && filter._page > 1) {
      await this.setState({ filter: { ...filter, _page: 1 } })
    }

    if ('numero_titulo' in filter && filter.numero_titulo.length === 0) {
      delete filter.numero_titulo;
    }

    if ('name_like' in filter && filter.name_like.length === 0) {
      delete filter.name_like;
    }

    const date = {
      'data_cadastro>=': filter['data_cadastro>='] + " 00:00:00",
      'data_cadastro<=': filter['data_cadastro<='] + " 23:59:59"
    }

    const response = await api.get(`/api/documento-agenda?${jsonToUrlFilter({...filter, ...date})}`);

    if (response) {
      if (removeData) {
        this.setState({
          ...response,
          loading: false,
          loadingMore: false
        })
      }
      else {
        this.setState({
          ...response,
          data: [...data, ...response.data],
          loading: false,
          loadingMore: false
        })
      }
    }
    else {
      this.setState({ loading: false, loadingMore: false })
    }
  }

  loadMore = () => {
    const { filter } = this.state;

    this.setState({
      filter: {
        ...filter,
        _page: this.state.filter._page + 1
      }
    }, () => {
      this.findReservas(false);
    });
  }

  handleLimpar = () => {
    this.setState({
      data: [],
      length: 0,
      total_data: 0,
      filter: {
        _limit: 50,
        _order: 'ASC',
        _sort: "name",
        profile: { id: [2, 3] },
        status_acesso: [1, 2, 3, 5, 6, 7, 8, 9, 10]
      }
    }, () => {
      this.findReservas();
    });

  }

  renderContent() {
    const { data, length, total_pages, filter, loading } = this.state;

    if (loading) return <BoxLoading />;

    if (length === undefined || length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Nenhum Reserva Encontrada!</h3>
        </CardHeader>
      )
    }

    return (
      <div>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">DATA CRIAÇÃO</th>
              <th scope="col">AMBIENTE</th>
              <th scope="col">SÓCIO</th>
              <th scope="col">STATUS</th>
              <th scope="col">APP</th>
              <th scope="col">AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {data.map(single => {
              return (
                <tr key={single.uk}>
                  <td>{moment(single.dataCadastro).format('DD/MM/YYYY HH:mm')}</td>
                  <td>{single.ambiente.nome}</td>
                  <td>{single.responsavel.name}</td>
                  <td>{getStatusReserva(single.status)}</td>
                  <td>{!!single.userDispositivo ? "Sim" : "Não"}</td>
                  <td>
                    <div className="avatar-group">
                      <ButtonControl color='secondary' to={`/admin/agenda/${single.uk}/editar`}>
                        <i className="fa fa-edit"></i>
                      </ButtonControl>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>

        {filter._page < total_pages && (
          <Button style={{ backgroundColor: "#3f51b5", color: "white", width: '100%', height: "45px" }} disabled={this.state.loadingMore} variant="contained" onClick={this.loadMore}>
            BUSCAR MAIS {this.state.loadingMore && <BoxLoading />}
          </Button>
        )}

      </div>
    )
  }

  render() {
    const { filter, total_data } = this.state;

    return (
      <>
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to='/admin/agenda'>
                    <i className="fas fa-arrow-left" /> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"ÚLTIMAS RESERVAS"}
          subTitulo={"Gerenciamento completo de Agendamentos"}
        />
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 style={{ float: "left" }} className="mb-0">Reservas ({total_data ? total_data : 0})</h3>
                  <Filter handleLimpar={this.handleLimpar} handlePesquisar={this.findReservas} isOpened={true}>
                    <Row>
                      <Col lg="2">
                        <input format="YYYY-MM-DD" type="date" className="form-control" value={filter['data_cadastro>=']} onChange={value => this.setState({ filter: { ...filter, 'data_cadastro>=': value.target.value } })} />
                      </Col>
                      <span style={{ display: 'flex', alignItems: 'center' }}>Até</span>
                      <Col lg="2">
                        <input format="YYYY-MM-DD" type="date" className="form-control" value={filter['data_cadastro<=']} onChange={value => this.setState({ filter: { ...filter, 'data_cadastro<=': value.target.value } })} />
                      </Col>
                      <Col lg="2">
                        <FormControl variant="outlined" style={{ width: '100%' }}>
                          <Select
                            value={filter.user_dispositivo}
                            onChange={value => {
                              this.setState({ filter: { ...filter, user_dispositivo: value.target.value } });
                            }}
                            multiple={true}
                          >
                            <MenuItem value="not_null">Aplicativo</MenuItem>
                            <MenuItem value="null">Sistema</MenuItem>
                          </Select>
                        </FormControl>
                      </Col>
                    </Row>
                  </Filter>
                </CardHeader>
                {this.renderContent()}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

}

export default withSnackbar(UltimasReservas);
