import { ButtonGroup, FormControl, MenuItem, Select } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import Config, { currencyConfig } from "Config";
import moment from 'moment';
import { withSnackbar } from "notistack";
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import IntlCurrencyInput from "react-intl-currency-input";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row } from "reactstrap";
import api from "services/api";
import { handleDeleteFile, handleUploadArrayFile } from "utils/file";
import { jsonToUrlFilter } from "utils/filter";
import FormButtonAction from "views/components/FormButtonAction";
import FormFields from "./Form";
import { getAccessWithStatus } from "utils/StatusAcesso";
import ButtonControl from "views/components/ButtonControl";

class FinanceiroCRUD extends React.Component {

  state = {
    valor: null,
    ct: null,
    tipoBusca: this.props.match.params.type === 'receber' ? 'Clientes' : 'Fornecedor',
    departamento: 0,
    status: [],
    categorias: [],
    departamentos: [],
    contas: [],
    associados: [],
    dataFornecedor: [],
    parcelamentos: [],
    tipoPagamentos: [],
    clientes: [],
    desconto: 0,
    multa: 0,
    juros: 0,
    recorrencia: "Nenhuma",
    tipoPagamento: 1,
    todosClientes: false,
    dtnfe: new Date(),
    enviaParaProprietario: true,
    enviaParaBenemerito: false,
    enviaParaRemido: false,
    files: [],
    filesRemovidos: [],
    loading: false,
    gerarBoletoLoading: false,
    emitirBoletoVia: this.props.match.params.type === 'receber' ? 'Viacredi' : '',
    agruparCobrancaMensal: false,
    caixa: 10,
    filter: {
      _limit: 999,
      _order: 'ASC',
      _sort: "nome",
      ativo: true
    },
    modal: {
      dtRecebimento: '2022-10-11',
      valorPagamentoRecebido: 0,
      observacao: ''
    }
  }

  async componentDidMount() {
    this.setState({ loading: true });

    const { uk } = this.props.match.params;
    let parcelas = {};

    for (let i = 1; i <= 100; i++) {
      parcelas = {
        ...parcelas,
        ['valorPagamento' + i]: 0,
        ['status' + i]: 0,
        ['dtVencimento' + i]: moment().add(i - 1, 'months').endOf('month').format('YYYY-MM-DD'),
      };
    }

    this.setState({ ...parcelas, numeroParcelas: 1 });

    !!uk && await this.findBy();
    !uk && await this.findCategorias();
    !uk && await this.findTipoPagamentos();
    !uk && await this.findAllDepartamento();
    !uk && await this.findAllCaixa();

    if(!uk) {
      const socioUk = (new URLSearchParams(window.location.search)).get("socio");
      const descricao = (new URLSearchParams(window.location.search)).get("descricao");
      const valor = (new URLSearchParams(window.location.search)).get("valor");

      if(!!socioUk) {
        const response = await api.get(`/api/user/${socioUk}`);
        this.setState({ clientes: [response.data] });
      }

      if(!!descricao) {
        this.setState({ descricao });
      }

      if(!!valor) {
        this.setState({ vlTotal: parseFloat((parseFloat(valor)).toFixed(2)) }, () => this.handleChangeParcelas());
      }
    }

    this.setState({ loading: false });
  }

  findBy = async () => {
    const { uk } = this.props.match.params;
    if(!uk) return;

    const response = await api.get(`/api/financeiro/contas/${uk}`);

    if (response) {
      let temp = response.data.data;
      temp.contas = [temp.cx];
      temp.departamentos = [temp.dp];
      temp.associados = [temp.cliente];
      temp.dataFornecedor = [temp.fornecedor];
      temp.tipoPagamentos = [temp.tipoPagamento];
      temp.categorias = [temp.ct];
      temp.caixa = temp.cx.id;
      temp.departamento = temp.dp.id;
      temp.clientes = [temp.cliente];
      temp.tipoPagamento = temp.tipoPagamento.id;
      temp.files = response.data.files;
      temp.emitirBoletoVia = temp.parcelamentos[0].emitirBoletoVia

      this.setState({ ...this.state, ...temp });
    }
  }

  findAllDepartamento = async () => {
    const response = await api.get("/api/financeiro/departamento");

    if (response) {
      this.setState({
        departamentos: response.status === 200 ? response.data : []
      })
    }
  }

  findCategorias = async () => {
    const { type } = this.props.match.params;

    const filter = {
      _limit: 999,
      _order: 'ASC',
      _sort: "nome",
      ativo: true,
      codigo_like: type === 'receber' ? '1.%' : '2.%'
    }

    const response = await api.get(`/api/financeiro/categoria?${jsonToUrlFilter(filter)}`);
    this.setState({ categorias: response.data });
  }

  findTipoPagamentos = async () => {
    const filter = {
      _limit: 999,
      _order: 'ASC',
      _sort: "id",
    }

    const response = await api.get(`/api/tipo-pagamentos?${jsonToUrlFilter(filter)}`);
    this.setState({ tipoPagamentos: response.data });
  }

  handleGerarBoleto = async parcelaId => {
    this.setState({ gerarBoletoLoading: true });
    const response = await api.post(`/api/boleto/${parcelaId}`);
    this.setState({ gerarBoletoLoading: false });

    if(!response || response.status === 400) {
      this.props.enqueueSnackbar("Ocorreu um erro ao gerar o boleto!", {variant: 'error'})
    }

    await this.findBy();
  }

  searchFornecedor = async (search) => {
    if (!search) return;

    this.setState({ loadFornecedor: true });
    const response = await api.get(`/api/fornecedor?nome_like=${search}`);
    const response2 = await api.get(`/api/fornecedor?cnpj_cpf_like=${search}`);

    if (response && response2) {
      this.setState({
        dataFornecedor: [...response.data, ...response2.data],
        loadFornecedor: false,
      })
    }
    else {
      this.setState({ loadFornecedor: false })
    }
  }

  searchAssociado = async (search) => {
    if (!search) return;

    const filter = {
      _limit: 50,
      _order: 'ASC',
      _sort: "name",
      profile: { id: [2, 4] },
      status_acesso: getAccessWithStatus("App")
    };

    this.setState({ loadAssociado: true });
    const response = await api.get(`/api/user?name_like=${search}&${jsonToUrlFilter(filter)}`);
    const response2 = await api.get(`/api/user?numero_titulo_like=${search}&${jsonToUrlFilter(filter)}`);
    const response3 = await api.get(`/api/user?cpf_orcnpj_like=${search}&${jsonToUrlFilter(filter)}`);

    if (response && response2 && response3) {
      this.setState({
        associados: [...response.data, ...response2.data, ...response3.data],
        loadAssociado: false,
      })
    }
    else {
      this.setState({ loadAssociado: false })
    }
  }

  findAllCaixa = async () => {
    const response = await api.get('/api/financeiro/caixa?ativo=true');

    if (response) {
      this.setState({ contas: response.data })
    }
  }

  handleChangeValorTotal = (event, value) => {
    event.preventDefault();
    this.setState({ vlTotal: value })
  }

  delete = () => {
    this.setState({ loading: true });
    let dataUser = JSON.stringify({ uk: this.state.uk });

    fetch(Config._HOST + "/api/financeiro/contas",
      {
        body: dataUser,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => response.json())
      .then(data => {
        this.props.enqueueSnackbar(data.mensagem);

        this.setState({ loading: false }, () => {
          const { type } = this.props.match.params;
          if (data.status === 200) {
            this.props.history.push(`/admin/financeiro/contas/${type}`);
          }
        })
      }).catch(e => {
        this.setState({ loading: false });
        this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
      })
  }

  changeData = (data, uk) => {
    this.setState({ loading: true })

    if(!!uk) {
      let dataUser = JSON.stringify({ dtVencimento: moment(data).format('YYYY-MM-DD') + "T00:00:00" });

      fetch(Config._HOST + `/api/documento-agenda/parcelamentos/${uk}/data`,
        {
          body: dataUser,
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
        .then(response => response.json())
        .then(response => {
          if (!this.props.match.params.uk) {
            this.setState({
              loading: false
            })
            return
          }
        }).catch(e => {
          this.setState({
            loading: false
          })
        })
    }
  }

  showInfo = parcela => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <h1>Mais informações da parcela selecionada</h1>
            <Row className="mt-margin-top mt-margin-bottom">
              {parcela.status === 1 && (
                <>
                  <Col xs="6">
                    <FormGroup>
                      <label className="form-control-label">Data Pagamento</label>
                      <Input
                        type="date"
                        format="YYYY-MM-DD"
                        className="form-control"
                        value={moment(parcela.dtRecebimento).format('YYYY-MM-DD')}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <label className="form-control-label">Valor Pago</label>
                      <IntlCurrencyInput
                        className="form-control"
                        currency="BRL"
                        config={currencyConfig}
                        value={parcela.valorPagamento}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </>
              )}
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label">Observação</label>
                  <Input
                    type="textarea"
                    className="form-control"
                    value={parcela.observacao}
                    rows={5}
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="react-confirm-alert-button-group">
              <button onClick={onClose}>OK</button>
            </div>
          </div>
        );
      }
    })
  }

  changeStatus = (status, uk, valorPagamento) => {
    if (this.props.match.params.uk) {
      this.setState({
        modal: {
          dtRecebimento: status === 1 ? moment().format('YYYY-MM-DD') : null,
          valorPagamentoRecebido: status === 1 ? valorPagamento : null,
          observacao: ''
        }
      }, () => {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="react-confirm-alert-body">
                <h1>Tem certeza que deseja alterar status?</h1>
                <Row className="mt-margin-top mt-margin-bottom">

                  {status === 1 && (
                    <Col xs="12">
                      <FormGroup>
                        <label className="form-control-label">Data Pagamento</label>
                        <Input
                          type="date"
                          format="YYYY-MM-DD"
                          className="form-control"
                          onChange={value => this.setState({ modal: { ...this.state.modal, dtRecebimento: value.target.value } })}
                          defaultValue={this.state.modal.dtRecebimento}
                        />
                      </FormGroup>
                    </Col>
                  )}

                  <Col xs="12">
                    <FormGroup>
                      <label className="form-control-label">Observação</label>
                      <Input
                        type="textarea"
                        className="form-control"
                        rows={5}
                        onChange={value => this.setState({ modal: { ...this.state.modal, observacao: value.target.value } })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="react-confirm-alert-button-group">
                  <button onClick={onClose}>Não</button>
                  <button
                    onClick={async () => {
                      const { modal } = this.state;

                      if(status === 1 && modal.dtRecebimento.length === 0) {
                        this.props.enqueueSnackbar("preencha o campo 'Data Pagamento'.");
                        return;
                      }

                      if(modal.observacao.length === 0) {
                        this.props.enqueueSnackbar("preencha o campo 'Observação'.");
                        return;
                      }

                      this.setState({ loading: true });
                      onClose();

                      const response = await api.post(`/api/documento-agenda/parcelamentos/${uk}/status/${status}`, modal);

                      if (response.status === 200) {
                        this.setState({ ["status" + uk]: status })
                      }

                      this.setState({ loading: false })
                      this.props.enqueueSnackbar(response.mensagem);
                    }}
                  >
                    Sim
                  </button>
                </div>
              </div>
            );
          },
          buttons: [
            {
              label: 'Não'
            },
            {
              label: 'Sim',
            }
          ]
        })
      });
    }
    else {
      this.setState({ ["status" + uk]: status });
    }
  }

  create = async () => {
    const { uk, type } = this.props.match.params;
    let files = this.state.files;
    let temp = {...this.state};
    temp.files = [];

    if (!uk) {
      if (temp.tipoBusca === 'Fornecedor') {
        if (!temp.fornecedor) {
          this.props.enqueueSnackbar("Selecione um fornecedor");
          return;
        }
      }
      else {
        if (!temp.todosClientes && !temp.clientes.length) {
          this.props.enqueueSnackbar("Selecione um associado");
          return;
        }
      }
    }

    if (!temp.ct) {
      this.props.enqueueSnackbar("Selecione uma categoria")
      return;
    }

    if (!temp.departamento) {
      this.props.enqueueSnackbar("Selecione um departamento")
      return;
    }

    if (temp.caixa === 0) {
      this.props.enqueueSnackbar("Selecione uma conta")
      return;
    }

    if (!temp.vlTotal || temp.vlTotal < 0) {
      this.props.enqueueSnackbar("Preencha um valor total")
      return;
    }

    if (!temp.tipoPagamento) {
      this.props.enqueueSnackbar("Preencha a Forma de Pagamento")
      return;
    }
    else {
      temp.tipoPagamento = { id: temp.tipoPagamento }
    }

    let menorParcela = 0;
    let total = temp.numeroParcelas;
    let parcelamentos = [];
    let totalParcelas = 0;

    for (let i = 1; i <= total; i++) {
      parcelamentos.push({
        dtVencimento: moment(temp["dtVencimento" + i]).format('YYYY-MM-DD') + "T00:00:00",
        parcela: i,
        valorPagamento: temp["valorPagamento" + i],
        status: temp["status" + i],
        emitirBoletoVia: temp.emitirBoletoVia
      })

      totalParcelas = parseFloat((parseFloat(totalParcelas) + parseFloat(temp["valorPagamento" + i])).toFixed(2));

      if (temp["valorPagamento" + i] > menorParcela)
        menorParcela = temp["valorPagamento" + i];
    }

    if (!uk && totalParcelas !== temp.vlTotal) {
      this.props.enqueueSnackbar("Soma de Parcelas e Valor Total Diferentes!");
      return;
    }

    if (!uk && menorParcela < 7.5) {
      this.props.enqueueSnackbar("O valor da parcela do boleto deve ser maior ou igual à R$7,50")
      return;
    }

    this.setState({ loading: true });

    temp["type"] = type;
    temp["dtnfe"] = moment(new Date()).format('YYYY-MM-DD') + "T00:00:00";
    temp['recorrencia'] = temp['recorrencia'] === 'Nenhuma' ? null : temp['recorrencia'];

    if(temp.recorrencia === 'Fixa Mensal') {
      const response = await api.post('/api/financeiro/recorrencias', {
        descricao: temp.descricao,
        tipo: temp.type,
        tipo_busca: temp.tipoBusca,
        todos_clientes: temp.todosClientes,
        envia_para_proprietario: temp.enviaParaProprietario,
        envia_para_remido: temp.enviaParaRemido,
        envia_para_benemerito: temp.enviaParaBenemerito,
        tipo_pagamento: temp.tipoPagamento,
        valor: temp.vlTotal,
        usuarios: temp.tipoBusca === 'Clientes' ? temp.clientes.map(item => { return { id: item.id } }) : null,
        fornecedor: temp.tipoBusca === 'Fornecedor' ? temp.fornecedor : null,
        departamento: { id: temp.departamento },
        categoria: { id: temp.ct.id },
        conta: { id: temp.caixa },
        juros: temp.juros,
        desconto: temp.desconto,
        multa: temp.multa,
        data_primeira_parcela: temp.dataPrimeiraParcela
      });

      if(!!response) {
        this.props.enqueueSnackbar("Conta criada com sucesso!");
        this.props.history.replace(`/admin/financeiro/recorrencia/${type}/${response.id}/editar`);
        await this.findBy();
        this.setState({ loading: false });
      }
      else {
        this.setState({ loading: false });
        this.props.enqueueSnackbar("Ops, ocorreu um erro!");
      }
    }
    else {
      let dataUser = JSON.stringify({ ...temp, parcelamentos });
      await handleDeleteFile(this.state.filesRemovidos);

      fetch(Config._HOST + "/api/financeiro/contas",
        {
          body: dataUser,
          method: uk ? 'PUT' : 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then(response => response.json())
      .then(async (response) => {
        this.props.enqueueSnackbar(response.mensagem)

        if (response.status === 200) {
          if (files.length > 0) {
            const saveFile = await handleUploadArrayFile(Array.isArray(response.data) ? response.data[0].uk : response.data.uk, `conta-${type}`, files);

            if (saveFile === 200) {
              this.props.enqueueSnackbar("Upload Realizada com sucesso");
            }
            else {
              this.props.enqueueSnackbar("Falha no upload do arquivo.")
              return;
            }
          }

          await this.props.history.replace(`/admin/financeiro/contas/${type}/${Array.isArray(response.data) ? response.data[0].uk : response.data.uk}/editar`);
          await this.findBy();
        }

        this.setState({ loading: false });
      }).catch(e => {
        this.setState({ loading: false });
        this.props.enqueueSnackbar("Ops, ocorreu um erro!");
      })
    }
  }


  handleChangeParcelas = async () => {
    const { vlTotal, numeroParcelas } = this.state;
    const valorParcela = (vlTotal / numeroParcelas);
    let parcelas = {};
    let valor_total = 0;

    for (let i = 1; i <= parseInt(numeroParcelas); i++) {
      valor_total += parseFloat((valorParcela).toFixed(2));
      parcelas = {
        ...parcelas,
        ['dtVencimento' + i]: moment().add(i - 1, 'months').endOf('month').format('YYYY-MM-DD'),
        ['valorPagamento' + i]: parseFloat((valorParcela).toFixed(2)),
        ['status' + i]: 0,
      };
    }

    let valorResto = parseFloat((vlTotal - valor_total).toFixed(2));
    parcelas['valorPagamento' + numeroParcelas] += valorResto;
    await this.setState({ ...parcelas });
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  }

  render() {
    const { tipoPagamento, loading } = this.state;
    const { uk, type } = this.props.match.params;
    const disabled = uk && tipoPagamento === "BOLETO" ? true : false;

    return (
      <>
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={`/admin/financeiro/contas/${type}`}>
                    <i className="fas fa-arrow-left" /> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={`Contas a ${type === 'receber' ? 'Receber' : 'Pagar'}`}
          subTitulo={(uk ? `Atualizar contas a ${type === 'receber' ? 'Receber' : 'Pagar'}` : `Cadastrar nova conta a ${type === 'receber' ? 'Receber' : 'Pagar'}`)}
        />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {uk ? `Atualizar contas a ${type === 'receber' ? 'Receber' : 'Pagar'}` : `Cadastro de conta a ${type === 'receber' ? 'Receber' : 'Pagar'}`}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <BoxLoading />
                  ) : (
                    <Form>
                      <FormFields {...this} disabled={disabled} />
                      <FormButtonAction create={this.create} cancel={`/admin/financeiro/contas/${type}`} history={this.props.history} isEdit={uk} />
                    </Form>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  renderTotalParcelamentoItem = () => {
    let total = this.state.numeroParcelas;
    let response = [];

    for (let i = 1; i <= total; i++) {
      response.push(
        <tr>
          {this.state.recorrencia === 'Parcelado' && (
            <td>Parcela {i}</td>
          )}
          <td>
            <IntlCurrencyInput
              className="form-control"
              currency="BRL"
              config={currencyConfig}
              value={this.state["valorPagamento" + i]}
              onChange={(event, value) => {
                event.preventDefault();
                if (value !== this.state["valorPagamento" + i]) {
                  this.setState({
                    ["valorPagamento" + i]: value
                  })
                }
              }}
            />
          </td>
          <td>
            <Col lg="3">
              <FormControl variant="outlined" style={{ padding: "10px 0", width: '280px' }}>
                <Select
                  className="mt-select"
                  value={this.state["status" + i]}
                  onChange={value => this.changeStatus(value.target.value, i, this.state["valorPagamento" + i])}
                >
                  <MenuItem value={0}>Aguardando pagamento</MenuItem>
                  <MenuItem value={1}>Confirmado</MenuItem>
                </Select>
              </FormControl>
            </Col>
          </td>
          <td>
            <Input
              type="date"
              format="YYYY-MM-DD"
              value={this.state["dtVencimento" + i]}
              onChange={(value) => {
                if (!moment(value.target.value.toUpperCase()).isValid()) return;

                if (value.target.value.toUpperCase() !== this.state["dtVencimento" + i]) {
                  this.setState({
                    ["dtVencimento" + i]: value.target.value.toUpperCase()
                  })
                }
              }}
              onBlur={(value) => {
                if (!moment(value.target.value.toUpperCase()).isValid()) return;

                if (value.target.value.toUpperCase() !== this.state["dtVencimento" + i]) {
                  this.setState({
                    ["dtVencimento" + i]: value.target.value.toUpperCase()
                  })
                  this.changeData(value.target.value.toUpperCase(), i);
                }
              }}
              className="form-control"
            />
          </td>
        </tr>
      );
    }

    return response;
  }
}

export default withSnackbar(FinanceiroCRUD);
