import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import hasPermission from 'utils/UserPermission';

class ButtonControl extends Component {
  state = {
    has_permission: true
  }

  componentDidMount() {
    this.setState({ has_permission: hasPermission(this.props.to) })
  }

  render() {
    if(!this.state.has_permission && !this.props.forceShowChild) return null;

    return (
      <>
        {!this.state.has_permission && !!this.props.forceShowChild ? (
          <>
            {this.props.children}
          </>
        ) : (
          <Link
            {...this.props}
            className={`btn ${!!this.props.color && `btn-${this.props.color}`} ${!!this.props.className && this.props.className}`}
          >
            {this.props.children}
          </Link>
        )}
      </>
    );
  }
}

export default ButtonControl;
