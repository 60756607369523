const jsonToUrlFilter = object => {
  let filter = '';

  if (object) {
    Object.keys(object).forEach(key => {
      filter = filter.length ? `${filter}&` : '';

      if (Array.isArray(object[key])) {
        filter += `${encodeURIComponent(key)}=${encodeURIComponent(`[${object[key].toString().trim()}]`)}`;
      }
      else if (typeof object[key] === 'object') {
        Object.keys(object[key]).forEach(KeyChild => {
          filter += KeyChild ? `&` : '';

          if (Array.isArray(object[key][KeyChild])) {
            filter += `${key}.${encodeURIComponent(KeyChild)}=${encodeURIComponent(`[${object[key][KeyChild].toString().trim()}]`)}`;
          }
          else if (typeof object[key][KeyChild] === 'object') {
            Object.keys(object[key][KeyChild]).forEach(KeyChildChild => {
              if (Array.isArray(object[key][KeyChild][KeyChildChild])) {
                filter += `${key}.${KeyChild}.${encodeURIComponent(KeyChildChild)}=${encodeURIComponent(`[${object[key][KeyChild][KeyChildChild].toString().trim()}]`)}`;
              }
              else {
                filter += `${key}.${KeyChild}.${encodeURIComponent(KeyChildChild)}=${encodeURIComponent(object[key][KeyChild][KeyChildChild].toString().trim())}`;
              }
            });
          }
          else {
            filter += `${key}.${encodeURIComponent(KeyChild)}=${encodeURIComponent(object[key][KeyChild].toString().trim())}`;
          }
        });
      }
      else {
        filter += `${encodeURIComponent(key)}=${encodeURIComponent(object[key].toString().trim())}`;
      }
    });
  }

  return filter;
};

export { jsonToUrlFilter };
