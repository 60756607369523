/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Card, CardHeader, CardBody, Form, Container, Row, Col } from "reactstrap";
import Config from "Config";
import { withSnackbar } from "notistack";
import { ButtonGroup } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import FormFields from "./Form/FormFields";
import FormButtonAction from "views/components/FormButtonAction";
import ButtonControl from "views/components/ButtonControl";

const HISTORY_BACK = '/admin/controle-acesso/zonas';
const ROUTE_API = '/api/controle-acesso/zona';

class ZonaCrud extends React.Component {
  state = {
    nome: ''
  }

  componentDidMount() {
    let user = JSON.parse(window.localStorage.getItem(Config._USER))
    if (!user) {
      window.localStorage.removeItem(Config._USER)
      this.props.history.replace('/auth')
      return;
    }

    if (this.props.match.params.id) {
      this.findBy(this.props.match.params.id)
    }

    this.setState({ session: user })
  }

  findBy = (id) => {
    this.setState({ loading: true })

    fetch(Config._HOST + `${ROUTE_API}/${id}`)
      .then(response => response.json())
      .then(response => {
        this.setState({ ...response })
      }).catch(e => {
        this.setState({ loading: false })
      })
  }


  create(id) {
    let temp = this.state;

    if (!this.state.nome || this.state.nome === 0) {
      this.props.enqueueSnackbar("Selecione um nome")
      return;
    }

    this.setState({ loading: true });

    let data = JSON.stringify(temp);
    fetch(Config._HOST + ROUTE_API + '/' + (id ? id : ''),
      {
        body: data,
        method: id ? 'PUT' : 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => response.json())
      .then(data => {
        this.setState({ loading: false });

        if (data.status === 400) {
          this.props.enqueueSnackbar(data.message)
        }
        else {
          this.props.enqueueSnackbar(data ? (id ? "Atualizado com sucesso!" : "Cadastrado com sucesso!") : "Ops, ocorreu um erro!")
          this.props.history.push(HISTORY_BACK)
        }
      }).catch(e => {
        this.setState({ loading: false })
        this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
      })
  }

  handleChange = (value) => {
    this.setState({ ...this.state, ...value });
  }

  render() {
    return (
      <>

        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={HISTORY_BACK}>
                    <i className="fas fa-arrow-left"></i> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={this.props.match.params.id ? "EDITAR ZONA" : "NOVO ZONA"}
          subTitulo={"Gerenciamento dos Zona"}
        />


        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {this.props.match.params.id ? "Atualizar Zona" : "Cadastrar Novo Zona"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormFields {...this} />
                    <FormButtonAction create={() => this.create(this.props.match.params.id)} cancel={HISTORY_BACK} history={this.props.history} isEdit={this.props.match.params.id} />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(ZonaCrud);
