/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Card, CardHeader, Media, Table, Container, Row } from "reactstrap";
import Config from "Config";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import { createFilter } from "Config";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { ButtonGroup } from "@material-ui/core";
import { withSnackbar } from "notistack";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Avatar from '@material-ui/core/Avatar';
import { getCorStatusAssociado } from "utils/StatusAssociado";
import ButtonControl from "views/components/ButtonControl";
import api from "services/api";

const KEYS_TO_FILTERS = ['name', 'rg', 'cpfOrcnpj'];

class Dependentes extends React.Component {
  state = {
    data: [],
    dataComplete: [],
  };

  async componentDidMount() {
    this.setState({ loading: true });
    await this.findAll();
    await this.findBy();
    this.setState({ loading: false });
  }

  findBy = async () => {
    const { uk } = this.props.match.params;

    const response = await api.get(`/api/user/${uk}`);
    if(!!response) {
      this.setState({ ...response.data });
    }
  }

  findAll = async () => {
    const { uk } = this.props.match.params;

    const response = await api.get(`/api/user/${uk}/dependentes`);
    if(!!response) {
      this.setState({
        data: response.data,
        dataComplete: response.data,
      });
    }
  }

  searchUpdated(chave) {
    this.setState({
      data: createFilter((chave && chave.trim().length > 0 ? chave : ""), KEYS_TO_FILTERS, this.state.dataComplete),
      searchTerm: (chave && chave.trim().length > 0 ? chave : "")
    });
  }

  renderContent() {
    const { uk } = this.props.match.params;

    if (this.state.dataComplete.length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Não existem Dependentes Cadastrados</h3>
        </CardHeader>
      );
    }

    if (this.state.data.length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Filtro de Dependentes não encontrado.</h3>
        </CardHeader>
      );
    }

    return (
      <div>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">FOTO</th>
              <th scope="col">NOME</th>
              <th scope="col">RG</th>
              <th scope="col">CPF/CNPJ</th>
              <th scope="col">AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map(single => {
              return (
                <tr>
                  <th scope="row">
                    <Media className="align-items-center">
                      <Avatar alt={single.name} src={`${Config._HOST}/api/bucket/owner/${single.uk}/key/foto-capa`} />
                      <span style={{
                        background: getCorStatusAssociado(single.statusAcesso),
                        padding: "4px",
                        borderRadius: "50%",
                        width: "15px",
                        height: "15px",
                        position: "relative",
                        left: "-12px",
                        top: "14px",
                        border: "solid 2px white"
                      }} />
                    </Media>
                  </th>
                  <td>{single.name ? single.name : "-"}</td>
                  <td>{single.rg ? single.rg : '-'}</td>
                  <td>{single.cpfOrcnpj ? single.cpfOrcnpj : '-'}</td>
                  <td>
                    <div className="avatar-group">
                      <ButtonControl color='secondary' to={`/admin/associado/${uk}/dependentes/${single.uk}/editar`}>
                        <i className="fas fa-edit" />
                      </ButtonControl>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    const { loading } = this.state;
    const { uk } = this.props.match.params;

    return (
      <>
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={`/admin/associado/${uk}/editar`}>
                    <i className="fas fa-arrow-left"></i> VOLTAR
                  </ButtonControl>
                  <ButtonControl color='secondary' to={`/admin/associado/${uk}/dependentes/novo`}>
                    <i className="fas fa-plus"></i> NOVO
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          } titulo={"Dependentes"} subTitulo={"Módulo de Gestão para Dependentes de " + this.state.name} />

        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Total Dependentes ({this.state.data.length})</h3>
                </CardHeader>
                {loading && <BoxLoading />}
                {this.renderContent()}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(Dependentes);
