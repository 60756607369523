
const STATUS_ATIVO = 1;
const STATUS_INATIVO = 2;
const STATUS_FALECIDO = 3;
// const STATUS_PUBLICO = 4;
const STATUS_SUSPENSO = 5;
const STATUS_IRREGULAR = 6;
const STATUS_INADIMPLENTE = 7;
const STATUS_DESLIGADO = 8;
const STATUS_LICENCIADO = 9;
const STATUS_BLOQUEADO = 10;

const getAccessWithStatus = acesso => {
  let access = [];

  for (let i = 1; i <= 10; i++) {
    const hasAccess = hasAccessWithStatus(i, acesso);
    if(hasAccess) access.push(i);
  }

  return access;
}

const hasAccessWithStatus = (statusAcesso, acesso) => {
  if(statusAcesso === STATUS_ATIVO) {
    if(acesso === "App") return true;
    if(acesso === "AcessoClube") return true;
    if(acesso === "Reserva") return true;
    if(acesso === "Inscrição") return true;
  }
  else if(statusAcesso === STATUS_IRREGULAR) {
    if(acesso === "App") return true;
    if(acesso === "AcessoClube") return true;
    if(acesso === "Reserva") return true;
    if(acesso === "Inscrição") return false;
  }
  else if(statusAcesso === STATUS_INADIMPLENTE) {
    if(acesso === "App") return true;
    if(acesso === "AcessoClube") return true;
    if(acesso === "Reserva") return false;
    if(acesso === "Inscrição") return false;
  }
  else if(statusAcesso === STATUS_BLOQUEADO) {
    if(acesso === "App") return true;
    if(acesso === "AcessoClube") return false;
    if(acesso === "Reserva") return false;
    if(acesso === "Inscrição") return false;
  }
  else if(statusAcesso === STATUS_INATIVO) {
    if(acesso === "App") return true;
    if(acesso === "AcessoClube") return false;
    if(acesso === "Reserva") return false;
    if(acesso === "Inscrição") return false;
  }
  else if(statusAcesso === STATUS_LICENCIADO) {
    if(acesso === "App") return true;
    if(acesso === "AcessoClube") return false;
    if(acesso === "Reserva") return false;
    if(acesso === "Inscrição") return false;
  }
  else if(statusAcesso === STATUS_SUSPENSO) {
    if(acesso === "App") return false;
    if(acesso === "AcessoClube") return false;
    if(acesso === "Reserva") return false;
    if(acesso === "Inscrição") return false;
  }
  else if(statusAcesso === STATUS_FALECIDO) {
    if(acesso === "App") return false;
    if(acesso === "AcessoClube") return false;
    if(acesso === "Reserva") return false;
    if(acesso === "Inscrição") return false;
  }
  else if(statusAcesso === STATUS_DESLIGADO) {
    if(acesso === "App") return false;
    if(acesso === "AcessoClube") return false;
    if(acesso === "Reserva") return false;
    if(acesso === "Inscrição") return false;
  }

  return false;
}


export { getAccessWithStatus, hasAccessWithStatus };
