/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { ButtonGroup } from "@material-ui/core";
import Filter from "views/components/Filter";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import moment from 'moment';
import { withSnackbar } from "notistack";
import { Component } from "react";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import BoxLoading from "react-loadingg/lib/BoxLoading";
import { Button, Card, CardHeader, Container, FormGroup, Input, Row, Table } from "reactstrap";
import Col from "reactstrap/lib/Col";
import api from "services/api";
import ButtonExport from "utils/ButtonExport";
import { jsonToUrlFilter } from "utils/filter";
import ButtonControl from "views/components/ButtonControl";

class AssociadoCarteirinhas extends Component {
  state = {
    data: [],
    loading: false,
    loadingMore: false,
    categorias: [],
    filter: {
      _page: 1,
      _limit: 20,
      _order: 'ASC',
      _sort: "validade_exame"
    }
  };

  updateDependentes = async data => {
    for (const key in data) {
      if(!!data[key].associadoUk) {
        const reponseSocio = await api.get(`api/user/${data[key].associadoUk}`);
        data[key].numeroTitulo = reponseSocio.data.numeroTitulo;
      }
    }

    return data;
  }

  findAssociados = async (removeData = true) => {
    const { data, filter, loading } = this.state;
    if (loading) return;

    let filterTmp = { ...filter };

    await this.setState({ loading: removeData, loadingMore: !removeData });

    if (removeData && filter._page > 1) {
      await this.setState({ filter: { ...filter, _page: 1 } })
    }

    for (const key in filterTmp) {
      if(filterTmp[key].length === 0 ) delete filterTmp[key];
    }

    const response = await api.get(`api/user/carteirinhas?${jsonToUrlFilter(filterTmp)}`);

    if (response && 'data' in response) {
      response.data = await this.updateDependentes(response.data);

      if (removeData) {
        this.setState({
          ...response,
          loading: false,
          loadingMore: false
        })
      }
      else {
        this.setState({
          ...response,
          data: [...data, ...response.data],
          loading: false,
          loadingMore: false
        })
      }
    }
    else {
      this.setState({ loading: false, loadingMore: false })
    }
  }

  loadMore = () => {
    const { filter } = this.state;

    this.setState({
      filter: {
        ...filter,
        _page: this.state.filter._page + 1
      }
    }, () => {
      this.findAssociados(false);
    });
  }

  handleLimpar = () => {
    this.setState({
      data: [],
      length: 0,
      total_data: 0,
      filter: {
        _page: 1,
        _limit: 20,
        _order: 'ASC',
        _sort: "validade_exame",
        'validade_exame<=': '',
        'validade_exame>=': ''
      }
    });
  }

  exportData = (data, type) => {
    let dataTemp = { ...data };

    for (const key in dataTemp) {
      if(dataTemp[key].length === 0 ) delete dataTemp[key];
    }

    dataTemp['_limit'] = 10000;

    dataTemp['_view'] = {
      title: 'Carteirinhas',
      head: 'Nome, Número, Validade Exame',
      body: 'user_entity.name,numero,validade_exame:date'
    };

    if(type === "pdf") {
      dataTemp['_view'] = { ...dataTemp['_view'], orientacao: 'landscape' }
    }

    delete dataTemp['data_inicio'];
    delete dataTemp['data_fim'];

    return dataTemp;
  }

  renderContent() {
    const { data, length, total_pages, filter, loading } = this.state;

    if (loading) return <BoxLoading />;

    if (length === undefined || length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Nenhuma carteirinha encontrada!</h3>
        </CardHeader>
      )
    }

    return (
      <div>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">NOME</th>
              <th scope="col">NÚMERO</th>
              <th scope="col">VALIDADE EXAME</th>
              <th scope="col">TIPO</th>
            </tr>
          </thead>
          <tbody>
            {data.map(item => {
              return (
                <tr key={item.uk}>
                  <td>
                    <a href={`/admin/associado/${item.userEntity.uk}`} style={{ color: '#525f7f' }} rel="noreferrer" target="_blank">
                      {item.userEntity.name}
                    </a>
                  </td>
                  <td>{item.numero}</td>
                  <td>{moment(item.validadeExame).isValid() ? moment(item.validadeExame).format('DD/MM/YYYY') : '-'}</td>
                  <td>{item.userEntity.profile.id === 2 ? "ASSOCIADO" : 'DEPENDENTE'}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>

        {filter._page < total_pages && (
          <Button style={{ backgroundColor: "#3f51b5", color: "white", width: '100%', height: "45px" }} disabled={this.state.loadingMore} variant="contained" onClick={this.loadMore}>
            BUSCAR MAIS {this.state.loadingMore && <BoxLoading />}
          </Button>
        )}

      </div>
    )
  }

  render() {
    const { filter, total_data } = this.state;

    return (
      <>
        <HeaderButtonGroup
          component={
            <div className="text-center">
              <ButtonGroup color="primary" aria-label="outlined primary button group">
                <ButtonControl color='secondary' to='/admin/associado'>
                  <i className="fas fa-arrow-left"></i> VOLTAR
                </ButtonControl>
              </ButtonGroup>
            </div>
          }
          titulo={"CARTEIRINHAS"}
          subTitulo={"Módulo de Gestão para Associados"}
        />
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 style={{ float: "left" }} className="mb-0">Total ({total_data ? total_data : 0})</h3>

                  {!!total_data && (
                    <>
                      <ButtonExport notification={this.props.enqueueSnackbar} name="Carteirinhas" type="pdf" url={'/api/user/carteirinhas/export/pdf?' + jsonToUrlFilter(this.exportData(filter, 'pdf'))} />
                      <ButtonExport notification={this.props.enqueueSnackbar} name="Carteirinhas" type="excel" url={'/api/user/carteirinhas/export/excel?' + jsonToUrlFilter(this.exportData(filter, 'excel'))} />
                    </>
                  )}

                  <Filter handleLimpar={this.handleLimpar} handlePesquisar={this.findAssociados} isOpened={true}>
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <Input
                            className="form-control"
                            placeholder="Número"
                            type="text"
                            onChange={value => this.setState({ filter: { ...filter, numero: value.target.value } })}
                            value={'numero' in filter ? filter.numero : ''}
                            autoComplete="off"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <Input format="YYYY-MM-DD" type="date" className="form-control" value={filter['validade_exame>=']} onChange={value => this.setState({ filter: { ...filter, 'validade_exame>=': value.target.value } })} />
                        </FormGroup>
                      </Col>
                      <span style={{ display: 'flex', alignItems: 'center' }}>Até</span>
                      <Col lg="3">
                        <FormGroup>
                          <Input format="YYYY-MM-DD" type="date" className="form-control" value={filter['validade_exame<=']} onChange={value => this.setState({ filter: { ...filter, 'validade_exame<=': value.target.value } })} />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <Input
                            className="form-control"
                            placeholder="Nome"
                            type="text"
                            onChange={value => this.setState({ filter: { ...filter, 'user_entity.name_like': value.target.value.toUpperCase() } })}
                            value={'user_entity.name_like' in filter ? filter['user_entity.name_like'] : ''}
                            autoComplete="off"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Filter>
                </CardHeader>
                {this.renderContent()}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

}

export default withSnackbar(AssociadoCarteirinhas);
