/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { ButtonGroup } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import BoxLoading from "react-loadingg/lib/BoxLoading";
import { jsonToUrlFilter } from "utils/filter"
// reactstrap components
import { Button, Card, CardHeader, Container, Row, Table } from "reactstrap";
import api from "services/api";
import ButtonControl from "views/components/ButtonControl";


const ROUTE_API = '/api/documentos';

class Documentos extends Component {
  state = {
    data: [],
    filter: {
      _page: 1,
      _limit: 50,
      _order: 'asc',
      _sort: 'nome',
    },
  };

  componentDidMount() {
    this.findAll()
  }

  findAll = async () => {
    this.setState({ loading: true })

    let response = await api.get(`${ROUTE_API}?${jsonToUrlFilter(this.state.filter)}`);
    this.setState({ data: response.data, loading: false })
  }

  delete(id) {
    this.setState({ loading: true });

    api.delete(`${ROUTE_API}/${id}`)
      .then((response) => {
        this.props.enqueueSnackbar(response ? "Deletado com sucesso!" : 'Ops, ocorreu um erro!');
        this.setState({ loading: false });
        this.findAll();
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
        this.setState({ loading: false });
      });
  }

  handleRemove = (id) => {
    confirmAlert({
      title: 'Deseja Deletar o Cadastro?',
      message: '',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim', onClick: () => {
            this.delete(id);
          }
        }
      ]
    })

  }

  renderContent() {
    if (this.state.loading) {
      return <BoxLoading />
    }

    if (this.state.data.length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Não Existem Documentos Cadastrados</h3>
        </CardHeader>
      );
    }

    return (
      <div>
        <Table className="align-items-center table-flush table-with-button" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">NOME</th>
              <th>CATEGORIA</th>
              <th>AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map(single => {
              return (
                <tr key={single.id}>
                  <th>{single.nome ? single.nome : "-"}</th>
                  <th>{single.categoria ? single.categoria.nome : ""}</th>
                  <td>
                    <div>
                      <ButtonControl color='secondary' to={`/admin/documentos/${single.id}/editar`}>
                        <i className="fas fa-edit"></i>
                      </ButtonControl>
                      <Button color="secondary" onClick={() => this.handleRemove(single.id)}>
                        <i className="fas fa-trash"></i>
                      </Button>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    return (
      <>

        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl
                    color='secondary' to='/admin/documentos/novo'>
                    <i className="fas fa-plus"></i> NOVO
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"Documentos"} subTitulo={"Módulo de Gestão para Documentos"}
        />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Total de Documentos ({this.state.data.length})</h3>
                </CardHeader>

                {this.renderContent()}

              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(Documentos);
