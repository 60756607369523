import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import { withSnackbar } from "notistack";
import { ButtonGroup, Divider, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { jsonToUrlFilter } from "utils/filter";
import BoxLoading from "react-loadingg/lib/BoxLoading";

import moment from 'moment';
import numeral from 'numeral';
import api from "services/api";
import ButtonControl from "views/components/ButtonControl";

class FinanceiroDashboad extends React.Component {

  state = {
    contasPagar: [],
    contasReceber: [],
    loadingContasPagar: false,
    loadingContasReceber: false
  }

  componentDidMount() {
    this.findContas('pagar');
    this.findContas('receber');
  }

  findContas = async type => {
    if (type === 'pagar')
      this.setState({ loadingContasPagar: true });
    else
      this.setState({ loadingContasReceber: true });

    const filter = {
      'dt_vencimento>=': moment().format('YYYY-MM-DD'),
      'dt_vencimento<=': moment().add(60, 'days').format('YYYY-MM-DD'),
      status: [0],
      financeiro_contas_entity: { type },
      _page: 1,
      _limit: 10,
      _order: 'asc',
      _sort: "dt_vencimento",
    }
    const response = await api.get(`api/documento-agenda/parcelamentos?${jsonToUrlFilter(filter)}`);

    if (response) {
      if (type === 'pagar')
        this.setState({ contasPagar: response.data });
      else
        this.setState({ contasReceber: response.data });
    }

    if (type === 'pagar')
      this.setState({ loadingContasPagar: false });
    else
      this.setState({ loadingContasReceber: false });
  }

  render() {
    const { contasPagar, contasReceber, loadingContasPagar, loadingContasReceber } = this.state;

    return (
      <>
        <HeaderButtonGroup
          titulo={"Financeiro"}
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to='/admin/financeiro/contas/pagar'>
                    Contas a pagar
                  </ButtonControl>
                  <ButtonControl color='secondary' to='/admin/financeiro/contas/receber'>
                    Contas a receber
                  </ButtonControl>
                  <ButtonControl color='secondary' to='/admin/financeiro/caixa'>
                    Conta
                  </ButtonControl>
                  <ButtonControl color='secondary' to='/admin/financeiro/departamento'>
                    Departamento
                  </ButtonControl>
                  <ButtonControl color='secondary' to='/admin/financeiro/categoria'>
                    Categoria
                  </ButtonControl>
                  <ButtonControl color='secondary' to='/admin/fornecedores'>
                    Fornecedores
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          subTitulo={"Controle completo Financeiro"}
        />

        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <div className="pl-lg-12">
                  <Row>
                    <Col lg="6" style={{ padding: '25px 0 30px 45px' }}>
                      <CardHeader className="border-0" style={{ fontSize: 25, fontWeight: 'bold', color: "tomato" }}>
                        <i className="fas fa-cart-arrow-down"></i> Contas a Pagar
                      </CardHeader>

                      <Divider style={{ margin: '0 25px' }} />

                      <CardBody className='contas-container' style={{ marginTop: loadingContasPagar ? '180px' : '0px' }}>
                        {loadingContasPagar ? (
                          <BoxLoading />
                        ) : (
                          <>
                            {contasPagar.length === 0 ? (
                              <div style={{ padding: '12px 16px' }}>Nenhuma conta a pagar</div>
                            ) : (
                              <List className="mt-padding-remove">
                                {contasPagar.map(single => (
                                  <a key={single.uk} href={`/admin/financeiro/contas/pagar/${single.financeiroContasEntity.uk}/editar`} target="_blank" rel="noopener noreferrer">
                                    <ListItem alignItems="flex-start">
                                      <ListItemText
                                        primary={single.dtVencimento && single.dtVencimento !== undefined ? moment(single.dtVencimento.split('T')[0]).format('DD/MM/YYYY') : ''}
                                        secondary={
                                          <>
                                            <Typography component="span" variant="body2" color="textPrimary">
                                              {single.financeiroContasEntity != null && single.financeiroContasEntity.ct != null ? single.financeiroContasEntity.ct.nome :  ''}
                                            </Typography>
                                            <span style={{ float: 'right' }}>
                                              {numeral(single.valorPagamento).format('$ 0,0.00')}
                                            </span>
                                          </>
                                        }
                                      />
                                    </ListItem>
                                    <Divider component="li" />
                                  </a>
                                ))}
                              </List>
                            )}
                          </>
                        )}
                      </CardBody>
                    </Col>
                    <Col lg="6" style={{ padding: '25px 45px 30px 0' }}>
                      <CardHeader className="border-0" style={{ fontSize: 25, fontWeight: 'bold', color: 'green' }}>
                        <i className="fas fa-cart-plus"></i> Contas a Receber
                      </CardHeader>

                      <Divider style={{ margin: '0 25px' }} />

                      <CardBody className='contas-container' style={{ marginTop: loadingContasReceber ? '180px' : '0px' }}>
                        {loadingContasReceber ? (
                          <BoxLoading />
                        ) : (
                          <>
                            {contasReceber.length === 0 ? (
                              <div style={{ padding: '12px 16px' }}>Nenhuma conta a receber</div>
                            ) : (
                              <List className="mt-padding-remove">
                                {contasReceber.map(single => (
                                  <a key={single.uk} href={`/admin/financeiro/contas/receber/${single.financeiroContasEntity.uk}/editar`} target="_blank" rel="noopener noreferrer">
                                    <ListItem alignItems="flex-start">
                                      <ListItemText
                                        primary={single.dtVencimento && single.dtVencimento !== undefined ? moment(single.dtVencimento.split('T')[0]).format('DD/MM/YYYY') : ''}
                                        secondary={
                                          <>
                                            <Typography component="span" variant="body2" color="textPrimary">
                                              {single.financeiroContasEntity != null && single.financeiroContasEntity.ct != null ? single.financeiroContasEntity.ct.nome :  ''}
                                            </Typography>
                                            <span style={{ float: 'right' }}>
                                              {numeral(single.valorPagamento).format('$ 0,0.00')}
                                            </span>
                                          </>
                                        }
                                      />
                                    </ListItem>
                                    <Divider component="li" />
                                  </a>
                                ))}
                              </List>
                            )}
                          </>
                        )}
                      </CardBody>
                    </Col>
                  </Row>
                </div>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(FinanceiroDashboad);
