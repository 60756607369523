
import { FormControl, MenuItem, Select } from '@material-ui/core';
import React, { Component } from 'react';
import { Col, Row, Input, FormGroup } from 'reactstrap';
import cnpjMask from 'utils/cnpjMask';
import cpfMask from 'utils/cpfMask';

export default class FormFields extends Component {
  render() {
    const { state, handleChange, handleBuscaFornecedorSuperlogica } = this.props;

    return (
      <>
        <div className="mt-margin-left mt-margin-right">
          <Row>
            <Col lg="4">
              <FormGroup className="mt-margin-bottom">
                <label className="form-control-label">CNPJ/CPF</label>
                <Input
                  name="cnpjCpf"
                  type="text"
                  placeholder="CNPJ/CPF"
                  className="form-control"
                  onBlur={value => {
                    value = value.target.value.replace(/\D/g, '');
                    handleBuscaFornecedorSuperlogica(value)
                  }}
                  onChange={value => {
                    value = value.target.value.replace(/\D/g, '');

                    if (value.length <= 18) {
                      handleChange("cnpjCpf", value.length <= 11 ? cpfMask(value) : cnpjMask(value))
                    }
                  }}
                  value={state.cnpjCpf ? (state.cnpjCpf.replace(/\D/g, '').length <= 11 ? cpfMask(state.cnpjCpf) : cnpjMask(state.cnpjCpf)) : ''}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup className="mt-margin-bottom">
                <label className="form-control-label">Nome</label>
                <Input
                  name="nome"
                  type="text"
                  placeholder="Nome"
                  onChange={(value) => handleChange('nome', value.target.value.toUpperCase())}
                  value={state.nome ? state.nome : ''}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>

        <hr className="my-4 mt-margin-large-top" />
        <h6 className="heading-small text-muted mb-4">Contato</h6>
        <div className="mt-margin-left mt-margin-right">
          <Row>
            <Col lg="4">
              <FormGroup className="mt-margin-bottom">
                <label className="form-control-label">WhatsApp</label>
                <Input
                  name="whatsapp"
                  type="text"
                  placeholder="WhatsApp"
                  onChange={(value) => handleChange('whatsapp', value.target.value.toUpperCase())}
                  value={state.whatsapp ? state.whatsapp : ''}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup className="mt-margin-bottom">
                <label className="form-control-label">Telefone</label>
                <Input
                  name="telefone"
                  type="text"
                  placeholder="Telefone"
                  onChange={(value) => handleChange('telefone', value.target.value.toUpperCase())}
                  value={state.telefone ? state.telefone : ''}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup className="mt-margin-bottom">
                <label className="form-control-label">E-mail</label>
                <Input
                  name="email"
                  type="text"
                  placeholder="E-mail"
                  onChange={(value) => handleChange('email', value.target.value.toLowerCase())}
                  value={state.email ? state.email : ''}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>

        <hr className="my-4 mt-margin-large-top" />
        <h6 className="heading-small text-muted mb-4">Endereço</h6>
        <div className="mt-margin-left mt-margin-right">
          <Row>
            <Col lg="4">
              <FormGroup className="mt-margin-bottom">
                <label className="form-control-label">CEP</label>
                <Input
                  name="cep"
                  type="text"
                  placeholder="CEP"
                  onChange={(value) => handleChange('cep', value.target.value.toUpperCase())}
                  value={state.cep ? state.cep : ''}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup className="mt-margin-bottom">
                <label className="form-control-label">Endereço</label>
                <Input
                  name="endereco"
                  type="text"
                  placeholder="Endereço"
                  onChange={(value) => handleChange('endereco', value.target.value.toUpperCase())}
                  value={state.endereco ? state.endereco : ''}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup className="mt-margin-bottom">
                <label className="form-control-label">Complemento</label>
                <Input
                  name="complemento"
                  type="text"
                  placeholder="Complemento"
                  onChange={(value) => handleChange('complemento', value.target.value.toUpperCase())}
                  value={state.complemento ? state.complemento : ''}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup className="mt-margin-bottom">
                <label className="form-control-label">Bairro</label>
                <Input
                  name="bairro"
                  type="text"
                  placeholder="Bairro"
                  onChange={(value) => handleChange('bairro', value.target.value.toUpperCase())}
                  value={state.bairro ? state.bairro : ''}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup className="mt-margin-bottom">
                <label className="form-control-label">Cidade</label>
                <Input
                  name="cidade"
                  type="text"
                  placeholder="Cidade"
                  onChange={(value) => handleChange('cidade', value.target.value.toUpperCase())}
                  value={state.cidade ? state.cidade : ''}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup className="mt-margin-bottom">
                <label className="form-control-label">UF</label>
                <Input
                  name="uf"
                  type="text"
                  placeholder="UF"
                  onChange={(value) => handleChange('uf', value.target.value.toUpperCase())}
                  value={state.uf ? state.uf : ''}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>

        <hr className="my-4 mt-margin-large-top" />
        <h6 className="heading-small text-muted mb-4">Pagamento</h6>
        <div className="mt-margin-left mt-margin-right">
          <Row>
            <Col lg="3">
              <FormControl className="mt-margin-bottom" variant="outlined">
                <label className="form-control-label">Forma de Pagamento Preferencial</label>
                <Select
                  name="formaPagamentoPreferencial"
                  className="mt-select"
                  placeholder="Forma de Pagamento Preferencial"
                  onChange={(value) => handleChange('formaPagamentoPreferencial', value.target.value)}
                  value={state.formaPagamentoPreferencial ? state.formaPagamentoPreferencial : "Boleto"}
                >
                  <MenuItem value="Boleto">Boleto</MenuItem>
                  <MenuItem value="Conta Bancaria">Conta Bancaria</MenuItem>
                  <MenuItem value="Pix">Pix</MenuItem>
                </Select>
              </FormControl>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <FormGroup className="mt-margin-bottom">
                <label className="form-control-label">Banco</label>
                <Input
                  name="banco"
                  type="text"
                  placeholder="Banco"
                  onChange={(value) => handleChange('banco', value.target.value.toUpperCase())}
                  value={state.banco ? state.banco : ''}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup className="mt-margin-bottom">
                <label className="form-control-label">Agência</label>
                <Input
                  name="agencia"
                  type="text"
                  placeholder="Agência"
                  onChange={(value) => handleChange('agencia', value.target.value.toUpperCase())}
                  value={state.agencia ? state.agencia : ''}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup className="mt-margin-bottom">
                <label className="form-control-label">Conta (Com Dígito)</label>
                <Input
                  name="conta"
                  type="text"
                  placeholder="Conta"
                  onChange={(value) => handleChange('conta', value.target.value.toUpperCase())}
                  value={state.conta ? state.conta : ''}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="mt-margin-bottom">
                <label className="form-control-label">Chave Pix</label>
                <Input
                  name="chavePix"
                  type="text"
                  placeholder="Chave Pix"
                  onChange={(value) => handleChange('chavePix', value.target.value.toUpperCase())}
                  value={state.chavePix ? state.chavePix : ''}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
