import { ButtonGroup, CircularProgress, FormControl, MenuItem, Select } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import Config from "Config";
import moment from 'moment';
import 'moment-business-days';
import { withSnackbar } from "notistack";
import numeral from 'numeral';
import React from "react";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import IntlCurrencyInput from "react-intl-currency-input";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row, Table } from "reactstrap";
import { jsonToUrlFilter } from "utils/filter";
import api from "services/api";
import Dropzone from "views/components/Dropzone";
import { handleDeleteFile } from "utils/file";
import { handleUploadArrayFile } from "utils/file";
import { getAccessWithStatus } from "utils/StatusAcesso";
import ButtonControl from "views/components/ButtonControl";


const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

class agendaCrud extends React.Component {

  state = {
    dataAmbiente: [],
    dataResponsavelFinanceiro: [],
    dataResponsavel: [],
    parcelamentos: [],
    numeroParcelas: 1,
    status: 0,
    autorizado: null,
    emitir_boleto: true,
    files: [],
    filesRemovidos: [],
    gerarBoletoLoading: false,
    emitirBoletoVia: 'Viacredi'
  }

  componentDidMount() {
    const { uk } = this.props.match.params;

    this.findAllAmbiente();
    !!uk && this.findBy();
  }

  findAllResponsavel = () => {
    this.setState({ loading: true });

    const params = {
      _limit: 100,
      profile: {
        id: [2, 3]
      }
    };

    fetch(Config._HOST + `/api/user?${jsonToUrlFilter(params)}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          dataResponsavelFinanceiro: response.status === 200 ? response.data : [],
          loading: false,
        })
      }).catch(e => {
        this.setState({
          loading: false
        })
      })
  }

  findBy = async () => {
    const { uk } = this.props.match.params;
    this.setState({ loading: true });
    const response = await api.get("/api/documento-agenda/" + uk);

    if (response.status === 200) {
      const numeroParcelas = response.data.financeiroContasEntity && 'numeroParcelas' in response.data.financeiroContasEntity ? response.data.financeiroContasEntity.numeroParcelas : 0;
      response.data["dtInicioHorario"] = moment(response.data["dtInicio"]).format('HH:mm:ss');
      response.data["dtInicio"] = moment(response.data["dtInicio"]).format('YYYY-MM-DD');
      response.data["parcelamentos"] = !!response.data.financeiroContasEntity ? response.data.financeiroContasEntity.parcelamentos : [];

      let responseFiles = await api.get(`/api/bucket?${jsonToUrlFilter({ owner: uk, key: "reserva" })}`);
      this.setState({ ...this.state, ...response.data, files: responseFiles.data, numeroParcelas });
    }
  }

  findAllAmbiente = () => {
    this.setState({ loading: true });

    fetch(Config._HOST + "/api/ambiente?_limit=9999&ativo=true&permitir_locacao=true&_sort=nome")
      .then(response => response.json())
      .then(response => {
        this.setState({
          dataAmbiente: response.data,
          loading: false,
        })
      }).catch(e => {
        this.setState({ loading: false });
      })
  }

  delete() {
    this.setState({ loading: true });

    let dataUser = JSON.stringify(this.state);
    fetch(Config._HOST + "/api/documento-agenda/" + this.props.match.params.uk,
      {
        body: dataUser,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => response.json())
      .then(data => {
        this.props.enqueueSnackbar(data.mensagem);
        this.setState({ loading: false }, () => {
          if (data.status === 200) {
            this.props.history.push("/admin/agenda");
          }
        })
      }).catch(e => {
        this.setState({ loading: false });
        this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.");
      })
  }

  async create() {
    let temp = { ...this.state };
    const { uk } = this.props.match.params;

    if (!this.state.ambiente) {
      this.props.enqueueSnackbar("Selecione um Ambiente")
      return;
    }

    if (!this.state.responsavel) {
      this.props.enqueueSnackbar("Selecione um Responsável")
      return;
    }

    if (!moment(moment(temp.dtInicio).format('YYYY-MM-DD') + "T" + temp.dtInicioHorario).isValid()) {
      this.props.enqueueSnackbar("Defina um horário de início válido")
      return;
    }

    temp.dtInicio = moment(temp.dtInicio).format('YYYY-MM-DD') + "T" + temp.dtInicioHorario;
    temp.dtFim = moment(temp.dtInicio).format('YYYY-MM-DD') + "T23:59:00";
    delete temp.files;
    delete temp.filesRemovidos;

    this.setState({ loading: true });

    if (this.state.status === 4) {
      this.setState({
        parcelamentos: [],
        valorAluguel: null,
        numeroParcelas: 0
      });
    }

    let response = null;

    delete temp.responsavel.profile;

    temp.parcelamentos = temp.parcelamentos.map(item => {
      if(temp.emitir_boleto) {
        item.emitirBoletoVia = temp.emitirBoletoVia;
      }
      return item;
    });

    if (!!uk) {
      temp.financeiroContasEntity = { id: temp.financeiroContasEntity.id };
      temp.responsavel = { id: temp.responsavel.id };

      temp.parcelamentos = temp.parcelamentos.map(item => {
        item.financeiroContasEntity = { id: item.financeiroContasEntity.id };
        return item;
      })

      response = await api.put('/api/documento-agenda', temp);
    }
    else {
      response = await api.post(`/api/documento-agenda?emitir_boleto=${this.state.emitir_boleto ? 1 : 0}`, temp);
    }

    if (response.status === 200) {
      await handleDeleteFile(this.state.filesRemovidos);
      await handleUploadArrayFile(uk, 'reserva', this.state.files);
      this.props.enqueueSnackbar(`Reserva ${!!uk ? 'atualizada' : 'cadastrada'} com sucesso!`);
      this.props.history.push(`/admin/agenda/${response.data.uk}/visualizar`);
    }
    else if ('mensagem' in response.data) {
      this.props.enqueueSnackbar(response.data.mensagem);
    }
    else {
      this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
    }

    this.setState({ loading: false });
  }

  searchAssociado = async (search) => {
    if (!search) return;

    const filter = {
      _limit: 50,
      _order: 'ASC',
      _sort: "name",
      profile: { id: [2, 4] },
      status_acesso: getAccessWithStatus("Reserva")
    };

    this.setState({ loadClientes: true });
    const response = await api.get(`/api/user?name_like=${search}&${jsonToUrlFilter(filter)}`);
    const response2 = await api.get(`/api/user?numero_titulo_like=${search}&${jsonToUrlFilter(filter)}`);
    const response3 = await api.get(`/api/user?cpf_orcnpj_like=${search}&${jsonToUrlFilter(filter)}`);

    if (response && response2 && response3) {
      this.setState({
        dataResponsavelFinanceiro: [...response.data, ...response2.data, ...response3.data],
        loadClientes: false,
      })
    }
    else {
      this.setState({ loadClientes: false })
    }
  }

  handleChangeDropzone = (key, value) => {
    this.setState({ [key]: value });
  }

  handleGerarBoleto = async parcelaId => {
    this.setState({ gerarBoletoLoading: true });
    const response = await api.post(`/api/boleto/${parcelaId}`);

    if(!!response) {
      await this.findBy();
    }
    else {
      this.props.enqueueSnackbar("Ocorreu um erro ao gerar o boleto!")
    }

    this.setState({ gerarBoletoLoading: false });
  }

  render() {
    const { uk } = this.props.match.params;
    const disabled = !!uk;

    return (
      <>
        {this.state.loading && (
          <div className="container-load-with-margin">
            <BoxLoading />
          </div>
        )}

        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={uk ? `/admin/agenda/${uk}/visualizar` : '/admin/agenda'}>
                    <i className="fas fa-arrow-left" /> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={uk ? 'Editar Reserva' : 'Nova Reserva'}
          subTitulo={"Gerenciamento completo de Agendamentos"}
        />

        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {uk ? "Atualizar Agenda" : "Cadastro de Agenda"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4 mb-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                              <label className="form-control-label">
                                Descrição do evento
                              </label>
                              <Input
                                type="text"
                                value={this.state.descricao}
                                className="form-control"
                                placeholder="Descrição do evento"
                                onChange={value => {
                                  this.setState({ descricao: value.target.value.toUpperCase() })
                                }}
                              />
                            </FormControl>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                              <label className="form-control-label">
                                Ambiente
                              </label>
                              {uk && this.state.ambiente ? (
                                <Input
                                  type="text"
                                  value={this.state.ambiente.nome}
                                  className="form-control"
                                  disabled={true}
                                />
                              ) : (
                                <Select
                                  style={{
                                    background: "#fff",
                                    height: "3rem",
                                    fontSize: "1rem",
                                    color: "#8898aa",
                                    border: 0
                                  }}
                                  value={this.state.ambiente ? this.state.ambiente : 0}
                                  onChange={value => {
                                    const ambiente = value.target.value;
                                    const { numeroParcelas } = this.state;
                                    let parcelamentosTmp = [];

                                    for (let i = 0; i < numeroParcelas; i++) {
                                      parcelamentosTmp.push({
                                        parcela: i + 1,
                                        status: 0,
                                        valorPagamento: ambiente.valorAluguelAssociado / numeroParcelas,
                                        dtVencimento: moment().businessAdd(2).add(i, 'months').format('YYYY-MM-DD\\T23:59:00')
                                      });
                                    }

                                    this.setState({
                                      ambiente,
                                      valorAluguel: ambiente.valorAluguelAssociado,
                                      listaItens: ambiente.listaItens,
                                      parcelamentos: parcelamentosTmp
                                    })
                                  }}
                                >
                                  <MenuItem value={0}>Selecione</MenuItem>
                                  {this.state.dataAmbiente.map(single => (
                                    <MenuItem key={single.uk} value={single}>{single.nome}</MenuItem>
                                  ))}
                                </Select>
                              )}
                            </FormControl>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label">Responsável</label>
                            {uk && this.state.responsavel ? (
                              <Input
                                type="text"
                                value={this.state.responsavel.name}
                                className="form-control"
                                disabled={true}
                              />
                            ) : (
                              <Autocomplete
                                disabled={!!uk}
                                options={this.state.dataResponsavelFinanceiro}
                                getOptionLabel={option => `(${!!option.numeroTitulo ? option.numeroTitulo : option.cpfOrcnpj}) ${option.name}`}
                                onChange={(_, newValue) => this.setState({ responsavel: newValue })}
                                onInputChange={(_, value) => this.searchAssociado(value)}
                                inputMode={"text"}
                                loading={this.state.loadingCliente}
                                value={this.state.responsavel}
                                renderOption={(option) => (
                                  <span>{`(${!!option.numeroTitulo ? option.numeroTitulo : option.cpfOrcnpj}) ${option.name}`}</span>
                                )}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Buscar pelo nome do Responsável"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {this.state.loadClientes ? <CircularProgress color="inherit" size={20} /> : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                              <label className="form-control-label">
                                Responsável pela retirada da chave
                              </label>
                              <Input
                                type="text"
                                value={this.state.autorizado}
                                className="form-control"
                                placeholder="Nome pessoa responsável"
                                onChange={(value) => {
                                  this.setState({ autorizado: value.target.value.toUpperCase() })
                                }}
                              />
                            </FormControl>
                          </FormGroup>
                        </Col>

                      </Row>
                    </div>

                    <h6 className="heading-small text-muted mb-2">
                      HORÁRIO
                    </h6>
                    <div className="pl-lg-4 mb-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Data
                            </label>
                            <Input
                              type="date"
                              format="YYYY-MM-DD"
                              value={this.state.dtInicio ? moment(this.state.dtInicio).format('YYYY-MM-DD') : null}
                              onChange={value => {
                                if (moment(value.target.value).isValid()) {
                                  this.setState({ dtInicio: value.target.value })
                                }
                              }}
                              className="form-control"
                              disabled={disabled}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Hora
                            </label>
                            <Input
                              type="time"
                              value={this.state.dtInicioHorario}
                              onChange={(value) => {
                                this.setState({ dtInicioHorario: value.target.value.toUpperCase() })
                              }}
                              className="form-control"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <h6 className="heading-small text-muted mb-2">
                      Lista de Itens do Ambiente
                    </h6>

                    <div className="pl-lg-4 mb-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <textarea className="form-control-alternative"
                              id="input-username"
                              style={{
                                padding: '10px',
                                width: '100%',
                                marginTop: '10px',
                                borderRadius: '10px',
                                fontSize: '14px',
                                height: '150px'
                              }}
                              placeholder="Lista Itens"
                              type="text"
                              onChange={(value) => {
                                this.setState({ listaItens: value.target.value.toUpperCase() })
                              }}
                              value={this.state.listaItens}
                              disabled={disabled}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <h6 className="heading-small text-muted mb-2">
                      STATUS
                    </h6>
                    <div className="pl-lg-4 mb-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                              <label className="form-control-label">
                                Status do Aluguel
                              </label>
                              <Select
                                style={{
                                  background: "#fff",
                                  height: "3rem",
                                  fontSize: "1rem",
                                  color: "#8898aa",
                                  border: 0
                                }}
                                value={this.state.status}
                                onChange={value => this.setState({ status: value.target.value })}
                              >
                                <MenuItem value={0}>Pagamento Pendente</MenuItem>
                                <MenuItem value={1}>Pagamento Confirmado</MenuItem>
                                <MenuItem value={4}>Pagamento Isento</MenuItem>
                                {!!uk && <MenuItem value={2}>Cancelado</MenuItem>}
                                {!!uk && <MenuItem value={3}>Vencido</MenuItem>}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Col>

                        {this.state.status === 0 && !uk && (
                          <>
                            <Col lg="3">
                              <FormGroup>
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                  <label className="form-control-label">
                                    Gerar boleto
                                  </label>
                                  <Select
                                    style={{
                                      background: "#fff",
                                      height: "3rem",
                                      fontSize: "1rem",
                                      color: "#8898aa",
                                      border: 0
                                    }}
                                    value={this.state.emitir_boleto}
                                    onChange={value => this.setState({ emitir_boleto: value.target.value })}
                                  >
                                    <MenuItem value={true}>Sim</MenuItem>
                                    <MenuItem value={false}>Não</MenuItem>
                                  </Select>
                                </FormControl>
                              </FormGroup>
                            </Col>

                            {this.state.emitir_boleto && (
                              <Col lg="3">
                                <FormControl variant="outlined" style={{ padding: "10px 0" }}>
                                  <label className="form-control-label">Emitir boleto via</label>
                                  <Select
                                    className="mt-select"
                                    value={this.state.emitirBoletoVia}
                                    onChange={value => {
                                       this.setState({ emitirBoletoVia: value.target.value })
                                    }}
                                    defaultValue={this.state.emitirBoletoVia}
                                    disabled={disabled}
                                  >
                                    <MenuItem value='Viacredi'>Viacredi</MenuItem>
                                    <MenuItem value='Superlogica'>Superlogica</MenuItem>
                                  </Select>
                                </FormControl>
                              </Col>
                            )}
                          </>
                        )}
                      </Row>
                    </div>
                    {this.state.status !== 4 && this.state.valorAluguel > 0 && (
                      <>
                        <h6 className="heading-small text-muted mb-2">
                          FINANCEIRO
                        </h6>
                        <div className="pl-lg-4 mb-4">
                          <Row>
                            <Col lg="4">
                              <FormGroup>
                                <label className="form-control-label">Valor Total</label>
                                <IntlCurrencyInput
                                  style={{
                                    border: 0,
                                    padding: '10px',
                                    boxShadow: '0px 0px 3px #cdcdcd',
                                    borderRadius: '4px',
                                    color: '#8898aa',
                                    width: '100%',
                                  }}
                                  currency="BRL"
                                  config={currencyConfig}
                                  disabled={disabled}
                                  value={this.state.valorAluguel}
                                  onChange={(event, value) => {
                                    event.preventDefault();

                                    const numeroParcelas = parseInt(this.state.numeroParcelas);
                                    const valorAluguel = value > 0 ? value : 1
                                    let parcelamentosTmp = [];

                                    for (let i = 0; i < numeroParcelas; i++) {
                                      parcelamentosTmp.push({
                                        parcela: i + 1,
                                        status: 0,
                                        valorPagamento: valorAluguel / numeroParcelas,
                                        dtVencimento: moment().businessAdd(2).add(i, 'months').format('YYYY-MM-DD\\T23:59:00')
                                      });
                                    }

                                    this.setState({ valorAluguel: valorAluguel, parcelamentos: parcelamentosTmp });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label">Número de Parcelas</label>
                                <Input
                                  type="number"
                                  disabled={!!uk}
                                  value={this.state.numeroParcelas}
                                  onChange={(value) => {
                                    const numeroParcelas = parseInt(value.target.value);
                                    let parcelamentosTmp = [];

                                    for (let i = 0; i < numeroParcelas; i++) {
                                      parcelamentosTmp.push({
                                        parcela: i + 1,
                                        status: 0,
                                        valorPagamento: this.state.valorAluguel / numeroParcelas,
                                        dtVencimento: moment().businessAdd(2).add(i, 'months').format('YYYY-MM-DD\\T23:59:00'),
                                      });
                                    }
                                    this.setState({ numeroParcelas, parcelamentos: parcelamentosTmp });
                                  }}
                                  className="form-control"
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <div className='mt-margin-medium-top'>
                            {uk ? this.renderTotalParcelamentoEdit() : this.renderTotalParcelamento()}
                          </div>

                        </div>
                      </>
                    )}

                    <hr className="my-4 mt-margin-large-top" />
                    <h6 className="heading-small text-muted mb-2">
                      Observação
                    </h6>
                    <div className="pl-lg-4 mb-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <textarea className="form-control-alternative"
                              id="input-username"
                              style={{
                                padding: '10px',
                                width: '100%',
                                marginTop: '10px',
                                borderRadius: '10px',
                                fontSize: '14px',
                                height: '150px'
                              }}
                              type="text"
                              onChange={(value) => {
                                this.setState({ observacao: value.target.value.toUpperCase() })
                              }}
                              value={this.state.observacao}></textarea>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <hr className="my-4 mt-margin-large-top"></hr>
                    <h6 className="heading-small text-muted mb-4">Anexo</h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <Dropzone {...this} handleChange={this.handleChangeDropzone} />
                        </Col>
                      </Row>
                    </div>

                    <hr className="my-4" />
                    <div className="pl-lg-4 mb-4" style={{ marginTop: "10px" }}>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <ButtonControl color='warning' style={{ width: "100%" }} to={uk ? `/admin/agenda/${uk}/visualizar` : '/admin/agenda'}>
                              <i className="fas fa-arrow-left" /> CANCELAR
                            </ButtonControl>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Button color="default" style={{ width: "100%" }} onClick={() => {
                              confirmAlert({
                                title: uk ? 'Deseja confirmar a atualização?' : 'Deseja confirmar o cadastro?',
                                message: '',
                                buttons: [
                                  {
                                    label: 'Não',
                                  },
                                  {
                                    label: 'Sim',
                                    onClick: () => this.create()
                                  }
                                ]
                              })
                            }
                            }>
                              <i className="fas fa-check" style={{ marginRight: 5 }}></i>
                              {uk ? "ATUALIZAR" : "CADASTRAR"}</Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  renderTotalParcelamento() {
    const { parcelamentos, numeroParcelas } = this.state;

    return (
      <>
        {parcelamentos.map((item, id) => (
          <Row key={id} className="mt-margin-bottom">
            <Col lg="6">
              <Input
                className="form-control"
                type="text"
                disabled
                value={`Parcela ${item.parcela}x de ${this.state.valorAluguel > 0 ? numeral((this.state.valorAluguel / (numeroParcelas > 0 ? numeroParcelas : 1))).format('$ 0,0.00') : ""}`}
              />
            </Col>
            <Col lg="6">
              <Input
                type="date"
                format="YYYY-MM-DD"
                value={moment(parcelamentos[id].dtVencimento).format('YYYY-MM-DD')}
                onChange={value => {
                  let parcelamentosTmp = [...parcelamentos];
                  parcelamentosTmp[id] = {
                    ...item,
                    dtVencimento: moment(value.target.value).format('YYYY-MM-DD\\T23:59:00')
                  };

                  this.setState({ parcelamentos: parcelamentosTmp });
                }}
                className="form-control"
              />
            </Col>
          </Row>
        ))}
      </>
    )
  }

  renderTotalParcelamentoEdit() {
    const { uk } = this.props.match.params;
    const disabled = !!uk;
    const { financeiroContasEntity, gerarBoletoLoading } = this.state;
    if (!financeiroContasEntity) return;

    const total = financeiroContasEntity.parcelamentos.sort((a, b) => {
      if (a.parcela < b.parcela)
        return -1;
      if (a.parcela > b.parcela)
        return 1;

      return 0;
    })

    return (
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            {financeiroContasEntity.recorrencia === 'Parcelado' && (
              <th scope="col">Parcelas</th>
            )}
            <th scope="col">Valor</th>
            <th scope="col" style={{ width: '280px' }}>Status</th>
            <th scope="col">Data Vencimento</th>
            {financeiroContasEntity.tipoPagamento.id === 1 && (
              <th scope="col">Link Boleto</th>
            )}
          </tr>
        </thead>
        <tbody>
          {total.map(single => {
            return (
              <tr key={single.id}>
                {financeiroContasEntity.recorrencia === 'Parcelado' && (
                  <td>{single.parcela}ª Parcela</td>
                )}
                <td>
                  <IntlCurrencyInput
                    className="form-control"
                    currency="BRL"
                    config={currencyConfig}
                    value={single.valorPagamento}
                    disabled
                  />
                </td>
                <td>
                  <Col lg="3">
                    <FormControl variant="outlined" style={{ padding: "10px 0", width: '280px' }}>
                      <Select
                        className="mt-select"
                        value={this.state[`status${single.uk}`] >= 0 ? this.state[`status${single.uk}`] : single.status}
                        onChange={value => {
                          this.setState({
                            [value.target.value]: single.uk
                          })
                        }}
                        disabled
                      >
                        <MenuItem value={0}>Aguardando pagamento</MenuItem>
                        <MenuItem value={1}>Confirmado</MenuItem>
                        <MenuItem value={2}>Cancelado</MenuItem>
                        <MenuItem value={3}>Vencido</MenuItem>
                      </Select>
                    </FormControl>
                  </Col>
                </td>
                <td>
                  <Input
                    type="date"
                    format="YYYY-MM-DD"
                    value={moment(single.dtVencimento).format('YYYY-MM-DD')}
                    onChange={(value) => {
                      if (!moment(value.target.value).isValid()) return;
                    }}
                    onBlur={(value) => {
                      this.setState({
                        [value.target.value]: single.uk
                      });
                    }}
                    className="form-control"
                    disabled={disabled}
                  />
                </td>
                <td style={{ paddingLeft: 0, textAlign: 'center' }}>
                  {financeiroContasEntity.tipoPagamento.id === 1 && !!single.linkBoleto ? (
                    <a style={{ color: "#00000080" }} target="_blank" rel="noopener noreferrer" href={single.linkBoleto}>
                      <i className="fas fa-barcode mt-margin-xsmall-right"></i>
                      Link Boleto
                    </a>
                  ) : financeiroContasEntity.tipoPagamento.id === 1 ? (
                    <Button onClick={() => !gerarBoletoLoading && this.handleGerarBoleto(single.id)}>
                      {gerarBoletoLoading ? (
                        <i className="fas fa-sync fa-solid fa-spin mt-margin-xsmall-right"></i>
                      ) : (
                        <>
                          <i className="fas fa-sync mt-margin-xsmall-right"></i>
                          Gerar Boleto
                        </>
                      )}
                    </Button>
                  ) : (
                    <span style={{ color: "#aaaaaa" }}>Não disponivel</span>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }
}

export default withSnackbar(agendaCrud);
