// import { FormControl, MenuItem, Select } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import React, { Component } from 'react';
import Select from 'react-select';
import { Col, Input, Row } from 'reactstrap';
import Dropzone from "views/components/Dropzone";

export default class FormFields extends Component {
  render() {
    const { state, handleChange } = this.props;
    const customStyles = {
      valueContainer: (provided, state) => ({
        ...provided,
        height: '40px',
      })
    }

    return (
      <>
        <div className="mt-margin-left mt-margin-right">
          <Row>
            <Col lg="6">
              <FormControl>
                <label className="form-control-label">Nome</label>
                <Input
                  className="form-control"
                  placeholder="Nome"
                  type="text"
                  onChange={value => handleChange( "nome", value.target.value.toUpperCase() )}
                  value={state.nome}
                />
              </FormControl>
            </Col>
            <Col lg="6">
              <FormControl variant="outlined">
                <label className="form-control-label">Categoria</label>
                {/* <Select
                  className="mt-select"
                  value={state.categoria}
                  onChange={(value) => handleChange("categoria", value.target.value)}
                > */}
                  {/* <MenuItem value={0}>Selecione uma Categoria</MenuItem> */}
                  {/* {state.categorias.map(single => {
                    // return <MenuItem key={single.id} value={single.id}>{single.nome}</MenuItem>
                  })} */}
                {/* </Select> */}
                <Select
                  styles={customStyles}
                  value={state.categoria ? state.categoria : ""}
                  onChange={(value) => handleChange("categoria", value)}
                  options={state.categorias.map(data => ({label: data.nome, value: data.id}))}
                />
              </FormControl>
            </Col>
          </Row>
        </div>
        <hr className="my-4 mt-margin-large-top"></hr>
        <h6 className="heading-small text-muted mb-4">Anexo</h6>
        <div className="pl-lg-4">
          <Row>
            <Col lg="12">
              <Dropzone {...this.props} maxFiles={1} acceptedFiles=".pdf" />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
