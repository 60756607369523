/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { FormControl, MenuItem, Select } from "@material-ui/core";
import Filter from "views/components/Filter";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import Config from "Config";
import moment from "moment";
import { withSnackbar } from "notistack";
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import BoxLoading from "react-loadingg/lib/BoxLoading";
// reactstrap components
import { Card, CardHeader, Col, Container, Row, Table } from "reactstrap";
import FormGroup from "reactstrap/lib/FormGroup";
import Input from "reactstrap/lib/Input";
import ButtonControl from "views/components/ButtonControl";
import api from "services/api";
import { jsonToUrlFilter } from "utils/filter";

class FaleConosco extends React.Component {
  filter = {
    _limit: 100,
    _order: 'desc',
    _sort: 'data',
    status: { id: 1 }
  };

  state = {
    loading: false,
    filter: this.filter,
    data: [],
    dataTipos: [],
    dataStatus: []
  };

  async componentDidMount() {
    this.setState({ loading: true });

    await this.findOcorrenciaTipo();
    await this.findOcorrenciaStatus();
    await this.handlePesquisar();

    this.setState({ loading: false });
  }

  findOcorrenciaTipo = async () => {
    const response = await api.get('/api/ocorrencia/tipo');
    this.setState({ dataTipos: response.data });
  }

  findOcorrenciaStatus = async () => {
    const response = await api.get('/api/ocorrencia/status');
    this.setState({ dataStatus: response.data });
  }

  handlePesquisar = async () => {
    this.setState({ loading: true });
    const response = await api.get(`/api/ocorrencia?${jsonToUrlFilter(this.state.filter)}`);
    this.setState({ data: response.data, loading: false });
  }

  handleRemove = (id) => {
    confirmAlert({
      title: 'Deseja Deletar o cadastro?',
      message: '',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim', onClick: () => {
            this.setState({ loading: true })

            fetch(Config._HOST + `/api/ocorrencia/${id}`,
              {
                method: 'DELETE',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                }
              },
            )
              .then(response => response.json())
              .then(data => {
                if (data) {
                  const dataUpdated = this.state.data.filter(row => row.id !== id);
                  this.setState({ data: dataUpdated });
                }

                this.props.enqueueSnackbar(data ? "Deletado com sucesso!" : 'Ops, ocorreu um erro!')
                this.setState({ loading: false })
              })
              .catch(e => {
                this.setState({ loading: false })
                this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
              })

          }
        }
      ]
    })

  }

  onChangeFilter = (campo, value) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [campo]: value
      }
    })
  }

  handleLimpar = () => {
    this.setState({
      filter: this.filter
    }, this.handlePesquisar)
  }

  renderContent() {
    if (this.state.loading) return <BoxLoading />

    if (this.state.data.length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Não Existem Mensagens!</h3>
        </CardHeader>
      );
    }

    return (
      <div>
        <Table className="align-items-center table-flush table-with-button" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">DESCRIÇÃO</th>
              <th>TIPO</th>
              <th>STATUS</th>
              <th>DATA</th>
              <th>AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map(single => {
              return (
                <tr key={single.id}>
                  <th>{single.descricao ? (single.descricao.substring(0, 80) + (single.descricao.length > 80 ? "..." : "")) : "-"}</th>
                  <th>{single.tipo ? single.tipo.nome : "-"}</th>
                  <th>{single.status ? single.status.nome : "-"}</th>
                  <th>{single.data ? moment(single.data.split('.000')[0]).format("DD/MM/YYYY \\à\\s HH:mm") : "-"}</th>
                  <td>
                    <div>
                      <ButtonControl color='secondary' to={`/admin/fale-conosco/${single.id}/visualizar`}>
                        <i className="fas fa-comments"></i>
                      </ButtonControl>
                      {/* <Button color="orange" onClick={() => this.handleRemove(single.id)}>
                        <i className="fas fa-trash"></i>
                      </Button> */}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    const { filter, dataTipos, dataStatus } = this.state;

    return (
      <>

        {/* <Header /> */}
        <HeaderButtonGroup titulo={"Fale Conosco"} subTitulo={"Módulo de Gestão de Mensagens"} />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 style={{ float: "left" }} className="mb-0">Total de Mensagens ({this.state.data.length})</h3>
                  <Filter handleLimpar={this.handleLimpar} handlePesquisar={this.handlePesquisar}>
                    <Row>
                      <Col lg="3">
                        <FormGroup className="mt-margin-remove">
                          <label className="form-control-label">Descricao</label>
                          <Input
                            className="form-control"
                            placeholder="Descricao"
                            type="text"
                            onChange={value => this.onChangeFilter("descricao_like", value.target.value.toUpperCase())}
                            value={filter.descricao}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormControl variant="outlined">
                          <label className="form-control-label">Tipo</label>
                          <Select
                            name="tipo"
                            className="mt-select"
                            placeholder="Tipo"
                            onChange={value => this.onChangeFilter("tipo.id", value.target.value)}
                            value={filter["tipo.id"] ? filter["tipo.id"] : 0}
                          >
                            <MenuItem value={0}>Todos os tipos</MenuItem>
                            {dataTipos.map(tipo => (
                              <MenuItem key={tipo.id} value={tipo.id}>{tipo.nome}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Col>
                      <Col lg="3">
                        <FormControl variant="outlined">
                          <label className="form-control-label">Status</label>
                          <Select
                            name="status"
                            className="mt-select"
                            placeholder="Status"
                            onChange={value => this.onChangeFilter("status.id", value.target.value)}
                            value={filter["status.id"] ? filter["status.id"] : 0}
                          >
                            <MenuItem value={0}>Todos os status</MenuItem>
                            {dataStatus.map(status => (
                              <MenuItem key={status.id} value={status.id}>{status.nome}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Col>
                      <Col lg="3">
                        <FormControl>
                          <label className="form-control-label">Data</label>
                          <Input
                            type="date"
                            name="data"
                            className="form-control"
                            value={filter.data}
                            onChange={value => {
                              if (moment(value.target.value.toUpperCase()).isValid())
                                this.onChangeFilter('data_like', value.target.value.toUpperCase())
                            }}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  </Filter>
                </CardHeader>

                {this.renderContent()}

              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(FaleConosco);
