/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Card, CardHeader, Media, Table, Container, Row, FormGroup, Input, Button } from "reactstrap";
import Config from "Config";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { ButtonGroup, FormControl, MenuItem, Select } from "@material-ui/core";
import { withSnackbar } from "notistack";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Avatar from '@material-ui/core/Avatar';
import Col from "reactstrap/lib/Col";
import Filter from "views/components/Filter";
import api from "services/api";
import { jsonToUrlFilter } from "utils/filter";
import { getStatusAssociado } from "utils/StatusAssociado";
import cpfMask from "utils/cpfMask";
import moment from 'moment';
import { getCorStatusAssociado } from "utils/StatusAssociado";
import ButtonControl from "views/components/ButtonControl";
import cnpjMask from "utils/cnpjMask";
import ButtonExport from "utils/ButtonExport";

class Associados extends Component {
  state = {
    data: [],
    loading: false,
    loadingMore: false,
    categorias: [],
    filter: {
      _page: 1,
      _limit: 20,
      _order: 'ASC',
      _sort: "name",
      status_acesso: []
    },
    isNaoSocio: window.location.pathname.indexOf('nao-socios') >= 0 ? true : false
  };

  async componentDidMount() {
    if(this.state.isNaoSocio) {
      const filter = {
        profile: { id: [4] },
        status_acesso: [1, 2, 3, 5, 6, 7, 8, 10]
      }
      this.setState({...this.state, filter: { ...this.state.filter, ...filter }})
    }
    else {
      const filter = {
        categoria: [1, 2, 3],
        profile: { id: [2, 3] },
        status_acesso: [1, 2, 3, 5, 6, 7, 8, 9, 10]
      }
      this.setState({...this.state, filter: { ...this.state.filter, ...filter }})
    }

    this.setState({ loading: true });
    await this.findAllCategoria();
    this.setState({ loading: false });
  }

  findAllCategoria = async () => {
    const response = await api.get("/api/categoria");

    this.setState({
      categorias: response.status === 200 ? response.data : [],
    })
  }

  updateDependentes = async (data, titulo) => {
    for (const key in data) {
      if(!!data[key].associadoUk) {
        if(titulo) {
          data[key].numeroTitulo = titulo;
        }
        else {
          const reponseSocio = await api.get(`api/user/${data[key].associadoUk}`);
          if(reponseSocio) {
            data[key].numeroTitulo = reponseSocio.data.numeroTitulo;
          }
        }
      }
    }

    return data;
  }

  findAssociados = async (removeData = true) => {
    const { data, filter, loading } = this.state;
    if (loading) return;

    let filterTmp = { ...filter };

    await this.setState({ loading: removeData, loadingMore: !removeData });

    if (removeData && filter._page > 1) {
      await this.setState({ filter: { ...filter, _page: 1 } })
    }

    if ('numero_titulo' in filter && filter.numero_titulo.length === 0) {
      delete filterTmp.numero_titulo;
    }

    if ('name_like' in filter && filter.name_like.length === 0) {
      delete filterTmp.name_like;
    }

    if ('cpf_orcnpj_like' in filter && filter.cpf_orcnpj_like.length === 0) {
      delete filterTmp.cpf_orcnpj_like;
    }

    if('categoria' in filter && filter.categoria.length > 0) {
      filterTmp.categoria = [...filterTmp.categoria, 'null'];
    }

    const response = await api.get(`api/user?${jsonToUrlFilter(filterTmp)}`);

    if (response) {
      let depententes = [];
      const titulo = filterTmp.numero_titulo;

      if ('numero_titulo' in filter && filter.numero_titulo.length > 0) {
        if(!('name_like' in filter) && !('cpf_orcnpj_like' in filter) && response.data.length > 0) {
          delete filterTmp.numero_titulo;

          for (const key in response.data) {
            const socio = response.data[key];

            if(!socio.associado_uk) {
              filterTmp.associado_uk = socio.uk;
              const responseDependente = await api.get(`api/user?${jsonToUrlFilter(filterTmp)}`);

              if(responseDependente) {
                depententes = [...depententes, ...responseDependente.data]
              }
            }
          }
        }
        else {
          const teste = await api.get(`api/user?${jsonToUrlFilter({ numero_titulo: titulo, 'profile.id': 2 })}`);

          for (const key in teste.data) {
            const associado_uk = teste.data[key].uk;
            filterTmp.associado_uk = associado_uk;
            delete filterTmp.numero_titulo;

            const responseDependente = await api.get(`api/user?${jsonToUrlFilter(filterTmp)}`);

            if(responseDependente) {
              depententes = [...depententes, ...responseDependente.data]
            }
          }
        }
      }

      response.data = [ ...response.data, ...depententes ];
      response.data = await this.updateDependentes(response.data, titulo);


      if (removeData) {
        this.setState({
          ...response,
          length: (response.length + depententes.length),
          total_data: (response.total_data + depententes.length),
          loading: false,
          loadingMore: false
        })
      }
      else {
        this.setState({
          ...response,
          data: [...data, ...response.data, ...depententes],
          length: (response.length + depententes.length),
          total_data: (response.total_data + depententes.length),
          loading: false,
          loadingMore: false
        })
      }
    }
    else {
      this.setState({ loading: false, loadingMore: false })
    }
  }

  loadMore = () => {
    const { filter } = this.state;

    this.setState({
      filter: {
        ...filter,
        _page: this.state.filter._page + 1
      }
    }, () => {
      this.findAssociados(false);
    });
  }

  handleLimpar = () => {
    let filter = {};

    if(this.state.isNaoSocio) {
      filter = {
        profile: { id: [4] },
        status_acesso: [1, 2, 3, 5, 6, 7, 8, 10]
      }
    }
    else {
      filter = {
        categoria: [1, 2, 3],
        profile: { id: [2, 3] },
        status_acesso: [1, 2, 3, 5, 6, 7, 8, 9, 10]
      }
    }

    this.setState({
      data: [],
      length: 0,
      total_data: 0,
      filter: {
        _limit: 50,
        _order: 'ASC',
        _sort: "name",
        ...filter
      }
    });
  }

  exportData = () => {
    const { filter } = this.state;

    return {
      ...filter,
      _limit: 10000,
      _view: {
        title: `Associados`,
        head: 'Título,Nome,CPF/CNPJ,Status,Tipo',
        body: 'numeroTitulo,name,cpfOrcnpj,statusAcesso:statusUser,profile.descricao',
        orientacao: 'landscape',
        template: 2,
      },
    }
  }

  renderContent() {
    const user = JSON.parse(window.localStorage.getItem(Config._USER));
    const { data, length, total_pages, filter, loading, isNaoSocio } = this.state;

    if (loading) return <BoxLoading />;

    if (length === undefined || length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">
            {`Nenhum ${!isNaoSocio ? 'associado ou dependente' : 'não sócio'} encontrado!`}
          </h3>
        </CardHeader>
      )
    }

    return (
      <div>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">FOTO</th>
              {!isNaoSocio && <th scope="col">TÍTULO</th>}
              <th scope="col">NOME</th>
              <th scope="col">CPF/CNPJ</th>
              {!isNaoSocio && <th scope="col">TIPO</th>}
              <th scope="col">STATUS</th>
              {!isNaoSocio && <th scope="col">VALIDADE EXAME</th>}
              {user.profile.id === 1 && <th scope="col">AÇÃO</th>}
            </tr>
          </thead>
          <tbody>
            {data.map(single => {
              return (
                <tr key={single.uk}>
                  <th scope="row">
                    <Media className="align-items-center">
                      <Avatar alt={single.name} src={`${Config._HOST}/api/bucket/owner/${single.uk}/key/foto-capa`} />
                      <span style={{
                        background: getCorStatusAssociado(single.statusAcesso),
                        padding: "4px",
                        borderRadius: "50%",
                        width: "15px",
                        height: "15px",
                        position: "relative",
                        left: "-12px",
                        top: "14px",
                        border: "solid 2px white"
                      }} />
                    </Media>
                  </th>
                  {!isNaoSocio && <td>{single.numeroTitulo}</td>}
                  <td>{single.name}</td>
                  <td>{single.cpfOrcnpj}</td>
                  {!isNaoSocio && <td>{single.profile.id === 2 ? "ASSOCIADO" : 'DEPENDENTE'}</td>}
                  <td>{getStatusAssociado(single.statusAcesso)}</td>
                  {!isNaoSocio && (
                    <td>
                      {!!single.listaCarteirinhas.length && !!single.listaCarteirinhas[0].validadeExame ? moment(single.listaCarteirinhas[0].validadeExame).format('DD/MM/YYYY') : '-'}
                    </td>
                  )}
                  <td>
                    <div className="avatar-group">
                      {!isNaoSocio ? (
                        <ButtonControl color='secondary' to={!!single.associadoUk ? `/admin/associado/${single.associadoUk}/dependentes/${single.uk}/editar` : `/admin/associado/${single.uk}/editar`}>
                          <i className="fas fa-edit"></i>
                        </ButtonControl>
                      ) : (
                        <ButtonControl color='secondary' to={`/admin/nao-socios/${single.uk}/editar`}>
                          <i className="fas fa-edit"></i>
                        </ButtonControl>
                      )}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>

        {filter._page < total_pages && (
          <Button style={{ backgroundColor: "#3f51b5", color: "white", width: '100%', height: "45px" }} disabled={this.state.loadingMore} variant="contained" onClick={this.loadMore}>
            BUSCAR MAIS {this.state.loadingMore && <BoxLoading />}
          </Button>
        )}

      </div>
    )
  }

  render() {
    const user = JSON.parse(window.localStorage.getItem(Config._USER));
    const { filter, total_data, isNaoSocio, loading } = this.state;

    return (
      <>
        <HeaderButtonGroup
          component={(
            <div>
              {user.profile.id === 1 && (
                <div className="text-center">
                  <ButtonGroup>
                  {isNaoSocio ? (
                    <ButtonControl color='secondary' to='/admin/nao-socios/novo'>
                      <i className="fas fa-plus"></i> NOVO
                    </ButtonControl>
                  ) : (
                    <>
                      <ButtonControl color='secondary' to='/admin/associado/novo'>
                        <i className="fas fa-plus"></i> NOVO
                      </ButtonControl>

                      <ButtonControl color='secondary' to='/admin/associado/carteirinhas'>
                        <i className="fas fa-id-card"></i> Carteirinhas
                      </ButtonControl>

                      {/* <Button onClick={() => this.props.history.push('/admin/veiculos')}>
                        <i className="fas fa-car"></i> Veículos
                      </Button> */}
                    </>
                  )}
                  </ButtonGroup>
                </div>
              )}
            </div>
          )}
          titulo={!isNaoSocio ? "ASSOCIADOS" : "NÃO SÓCIOS"}
          subTitulo={`Módulo de Gestão para ${!isNaoSocio ? 'Associados' : 'não sócios'}`}
        />
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 style={{ float: "left" }} className="mb-0">
                    {!isNaoSocio ? 'Associados/Dependentes ' : 'Não sócios '}
                    ({total_data ? total_data : 0})
                  </h3>

                  {!!this.state.data.length && !loading && (
                    <>
                      <ButtonExport notification={this.props.enqueueSnackbar} name="Lista de Prestadores de Serviço" type="pdf" url={'/api/user/export/pdf?' + jsonToUrlFilter(this.exportData())} />
                      <ButtonExport notification={this.props.enqueueSnackbar} name="Lista de Prestadores de Serviço" type="excel" url={'/api/user/export/excel?' + jsonToUrlFilter(this.exportData())} />
                    </>
                  )}

                  <Filter handleLimpar={this.handleLimpar} handlePesquisar={this.findAssociados} isOpened={true}>
                    <Row>

                      {!isNaoSocio && (
                        <Col lg="2">
                          <FormGroup>
                            <Input
                              className="form-control"
                              placeholder="Titulo"
                              type="text"
                              onChange={value => this.setState({ filter: { ...filter, numero_titulo: value.target.value.toUpperCase() } })}
                              value={'numero_titulo' in filter ? filter.numero_titulo : ''}
                              autoComplete="off"
                            />
                          </FormGroup>
                        </Col>
                      )}

                      <Col lg="4">
                        <FormGroup>
                          <Input
                            className="form-control"
                            placeholder="Nome"
                            type="text"
                            onChange={value => {
                              this.setState({ filter: { ...filter, name_like: value.target.value.toUpperCase() } })
                            }}
                            value={'name_like' in filter ? filter.name_like : ''}
                            autoComplete="off"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="3">
                        <FormGroup>
                          <Input
                            className="form-control"
                            id="input-last-name"
                            placeholder="CPF ou CNPJ"
                            type="text"
                            onChange={(value) => {
                              if (value.target.value.length <= 18) {
                                this.setState({ filter: { ...filter, cpf_orcnpj_like: value.target.value.length <= 14 ? cpfMask(value.target.value) : cnpjMask(value.target.value) } })
                              }
                            }}
                            value={'cpf_orcnpj_like' in filter ? filter.cpf_orcnpj_like : ''}
                            autoComplete="off"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="3">
                        <FormGroup>
                          <FormControl variant="outlined" style={{ width: '100%' }}>
                            <Select
                              value={filter.status_acesso}
                              onChange={value => {
                                this.setState({ filter: { ...filter, status_acesso: value.target.value } });
                              }}
                              multiple
                            >
                              <MenuItem value={1}>Ativo</MenuItem>
                              <MenuItem value={6}>Irregular (Após 5 dias)</MenuItem>
                              <MenuItem value={7}>Inadimplente (Após 30 dias)</MenuItem>
                              <MenuItem value={10}>Bloqueado (Após 60 dias)</MenuItem>
                              <MenuItem value={2}>Inativo (Após 180 dias)</MenuItem>

                              <MenuItem value={8}>Desligado</MenuItem>
                              <MenuItem value={5}>Suspenso</MenuItem>
                              <MenuItem value={3}>Falecido</MenuItem>
                              {!isNaoSocio && <MenuItem value={9}>Licenciado (Por 6 meses)</MenuItem>}
                            </Select>
                          </FormControl>
                        </FormGroup>
                      </Col>

                      {!isNaoSocio && (
                        <>
                          <Col lg="4">
                            <FormGroup>
                              <FormControl variant="outlined" style={{ width: '100%' }}>
                                <Select
                                  value={'profile' in filter ? filter.profile.id : []}
                                  onChange={value => {
                                    this.setState({ filter: { ...filter, profile: { id: value.target.value } } });
                                  }}
                                  multiple
                                >
                                  <MenuItem value={2}>Associado</MenuItem>
                                  <MenuItem value={3}>Dependente</MenuItem>
                                </Select>
                              </FormControl>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <FormControl variant="outlined" style={{ width: '100%' }}>
                                <Select
                                  value={'categoria' in filter ? filter.categoria : []}
                                  onChange={value => {
                                    this.setState({ filter: { ...filter, categoria: value.target.value } });
                                  }}
                                  multiple
                                >
                                  {this.state.categorias.map(item => (
                                    <MenuItem key={item.id} value={item.id}>{item.nome}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </FormGroup>
                          </Col>
                        </>
                      )}

                    </Row>
                  </Filter>
                </CardHeader>
                {this.renderContent()}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

}

export default withSnackbar(Associados);
