import moment from "moment";

const replaceAt = (value, index, replacement) => {
  if (index >= value.length) {
    return value.valueOf();
  }

  return value.substring(0, index) + replacement + value.substring(index + 1);
}

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

const addMask = (value, mask) => {
  if (value) {
    for (let i = 0; i < value.length; i++) {
      const pos = mask.indexOf('#');
      mask = replaceAt(mask, pos, value[i]);
    }

    return mask;
  }
}

const addMaskMoney = (value, decimal = 2) => {
  if (value) {
    value = isNumeric(value) ? value.toString() : value;

    if(value.split('.').length === 2 && value.split('.')[1].length === 1) {
      value = value + "0";
    }

    value = value.indexOf('.') === -1 ? value + '00' : value;
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '');
    const options = { minimumFractionDigits: decimal };
    const result = new Intl.NumberFormat('pt-BR', options).format(parseFloat(value) / 100);
    return 'R$ ' + result;
  }

  return 'R$ 0,00';
}

const addMaskWithPrefix = (value, prefix, decimal = 2) => {
  if (value) {
    if (isNumeric(value))
      return prefix + ' ' + value.toFixed(decimal).toString().replace('.', ',');

    return prefix + ' ' + value.toString().replace('.', ',');
  }
  else {
    if (isNumeric(value))
      return prefix + ' ' + value.toFixed(decimal).toString().replace('.', ',');
  }
}

const addMaskWithSuffix = (value, suffix, decimal = 2) => {
  if (value) {
    if (isNumeric(value))
      return value.toFixed(decimal).toString().replace('.', ',') + ' ' + suffix;

    return value.toString().replace('.', ',') + ' ' + suffix;
  }
  else {
    if (isNumeric(value))
      return value.toFixed(decimal).toString().replace('.', ',') + ' ' + suffix;
  }
}

const addPhoneMask = (value) => {
  if (value) {
    if (value.length === 10)
      return addMask(value, '(##) ####-####');
    else if (value.length === 11)
      return addMask(value, '(##) #####-####');

    return value;
  }
}

const dateFormat = (date) => {
  return moment(date).format('DD/MM/YYYY');
}

const datetimeFormat = (date) => {
  return moment(date).format('DD/MM/YYYY HH:mm:ss');
}

function __t(text) {
  return text ? text : '-';
}

export {
  addMask,
  addMaskMoney,
  addMaskWithPrefix,
  addMaskWithSuffix,
  addPhoneMask,
  dateFormat,
  datetimeFormat,
  __t
}
