/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

import { Avatar as Avatars, ButtonGroup } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import Config from "Config";
import { withSnackbar } from "notistack";

import moment from 'moment';

import WebcamCapture from "views/components/WebcamCapture";
import { b64toBlob, existIn } from "Config";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Media from "reactstrap/lib/Media";

import Rodal from 'rodal';
// include styles
import { validateEmail } from "Config";
import { BoxLoading } from "react-loadingg";
import 'rodal/lib/rodal.css';
import api from "services/api";
import PhotoEditor from "utils/AvatarEditor";
import { getCorStatusAssociado } from "utils/StatusAssociado";
import FormButtonAction from "views/components/FormButtonAction";
import FormFields from "./Form/FormFields";
import { jsonToUrlFilter } from "utils/filter";
import { handleDeleteFile } from "utils/file";
import { handleUploadArrayFile } from "utils/file";
import { isValidCPF, isValidCNPJ } from "utils/Validations";
import phoneMask from "utils/phoneMask";
import ButtonControl from "views/components/ButtonControl";

const HISTORY_BACK = window.location.pathname.indexOf('nao-socios') >= 0 ? '/admin/nao-socios' : '/admin/associado';

class AssociadoCrud extends React.Component {
  state = {
    numeroTitulo: '',
    name: "",
    emails: [],
    telefones: [],
    enderecos: [],
    sexo: 0,
    estadoCivil: 0,
    statusAcesso: 1,
    categoria: 1,
    categoriaComplete: [],
    listaCarteirinhas: [],
    listaTelefone: [{
      id: null,
      tipo: 0,
      telefone: null
    }],
    listaEnderecos: [],
    observacoes: "",
    foto: null,
    loadSelect: false,
    dataVeiculos: [],
    veiculo: [],
    loading: true,
    files: [],
    filesRemovidos: [],
    isNaoSocio: window.location.pathname.indexOf('nao-socios') >= 0 ? true : false
  };

  async componentDidMount() {
    let user = JSON.parse(window.localStorage.getItem(Config._USER));

    if (!user) {
      window.localStorage.removeItem(Config._USER);
      this.props.history.replace('/auth');
      return;
    }

    this.setState({ loading: true });
    await this.findAllCategoria();

    if (this.props.match.params.uk) {
      await this.findBy();
    }
    else if("ukAssociado" in this.props.match.params) {
      let associadoStatus = 1;
      const responseAssociado = await api.get(`/api/user/${this.props.match.params.ukAssociado}`);
      associadoStatus = responseAssociado.data.statusAcesso;

      this.setState({
        statusAcessoAtual: existIn(associadoStatus, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]) ? associadoStatus : 1,
        statusAcesso: existIn(associadoStatus, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]) ? associadoStatus : 1
      });
    }

    this.setState({ loading: false });
  }

  findAllCategoria = async () => {
    const response = await api.get("/api/categoria");

    this.setState({
      categoriaComplete: response.status === 200 ? response.data : [],
    })
  }

  findBy = async () => {
    const response = await api.get(`/api/user/${this.props.match.params.uk}`);
    let associadoStatus = 1;

    if("ukAssociado" in this.props.match.params) {
      const responseAssociado = await api.get(`/api/user/${this.props.match.params.ukAssociado}`);
      associadoStatus = responseAssociado.data.statusAcesso;
    }

    let enderecoToState = {};
    if (response.status === 200) {
      response.data["_dtNascimento"] = moment(response.data["dtNascimento"]).format('YYYY-MM-DD');
      response.data["_dtExpedicao"] = moment(response.data["dtExpedicao"]).format('YYYY-MM-DD');
      response.data["_dtAssociacao"] = moment(response.data["dtAssociacao"]).format('YYYY-MM-DD');
      response.data["estadoCivil"] = existIn(response.data['estadoCivil'], [1, 2, 3, 4, 5, 6]) ? response.data['estadoCivil'] : 1;
      response.data["statusAcesso"] = existIn(response.data['statusAcesso'], [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]) ? response.data['statusAcesso'] : 1;
      response.data["statusAcessoAtual"] = existIn(associadoStatus, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]) ? associadoStatus : 1;
      response.data["sexo"] = existIn(response.data['sexo'], [1, 2, 3]) ? response.data['sexo'] : 1;

      if (response.data["listaEmails"] && response.data["listaEmails"].length > 0) {
        for (let i = 0; i <= response.data["listaEmails"].length - 1; i++) {
          let tempListaEmails = response.data["listaEmails"][i]
          enderecoToState['master-email-' + i] = tempListaEmails['principal'];
          enderecoToState['email-' + i] = tempListaEmails['email'];
        }
      }
      if (!response.data["listaTelefone"] || response.data["listaTelefone"].length <= 0) {
        response.data["listaTelefone"] = [{
          tipo: 0,
          telefone: ""
        }]
      }

      if (!response.data["listaEnderecos"] || response.data["listaEnderecos"].length <= 0) {
        response.data["listaEnderecos"] = [{
          bairro: null,
          cep: null,
          cidade: null,
          complemento: null,
          estado: 24,
          id: null,
          logradouro: null,
          numero: null,
          principal: false,
          tipo: null,
          uk: null,
        }]
      }
    }

    let responseFiles = await api.get(`/api/bucket?${jsonToUrlFilter({ owner: this.props.match.params.uk, key: "documentos" })}`);
    this.setState({ files: responseFiles.data, loading: false })

    this.setState({
      ...response.data,
      ...enderecoToState,
    })
  }

  handleSave = async (usuarioDesligar) => {
    let temp = this.state;
    const { ukAssociado } = this.props.match.params;
    this.setState({ loading: false });

    if(temp.isNaoSocio) {
      temp['profile'] = { id: 4 };
    }
    else {
      temp['profile'] = { id: !ukAssociado ? 2 : 3 };
      temp['associadoUk'] = ukAssociado;
    }

    if (!ukAssociado && temp.profile.id !== 4 && temp.numeroTitulo.length === 0) {
      this.props.enqueueSnackbar("Preencha o Número do Titulo");
      return;
    }

    if (!temp.name || (temp.name.trim() === "")) {
      this.props.enqueueSnackbar("Preencha o Nome Completo")
      return;
    }

    if (!ukAssociado && (!temp.cpfOrcnpj || (temp.cpfOrcnpj.trim() === ""))) {
      this.props.enqueueSnackbar("Preencha o CPF ou CNPJ")
      return;
    }

    if (!temp._dtNascimento || !moment(temp._dtNascimento).isValid()) {
      this.props.enqueueSnackbar("Preencha uma data de Nascimento válida")
      return;
    }

    if (!temp.sexo || !existIn(temp.sexo, [1, 2, 3])) {
      this.props.enqueueSnackbar("Preencha um valor para Sexo corretamente")
      return;
    }

    if (!temp.estadoCivil || !existIn(temp.estadoCivil, [1, 2, 3, 4, 5, 6])) {
      this.props.enqueueSnackbar("Preencha um valor para Estado Cívil corretamente")
      return;
    }

    // preencha ao menos um email
    if(!ukAssociado) {
      if ((!temp.email || (temp.email.trim() === ""))) {
        this.props.enqueueSnackbar("Preencha um E-mail")
        return;
      }

      if (!validateEmail(temp.email)) {
        this.props.enqueueSnackbar("Preencha um E-mail Válido")
        return;
      }
    }

    if (!this.props.match.params.uk) {
      if (!temp.senha || (temp.senha.trim() === "")) {
        this.props.enqueueSnackbar("Preencha uma Senha")
        return;
      }
    }

    if(!temp.isNaoSocio) {
      if (!temp._dtAssociacao || (temp._dtAssociacao.trim() === "")) {
        this.props.enqueueSnackbar("Preencha a Data de Associação")
        return;
      }
    }

    if (moment(temp['_dtNascimento']).isValid()) {
      temp['dtNascimento'] = moment(temp['_dtNascimento']).format('YYYY-MM-DD') + "T00:00:00"
    }
    if (moment(temp['dtExpedicao']).isValid()) {
      temp['dtExpedicao'] = moment(temp['_dtExpedicao']).format('YYYY-MM-DD') + "T00:00:00"
    }
    if (moment(temp['dtAssociacao']).isValid()) {
      temp['dtAssociacao'] = moment(temp['_dtAssociacao']).format('YYYY-MM-DD') + "T00:00:00"
    }

    temp['foto'] = null;

    delete temp['files'];
    delete temp['filesRemovidos'];

    let saveUser = true;
    this.setState({ loading: true });

    if(!!usuarioDesligar) {
      const responseUserUpdate = await api.put("/api/user", usuarioDesligar);
      await api.get(`/api/integracoes/khronos/user/${usuarioDesligar.id}`);
      saveUser = !!('data' in responseUserUpdate)
      temp.unidadeSuperlogica = usuarioDesligar.unidadeSuperlogica;
    }

    let dataUser = JSON.stringify(temp);

    if(saveUser) {
      fetch(Config._HOST + "/api/user",
        {
          body: dataUser,
          method: this.props.match.params.uk ? 'PUT' : 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
        .then(response => response.json())
        .then(async data => {
          if (data.status === 200) {
            const dataSend = new FormData();

            await handleDeleteFile(this.state.filesRemovidos);
            await handleUploadArrayFile(data.data.uk, 'documentos', this.state.files);

            if (this.sendFile(dataSend)) {
              await this.handleUploadfile(data.data.id, data.data.uk, dataSend, false);
            }
            else {
              this.props.enqueueSnackbar(data.mensagem, { variant: 'success' });

              if(temp.profile.id !== 4) {
                const responseKhronos = await api.get(`/api/integracoes/khronos/user/${data.data.id}`);
                this.props.enqueueSnackbar(responseKhronos ? 'Integrado com sucesso na Khronos!' : 'Erro ao integrar na Khronos!', { variant: responseKhronos ? 'success' : 'error' });

                if(!ukAssociado) {
                  const responseSuperlogica = await api.get(`/api/integracoes/superlogica/user/${data.data.id}`);
                  this.props.enqueueSnackbar(responseSuperlogica ? 'Integrado com sucesso no Superlogica!' : 'Erro ao integrar no Superlogica!', { variant: responseSuperlogica ? 'success' : 'error' });
                }
              }

              this.setState({ loading: false })
              this.props.history.push(!!ukAssociado ? `${HISTORY_BACK}/${ukAssociado}/dependentes` : HISTORY_BACK);
            }
          }
          else {
            this.setState({ loading: false });
            this.props.enqueueSnackbar(data.mensagem, { variant: 'error' });
          }
        }).catch(e => {
          this.setState({ loading: false })
          this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.");
        });
    }
    else {
      this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.");
    }
  }

  create = async () => {
    const { numeroTitulo, id } = this.state;

    if(this.state.cpfOrcnpj.length === 14 && !isValidCPF(this.state.cpfOrcnpj)) {
      this.props.enqueueSnackbar("O campo CPF invalido!")
      return;
    }

    if(this.state.cpfOrcnpj.length === 18 && !isValidCNPJ(this.state.cpfOrcnpj)) {
      this.props.enqueueSnackbar("O campo CNPJ invalido!")
      return;
    }

    await setTimeout(async () => {
      this.setState({ loading: true });

      if(!!numeroTitulo) {
        let filter = {
          numero_titulo: numeroTitulo,
          'status_acesso!=': 8
        }

        filter = !!id ? { ...filter, 'id!=': id } : filter;
        const response = await api.get(`/api/user?${jsonToUrlFilter(filter)}`);

        if(response.data.length) {
          this.setState({ loading: false });

          await confirmAlert({
            title: 'Outro associado já está ativo com esse titulo!',
            message: `Desligar associado (${response.data[0].cpfOrcnpj}) ${response.data[0].name}?`,
            buttons: [
              { label: 'Não' },
              {
                label: 'Sim',
                onClick: async () => {
                  this.setState({ loading: true });
                  const responseUser = await api.get(`/api/user/${response.data[0].uk}`);

                  if('data' in responseUser) {
                    responseUser.data.observacoes = `TITULO TRANSFERIDO PARA "${this.state.name}" EM ${moment().format('DD/MM/YYYY')}`;
                    responseUser.data.statusAcesso = 8;
                    await this.handleSave(responseUser.data);
                    this.setState({ loading: false });
                  }
                  else {
                    this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
                  }
                }
              }
            ]
          });
        }
        else {
          await this.handleSave();
        }
      }
      else {
        await this.handleSave();
      }
    }, 500);
  }

  handleUploadfile = async (id, uk, data, onlySaveImage = true) => {
    const { ukAssociado } = this.props.match.params;
    this.setState({ loading: true });
    const responseFoto = await api.post(`/api/bucket/owner/${uk}/foto-capa`, data);

    if (responseFoto.status === 200) {
      this.props.enqueueSnackbar(uk ? "Foto atualizada com sucesso" : "Foto salva com sucesso", { variant: 'success' });
    }
    else {
      this.props.enqueueSnackbar(uk, "Ops, foto não foi salva.", { variant: 'error' });
    }

    if(!onlySaveImage) {
      const responseKhronos = await api.get(`/api/integracoes/khronos/user/${id}`);
      this.props.enqueueSnackbar(responseKhronos ? 'Integrado com sucesso na Khronos!' : 'Erro ao integrar na Khronos!', { variant: responseKhronos ? 'success' : 'error' });

      if(!ukAssociado) {
        const responseSuperlogica = await api.get(`/api/integracoes/superlogica/user/${id}`);
        this.props.enqueueSnackbar(responseSuperlogica ? 'Integrado com sucesso no Superlogica!' : 'Erro ao integrar no Superlogica!', { variant: responseSuperlogica ? 'success' : 'error' });
      }
    }

    this.setState({ loading: false });
  }

  sendFile = (dataSend) => {
    let sendFile = false;

    if (this.state.avatar) {
      dataSend.append('file', b64toBlob(this.state.avatar), Config.FOTO_CAPA);
      sendFile = true;
    }

    return sendFile ? dataSend : null;
  }

  addTelefone = () => {
    const { listaTelefone } = this.state;

    listaTelefone.push({
      tipo: 0,
      telefone: null
    })

    this.setState({ listaTelefone: listaTelefone });
  }

  addCarteirinha = () => {
    const { listaCarteirinhas } = this.state;

    listaCarteirinhas.push({
      numero: null,
      validadeExame: null,
      userEntity: { id: this.state.id }
    })

    this.setState({ listaCarteirinhas });
  }

  addEndereco = () => {
    const { listaEnderecos } = this.state;

    listaEnderecos.push({
      bairro: null,
      cep: null,
      cidade: null,
      complemento: null,
      estado: 24,
      id: null,
      logradouro: null,
      numero: null,
      principal: false,
      tipo: null,
      uk: null,
    })

    this.setState({ listaEnderecos: listaEnderecos });
  }

  removeTelefone = async (index, telefone) => {
    this.setState({ loading: true })
    if(telefone.id) await api.delete("/api/user/telefone/" + telefone.id);
    this.setState({ listaTelefone: this.state.listaTelefone.filter((single, key) => key !== index) });
    this.setState({ loading: false })
  }

  removeEndereco = async (index, endereco) => {
    this.setState({ loading: true })
    if(endereco.id) await api.delete("/api/user/endereco/" + endereco.id);
    this.setState({ listaEnderecos: this.state.listaEnderecos.filter((single, key) => key !== index) });
    this.setState({ loading: false })
  }

  handleInputChangeTelefone = (value, single, index) => {
    let { listaTelefone } = this.state;
    listaTelefone[index].telefone = phoneMask(value);
    this.setState({ listaTelefone });
  }

  handleInputChangeTelefoneTipo = (value, single, index) => {
    let { listaTelefone } = this.state;
    listaTelefone[index].tipo = value;
    this.setState({ listaTelefone });
  }

  delete() {
    const { ukAssociado } = this.props.match.params;
    this.setState({ loading: true });
    let dataUser = JSON.stringify(this.state);

    fetch(Config._HOST + "/api/user",
      {
        body: dataUser,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => response.json())
      .then(data => {
        this.props.enqueueSnackbar(data.mensagem)
        this.setState({
          loading: false
        }, () => {
          if (data.status === 200) {
            this.props.history.push(!!ukAssociado ? `${HISTORY_BACK}/${ukAssociado}/dependentes` : HISTORY_BACK);
          }
        })
      }).catch(e => {
        this.setState({
          loading: false,
        })
        this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
      })
  }

  handleChange = (value) => {
    this.setState({ ...this.state, ...value });
  }

  handleChangeDropzone = (key, value) => {
    this.setState({ [key]: value });
  }

  handleChangeTipoEndereco = (e, targetIndex) => {
    const { value } = e.target;
    let { listaEnderecos } = this.state;
    listaEnderecos[targetIndex].tipo = value;
    this.setState({ listaEnderecos });
  }

  render() {
    const { uk, ukAssociado } = this.props.match.params;
    const { isNaoSocio } = this.state;

    return (
      <>
        {this.state.loading && (
          <div className="container-load-with-margin">
            <BoxLoading />
          </div>
        )}

        <HeaderButtonGroup
          avatar={
            <>
              {this.state.makeFile ? (
                <Rodal
                  width={60}
                  height={null}
                  measure={"%"}
                  visible={this.state.makeFile}
                  onClose={() => this.setState({ makeFile: false })}
                  customStyles={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '60px 30px 40px 30px',
                    margin: '5% 20%'
                  }}
                >
                  <PhotoEditor
                    handleSave={async data => {
                      this.setState({ foto: data.image, avatar: data.image, makeFile: false }, () => {
                        if (uk) {
                          const dataSend = new FormData();
                          if (this.sendFile(dataSend)) {
                            this.handleUploadfile(this.state.id, this.state.uk, dataSend)
                          }
                        }
                      })
                    }}
                  />
                </Rodal>
              ) : (
                this.state.makeWebCam ? (
                  <WebcamCapture cancelar={() => { this.setState({ makeWebCam: false }) }} callback={(photo) => {
                    this.setState({ avatar: photo, makeWebCam: false }, () => {
                      if (uk) {
                        const dataSend = new FormData();
                        if (this.sendFile(dataSend)) {
                          this.handleUploadfile(this.state.id, this.state.uk, dataSend)
                        }
                      }
                    })
                  }} />
              ) : null)}

              <Media className="align-items-center">
                {this.state.avatar ? (
                  <Avatars
                    onClick={() => {
                      confirmAlert({
                        title: 'Deseja adicionar uma foto?',
                        message: '',
                        buttons: [
                          { label: 'Cancelar' },
                          {
                            label: 'Buscar Foto no Computador',
                            onClick: () => this.setState({ makeFile: true })
                          },
                          {
                            label: 'Foto por WebCam',
                            onClick: () => this.setState({ makeWebCam: true })
                          }
                        ]
                      })
                    }}
                    style={{ width: '200px', height: '200px' }}
                    alt={this.props.match.params.name}
                    src={this.state.avatar}
                  />
                ) : (
                  <Avatars
                    onClick={() => {
                      confirmAlert({
                        title: 'Deseja adicionar uma foto?',
                        message: '',
                        buttons: [
                          { label: 'Cancelar' },
                          {
                            label: 'Buscar Foto no Computador',
                            onClick: () => this.setState({ makeFile: true })
                          },
                          {
                            label: 'Foto por WebCam',
                            onClick: () => this.setState({ makeWebCam: true })
                          }
                        ]
                      })
                    }}
                    style={{ width: '200px', height: '200px' }}
                    alt={this.props.match.params.name}
                    src={Config._HOST + "/api/bucket/owner/" + uk + "/key/foto-capa"}
                  />
                )}
                {this.state.statusAcesso ? (
                  <span style={{
                    background: getCorStatusAssociado(this.state.statusAcesso),
                    padding: "4px",
                    borderRadius: "50%",
                    width: "35px",
                    height: "35px",
                    position: "relative",
                    left: "-55px",
                    top: "75px",
                    border: "solid 2px white",
                    boxShadow: "1px 1px 5px #363636"
                  }} />
                ) : ''}
              </Media>
            </>
          }
          component={
            <div>
              <div className="text-center">
                <ButtonGroup>
                  {!isNaoSocio ? (
                    <ButtonControl color='secondary' to={ukAssociado ? `/admin/associado/${ukAssociado}/dependentes` : '/admin/associado'}>
                      <i className="fas fa-arrow-left"></i> VOLTAR
                    </ButtonControl>
                  ) : (
                    <ButtonControl color='secondary' to={'/admin/nao-socios'}>
                      <i className="fas fa-arrow-left"></i> VOLTAR
                    </ButtonControl>
                  )}

                  {!!uk && !ukAssociado && !isNaoSocio && (
                    <ButtonControl color='secondary' to={`/admin/associado/${uk}/dependentes`}>
                      <i className="fas fa-users"></i> DEPENDENTES
                    </ButtonControl>
                  )}

                  {!!uk && !ukAssociado && (
                    <ButtonControl color='secondary' to={`/admin/${isNaoSocio ? 'nao-socios' : 'associado'}/historico-financeiro/${uk}`}>
                      <i className="fas fa-file-alt"></i> HISTÓRICO FINANCEIRO
                    </ButtonControl>
                  )}

                  {!!uk && !ukAssociado && !isNaoSocio && (
                    <ButtonControl color='secondary' to={`/admin/associado/historico-reservas/${uk}`}>
                      <i className="fas fa-file-alt"></i> HISTÓRICO RESERVAS
                    </ButtonControl>
                  )}

                  {!!uk && !ukAssociado && !isNaoSocio && (
                    <ButtonControl color='secondary' to={`/admin/associado/certificados/${uk}`}>
                      <i className="fas fa-medal"></i> CERTIFICADOS
                    </ButtonControl>
                  )}

                </ButtonGroup>
              </div>
            </div>
          }
          titulo={(uk ? this.state.name : "")
          }
        />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {!isNaoSocio ? (uk ? "Atualizar Associado" : "Cadastrar Novo Associado") : (uk ? "Atualizar Não Sócio" : "Cadastrar Novo Não Sócio") }
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <FormFields {...this} handleChange={this.handleChange} isDependente={!!ukAssociado} />
                  <FormButtonAction create={this.create} cancel={!!ukAssociado ? `${HISTORY_BACK}/${ukAssociado}/dependentes` : HISTORY_BACK} history={this.props.history} isEdit={uk} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

}

export default withSnackbar(AssociadoCrud);
