import moment from 'moment';
import React, { Component } from 'react';
import { Col, Row, Input, FormGroup } from 'reactstrap';
import Dropzone from 'views/components/Dropzone';

export default class FormFields extends Component {
  render() {
    const { state, handleChange } = this.props;

    return (
      <>
        <div className="mt-margin-left mt-margin-right">
          <Row>
            <Col lg="4">
              <FormGroup className="mt-margin-bottom">
                <label className="form-control-label">Nome</label>
                <Input
                  name="nome"
                  type="text"
                  placeholder="Nome"
                  onChange={(value) => handleChange('nome', value.target.value.toUpperCase())}
                  value={state.nome ? state.nome : ''}
                />
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-username">Enviar Notificação</label>
                <Input
                  type="datetime-local"
                  value={moment(state.dataPublicacao).format('YYYY-MM-DDTHH:mm:ss')}
                  onChange={(value) => handleChange('dataPublicacao', value.target.value)}
                  className="form-control"
                />
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup className="mt-margin-bottom">
                <label className="form-control-label">Mensagem</label>
                <Input
                  type="textarea"
                  name="mensagem"
                  rows={10}
                  value={state.mensagem ? state.mensagem : ''}
                  onChange={(value) => handleChange('mensagem', value.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <hr className="my-4 mt-margin-large-top"></hr>
          <h6 className="heading-small text-muted mb-4">Botão</h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="4">
                <FormGroup className="mt-margin-bottom">
                  <label className="form-control-label">Texto do Botão</label>
                  <Input
                    name="textoBotao"
                    type="text"
                    placeholder="Texto do Botão"
                    onChange={(value) => handleChange('textoBotao', value.target.value.toUpperCase())}
                    value={state.textoBotao ? state.textoBotao : ''}
                  />
                </FormGroup>
              </Col>
              <Col lg="8">
                <FormGroup className="mt-margin-bottom">
                  <label className="form-control-label">Link do botão</label>
                  <Input
                    name="linkBotao"
                    type="text"
                    placeholder="Link do botão"
                    onChange={(value) => handleChange('linkBotao', value.target.value)}
                    value={state.linkBotao ? state.linkBotao : ''}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
          <hr className="my-4 mt-margin-large-top"></hr>
          <h6 className="heading-small text-muted mb-4">Anexo</h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="12">
                <Dropzone {...this.props} />
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}
