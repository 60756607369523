import React from "react";

const DescricaoStatusInscricao = status => {
  switch (status) {
    case 'Confirmado':
      return <span><i className="fas fa-check-circle mt-margin-xsmall-right" style={{ color: 'green' }}></i>{status}</span>;
    case 'Aguardando Pagamento':
      return <span><i className="fas fa-dollar-sign mt-margin-xsmall-right" style={{ color: '#318a99' }}></i>{status}</span>;
    case 'Aguardando Aprovação':
      return <span><i className="far fa-clock mt-margin-xsmall-right" style={{ color: 'darkblue' }}></i>{status}</span>;
    case 'Cancelado':
      return <span><i className="fas fa-times-circle mt-margin-xsmall-right" style={{ color: 'red' }}></i>{status}</span>;
    case 'Reprovado':
      return <span><i className="fas fa-times-circle mt-margin-xsmall-right" style={{ color: '#c50000' }}></i>{status}</span>;
    default:
      return '';
  }
};

export { DescricaoStatusInscricao };
