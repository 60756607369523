// import { FormControl, MenuItem, Select } from '@material-ui/core';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import React, { Component } from 'react';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import Dropzone from "views/components/Dropzone";

export default class FormFields extends Component {
  render() {
    const { state, handleChange } = this.props;

    return (
      <>
        <div className="mt-margin-left mt-margin-right">
          <Row>
            <Col lg="3">
              <FormGroup>
                <FormControl variant="outlined">
                  <label className="form-control-label">Tipo de Reunião</label>
                  <Select
                    value={state.tipoReuniao}
                    onChange={(value) => handleChange('tipoReuniao', value.target.value)}
                  >
                    <MenuItem value="Ordinária">Ordinária</MenuItem>
                    <MenuItem value="Extraordinária">Extraordinária</MenuItem>
                  </Select>
                </FormControl>
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <FormControl variant="outlined">
                  <label className="form-control-label">Tipo de Órgão</label>
                  <Select
                    value={state.tipoOrgao}
                    onChange={(value) => handleChange('tipoOrgao', value.target.value)}
                  >
                    <MenuItem value="Executivo">Executivo</MenuItem>
                    <MenuItem value="Deliberativo">Deliberativo</MenuItem>
                    <MenuItem value="Fiscal">Fiscal</MenuItem>
                    <MenuItem value="Assembleia Geral">Assembleia Geral</MenuItem>
                  </Select>
                </FormControl>
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <label className="form-control-label">Data</label>
                <Input
                  className="form-control"
                  type="date"
                  onChange={value => handleChange('data', value.target.value)}
                  value={state.data}
                />
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label">Observação</label>
                <Input
                  className="form-control"
                  placeholder="Observação"
                  type="textarea"
                  rows="5"
                  onChange={value => handleChange('observacao', value.target.value.toUpperCase())}
                  value={state.observacao}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <hr className="my-4 mt-margin-large-top"></hr>
        <h6 className="heading-small text-muted mb-4">Anexo</h6>
        <div className="pl-lg-4">
          <Row>
            <Col lg="12">
              <Dropzone {...this.props} maxFiles={1} acceptedFiles=".pdf" />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
