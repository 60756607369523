import React, { Component } from 'react';
import AvatarEditor from "react-avatar-editor";
import Dropzone from 'react-dropzone';
import { Button } from 'reactstrap';

class PhotoEditor extends Component {
  state = {
    foto: null,
    zoom: 1,
    rotate: 0
  }

  getImage = () => {
    const image = this.editor.getImageScaledToCanvas().toDataURL();
    const { zoom, rotate } = this.state;

    return { image, zoom, rotate }
  };

  setEditorRef = editor => {
    if (editor) {
      this.editor = editor;
    }
  };

  render() {
    const { foto, zoom, rotate } = this.state;

    return (
      <>
        {foto && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: 85
            }}
          >
            <AvatarEditor
              ref={this.setEditorRef}
              width={280}
              height={280}
              image={foto}
              style={{ paddingBottom: 30 }}
              scale={zoom}
              rotate={rotate}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <button
                style={{ cursor: 'pointer' }}
                className="mt-margin-bottom"
                onClick={() => {
                  this.setState({ rotate: parseFloat(rotate + 90) });
                }}
              >
                <i className="fas fa-redo" />
              </button>
              <button
                style={{ cursor: 'pointer' }}
                className="mt-margin-bottom"
                onClick={() => {
                  this.setState({ rotate: parseFloat(rotate - 90) });
                }}
              >
                <i className="fas fa-undo" />
              </button>
              <input
                name="scale"
                type="range"
                onChange={event => this.setState({ zoom: parseFloat(event.target.value) })}
                value={zoom}
                style={{ transform: 'rotate(270deg)', width: 100, height: 140 }}
                max={2}
                min={1}
                step={0.01}
              />
            </div>
          </div>
        )}

        <div id="box-container" style={{ width: '90%', height: '90%' }}>
          <Dropzone
            onDrop={(dropped) => {
              this.setState({ foto: dropped[0] })
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                className="upload"
                {...getRootProps()}
                style={{
                  width: foto ? 'auto' : '100%',
                  height: foto ? 'auto' : '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <input {...getInputProps()} />
                <p>Clique aqui ou arraste seus arquivos</p>
              </div>
            )}
          </Dropzone>

          {foto && (
            <Button color="default" className="mt-margin-top" style={{ width: "100%" }} onClick={() => this.props.handleSave(this.getImage())}>
              <i className="fas fa-check" style={{ marginRight: 5 }}></i>
              {"CONFIRMAR"}
            </Button>
          )}
        </div>
      </>
    );
  }
}

export default PhotoEditor;
