/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Card, CardHeader, CardBody, Form, Container, Row, Col } from "reactstrap";
import Config from "Config";
import { withSnackbar } from "notistack";
import { ButtonGroup } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import FormFields from "./Form/FormFields";
import FormButtonAction from "views/components/FormButtonAction";
import { handleUploadArrayFile } from "utils/file";
import api from "services/api";
import { findAllDocumentoCategorias } from "utils/find";
import { handleDeleteFile } from "utils/file";
import { jsonToUrlFilter } from "utils/filter";
import ButtonControl from "views/components/ButtonControl";

const HISTORY_BACK = '/admin/documentos';
const ROUTE_API = '/api/documentos';

class DocumentosCrud extends React.Component {
  state = {
    // categoria: {id: 0, nome: ""},
    categorias: [],
    files: [],
    filesRemovidos: []
  }

  async componentDidMount() {
    let user = JSON.parse(window.localStorage.getItem(Config._USER));

    if (!user) {
      window.localStorage.removeItem(Config._USER);
      this.props.history.replace('/auth');
      return;
    }

    const response = await findAllDocumentoCategorias();
    this.setState({ categorias: response.data });

    if (this.props.match.params.id)
      this.findBy(this.props.match.params.id);

    this.setState({ session: user });
  }

  findBy = async (id) => {
    this.setState({ loading: true })

    let response = await api.get(`${ROUTE_API}/${id}`);
    this.setState({
      ...response,
      categoria: { value: response.categoria.id, label: response.categoria.nome }
    });
    let responseFiles = await api.get(`/api/bucket?${jsonToUrlFilter({ owner: response.uk })}`);
    this.setState({ files: responseFiles.data, loading: false })
  }

  create = async (id) => {
    if (!this.state.categoria) {
      this.props.enqueueSnackbar("Selecione uma categoria de documento!")
      return;
    }
    let files = this.state.files;
    if (files.length === 0) {
      this.props.enqueueSnackbar("Envie um arquivo!");
      return;
    }
    await handleDeleteFile(this.state.filesRemovidos);
    let temp = this.state;
    temp.files = [];
    temp.categoria = { id: this.state.categoria.value, nome: this.state.categoria.label }
    this.setState({ loading: true });
    (id ? api.put(`${ROUTE_API}/${id}`, temp) : api.post(`${ROUTE_API}/`, temp))
      .then(async (response) => {
        this.props.enqueueSnackbar(id ? "Atualizado com sucesso!" : "Cadastrado com sucesso!");
        if (files.length > 0) {
          this.props.enqueueSnackbar(await handleUploadArrayFile(response.uk, 'documento', files) === 200 ? "Upload Realizada com sucesso" : "Falha no upload do arquivo.")
        }
        this.setState({ loading: false }, () => {
          this.props.history.push(HISTORY_BACK);
        })
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
        this.setState({ loading: false });
      });
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  }

  render() {
    return (
      <>

        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={HISTORY_BACK}>
                    <i className="fas fa-arrow-left"></i> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={this.props.match.params.id ? "EDITAR DOCUMENTO" : "NOVO DOCUMENTO"}
          subTitulo={"Gerenciamento de Documento"}
        />


        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {this.props.match.params.id ? "Atualizar Documento" : "Cadastrar Novo Documento"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormFields {...this} />
                    <FormButtonAction create={() => this.create(this.props.match.params.id)} cancel={HISTORY_BACK} history={this.props.history} isEdit={this.props.match.params.id} />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(DocumentosCrud);
