import React, { Component } from 'react';
import { Col, FormGroup, Input, Row } from 'reactstrap';

export default class FormFields extends Component {

  render() {
    const { state, handleChange } = this.props;

    return (
      <>
        <div className="mt-margin-left mt-margin-right">
          <Row>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">Placa</label>
                <Input
                  className="form-control"
                  placeholder="Placa"
                  type="text"
                  onChange={value => handleChange({ placa: value.target.value.toUpperCase() })}
                  value={state.placa}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">Cor</label>
                <Input
                  className="form-control"
                  placeholder="Cor"
                  type="text"
                  onChange={value => handleChange({ cor: value.target.value.toUpperCase() })}
                  value={state.cor}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">Marca</label>
                <Input
                  className="form-control"
                  placeholder="Marca"
                  type="text"
                  onChange={value => handleChange({ marca: value.target.value.toUpperCase() })}
                  value={state.marca}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">Modelo</label>
                <Input
                  className="form-control"
                  placeholder="Modelo"
                  type="text"
                  onChange={value => handleChange({ modelo: value.target.value.toUpperCase() })}
                  value={state.modelo}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">Ano</label>
                <Input
                  className="form-control"
                  placeholder="Ano"
                  type="number"
                  onChange={value => handleChange({ ano: value.target.value.toUpperCase() })}
                  value={state.ano}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
