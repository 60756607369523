import { Component } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { Col, FormGroup, Input, Row } from 'reactstrap';

export default class FormFields extends Component {
  render() {
    const { state, handleChange } = this.props;

    return (
      <div className="mt-margin-left mt-margin-right">
        <Row className="mt-margin-medium-bottom">
          <Col lg="12">
            <FormGroup>
              <label className="form-control-label">Descrição</label>
              <Input
                name="descricao"
                type="text"
                placeholder="Descrição"
                className="form-control"
                value={state.descricao ? state.descricao : ''}
                autoComplete="off"
                onChange={(value) => handleChange('descricao', value.target.value.toUpperCase())}
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <label className="form-control-label">Data Início</label>
              <Input
                type="date"
                format="YYYY-MM-DD"
                className="form-control"
                value={state.data_inicio ? state.data_inicio : ''}
                onChange={(value) => handleChange('data_inicio', value.target.value)}
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <label className="form-control-label">Data Fim</label>
              <Input
                type="date"
                format="YYYY-MM-DD"
                className="form-control"
                value={state.data_fim ? state.data_fim : ''}
                onChange={(value) => handleChange('data_fim', value.target.value)}
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <FormControl variant="outlined">
                <label className="form-control-label">Dias da semana</label>
                <Select
                  value={state.diasSemana}
                  onChange={value => handleChange('diasSemana', value.target.value)}
                  multiple={true}
                >
                  <MenuItem value='segunda'>Segunda</MenuItem>
                  <MenuItem value='terca'>Terça</MenuItem>
                  <MenuItem value='quarta'>Quarta</MenuItem>
                  <MenuItem value='quinta'>Quinta</MenuItem>
                  <MenuItem value='sexta'>Sexta</MenuItem>
                  <MenuItem value='sabado'>Sábado</MenuItem>
                  <MenuItem value='domingo'>Domingo</MenuItem>
                </Select>
              </FormControl>
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <FormControl variant="outlined">
                <label className="form-control-label">Ambiente</label>
                <Select
                  value={state.ambientes}
                  onChange={value => handleChange('ambientes', value.target.value)}
                  multiple={true}
                >
                  {state.dataAmbientes.map(ambiente => {
                    return <MenuItem key={ambiente.id} value={ambiente.id}>{ambiente.nome}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </FormGroup>
          </Col>

        </Row>
      </div>
    );
  }
}
