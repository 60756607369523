import { FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { Component } from 'react';
import IntlCurrencyInput from "react-intl-currency-input";
import { Col, FormGroup, Row } from 'reactstrap';

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export default class Financeiro extends Component {
  render() {
    const { state, handleChange } = this.props;

    return (
      <div className="mt-margin-left mt-margin-right">
        <Row>

          <Col lg="12">
            <FormGroup>
              <label className="form-control-label"> Valor aluguel associado</label>
              <IntlCurrencyInput
                className="form-control"
                currency="BRL"
                config={currencyConfig}
                value={state.valorAluguelAssociado ? state.valorAluguelAssociado : ''}
                onChange={(_, value) => handleChange("valorAluguelAssociado", value)}
              />
            </FormGroup>
          </Col>

          <Col lg="6">
            <FormControl className="mt-margin-top" variant="outlined">
              <label className="form-control-label">Conta</label>
              <Autocomplete
                options={state.caixas}
                getOptionLabel={option => option.nome}
                onChange={(_, value) => handleChange("caixa", value)}
                inputMode="text"
                key="caixas"
                value={state.caixa}
                multiple={false}
                renderOption={option => <span>{option.nome}</span>}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Selecione uma categoria"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Col>

          <Col lg="6">
            <FormControl className="mt-margin-top" variant="outlined">
              <label className="form-control-label">Categoria</label>
              <Autocomplete
                options={state.categorias}
                getOptionLabel={option => `(${option.codigo}) ${option.nome}`}
                onChange={(_, value) => handleChange("categoria", value)}
                inputMode="text"
                key="categorias"
                value={state.categoria}
                multiple={false}
                renderOption={option => <span>({option.codigo}) {option.nome}</span>}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Selecione uma categoria"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Col>
        </Row>
      </div>
    );
  }
}
