import Config from "Config";
import moment from 'moment';
import React, { Component } from 'react';
import { default as InputDropzone } from 'react-dropzone';
import { Button, Col, Row } from 'reactstrap';
import Swal from 'sweetalert2';

export default class Dropzone extends Component {
  onDrop = (files) => {
    const { handleChange } = this.props;

    if ("maxFiles" in this.props && this.props.state.files.length > 0) {
      const { files, filesRemovidos } = this.props.state;
      handleChange("files", files.filter((_, key) => key !== 0));
      handleChange("filesRemovidos", filesRemovidos.concat(files.filter((_, key) => key === 0)));
    }

    const filesState = [
      ...this.props.state.files,
      ...files.map(file => Object.assign(file, {
        file: file,
        preview: URL.createObjectURL(file),
        dtCriacao: moment()
      }))
    ];

    handleChange("files", filesState);
  };

  getIconFile = (file) => {
    let extension = file.name.split(".");
    if (extension.length) {
      extension = extension[extension.length - 1]

      if (['xlsx', 'xls'].indexOf(extension) >= 0)
        return <i className="fas fa-file-excel" />
      else if (['jpg', 'png'].indexOf(extension) >= 0)
        return <i className="fas fa-file-image" />
      else if (['csv'].indexOf(extension) >= 0)
        return <i className="fas fa-file-csv" />
      else if (['pdf'].indexOf(extension) >= 0)
        return <i className="fas fa-file-pdf" />
      else if (['zip', 'rar'].indexOf(extension) >= 0)
        return <i className="fas fa-file-archive" />
      else if (['docx', 'doc'].indexOf(extension) >= 0)
        return <i className="fas fa-file-word" />
      else
        return <i className="fas fa-file" />
    }
  }

  handleRemove = (index) => {
    const { handleChange } = this.props;

    Swal.fire({
      title: 'Você tem certeza que deseja excluir?',
      text: "Você não poderá reverter isso depois!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, excluir!',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { files, filesRemovidos } = this.props.state;

        handleChange("files", await files.filter((_, key) => key !== index));
        handleChange("filesRemovidos", await filesRemovidos.concat(files.filter((_, key) => key === index)));
      }
    })
  }

  render() {
    return (
      <div id="box-container">
        <InputDropzone accept={this.props.acceptedFiles} onDrop={this.onDrop} maxFiles={"maxFiles" in this.props ? this.props.maxFiles : 0}>
          {({ getRootProps, getInputProps }) => (
            <div className="upload" {...getRootProps()}>
              <input {...getInputProps()} />
              <p>Clique aqui ou arraste seus arquivos</p>
            </div>
          )}
        </InputDropzone>

        <ul>
          {this.props.state.files.map((file, key) => {
            return (
              <li key={key}>
                <Row style={{ width: "100%" }}>
                  <Col sm="9">
                    <a href={file.preview} className="fileInfo" target="_blank" rel="noopener noreferrer">
                      {this.getIconFile(file)}
                      <strong>{file.name}</strong>
                    </a>
                    <span className="mt-float-left">
                      {file.dtCriacao && moment(file.dtCriacao).format('DD/MM/YYYY HH:mm:ss')}
                    </span>
                  </Col>
                  <Col sm="3" className="mt-text-right">
                    {!!file.id && (
                      <a className="btn btn-icon-only remove-file" href={`${Config._HOST}/api/bucket/id/${file.id}`}>
                        <i className="fas fa-download color-green" style={{ cursor: "pointer", color: "#219c0a" }} />
                      </a>
                    )}
                    <Button type="button" color="" style={{ backgroundColor: "transparent" }} className="btn-icon-only remove-file" onClick={() => this.handleRemove(key)}>
                      <i className="fas fa-trash color-red" style={{ cursor: "pointer", color: "#c31616" }} />
                    </Button>
                  </Col>
                </Row>
              </li>
            )
          })}
        </ul>
      </div>
    );
  }
}
