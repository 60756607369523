import { ButtonGroup } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import moment from "moment";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import { Card, CardBody, CardHeader, Col, Container, Form, Row } from "reactstrap";
import api from "services/api";
import { jsonToUrlFilter } from "utils/filter";
import FormButtonAction from "views/components/FormButtonAction";
import FormFields from "./Form";
import ButtonControl from "views/components/ButtonControl";

class FinanceiroAcordoCRUD extends Component {

  state = {
    tipo_pagamento: 1,
    parcelas_pendentes: [],
    parcelas_origem: [],
    parcelas_acordo: [],
    usuarios: [],
    usuario: null,
    contas: [],
    conta: null,
    numero_parcelas: 1,
    desconto: 0,
    multa: 0,
    juros: 0,
    data_acordo: moment(),
    data_acordo_realizado: moment(),
  }

  async componentDidMount() {
    await this.setState({ loading: true });
    await this.findAllConta();
    await this.findAcordos();
    await this.setState({ loading: false });
  }

  findAcordos = async () => {
    const { id } = this.props.match.params;

    if(!!id) {
      const response = await api.get(`/api/financeiro/acordos/${id}`);

      if (response) {
        let temp = { ...response };

        const parcelas_acordo = temp.financeiro.parcelamentos.map(item => {
          if(temp.financeiro.numeroParcelas > 1) {
            item.descricao = `Parcela ${item.parcela} de ${temp.financeiro.numeroParcelas}`;

            if(!!item.observacao && item.observacao.indexOf('Entrada referente ao acordo') >= 0) {
              item.descricao += ' (Entrada)';
            }
          }
          else {
            item.descricao = 'Parcela Única';
          }

          return item;
        });

        parcelas_acordo.sort((a,b) => a.parcela - b.parcela);

        this.setState({
          tipo_pagamento: temp.financeiro.tipoPagamento.id,
          parcelas_pendentes: temp.parcelas_origem,
          parcelas_origem: temp.parcelas_origem,
          parcelas_acordo,
          usuarios: [temp.financeiro.cliente],
          usuario: temp.financeiro.cliente,
          contas: [temp.financeiro.cx],
          conta: temp.financeiro.cx.id,
          vencimento_primeira_parcela: temp.financeiro.parcelamentos[0].dtVencimento,
          numero_parcelas: temp.financeiro.numeroParcelas,
          desconto: temp.financeiro.desconto,
          multa: temp.financeiro.multa,
          juros: temp.financeiro.juros,
          data_acordo: temp.data_acordo,
          data_acordo_realizado: moment(temp.data_acordo),
        })
      }
    }
  }

  searchUsuarios = async (search) => {
    if (!search) return;

    this.setState({ loadUsuarios: true });
    const response = await api.get(`/api/user?name_like=${search}&profile.id=2`);
    const response2 = await api.get(`/api/user?numero_titulo_like=${search}&profile.id=2`);

    if (response && response2) {
      this.setState({
        usuarios: [...response.data, ...response2.data],
        loadUsuarios: false,
      })
    }
    else {
      this.setState({ loadUsuarios: false })
    }
  }

  getParcelaAtraso = async usuario => {
    const filter = {
      financeiro_contas_entity: {
        cliente: { id: usuario.id }
      },
      status: [0,3],
      'dt_vencimento<': moment().format('YYYY-MM-DD'),
      _order: 'asc',
      _sort: 'dt_vencimento',
      _limit: 100
    }
    const response = await api.get(`/api/documento-agenda/parcelamentos?${jsonToUrlFilter(filter)}`);
    this.setState({
      parcelas_pendentes: response.data,
      usuario,
      parcelas_origem: [],
      parcelas_acordo: [],
      numero_parcelas: 1,
      desconto: 0,
      multa: 0,
      juros: 0,
      data_acordo: moment(),
      data_acordo_realizado: moment(),
    });
  }

  findAllConta = async () => {
    const response = await api.get('/api/financeiro/caixa?ativo=true');
    const responseContaPrincipal = await api.get('/api/financeiro/caixa?ativo=true&principal=true');

    if (response) {
      this.setState({
        contas: response.data,
        conta: responseContaPrincipal.data.length > 0 ? responseContaPrincipal.data[0].id : null
      })
    }
  }

  create = async () => {
    this.setState({ loading: true });

    const data = {
      ...this.state,
      conta: { id: this.state.conta },
      tipo_pagamento: { id: this.state.tipo_pagamento }
    };

    delete data.parcelas_pendentes;

    data.financeiro = {
      type: "receber",
      descricao: "ACORDO REALIZADO DIA " + this.state.data_acordo.format('DD/MM/YYYY'),
      recorrencia: "Parcelado",
      tipoPagamento: { id: this.state.tipo_pagamento },
      numeroParcelas: this.state.parcelas_acordo.length,
      cliente: this.state.usuario,
      dp: { id: 1 },
      ct: { id: 179 },
      cx: { id: this.state.conta },
      vlTotal: this.state.valor_total,
      desconto: this.state.desconto,
      multa: this.state.multa,
      juros: this.state.juros,
    }

    const response = await api.post(`/api/financeiro/acordos`, data);

    if(!response || ('data' in response && 'error' in response.data && 'status' in response.data)) {
      this.setState({ loading: false });
      this.props.enqueueSnackbar("Ops, ocorreu um erro!");
      return;
    }

    this.setState({ loading: false });
    this.props.enqueueSnackbar("Acordo realizado com sucesso!");
    this.props.history.push(`/admin/financeiro/acordo`);
  }

  handleChangeParcelasAcordo = async (valorTotal, multaTotal, jurosTotal) => {
    const { numero_parcelas, vencimento_primeira_parcela, entrada } = this.state;
    const valorParcela = parseFloat((parseFloat((valorTotal + multaTotal + jurosTotal - (!!entrada ? entrada : 0)).toFixed(2)) / numero_parcelas).toFixed(2));
    let parcelas_acordo = [];
    let count_parcelas = 0;
    let valor_total = 0;

    if(!!entrada) {
      valor_total += entrada;

      parcelas_acordo.push({
        dtVencimento: moment().format('YYYY-MM-DDT00:00:00'),
        valorPagamento: entrada,
        status: 1,
        parcela: ++count_parcelas,
        descricao: `Parcela 1 de ${parseInt(numero_parcelas)+count_parcelas} (Entrada)`,
        observacao: "Entrada referente ao acordo",
        dtRecebimento: moment().format('YYYY-MM-DDT00:00:00'),
        valorPagamentoRecebido: entrada
      });
    }

    for (let i = count_parcelas; i < parseInt(numero_parcelas)+count_parcelas; i++) {
      valor_total += valorParcela;

      console.log(moment(vencimento_primeira_parcela).subtract(-1*i, "months").endOf('month').format('YYYY-MM-DD'));
      parcelas_acordo.push({
        dtVencimento: moment(vencimento_primeira_parcela).subtract(-1*i, "months").endOf('month').format('YYYY-MM-DDT00:00:00'),
        valorPagamento: parseFloat(valorParcela.toFixed(2)),
        status: 0,
        parcela: i+1,
        descricao: parseInt(numero_parcelas)+count_parcelas > 1 ? `Parcela ${i+1} de ${parseInt(numero_parcelas)+count_parcelas}` : 'Parcela Única'
      });
    }

    valor_total = parseFloat(valor_total.toFixed(2))
    let valorResto = parseFloat(((valorTotal + multaTotal + jurosTotal) - valor_total).toFixed(2));

    parcelas_acordo[parcelas_acordo.length-1].valorPagamento += valorResto;
    await this.setState({ parcelas_acordo, valor_total });
  }

  handleChange = async data => {
    await this.setState(data);
  }

  render() {
    const { loading, valor_total, parcelas_acordo } = this.state;
    const { id } = this.props.match.params;
    const disabled = !!id ? true : false;

    let valorTotalParcela = parseFloat((parcelas_acordo.reduce((accumulator, item) => accumulator + item.valorPagamento, 0)).toFixed(2));
    const valorTotalDevido = !!valor_total ? parseFloat(valor_total).toFixed(2) : 0;
    valorTotalParcela = !!valorTotalParcela ? valorTotalParcela : 0;

    return (
      <>
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to='/admin/financeiro/acordo'>
                    <i className="fas fa-arrow-left" /> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={'Acordo'}
          subTitulo={'Módulo de Gestão para acordos'}
        />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {!!id ? 'Visualizar acordo' : 'Novo acordo'}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <BoxLoading />
                  ) : (
                    <Form>
                      <FormFields {...this} disabled={disabled} />

                      {!disabled && (
                        <FormButtonAction
                          isEdit={!!id}
                          create={this.create}
                          cancel={`/admin/financeiro/acordo`}
                          history={this.props.history}
                          isDisabled={
                            this.state.parcelas_acordo.length === 0 ||
                            this.state.parcelas_origem.length === 0 ||
                            this.state.numero_parcelas === 0 ||
                            !this.state.vencimento_primeira_parcela ||
                            parseFloat((valorTotalDevido - valorTotalParcela).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]) > 0.01
                          }
                        />
                      )}
                    </Form>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(FinanceiroAcordoCRUD);
