/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";

// reactstrap components
import { CardHeader, Table, Container, Row, Card } from "reactstrap";
import Config from "Config";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { ButtonGroup } from "@material-ui/core";
import { withSnackbar } from "notistack";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from "react-confirm-alert";
import { jsonToUrlFilter } from "utils/filter";
import ButtonControl from "views/components/ButtonControl";

class Usuarios extends Component {
  state = {
    data: [],
  };

  componentDidMount() {
    this.findAll()
  }

  findAll = () => {
    this.setState({ loading: true })

    const params = {
      _limit: 100,
      profile: {
        id_not_in: [2, 3, 4]
      }
    };

    fetch(Config._HOST + `/api/user?${jsonToUrlFilter(params)}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          data: 'data' in response ? response.data : [],
          loading: false,
        })
      }).catch(e => {
        this.setState({ loading: false });
      })
  }

  handleRemove = (id) => {
    confirmAlert({
      title: 'Deseja Deletar o cadastro?',
      message: '',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim', onClick: () => {
            this.setState({ loading: true })

            fetch(Config._HOST + `/api/user/${id}`,
              {
                method: 'DELETE',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                }
              },
            )
              .then(response => response.json())
              .then(data => {
                if (data) {
                  const dataUpdated = this.state.data.filter(row => row.id !== id);
                  this.setState({ data: dataUpdated });
                }

                this.props.enqueueSnackbar(data ? "Deletado com sucesso!" : 'Ops, ocorreu um erro!')
                this.setState({ loading: false })
              })
              .catch(e => {
                this.setState({ loading: false })
                this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.")
              })

          }
        }
      ]
    })

  }

  renderContent() {
    if (this.state.loading) {
      return <BoxLoading />
    }

    if (this.state.data.length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Não Existem Usuários cadastrados</h3>
        </CardHeader>
      );
    }

    return (
      <div>
        <Table className="align-items-center table-flush table-with-button" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">NOME</th>
              <th scope="col">EMAIL</th>
              <th scope="col">DESCRIÇÃO</th>
              <th scope="col">Status</th>
              <th scope="col">AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map(single => {
              return (
                <tr key={single.id}>
                  <th>{single.name ? single.name : "-"}</th>
                  <th>{single.email ? single.email : "-"}</th>
                  <th>{single.profile ? single.profile.descricao : "-"}</th>
                  <th>{single.statusAcesso === 1 ? "Ativo" : "Inativo" }</th>
                  <td>
                    <ButtonControl color='secondary' to={`/admin/usuarios/${single.uk}/editar`}>
                      <i className="fas fa-edit"></i>
                    </ButtonControl>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    return (
      <>
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to='/admin/usuarios/novo'>
                    <i className="fas fa-plus"></i> NOVO
                  </ButtonControl>
                  <ButtonControl color='secondary' to='/admin/usuarios/perfis'>
                    <i className="fas fa-users-cog"></i> PERFIS
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"Usuários"}
          subTitulo={"Módulo de Gestão para Usuários"}
        />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Total de Usuários ({this.state.data.length})</h3>
                </CardHeader>

                {this.renderContent()}

              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(Usuarios);
