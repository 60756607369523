import React from 'react';
import { ReactFormGenerator, ElementStore } from 'views/components/react-form-builder';

export default class Preview extends React.Component {

  state = {
    data: [],
    previewVisible: false,
  };

  _onUpdate = this._onChange.bind(this);

  componentDidMount() {
    ElementStore.subscribe(state => this._onUpdate(state.data));
  }

  showPreview() {
    this.setState({
      previewVisible: true,
    });
  }

  closePreview() {
    this.setState({ previewVisible: false });
  }

  _onChange(data) {
    this.setState({ data });
    this.props.handleChange({ form: data });
  }

  render() {
    let modalClass = 'modal';

    if (this.state.previewVisible) {
      modalClass += ' show d-block';
    }

    return (
      <div className="clearfix mt-margin-bottom" style={{ width: '75%' }}>
        <h4 className="float-left mt-margin-small-top">Formulário</h4>
        <button type="button" className="btn btn-primary float-right" onClick={this.showPreview.bind(this)}>
          <i className='fas fa-eye mt-margin-xsmall-right'/>
          Visualizar
        </button>

        {this.state.previewVisible && (
          <div className={modalClass} style={{ background: '#000000a3' }}>
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className='mt-padding'>

                  <div className="modal-footer mt-padding-remove">
                    <button type="button" className="btn" data-dismiss="modal" onClick={this.closePreview.bind(this)}>
                      <i className='fas fa-times'/>
                    </button>
                  </div>
                  <ReactFormGenerator
                    download_path=""
                    back_action="/"
                    back_name="Voltar"
                    answer_data={{}}
                    action_name="Salvar"
                    form_action="/"
                    form_method="POST"
                    hide_actions
                    variables={this.props.variables}
                    data={this.state.data}
                  />

                </div>

              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
