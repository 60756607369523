/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { ButtonGroup, FormControl, FormGroup, MenuItem, Select, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Filter from "views/components/Filter";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { onChangeCheckbox } from "Config";
import moment from "moment";
import { withSnackbar } from "notistack";
import numeral from 'numeral';
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { BoxLoading, WaveLoading } from "react-loadingg";
import { Button, Card, CardHeader, Col, Container, Input, Row, Table } from "reactstrap";
import api from "services/api";
import ButtonExport from "utils/ButtonExport";
import { jsonToUrlFilter } from "utils/filter";
import { DescricaoStatusPagamento } from "utils/StatusPagamento";
import { addMaskMoney } from "utils/Text/Mask";
import ButtonControl from "views/components/ButtonControl";

class FinanceiroList extends Component {

  state = {
    data: [],
    categorias: [],
    associados: [],
    fornecedores: [],
    checked: [],
    checkAll: true,
    loading: false,
    loadingMore: false,
    categoria: '',
    cliente: '',
    fornecedor: '',
    numeroParcelas: 0,
    total_valor_pagamento: null,
    total_valor_pago: null,
    total_valor_multa_juros: null,
    filter: {
      data_inicio: moment().startOf('month').format('YYYY-MM-DD'),
      data_fim: moment().endOf('month').format('YYYY-MM-DD'),
      status: [0],
      emitir_boleto_via: ['Viacredi','Superlogica'],
      financeiro_contas_entity: {
        type: this.props.match.params.type,
      },
      _page: 1,
      _limit: 50,
      _order: 'desc',
      _sort: "dt_vencimento",
    },
  };

  async componentDidMount() {
    this.defaultFilter = { ...this.state.filter };
    await this.findContas();
    await this.findCategorias();

    if(this.props.match.params.type === 'receber') {
      await this.findAssociados();
    }
    else {
      await this.findFornecedores();
    }
  }

  getValorTotal = async filter => {
    filter = { ...filter, _limit: 99999999 }
    this.setState({ total_valor_pagamento: null, total_valor_pago: null, total_valor_multa_juros: null });
    let total_valor_pagamento = 0, total_valor_pago = 0, total_valor_multa_juros = 0;
    const responseTotal = await api.get(`api/documento-agenda/parcelamentos?${jsonToUrlFilter(filter)}`);

    if(responseTotal) {
      responseTotal.data.forEach(parcela => {
        const valorPagamento = parcela.valorPagamento != null ? parseFloat(parcela.valorPagamento.toFixed(2)) : 0;
        const valorPagamentoRecebido = parcela.valorPagamentoRecebido != null ? parseFloat(parcela.valorPagamentoRecebido.toFixed(2)) : 0;
        total_valor_pagamento += valorPagamento
        total_valor_pago += valorPagamentoRecebido

        if(valorPagamentoRecebido > 0 && valorPagamento !== valorPagamentoRecebido) {
          total_valor_multa_juros +=  parseFloat((valorPagamentoRecebido - valorPagamento).toFixed(2))
        }
      })
    }

    this.setState({ total_valor_pagamento, total_valor_pago, total_valor_multa_juros });
  }

  getContas = async (filter, data, removeData) => {
    const response = await api.get(`api/documento-agenda/parcelamentos?${jsonToUrlFilter(filter)}`);

    if (response) {
      if (removeData) {
        this.setState({
          ...response,
          loading: false,
          loadingMore: false,
          checked: []
        })
      }
      else {
        this.setState({
          ...response,
          data: [...data, ...response.data],
          loading: false,
          loadingMore: false
        })
      }
    }
    else {
      this.setState({ loading: false, loadingMore: false })
    }
  }

  findContas = async (removeData = true) => {
    const { data, filter, loading } = this.state;
    if (loading) return;

    await this.setState({ loading: removeData, loadingMore: !removeData });

    if (removeData && filter._page > 1) {
      await this.setState({ filter: { ...filter, _page: 1 } })
    }

    let tmpFilter = { ...filter };

    tmpFilter[`${filter._sort}>=`] = filter.data_inicio + " 00:00:00";
    tmpFilter[`${filter._sort}<=`] = filter.data_fim + " 23:59:59";
    delete tmpFilter['data_inicio'];
    delete tmpFilter['data_fim'];

    if(this.props.match.params.type === 'pagar') {
      delete tmpFilter['emitir_boleto_via'];
    }

    this.getContas(tmpFilter, data, removeData);
    this.getValorTotal(tmpFilter);
  }

  findCategorias = async () => {
    const filter = {
      _limit: 999,
      _order: 'ASC',
      _sort: "nome",
      ativo: true
    }

    const response = await api.get(`/api/financeiro/categoria?${jsonToUrlFilter(filter)}`);
    this.setState({ categorias: response.data });
  }

  findAssociados = async () => {
    const filter = {
      _limit: 99999,
      _order: 'ASC',
      _sort: "name",
      profile: { id: [2] },
    }

    const response = await api.get(`/api/user?${jsonToUrlFilter(filter)}`);
    this.setState({ associados: response.data });
  }

  findFornecedores = async () => {
    const filter = {
      _limit: 99999,
      _order: 'ASC',
      _sort: "nome"
    }

    const response = await api.get(`/api/fornecedor?${jsonToUrlFilter(filter)}`);
    this.setState({ fornecedores: response.data });
  }

  loadMore = () => {
    const { filter } = this.state;

    this.setState({
      filter: {
        ...filter,
        _page: this.state.filter._page + 1
      }
    }, () => {
      this.findContas(false);
    });
  }

  handleLimpar = () => {
    this.setState({
      categoria: '',
      cliente: '',
      fornecedor: '',
      filter: {
        ...this.defaultFilter
      },
    }, () => {
      this.findContas();
    });
  }

  handleAlterarStatus = status => {
    setTimeout(() => {
      this.setState({
        modal: {
          dtRecebimento: status === 1 ? moment().format('YYYY-MM-DD') : null,
          observacao: ''
        }
      }, () => {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="react-confirm-alert-body">
                <h1>{"Mudar status para '" + (status === 1 ? "Confirmado" : "Cancelado") + "'?"}</h1>
                Todas as cobranças selecionas mudarão de status.
                <Row className="mt-margin-top mt-margin-bottom">

                  {status === 1 && (
                    <Col xs="12">
                      <FormGroup>
                        <label className="form-control-label">Data Pagamento</label>
                        <Input
                          type="date"
                          format="YYYY-MM-DD"
                          className="form-control"
                          onChange={value => this.setState({ modal: { ...this.state.modal, dtRecebimento: value.target.value } })}
                          defaultValue={this.state.modal.dtRecebimento}
                        />
                      </FormGroup>
                    </Col>
                  )}

                  <Col xs="12">
                    <FormGroup>
                      <label className="form-control-label">Observação</label>
                      <Input
                        type="textarea"
                        className="form-control"
                        rows={5}
                        onChange={value => this.setState({ modal: { ...this.state.modal, observacao: value.target.value } })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="react-confirm-alert-button-group">
                  <button onClick={onClose}>Não</button>
                  <button
                    onClick={async () => {
                      const { modal } = this.state;

                      if(status === 1 && modal.dtRecebimento.length === 0) {
                        this.props.enqueueSnackbar("preencha o campo 'Data Pagamento'.");
                        return;
                      }

                      if(modal.observacao.length === 0) {
                        this.props.enqueueSnackbar("preencha o campo 'Observação'.");
                        return;
                      }

                      this.setState({ loading: true });
                      onClose();

                      this.setState({ loading: true });
                      const response = await api.put('/api/financeiro/contas/status', { status: status, uks: this.state.checked, ...this.state.modal });
                      setTimeout(() => {
                        this.findContas();
                      }, 300);

                      this.setState({ loading: false });
                      this.props.enqueueSnackbar(response.mensagem);
                    }}
                  >
                    Sim
                  </button>
                </div>
              </div>
            );
          }
        })
      });
    }, 300);

  }

  exportData = (data, type) => {
    const { type: tipoConta } = this.props.match.params;

    data = {
      ...data,
      _limit: 10000,
      [`${data._sort}>=`]: data.data_inicio + " 00:00:00",
      [`${data._sort}<=`]: data.data_fim + " 23:59:59"
    };

    if(tipoConta === 'receber') {
      data['_view'] = {
        template: 3,
        title: `VALORES RECEBIDOS - ${moment(data.data_inicio).format('DD/MM/YYYY')} A ${moment(data.data_fim).format('DD/MM/YYYY')}`,
        head: 'Cliente, Categoria, Valor Parcela, Vencimento, Emissão do Boleto Via, Status, Valor Pago, Pago Dia, Créditado na Conta',
        body: 'financeiroContasEntity.cliente.name,financeiroContasEntity.ct.nome,valorPagamento,dtVencimento:date,emitirBoletoVia,status:statusPagamento,valorPagamentoRecebido,dtRecebimento:date,dtCreditoConta:date'
      };
    }
    else {
      data['_view'] = {
        template: 3,
        title: `VALORES PAGOS - ${moment(data.data_inicio).format('DD/MM/YYYY')} A ${moment(data.data_fim).format('DD/MM/YYYY')}`,
        head: 'Fornecedor, Categoria, Valor Parcela, Vencimento, Status, Pago Dia',
        body: 'financeiroContasEntity.fornecedor.nome,financeiroContasEntity.ct.nome,valorPagamento,dtVencimento:date,status:statusPagamento,dtRecebimento:date'
      };
    }

    if(type === "pdf") {
      data['_view'] = { ...data['_view'], orientacao: 'landscape' }
    }

    delete data['data_inicio'];
    delete data['data_fim'];

    return data;
  }

  renderContent() {
    const { type } = this.props.match.params;
    const { data, length, total_pages, filter, loading } = this.state;

    if (loading) return <BoxLoading />;

    if (length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Não existem Contas a {type === 'receber' ? 'Receber' : 'Pagar'}</h3>
        </CardHeader>
      )
    }

    return (
      <div>
        <Table className="align-items-center table-flush table-with-button" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">
                <div className="custom-control custom-checkbox" style={{ top: -10 }}>
                  <input className="custom-control-input" id="select-all" type="checkbox" onChange={(value) => onChangeCheckbox(value, "select-all", this)} checked={!this.state.checkAll} />
                  <label className="custom-control-label" htmlFor="select-all" />
                </div>
              </th>
              <th scope="col">{type === 'receber' ? 'SÓCIO' : 'FORNECEDOR'}</th>
              <th scope="col">CATEGORIA</th>
              <th scope="col">VALOR PARCELA</th>
              <th scope="col">VALOR TOTAL</th>
              <th scope="col">VENCIMENTO</th>
              <th scope="col">EMISSÃO DO BOLETO VIA</th>
              <th scope="col">STATUS</th>
              <th scope="col">BOLETO</th>
              <th scope="col">VALOR PAGO</th>
              <th scope="col">PAGO DIA</th>
              <th scope="col">CRÉDITADO NA CONTA</th>
              <th scope="col">JUROS/MULTA</th>
              <th scope="col">AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {data.map(single => {
              return (
                <tr key={single.id}>
                  <td>
                    <div className="custom-control custom-checkbox" style={{ top: -12 }}>
                      <input className="custom-control-input" id={single.uk} type="checkbox" checked={(this.state.checked.some(value => value === single.uk))} onChange={(value) => onChangeCheckbox(value, single.uk, this)} />
                      <label className="custom-control-label" htmlFor={single.uk} />
                    </div>
                  </td>
                  <td>
                    {single.financeiroContasEntity.cliente && single.financeiroContasEntity.cliente.name ? (
                      <a href={`/admin/financeiro/contas/${type}/${single.financeiroContasEntity.uk}/editar`} target='_blank' rel="noopener noreferrer" style={{ color: "#525f7f" }}>
                        {single.financeiroContasEntity.cliente.name}
                      </a>
                    ) : single.financeiroContasEntity.fornecedor && single.financeiroContasEntity.fornecedor.nome ? (
                      <a href={`/admin/financeiro/contas/${type}/${single.financeiroContasEntity.uk}/editar`} target='_blank' rel="noopener noreferrer" style={{ color: "#525f7f" }}>
                        {single.financeiroContasEntity.fornecedor.nome}
                      </a>
                    ) : "-"}
                  </td>
                  <td>
                    {single.financeiroContasEntity.ct && single.financeiroContasEntity.ct.nome}
                  </td>
                  <td>
                    {numeral(single.valorPagamento).format('$ 0,0.00')}
                    <span style={{ fontSize: '11px', marginLeft: '5px' }}>{single.financeiroContasEntity.numeroParcelas > 1 && ` (Parcela ${single.parcela} de ${single.financeiroContasEntity.numeroParcelas})`}</span>
                  </td>
                  <td>{numeral(single.financeiroContasEntity.vlTotal).format('$ 0,0.00')}</td>

                  <td>{single.dtVencimento ? moment(single.dtVencimento).format('DD/MM/YYYY') : '-'}</td>
                  <td>{single.emitirBoletoVia && single.emitirBoletoVia.toUpperCase()}</td>
                  <td><DescricaoStatusPagamento status={single.status} /></td>
                  <th>
                    {single.linkBoleto !== null && (
                      <a href={single.linkBoleto} target='_blank' rel="noopener noreferrer" style={{ color: '#525f7f' }}>
                        <i className="fas fa-barcode mt-margin-xsmall-right"></i>
                        Boleto
                      </a>
                    )}
                  </th>
                  <td>{numeral(single.valorPagamentoRecebido).format('$ 0,0.00')}</td>
                  <td>{single.dtRecebimento ? moment(single.dtRecebimento).format('DD/MM/YYYY') : '-'}</td>
                  <td>{single.dtCreditoConta ? moment(single.dtCreditoConta).format('DD/MM/YYYY') : '-'}</td>
                  <td>{(single.valorPagamentoRecebido && parseFloat(single.valorPagamentoRecebido.toFixed(2)) > parseFloat(single.valorPagamento.toFixed(2))) ? numeral(single.valorPagamentoRecebido - single.valorPagamento).format('$ 0,0.00') : '-'}</td>
                  <td>
                    <div className="avatar-group">
                      <ButtonControl color='secondary' to={`/admin/financeiro/contas/${type}/${single.financeiroContasEntity.uk}/editar`} target='_blank' rel="noopener noreferrer">
                        <i style={{ fontSize: 20 }} className="fa fa-eye" aria-hidden="true" />
                      </ButtonControl>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>

        {filter._page < total_pages && (
          <Button style={{ backgroundColor: "#3f51b5", color: "white", width: '100%', height: "45px" }} disabled={this.state.loadingMore} variant="contained" onClick={this.loadMore}>
            BUSCAR MAIS {this.state.loadingMore && <BoxLoading />}
          </Button>
        )}
      </div>
    )
  }

  render() {
    const { filter, total_data, categorias, categoria, associados, cliente, fornecedores, fornecedor, loading } = this.state;
    const { type } = this.props.match.params;

    return (
      <>
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to="/admin/financeiro">
                    <i className="fas fa-arrow-left" /> VOLTAR
                  </ButtonControl>
                  <ButtonControl color='secondary' to={`/admin/financeiro/contas/${type}/novo`}>
                    <i className="fas fa-plus" /> NOVO
                  </ButtonControl>
                  {type === 'receber' && (
                    <ButtonControl color='secondary' to={`/admin/financeiro/acordo`}>
                      <i className="fas fa-handshake"></i> ACORDO
                    </ButtonControl>
                  )}
                  <ButtonControl color='secondary' to={`/admin/financeiro/recorrencia/${type}`}>
                    <i className="fas fa-redo"></i> RECORRÊNCIA
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={`CONTAS A ${type === 'receber' ? 'RECEBER' : 'PAGAR'}`}
          subTitulo={`Controle completo das contas a ${type === 'receber' ? 'Receber' : 'Pagar'}`}
        />
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="12">
              <Card className="shadow">
                <CardHeader className="border-1">
                  <h3 style={{ float: "left" }} className="mb-0">Total {type === 'receber' ? 'a Receber' : 'a Pagar'} ({total_data ? total_data : 0})</h3>

                  {!!total_data && (
                    <>
                      <ButtonExport notification={this.props.enqueueSnackbar} name={`Contas a ${type === 'receber' ? 'receber' : 'pagar'}`} type="pdf" url={'/api/documento-agenda/parcelamentos/export/pdf?' + jsonToUrlFilter(this.exportData(filter, 'pdf'))} />
                      <ButtonExport notification={this.props.enqueueSnackbar} name={`Contas a ${type === 'receber' ? 'receber' : 'pagar'}`} type="excel" url={'/api/documento-agenda/parcelamentos/export/excel?' + jsonToUrlFilter(this.exportData(filter, 'excel'))} />
                    </>
                  )}

                  {this.state.checked.length > 0 && (
                    <Button className="btn-icon-only text-light btn-sm mt-margin-small-left" color="filter" onClick={() => {
                      confirmAlert({
                        title: "Mudar status da cobrança",
                        message: 'Todas as cobranças selecionadas serão alterada.',
                        buttons: [
                          {
                            label: 'Pagamento Confirmado',
                            className: 'btn-success',
                            onClick: () => this.handleAlterarStatus(1)
                          },
                          {
                            label: 'Pagamento Cancelado',
                            className: 'btn-danger',
                            onClick: () => this.handleAlterarStatus(2)
                          }
                        ]
                      })
                    }}>
                      <i className="fas fa-exchange-alt" style={{ cursor: "pointer", color: "#505050" }} />
                    </Button>
                  )}

                  <Filter handleLimpar={this.handleLimpar} handlePesquisar={this.findContas}>
                    <Row>
                      <Col lg="3">
                        <FormControl variant="outlined">
                          <Select
                            value={filter._sort}
                            onChange={value => this.setState({ filter: { ...filter, _sort: value.target.value } })}
                          >
                            <MenuItem value="dt_vencimento">Data de Vencimento</MenuItem>
                            <MenuItem value="dt_recebimento">Data de Recebimento</MenuItem>
                            <MenuItem value="dt_credito_conta">Data de Crédito na Conta</MenuItem>
                          </Select>
                        </FormControl>
                      </Col>
                      <Col lg="2">
                        <input format="YYYY-MM-DD" type="date" className="form-control" value={filter.data_inicio} onChange={value => this.setState({ filter: { ...filter, data_inicio: value.target.value } })} />
                      </Col>
                      <span style={{ display: 'flex', alignItems: 'center' }}>Até</span>
                      <Col lg="2">
                        <input format="YYYY-MM-DD" type="date" className="form-control" value={filter.data_fim} onChange={value => this.setState({ filter: { ...filter, data_fim: value.target.value } })} />
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <FormControl variant="outlined" style={{ width: '100%' }}>
                            <Select
                              value={filter.status}
                              onChange={value => this.setState({ filter: { ...filter, status: value.target.value } })}
                              multiple={true}
                            >
                              <MenuItem value={0}>Aguardando Pagamento</MenuItem>
                              <MenuItem value={1}>Confirmado</MenuItem>
                              <MenuItem value={2}>Cancelado</MenuItem>
                              <MenuItem value={3}>Vencido</MenuItem>
                              <MenuItem value={4}>Em Acordo</MenuItem>
                              <MenuItem value={5}>Agrupado na Cobrança Mensal</MenuItem>
                            </Select>
                          </FormControl>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-margin-top">
                      <Col lg="4">
                        <FormControl variant="outlined">
                          <Autocomplete
                            options={categorias}
                            getOptionLabel={option => `(${option.codigo}) ${option.nome}`}
                            onChange={(_, item) => {
                              if (item) {
                                this.setState({ filter: { ...filter, financeiro_contas_entity: { ...filter.financeiro_contas_entity, ct: { id: item.id } } } })
                              }
                              else {
                                const financeiro_contas_entity = Object.keys(filter.financeiro_contas_entity).filter(key => key !== 'ct').reduce((result, current) => {
                                  result[current] = filter.financeiro_contas_entity[current];
                                  return result;
                                }, {});

                                this.setState({ filter: { ...filter, financeiro_contas_entity } });
                              }
                            }}
                            inputMode="text"
                            multiple={false}
                            value={filter.financeiro_contas_entity.ct}
                            inputValue={categoria}
                            onInputChange={(_, newInputValue) => {
                              this.setState({ categoria: newInputValue });
                            }}
                            renderOption={(option) => (
                              <span>({option.codigo}) {option.nome}</span>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Selecione uma categoria"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Col>
                      {type === 'receber' ? (
                        <>
                          <Col lg="5">
                            <FormControl variant="outlined">
                              <Autocomplete
                                options={associados}
                                getOptionLabel={option => `(${option.numeroTitulo}) ${option.name}`}
                                onChange={(_, item) => {
                                  if (item) {
                                    this.setState({ filter: { ...filter, financeiro_contas_entity: { ...filter.financeiro_contas_entity, cliente: { id: item.id } } } })
                                  }
                                  else {
                                    const financeiro_contas_entity = Object.keys(filter.financeiro_contas_entity).filter(key => key !== 'cliente').reduce((result, current) => {
                                      result[current] = filter.financeiro_contas_entity[current];
                                      return result;
                                    }, {});

                                    this.setState({ filter: { ...filter, financeiro_contas_entity } });
                                  }
                                }}
                                inputMode="text"
                                multiple={false}
                                value={filter.financeiro_contas_entity.cliente}
                                inputValue={cliente}
                                onInputChange={(_, newInputValue) => {
                                  this.setState({ cliente: newInputValue });
                                }}
                                renderOption={(option) => (
                                  <span>({option.numeroTitulo}) {option.name}</span>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Selecione um Associado"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <>
                                          {params.InputProps.endAdornment}
                                        </>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </Col>
                          <Col lg="3">
                            <FormControl variant="outlined">
                              <Select
                                value={filter.emitir_boleto_via}
                                onChange={value => this.setState({ filter: { ...filter, emitir_boleto_via: value.target.value } })}
                                multiple={true}
                              >
                                <MenuItem value="Viacredi">Viacredi</MenuItem>
                                <MenuItem value="Superlogica">Superlogica</MenuItem>
                              </Select>
                            </FormControl>
                          </Col>
                        </>
                      ) : (
                        <Col lg="8">
                          <FormControl variant="outlined">
                            <Autocomplete
                              options={fornecedores}
                              getOptionLabel={option => `${option.nome}`}
                              onChange={(_, item) => {
                                if (item) {
                                  this.setState({ filter: { ...filter, financeiro_contas_entity: { ...filter.financeiro_contas_entity, fornecedor: { id: item.id } } } })
                                }
                                else {
                                  const financeiro_contas_entity = Object.keys(filter.financeiro_contas_entity).filter(key => key !== 'fornecedor').reduce((result, current) => {
                                    result[current] = filter.financeiro_contas_entity[current];
                                    return result;
                                  }, {});

                                  this.setState({ filter: { ...filter, financeiro_contas_entity } });
                                }
                              }}
                              inputMode="text"
                              multiple={false}
                              value={filter.financeiro_contas_entity.fornecedor}
                              inputValue={fornecedor}
                              onInputChange={(_, newInputValue) => {
                                this.setState({ fornecedor: newInputValue });
                              }}
                              renderOption={(option) => (
                                <span>{option.nome}</span>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Selecione um fornecedor"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Col>
                      )}
                    </Row>
                  </Filter>
                </CardHeader>
                {!loading && (
                  <CardHeader className="mt-margin-remove">

                    <strong className="mt-margin-xsmall-right">Valor total:</strong>
                    {this.state.total_valor_pagamento === null ? (
                      <WaveLoading size="small" style={{ position: "relative", display: "inline-flex", height: 10 }} />
                    ) : (
                      addMaskMoney(this.state.total_valor_pagamento.toFixed(2))
                    )}

                    <strong className="mt-margin-left mt-margin-xsmall-right">Valor total pago:</strong>
                    {this.state.total_valor_pago === null ? (
                      <WaveLoading size="small" style={{ position: "relative", display: "inline-flex", height: 10 }} />
                    ) : (
                      addMaskMoney(this.state.total_valor_pago.toFixed(2))
                    )}

                    <strong className="mt-margin-left mt-margin-xsmall-right">Valor total de juros/multa:</strong>
                    {this.state.total_valor_multa_juros === null ? (
                      <WaveLoading size="small" style={{ position: "relative", display: "inline-flex", height: 10 }} />
                    ) : (
                      addMaskMoney(this.state.total_valor_multa_juros.toFixed(2))
                    )}

                  </CardHeader>
                )}
                {this.renderContent()}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(FinanceiroList);
