import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Button, Col, Row } from 'reactstrap';

export default class FormButtonAction extends Component {
  render() {
    const { isEdit, isDisabled, cancel, create, history } = this.props;

    return (
      <>
        <hr className="my-4 mt-margin-large-top" />

        <div className="mt-margin-left mt-padding-right" style={{ marginTop: "10px" }}>
          <Row>
            <Col lg="6">
              <Button color="warning" style={{ width: "100%" }} onClick={() => { history.push(cancel) }}>
                CANCELAR
              </Button>
            </Col>
            <Col lg="6">
              <Button
                color="default"
                style={{ width: "100%" }}
                disabled={!!isDisabled}
                onClick={() => {
                  confirmAlert({
                    title: isEdit
                      ? 'Deseja confirmar a atualização?'
                      : 'Deseja confirmar o cadastro?',
                    message: '',
                    buttons: [
                      { label: 'Não' },
                      { label: 'Sim', onClick: create }
                    ]
                  })
                }
              }>
                <i className="fas fa-check" style={{ marginRight: 5 }} />
                {isEdit ? "ATUALIZAR" : "CADASTRAR"}
              </Button>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
