const getStatusReserva = status => {
  switch (parseInt(status, 10)) {
    case 0:
      return 'Aguardando Pagamento';
    case 1:
      return 'Confirmado';
    case 2:
      return 'Cancelado';
    case 3:
      return 'Pagamento Atrasado';
    case 4:
      return 'Pagamento Isento';
    default:
      return '';
  }
};

export { getStatusReserva };
