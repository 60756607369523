import React, { Component } from 'react';
import { Form } from 'reactstrap';
import CarteirinhaExameMedico from './CarteirinhaExameMedico';
import Enderecos from './Enderecos';
import OutrasInformacoes from './OutrasInformacoes';
import InformacoesGerais from './InformacoesGerais';
import Telefones from './Telefones';

class FormFields extends Component {
  render() {
    const { isNaoSocio } = this.props.state;
    return (
      <Form>
        <h6 className="heading-small text-muted mb-4">INFORMAÇÕES GERAIS</h6>
        <InformacoesGerais {...this.props} />

        <hr className="my-4 mt-margin-large-top" />
        <h6 className="heading-small text-muted mb-4">TELEFONE(S)</h6>
        <Telefones {...this.props} />

        <hr className="my-4 mt-margin-large-top" />
        <h6 className="heading-small text-muted mb-4">ENDEREÇO(S)</h6>
        <Enderecos {...this.props} />

        {!isNaoSocio && (
          <>
            <hr className="my-4 mt-margin-large-top" />
            <h6 className="heading-small text-muted mb-4">CARTEIRINHA E EXAME MÉDICO</h6>
            <CarteirinhaExameMedico {...this.props} />
          </>
        )}

        <hr className="my-4 mt-margin-large-top" />
        <h6 className="heading-small text-muted mb-4">OUTRAS INFORMAÇÕES</h6>
        <OutrasInformacoes {...this.props} />

      </Form>
    );
  }
}

export default FormFields;
