/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { Component } from "react";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import moment from "moment";
import { withSnackbar } from "notistack";
import numeral from "numeral";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import BoxLoading from "react-loadingg/lib/BoxLoading";
import { Badge, Button, ButtonGroup, Card, CardHeader, Container, Row, Table } from "reactstrap";
import api from "services/api";
import { jsonToUrlFilter } from "utils/filter";
import ButtonControl from "views/components/ButtonControl";

const ROUTE_API = '/api/financeiro/recorrencias';
const HISTORY_BACK = '/admin/financeiro/contas';

class FinanceiroRecorrenciaList extends Component {
  state = {
    data: [],
    totalData: 50,
    filter: {
      _page: 1,
      _limit: 50,
      _order: 'desc',
      _sort: 'data_primeira_parcela',
      deleted: false,
      tipo: this.props.match.params.type,
    },
  };

  componentDidMount() {
    this.loadMore()
  }

  loadMore = async () => {
    this.setState({ loading: true, filter: { ...this.state.filter, _page: this.state.filter._page + 1 } })
    let response = await api.get(`${ROUTE_API}?${jsonToUrlFilter(this.state.filter)}`);
    this.setState({
      data: this.state.data.concat(response.data),
      totalData: response.total_data,
      loading: false
    })
  }

  handleRemove = id => {
    confirmAlert({
      title: 'Deseja Deletar o cadastro?',
      message: '',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim', onClick: async () => {
            this.setState({ loading: true });
            const response = await api.delete(`/api/financeiro/recorrencias/${id}`);

            if(!!response) {
              const dataUpdated = this.state.data.filter(row => row.id !== id);
              this.setState({ data: dataUpdated });
              this.props.enqueueSnackbar('Deletado com sucesso!');
            }
            else {
              this.props.enqueueSnackbar('Ops, ocorreu um erro!');
            }

            this.setState({ loading: false });
          }
        }
      ]
    })
  }

  renderContent() {
    const { type } = this.props.match.params;

    if (this.state.loading) {
      return <BoxLoading />
    }

    if (this.state.data && this.state.data.length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Não existem recorrência cadastrada</h3>
        </CardHeader>
      );
    }

    return (
      <div>
        <Table className="align-items-center table-flush table-with-button" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">DESCRIÇÃO</th>
              <th scope="col">{type === 'receber' ? 'CLIENTE' : 'FORNECEDOR'}</th>
              <th scope="col">CATEGORIA</th>
              <th scope="col">VENCIMENTO DA PRIMEIRA PARCELA</th>
              <th scope="col">VALOR</th>
              <th scope="col">AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data && this.state.data.map(item => {
              return (
                <tr key={item.id}>
                  <td>{item.descricao}</td>
                  <td>
                    {!!item.usuarios && item.usuarios.length > 0 ? (
                      <a href={`/admin/financeiro/contas/${type}/${item.id}`} target='_blank' rel="noopener noreferrer" style={{ color: "#525f7f" }}>
                        {item.usuarios[0].name}
                        {item.usuarios.length > 1 && (
                          <Badge color="info" className="mt-margin-small-left" style={{ position: 'relative', top: -2 }}>+{item.usuarios.length - 1}</Badge>
                        )}
                      </a>
                    ) : !!item.fornecedor  ? (
                      <a href={`/admin/financeiro/contas/${type}/${item.id}`} target='_blank' rel="noopener noreferrer" style={{ color: "#525f7f" }}>
                        {item.fornecedor.nome}
                      </a>
                    ) : "-"}
                  </td>
                  <td>{item.categoria.nome}</td>
                  <th>{moment(item.data_primeira_parcela).format("DD/MM/YYYY")}</th>
                  <td>{numeral(item.valor).format('$ 0,0.00')}</td>
                  <td>
                    <div className="text-right">
                      <ButtonControl color='secondary' to={`/admin/financeiro/recorrencia/${type}/${item.id}/editar`}>
                        <i className="fas fa-edit"></i>
                      </ButtonControl>
                      <Button color="secondary" onClick={() => this.handleRemove(item.id)}>
                        <i className="fas fa-trash"></i>
                      </Button>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        {this.state.totalData > this.state.data.length ? (
          <Button style={{ backgroundColor: "#3f51b5", color: "white", width: '100%', height: "45px" }} disabled={this.state.loading} variant="contained" onClick={() => this.loadMore()}>
            BUSCAR MAIS {this.state.loading ? <BoxLoading /> : <p />}
          </Button>
        ) : <p />}
      </div>
    );
  }

  render() {
    const { type } = this.props.match.params;

    return (
      <>

        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={`${HISTORY_BACK}/${type}`}>
                    <i className="fas fa-arrow-left"></i> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"RECORRÊNCIA"} subTitulo={"Módulo de Gestão de Recorrência"}
        />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Total ({this.state.data ? this.state.data.length : 0})</h3>
                </CardHeader>

                {this.renderContent()}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(FinanceiroRecorrenciaList);
