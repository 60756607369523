/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import Config from "Config";
import numeral from 'numeral';

class Header extends React.Component {

  state = {
    apagar: 0,
    areceber: 0,
    associados: 0,
    dependentes: 0
  }

  componentDidMount() {
    let user = JSON.parse(window.localStorage.getItem(Config._USER))
    if (!user) {
      window.localStorage.removeItem(Config._USER)
      this.props.history.replace('/login')
      return;
    }
    this.setState({
      _session: user,
      _uk: user._uk,
      _ukFunc: user._ukFunc,
      _func: user._func,
      _name: user._name,
    }, () => this.findAll())
  }

  findAll = () => {
    this.setState({ loading: true })
    fetch(Config._HOST + "/api/geral")
      .then(response => response.json())
      .then(response => {
        this.setState({ ...response.data })
      }).catch(e => {
        this.setState({ loading: false })
      })
  }

  render() {
    return (
      <>
        <div className="header bg-gradient-default pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                            Associados
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.associados}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        {/* <span className="text-danger mr-2">
                          <i className="fas fa-arrow-down" /> 3.48%
                        </span>{" "} */}
                        <span className="text-nowrap" style={{ fontSize: 11 }}>
                          Total de Associados Ativos</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Dependentes
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.dependentes}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-child" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        {/* <span className="text-danger mr-2">
                          <i className="fas fa-arrow-down" /> 3.48%
                        </span>{" "} */}
                        <span className="text-nowrap" style={{ fontSize: 11 }}>
                          Total de Dependentes Ativos</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            A Receber
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {numeral(this.state.areceber).format('$ 0,0.00')}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                            <i className="fas fa-hand-holding-usd"></i>
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-nowrap" style={{ fontSize: 11 }}>
                          Estimativa do valor a receber desse mês
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            A Pagar
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {numeral(this.state.apagar).format('$ 0,0.00')}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-cart-arrow-down"></i>
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-nowrap" style={{ fontSize: 11 }}>
                          Estimativa do valor a pagar desse mês
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>

          <Container className="d-flex align-items-center" fluid>
            <Row>
              <div style={{ marginLeft: 15 }}>
                {this.props.componentRender}
              </div>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;
