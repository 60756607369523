/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Card, CardHeader, Table, Container, Row } from "reactstrap";
import Config from "Config";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { ButtonGroup } from "@material-ui/core";
import { withSnackbar } from "notistack";
import BoxLoading from "react-loadingg/lib/BoxLoading";
import moment from "moment";
import { TranslaterDayOfMonth } from "Config";
import { getStatusReserva } from "utils/StatusReserva";
import ButtonControl from "views/components/ButtonControl";

class HistoricoReservas extends React.Component {
  state = {
    loading: false,
    data: []
  };

  componentDidMount() {
    this.findAll()
  }

  findAll = () => {
    const { uk } = this.props.match.params;
    this.setState({ loading: true });

    fetch(Config._HOST + `/api/documento-agenda/responsavel/${uk}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          data: response.status === 200 ? response.data : [],
          loading: false,
        })
      }).catch(e => {
        this.setState({ loading: false })
      })
  }

  renderContent() {
    return (
      <div>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">LOCAL</th>
              <th scope="col">Data Início</th>
              <th scope="col">Dia da Semana</th>
              <th scope="col">Responsável</th>
              <th scope="col">Status</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map(single => (
              <tr>
                <td>{single.ambiente.nome}</td>
                <td>{moment(single.dtInicio).format('DD/MM') + " às " + moment(single.dtInicio).format('HH:MM')}</td>
                <td>{TranslaterDayOfMonth(moment(single.dtInicio).isoWeekday())}</td>
                <td>{single.usuario && single.usuario.name ? single.usuario.name : "-"}</td>
                <td>{getStatusReserva(single.status)}</td>
                <td>
                  <div className="avatar-group">
                    <a className="btn" href={`/admin/agenda/${single.uk}/editar`} rel="noopener noreferrer">
                      <i className="fa fa-edit" />
                    </a>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {this.state.loading && <BoxLoading />}
      </div>
    )
  }

  render() {
    const { uk } = this.props.match.params;

    return (
      <>
        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={`/admin/associado/${uk}`}>
                    <i className="fas fa-arrow-left"></i> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"HISTÓRICO RESERVAS"} subTitulo={"Módulo de Gestão de Histórico de Reservas"}
        />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Histórico Reservas ({this.state.data.length})</h3>
                </CardHeader>
                {this.renderContent()}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(HistoricoReservas);
