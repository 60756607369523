import React, { Component } from 'react';
import { Col, FormGroup, Row, Input } from 'reactstrap';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress, FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import Config from "Config";
import moment from 'moment';
import { jsonToUrlFilter } from 'utils/filter';

export default class FormFields extends Component {

  searchUsuario = (search) => {
    if (!search) return;

    const { handleChange } = this.props;
    handleChange({ loadClientes: true });


    if (!search) return;
    this.setState({ loadClientes: true });

    const params = {
      _limit: 100,
      name_like: search,
      profile: {
        id: [2, 3]
      }
    };

    fetch(Config._HOST + `/api/user?${jsonToUrlFilter(params)}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          dataUsuario: 'data' in response ? response.data : [],
          loadClientes: false,
        })
      }).catch(e => {
        this.setState({ loadClientes: false });
      })
  }

  searchFornecedor = (search) => {
    if (!search) return;

    const { handleChange } = this.props;
    handleChange({ loadClientes: true });

    fetch(Config._HOST + `/api/fornecedor?nome_like=${search}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.json())
      .then(response => {
        handleChange({
          dataFornecedor: response.data,
          loadFornecedor: false,
        })
      }).catch(e => {
        handleChange({
          loadFornecedor: false
        })
      })
  }

  searchVisitante = (search) => {
    if (!search) return;

    const { handleChange } = this.props;
    handleChange({ loadClientes: true });

    fetch(Config._HOST + `/api/visitante?nome_like=${search}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.json())
      .then(response => {
        handleChange({
          dataVisitante: response.data,
          loadVisitante: false,
        })
      }).catch(e => {
        handleChange({
          loadVisitante: false
        })
      })
  }

  async componentDidMount() {
    const { handleChange } = this.props;
    handleChange({ loading: true })

    fetch(Config._HOST + '/api/controle-acesso/tipo-abertura', { method: 'GET' })
      .then(response => response.json())
      .then(response => {
        if (response.data.length) {
          handleChange({
            tipoAbertura: response.data[0],
            dataTipoAbertura: response.data,
            loading: false,
          })
        }
      }).catch(e => {
        handleChange({ loading: false })
      })

    fetch(Config._HOST + '/api/controle-acesso/zona', { method: 'GET' })
      .then(response => response.json())
      .then(response => {
        if (response.data.length) {
          handleChange({
            zona: response.data[0],
            dataZona: response.data,
            loading: false,
          })
        }
      }).catch(e => {
        handleChange({ loading: false })
      })
  }

  render() {
    const { props, state, handleChange } = this.props;

    return (
      <>
        <div className="mt-margin-left mt-margin-right">
          <Row>
            <Col lg="3">
              <FormGroup>
                <FormControl variant="outlined">
                  <label className="form-control-label">Tipo de Busca</label>
                  <Select
                    className="mt-select"
                    placeholder="Tipo de Busca"
                    onChange={value => { handleChange({ tipoBusca: value.target.value }) }}
                    value={state.tipoBusca}
                  >
                    <MenuItem value="Usuário">Usuário</MenuItem>
                    <MenuItem value="Fornecedor">Fornecedor / Prestador</MenuItem>
                    <MenuItem value="Visitante">Visitante</MenuItem>
                  </Select>
                </FormControl>
              </FormGroup>
            </Col>

            {state.tipoBusca === "Usuário" ? (
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label">Usuário</label>
                  <Autocomplete
                    disabled={props.match.params.uk}
                    options={state.dataUsuario}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, value) => handleChange({ usuario: value })}
                    onInputChange={(_, value) => this.searchUsuario(value)}
                    inputMode="text"
                    key="usuario"
                    loading={state.loadUsuario}
                    value={state.usuario}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Buscar pelo nome do Usuário"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {state.loadUsuário ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            ) : state.tipoBusca === "Fornecedor" ? (
              <>
                <Col lg="11">
                  <FormGroup>
                    <label className="form-control-label">Fornecedor / Prestador</label>
                    <Autocomplete
                      disabled={props.match.params.uk}
                      options={state.dataFornecedor}
                      getOptionLabel={(option) => option.nome}
                      onChange={(_, value) => handleChange({ fornecedor: value })}
                      onInputChange={(_, value) => this.searchFornecedor(value)}
                      inputMode={"text"}
                      key="fornecedor"
                      loading={state.loadFornecedor}
                      value={state.fornecedor}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Buscar pelo nome do Fornecedor"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {state.loadFornecedor ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col lg="1">
                  <a className="btn" href={"/admin/fornecedores/novo"} target="_blank" rel="noopener noreferrer" style={{ marginTop: "40px", backgroundColor: "#3b5999" }}>
                    <i className="fa fa-plus" aria-hidden="true" style={{ fontSize: "18px", padding: "4px" }} />
                  </a>
                </Col>
              </>
            ) : (
              <>
                <Col lg="11">
                  <FormGroup>
                    <label className="form-control-label">Visitante</label>
                    <Autocomplete
                      disabled={props.match.params.uk}
                      options={state.dataVisitante}
                      getOptionLabel={(option) => option.nome}
                      onChange={(_, value) => handleChange({ visitante: value })}
                      onInputChange={(_, value) => this.searchVisitante(value)}
                      inputMode="text"
                      key="visitante"
                      loading={state.loadVisitante}
                      value={state.visitante}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Buscar pelo nome do Visitante"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {state.loadVisitante ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col lg="1">
                  <a className="btn" href={"/admin/visitantes/novo"} target="_blank" rel="noopener noreferrer" style={{ marginTop: "40px", backgroundColor: "#3b5999" }}>
                    <i className="fa fa-plus" aria-hidden="true" style={{ fontSize: "18px", padding: "4px" }} />
                  </a>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col lg="3">
              <FormGroup>
                <label className="form-control-label">Data</label>
                <Input
                  type="datetime-local"
                  placeholder="Data"
                  value={state.dataHora}
                  onChange={value => {
                    if (moment(value.target.value.toUpperCase()).isValid())
                      handleChange({ dataHora: value.target.value.toUpperCase() })
                  }}
                  className="form-control"
                />
              </FormGroup>
            </Col>

            <Col lg="3">
              <FormGroup>
                <label className="form-control-label">Placa Veiculo</label>
                <Input
                  className="form-control"
                  placeholder="Placa Veiculo"
                  type="text"
                  onChange={value => handleChange({ placaVeiculo: value.target.value.toUpperCase() })}
                  value={state.placaVeiculo}
                />
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <FormControl variant="outlined">
                  <label className="form-control-label">Tipo de Abertura</label>
                  <Select
                    className="mt-select"
                    placeholder="Tipo de Abertura"
                    onChange={value => { handleChange({ tipoAbertura: value.target.value }) }}
                    value={state.tipoAbertura}
                  >
                    {state.dataTipoAbertura.map(value => (
                      <MenuItem key={value.id} value={value}>{value.nome}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <FormControl variant="outlined">
                  <label className="form-control-label">Zona</label>
                  <Select
                    className="mt-select"
                    placeholder="Zona"
                    onChange={value => { handleChange({ zona: value.target.value }) }}
                    value={state.zona}
                  >
                    {state.dataZona.map(value => (
                      <MenuItem key={value.id} value={value}>{value.nome}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
