import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { BoxLoading } from 'react-loadingg';
import { Button, Col, Container, Row } from 'reactstrap';
import api from 'services/api';
import { isValidCPF } from 'utils/Validations';
import cpfMask from 'utils/cpfMask';
import phoneMask from 'utils/phoneMask';

class CadastroVisitante extends Component {
  state = {
    showLimiteExcedido: false,
    showGeradoComSucesso: false,
    showGeradoComErro: false,
    loading: false,
    cpf: '',
    telefone: '',
    email: '',
    usuario: null,
    novoVisitante: false
  }

  async componentDidMount() {
    document.body.style.backgroundColor = "#f6f6f6";
    !!this.props.match.params.uk && this.findBy();
  }

  findBy = async () => {
    this.setState({ loading: true });
    const { uk } = this.props.match.params;
    const response = await api.get(`/api/user/${uk}/acesso-publico/visitante`);

    if(!!response) {
      this.setState({
        usuario: response,
        loading: false
      });
    }
    else {
      this.setState({ showGeradoComErro: true, loading: false });
    }
  }

  handleFindVisitante = async cpf => {
    this.setState({ loading: true });
    const response = await api.get(`/api/visitantes/consulta-visita/${cpf}`);
    if(!response) this.setState({ showGeradoComErro: true, loading: false });

    if(!!response.visitante) {
      if(!!response.visitas_pendente.length && !!response.visitas_pendente.find(item => item.usuario.id === this.state.usuario.id)) this.setState({ showJaGerado: true, loading: false });
      if(response.visitas_mensal.length >= 2) this.setState({ showLimiteExcedido: true, loading: false });
      this.setState({ ...response.visitante, ...response, loading: false });
    }
    else {
      this.setState({ loading: false });
    }
  }

  handleSubmit = async () => {
    this.setState({ loading: true });
    const response = await api.post('/api/visitantes', this.state);

    if(!!response) {
      console.log(response);
      await api.post('/api/visitantes/historico', {
        visitante: { id: response.id },
        usuario: { id: this.state.usuario.id },
      });

      this.setState({ showGeradoComSucesso: true });
    }
    else {
      this.setState({ showGeradoComErro: true });
    }

    this.setState({ loading: false });
  }

  render() {
    const { loading, showLimiteExcedido, showGeradoComSucesso, showJaGerado, showGeradoComErro, usuario, cpf } = this.state;

    return (
      <>
        {loading && (
          <div className="container-load">
            <BoxLoading />
          </div>
        )}

        <div className='mt-inscricao'>
          <Container className='mt-padding-top mt-padding-large-bottom'>
            <Row>
              <Col lg="12">
                <div className="header-image" style={{ backgroundImage: `url(${"https://www.clubeatiradores.com.br/img/agendaHeader.jpg"})`}} />

                {!!usuario ? (
                  <div className="header rfb-item">
                    <h1 className="display-4 mt-margin-small-bottom">Convite especial para visita!</h1>
                    <hr className='mt-margin-remove'/>
                    <div className='header-descricao mt-margin-top mt-margin-bottom'>
                      Convidamos você para uma visita especial ao Clube Atiradores.
                      <br />
                      <br />
                      <strong>{usuario.name}</strong>, um de nossos sócios convidou você para conhecer nossas instalações e conhecer as variedades que o clube oferece.
                      <br />
                      De práticas esportivas até eventos sociais, o Clube Atiradores possui uma estrutura com as mais variadas opções para o seu lazer.
                      <br />
                      <br />
                      Aguardamos ansiosamente sua visita!
                    </div>
                    <hr className='mt-margin-remove'/>
                    <div style={{ color: '#d93025', fontSize: 14, marginTop: 15 }}>* Obrigatório</div>
                  </div>
                ) : !showGeradoComErro && (
                  <Row className='rfb-item mt-margin-remove mt-sended'>
                    <Col lg='12' className='mt-margin-top' />
                  </Row>
                )}

                {showGeradoComSucesso ? (
                  <Row className='rfb-item mt-margin-remove mt-sended'>
                    <Col lg='12' className='mt-text-center'>
                      <i className="fas fa-check-circle mt-margin-small-right" style={{ color: 'green', fontSize: 80 }}></i>
                    </Col>
                    <Col lg='12' className='mt-margin-top'>
                      <h2 className='mt-text-center'>
                        <strong>Convite gerado com sucesso!!!</strong>
                      </h2>
                      <h3 className='mt-text-center'>
                        Agora é só spresentar seu CPF e documento de identificação com foto na portaria.
                      </h3>
                    </Col>
                  </Row>
                ) : showJaGerado ? (
                  <Row className='rfb-item mt-margin-remove mt-sended'>
                    <Col lg='12' className='mt-text-center'>
                      <i className="fas fa-check-circle mt-margin-small-right" style={{ color: 'green', fontSize: 80 }}></i>
                    </Col>
                    <Col lg='12' className='mt-margin-top'>
                      <h2 className='mt-text-center'>
                        <strong>Convite já foi gerado!!!</strong>
                      </h2>
                      <h3 className='mt-text-center'>
                        Agora é só spresentar seu CPF e documento de identificação com foto na portaria.
                      </h3>
                    </Col>
                  </Row>
                ) : showLimiteExcedido ? (
                  <Row className='rfb-item mt-margin-remove mt-sended'>
                    <Col lg='12' className='mt-text-center'>
                      <i className="fas fa-exclamation-triangle mt-margin-small-right" style={{ color: '#f7f730', fontSize: 80 }}></i>
                    </Col>
                    <Col lg='12' className='mt-margin-top'>
                      <h2 className='mt-text-center'>
                        <strong>Não foi possível gerar seu convite!!!</strong>
                      </h2>
                      <h3 className='mt-text-center'>
                        Infelizmente você atingiu o limite de 2 visitas no mês.
                      </h3>
                    </Col>
                  </Row>
                ) : showGeradoComErro ? (
                  <Row className='rfb-item mt-margin-remove mt-sended'>
                    <Col lg='12' className='mt-text-center'>
                      <i className="fas fa-times-circle mt-margin-small-right" style={{ color: '#d52b2b', fontSize: 80 }}></i>
                    </Col>
                    <Col lg='12' className='mt-margin-top'>
                      <h2 className='mt-text-center'>
                        Ops, ocorreu um erro.
                      </h2>
                      <h2 className='mt-text-center'>
                        Não conseguimos gerar seu convite!
                      </h2>
                    </Col>
                    <Col lg='12' className='mt-text-center mt-margin-medium-top'>
                      <Button color="custom" block={true} onClick={() => window.location.reload()}>
                        Tentar Novamente
                      </Button>
                    </Col>
                  </Row>
                ) : !!usuario && (
                  <>
                    <div className="SortableItem rfb-item">
                      <div className="form-group">
                        <label className="">
                          <span>CPF: </span>
                          <span className="label-required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.cpf}
                          onChange={data => {
                            const cpf = cpfMask(data.target.value);
                            this.setState({ cpf });

                            if(cpf.length === 14) {
                              if(isValidCPF(cpf)) {
                                this.handleFindVisitante(cpf);
                              }
                              else {
                                this.props.enqueueSnackbar("Informe um CPF valido!", { variant: 'error' });
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                    {cpf.length === 14 && isValidCPF(cpf) && !loading && (
                      <>
                        <div className="SortableItem rfb-item">
                          <div className="form-group">
                            <label className="">
                              <span>Nome Completo: </span>
                              <span className="label-required">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.nome}
                              onChange={data => {
                                const nome = data.target.value;
                                this.setState({ nome });
                              }}
                            />
                          </div>
                        </div>

                        <div className="SortableItem rfb-item">
                          <div className="form-group">
                            <label className="">
                              <span>Telefone: </span>
                              <span className="label-required">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.telefone}
                              onChange={data => {
                                const telefone = phoneMask(data.target.value);
                                this.setState({ telefone });
                              }}
                            />
                          </div>
                        </div>

                        <Col lg='12' className='mt-text-center mt-margin-medium-top mt-padding-remove'>
                          <Button color="primary" block={true} onClick={this.handleSubmit}>
                            Gerar convite
                          </Button>
                        </Col>
                      </>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default withSnackbar(CadastroVisitante);
