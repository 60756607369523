const required = value => (value || (typeof value === 'number' && value > 0) || (typeof value === 'boolean' && (value === true || value === false)) ? undefined : 'Obrigatório');

const maxLength = max => value => value && value.length > max ? `Must be ${max} characters or less` : undefined;

const minLength = min => value => value && value.length < min ? `Must be ${min} characters or more` : undefined;

const equalsLength = min => value => value && value.length < min ? `Must be ${min} characters or more` : undefined

const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined;

const minValue = min => value => value && value < min ? `Must be at least ${min}` : undefined;
const maxValue = max => value => value && value > max ? `Must be at least ${max}` : undefined;

const positiveValue = value => value && value <= 0 ? `Valor tende ser positivo` : undefined;

const cep = value => value && value.length < 9 ? `Must be 8 characters or more` : undefined

const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'E-mail invalido'
    : undefined

const phoneNumber = value =>
  value && (!/^\(((1[1-9])|([2-9][0-9]))\) ((3[0-9]{3}-[0-9]{4})|(9[0-9]{3}-[0-9]{5}))$/i.test(value))
    ? 'Telefone inválido'
    : undefined;

const cellPhoneNumber = value =>
  value && (!/^\(((1[1-9])|([2-9][0-9]))\) (?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/i.test(value))
    ? 'Celular inválido'
    : undefined;

function isValidCPF(cpf) {
  if (typeof cpf !== "string") return false;

  cpf = cpf.replace(/[\s.-]*/igm, '');

  if (!cpf ||
    cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999")
    return false

  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i);

  resto = (soma * 10) % 11;
  if ((resto === 10) || (resto === 11)) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10)) ) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i);

  resto = (soma * 10) % 11;
  if ((resto === 10) || (resto === 11)) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11) ) ) return false;

  return true
}

function isValidCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g,'');

  if(cnpj === '') return false;

  if (cnpj.length !== 14)
      return false;

  // Elimina CNPJs invalidos conhecidos
  if (cnpj === "00000000000000" ||
      cnpj === "11111111111111" ||
      cnpj === "22222222222222" ||
      cnpj === "33333333333333" ||
      cnpj === "44444444444444" ||
      cnpj === "55555555555555" ||
      cnpj === "66666666666666" ||
      cnpj === "77777777777777" ||
      cnpj === "88888888888888" ||
      cnpj === "99999999999999")
      return false;

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0,tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;

    if (pos < 2)
      pos = 9;
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado !== parseInt(digitos.charAt(0)))
    return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0,tamanho);
  soma = 0;
  pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;

    if (pos < 2)
      pos = 9;
  }

  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado !== parseInt(digitos.charAt(1)))
    return false;

  return true;
}

const cpf = cpf => !isValidCPF(cpf) ? 'CPF Inválido' : undefined;
const cnpj = cnpj => {
  return !isValidCNPJ(cnpj) ? 'CNPJ Inválido' : undefined;
}

export {
  required,
  cep,
  maxLength,
  minLength,
  minValue,
  maxValue,
  positiveValue,
  equalsLength,
  number,
  phoneNumber,
  cellPhoneNumber,
  email,
  cpf,
  isValidCPF,
  cnpj,
  isValidCNPJ
}
