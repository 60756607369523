import React from "react";
import { Card, CardHeader, CardBody, Form, Container, Row, Col } from "reactstrap";
import { withSnackbar } from "notistack";
import { ButtonGroup } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import FormFields from "views/pages/Ambientes/Form/FormFields";
import FormButtonAction from "views/components/FormButtonAction";
import { findCategorias, findAllPdfs } from "utils/find";
import { jsonToUrlFilter } from "utils/filter";
import { BoxLoading } from "react-loadingg";
import api from "services/api";
import ButtonControl from "views/components/ButtonControl";

const HISTORY_BACK = '/admin/ambientes';
const ROUTE_API = '/api/ambiente';

class AmbienteCrud extends React.Component {
  state = {
    permitirLocacao: false,
    permitirLocacaoApp: false,
    categorias: [],
    categoria: null,
    caixas: [],
    caixa: null,
    pdfs: [],
    termoReserva: null,
    corFundo: '#000000',
    corTexto: '#ffffff',
    capacidadePessoas: null
  }

  async componentDidMount() {
    const { id } = this.props.match.params;

    this.setState({ loading: true })
    await this.findAllCaixa();

    const response = await findCategorias(jsonToUrlFilter({ _limit: 999, _order: 'ASC', _sort: "nome" }));
    this.setState({ categorias: response.data });

    const responsePdf = await findAllPdfs(jsonToUrlFilter({ _limit: 999, _order: 'ASC', _sort: "nome" }));
    this.setState({ pdfs: responsePdf.data });

    if (!!id) await this.findBy();
    this.setState({ loading: false });
  }

  findBy = async () => {
    const { id } = this.props.match.params;
    const response = await api.get(`${ROUTE_API}/${id}`);
    console.log(response)
    this.setState({ ...response })
  }

  findAllCaixa = async () => {
    const response = await api.get("/api/financeiro/caixa");
    this.setState({ caixas: response.data })
  }

  create = async () => {
    if (!this.state.nome) {
      this.props.enqueueSnackbar("Cadastre um nome");
      return;
    }

    if (!this.state.caixa) {
      this.props.enqueueSnackbar("Selecione uma Conta");
      return;
    }

    if (!this.state.categoria) {
      this.props.enqueueSnackbar("Selecione uma Categoria de Reserva");
      return;
    }

    this.setState({ loading: true })
    const { id } = this.props.match.params;
    let data = this.state;
    const response = !id ? await api.post(ROUTE_API, data) : await api.put(`${ROUTE_API}/${id}`, data);

    if(!!response) {
      this.props.enqueueSnackbar(id ? "Atualizado com sucesso!" : "Cadastrado com sucesso!");
      this.props.history.push(HISTORY_BACK);
    }
    else {
      this.props.enqueueSnackbar("Ops! Ocorreu algum erro, tente novamente mais tarde.")
    }

    this.setState({ loading: false })
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  }

  render() {
    return (
      <>
        {this.state.loading && (
          <div className="container-load-with-margin">
            <BoxLoading />
          </div>
        )}

        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to='/admin/ambientes'>
                    <i className="fas fa-arrow-left" /> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"Ambientes"}
          subTitulo={(this.props.match.params.id ? "Atualizar Ambiente" : "Cadastrar nova Ambiente")} />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {this.props.match.params.id ? "Atualizar Ambiente" : "Cadastro de Ambiente"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormFields {...this} />
                    <FormButtonAction create={this.create} cancel={HISTORY_BACK} history={this.props.history} isEdit={this.props.match.params.id} />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(AmbienteCrud);
