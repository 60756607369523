const estados = [
  { id: 1, sigla: 'AC', name: 'Acre' },
  { id: 2, sigla: 'AL', name: 'Alagoas' },
  { id: 3, sigla: 'AM', name: 'Amazonas' },
  { id: 4, sigla: 'AP', name: 'Amapá' },
  { id: 5, sigla: 'BA', name: 'Bahia' },
  { id: 6, sigla: 'CE', name: 'Ceará' },
  { id: 7, sigla: 'DF', name: 'Distrito Federal' },
  { id: 8, sigla: 'ES', name: 'Espírito Santo' },
  { id: 9, sigla: 'GO', name: 'Goiás' },
  { id: 10, sigla: 'MA', name: 'Maranhão' },
  { id: 11, sigla: 'MG', name: 'Minas Gerais' },
  { id: 12, sigla: 'MS', name: 'Mato Grosso do Sul' },
  { id: 13, sigla: 'MT', name: 'Mato Grosso' },
  { id: 14, sigla: 'PA', name: 'Pará' },
  { id: 15, sigla: 'PB', name: 'Paraíba' },
  { id: 16, sigla: 'PE', name: 'Pernambuco' },
  { id: 17, sigla: 'PI', name: 'Piauí' },
  { id: 18, sigla: 'PR', name: 'Paraná' },
  { id: 19, sigla: 'RJ', name: 'Rio de Janeiro' },
  { id: 20, sigla: 'RN', name: 'Rio Grande do Norte' },
  { id: 21, sigla: 'RO', name: 'Rondônia' },
  { id: 22, sigla: 'RR', name: 'Roraima' },
  { id: 23, sigla: 'RS', name: 'Rio Grande do Sul' },
  { id: 24, sigla: 'SC', name: 'Santa Catarina' },
  { id: 25, sigla: 'SE', name: 'Sergipe' },
  { id: 26, sigla: 'SP', name: 'São Paulo' },
  { id: 27, sigla: 'TO', name: 'Tocantins' }
];

export { estados };
