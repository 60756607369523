import React, { Component } from 'react';
import { Button, Card, CardBody, Col, FormGroup, Input, Row } from 'reactstrap';

export default class CarteirinhaExameMedico extends Component {
  render() {
    const { state, handleChange, addCarteirinha } = this.props;

    return (
      <div className="mt-margin-left mt-margin-right">
        {(!state.listaCarteirinhas || state.listaCarteirinhas.length === 0) && (
          <Row>
            <Col lg="6">
              <Button onClick={addCarteirinha}>
                <i className="fas fa-plus" /> Adicionar
              </Button>
            </Col>
          </Row>
        )}
        {!!state.listaCarteirinhas && state.listaCarteirinhas.map((carteirinha_value, index) => (
        <Card className="mt-margin-top">
          <CardBody>
              <Row key={index}>
                <Col lg="4">
                  <FormGroup className="mt-margin-bottom">
                    <label className="form-control-label" htmlFor="input-carteirinha-nome">
                      Nome
                    </label>
                    <Input
                      id="input-carteirinha-nome"
                      type="text"
                      value={state.name}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup className="mt-margin-bottom">
                    <label className="form-control-label" htmlFor="input-validade-exame">
                      Validade do Exame
                    </label>
                    <Input
                      type="date"
                      format="YYYY-MM-DD"
                      id="input-validade-exame"
                      value={carteirinha_value.validadeExame}
                      onChange={(value) => {
                        handleChange({ listaCarteirinhas: state.listaCarteirinhas.map((item, key) => {
                          if(index === key) item.validadeExame = value.target.value
                          return item
                        })});
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup className="mt-margin-bottom">
                    <label className="form-control-label" htmlFor="input-numero-carteirinha">
                      Número da Carteirinha
                    </label>
                    <Input
                      id="input-numero-carteirinha"
                      placeholder="Número da Carteirinha"
                      type="text"
                      value={carteirinha_value.numero}
                      onChange={(value) => {
                        handleChange({ listaCarteirinhas: state.listaCarteirinhas.map((item, key) => {
                          if(index === key) item.numero = value.target.value
                          return item
                        })});
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
          </CardBody>
        </Card>
        ))}
      </div>
    );
  }
}
