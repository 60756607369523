/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { ButtonGroup } from "@material-ui/core";
import { withSnackbar } from "notistack";
import React from "react";
import { Card, CardHeader, CardBody, Form, Container, Row, Col } from "reactstrap";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import FormFields from "./Form/FormFields";
import FormButtonAction from "views/components/FormButtonAction";
import ButtonControl from "views/components/ButtonControl";
import api from "services/api";
import { BoxLoading } from "react-loadingg";

const HISTORY_BACK = '/admin/usuarios/perfis';

class PerfisCrud extends React.Component {

  state = {
    permissaoWeb: [],
    loading: false
  };

  async componentDidMount() {
    const {id} = this.props.match.params;
    !!id && this.findProfileRotas()
  }

  findProfileRotas = async () => {
    this.setState({ loading: true })
    const {id} = this.props.match.params;
    const response = await api.get(`/api/profile/${id}`);
    if (!!response) {
      this.setState({
        ...response,
        loading: false,
      })
    }
  }

  handleChecked = rota => {
    const {permissaoWeb} = this.state;
    const searchRota = permissaoWeb.find(dataRota => dataRota.rota === rota);
    return !!searchRota
  }

  handleSelectAll = rotas => {
    const { permissaoWeb } = this.state;
    const { id } = this.props.match.params;
    const parent = rotas.layout+rotas.path;
    if (!this.handleChecked(parent)) {
      permissaoWeb.push({ rota: parent, profile: { id: parseInt(id) } })
    }
    rotas.children.forEach(children => {
      var rota = rotas.layout+rotas.path+children.path
      if (!this.handleChecked(rota)) {
        permissaoWeb.push({ rota, profile: { id: parseInt(id) } })
      }
    })
    this.setState({ permissaoWeb })
  }

  handleUnselectAll = rotas => {
    var { permissaoWeb } = this.state;
    const parent = rotas.layout+rotas.path;
    if (this.handleChecked(parent)) {
      permissaoWeb = permissaoWeb.filter(dataRota => dataRota.rota !== parent);
    }
    rotas.children.forEach(children => {
      var rota = rotas.layout+rotas.path+children.path
      if (this.handleChecked(rota)) {
        permissaoWeb = permissaoWeb.filter(dataRota => dataRota.rota !== rota);
      }
    })
    this.setState({ permissaoWeb })
  }

  handleChangePermissaoWeb = rota => {
    const { permissaoWeb } = this.state;
    const { id } = this.props.match.params;

    if (this.handleChecked(rota)) {
      const filterRota = permissaoWeb.filter(dataRota => dataRota.rota !== rota);
      this.setState({permissaoWeb: filterRota})
    } else {
      this.setState({ permissaoWeb: [...permissaoWeb,{ rota, profile: { id: parseInt(id) } }]})
    }
  }

  handleChange = (value) => {
    this.setState({ ...this.state, ...value });
  }

  create = async () => {
    this.setState({ loading: true })
    let { id } = this.props.match.params;
    let data = { ...this.state };
    let response = null
    if (!id) {
      response = await api.post('/api/profile/', {...data, permissaoWeb: []})
      id = response.id
      data.id = id
      data.permissaoWeb = data.permissaoWeb.map(item => {
        return { ...item, profile: { id: parseInt(id) } }
      })
    }
    if (!!id) {
      response = await api.put(`/api/profile/${id}`, data)
    }

    if(!!response) {
      this.props.enqueueSnackbar(id ? "Atualizado com sucesso!" : "Cadastrado com sucesso!");
      this.props.history.push(HISTORY_BACK);
    }
    else {
      this.props.enqueueSnackbar("Ops! Ocorreu algum erro, tente novamente mais tarde.")
    }

    this.setState({ loading: false })
  }

  render() {
    return (
      <>
        {this.state.loading && (
          <div className="container-load-with-margin">
            <BoxLoading />
          </div>
        )}


        {/* <Header /> */}
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to={HISTORY_BACK}>
                    <i className="fas fa-arrow-left"></i> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={this.props.match.params.id ? "EDITAR USUÁRIO" : "NOVO USUÁRIO"}
          subTitulo={"Gerenciamento dos Usuários"}
        />


        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {this.props.match.params.id ? "Atualizar Perfis" : "Cadastrar Novo Perfil"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormFields {...this} />
                    <FormButtonAction create={this.create} cancel={HISTORY_BACK} history={this.props.history} isEdit={this.props.match.params.id} />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(PerfisCrud);
