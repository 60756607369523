import React from "react";
import { Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col } from "reactstrap";
import { withSnackbar } from "notistack";
import { ButtonGroup, FormControl, MenuItem, Select } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import IntlCurrencyInput from "react-intl-currency-input"
import FormButtonAction from "views/components/FormButtonAction";
import moment from "moment";
import api from "services/api";
import ButtonControl from "views/components/ButtonControl";

const HISTORY_BACK = '/admin/financeiro/caixa';

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

class FinanceiroContaCRUD extends React.Component {

  state = {
    principal: false,
    ativo: false,
    tipo: "Conta Corrente",
    dtValorInicial: moment(new Date()).format('YYYY-MM-DD'),
    valorInicial: 0
  }

  async componentDidMount() {
    const { ukCaixa } = this.props.match.params;

    if (ukCaixa) {
      await this.findBy();
    }
  }

  findBy = async () => {
    const { ukCaixa } = this.props.match.params;
    this.setState({ loading: true });

    const response = await api.get(`api/financeiro/caixa/${ukCaixa}`);

    if (response) {
      this.setState({
        ...response,
        dtValorInicial: moment(response.dtValorInicial).format('YYYY-MM-DD'),
        loading: false
      })
    } else {
      this.setState({ loading: false })
    }
  }

  create = async () => {
    const { nome } = this.state;
    const { ukCaixa } = this.props.match.params;

    if (!nome || (nome.trim() === "")) {
      this.props.enqueueSnackbar("Preencha o Nome Referência")
      return;
    }

    let response = null;
    this.setState({ loading: true });

    if (ukCaixa) {
      response = await api.put(`api/financeiro/caixa/${ukCaixa}`, this.state);
    }
    else {
      response = await api.post('api/financeiro/caixa', this.state);
    }

    if (response) {
      this.setState({ loading: false });
      console.log('response success')
      this.props.history.push(HISTORY_BACK);
      this.props.enqueueSnackbar(ukCaixa ? "Atualizado com sucesso!" : "Cadastrado com sucesso!");
    }
    else {
      console.log('response error')
      this.setState({ loading: false });
      this.props.enqueueSnackbar("Ops! Ocorreu algum erro em nossa nuvem, tente novamente mais tarde.");
    }
  }

  handleChange = (event, value, maskedValue) => {
    event.preventDefault();
    this.setState({ valorInicial: value })
  };

  render() {
    const { ukCaixa } = this.props.match.params;

    return (
      <>
        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to='/admin/financeiro/caixa'>
                    <i className="fas fa-arrow-left" /> VOLTAR
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"Conta"}
          subTitulo={(ukCaixa ? "Atualizar Conta" : "Cadastrar novo Conta")} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {ukCaixa ? "Atualizar Conta" : "Cadastro de Conta"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Informações Gerais
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="2">
                          <FormGroup>
                            <FormControl variant="outlined">
                              <label className="form-control-label">Ativo</label>
                              <Select
                                value={this.state.ativo ? this.state.ativo : false}
                                onChange={value => this.setState({ ativo: value.target.value })}
                              >
                                <MenuItem value={false}>Não</MenuItem>
                                <MenuItem value={true}>Sim</MenuItem>
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <FormControl variant="outlined">
                              <label className="form-control-label">Principal</label>
                              <Select
                                value={this.state.principal ? this.state.principal : false}
                                onChange={value => this.setState({ principal: value.target.value })}
                              >
                                <MenuItem value={false}>Não</MenuItem>
                                <MenuItem value={true}>Sim</MenuItem>
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="mt-margin-top">
                        <Col lg="3">
                          <FormGroup>
                            <FormControl variant="outlined">
                              <label className="form-control-label">Tipo</label>
                              <Select
                                value={this.state.tipo}
                                onChange={value => this.setState({ tipo: value.target.value })}
                              >
                                <MenuItem value="Conta Corrente">Conta Corrente</MenuItem>
                                <MenuItem value="Investimento">Investimento</MenuItem>
                                <MenuItem value="Caixinha">Caixinha</MenuItem>
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label">Nome Referência</label>
                            <Input
                              className="form-control"
                              placeholder="Nome Referência"
                              type="text"
                              onChange={value => this.setState({ nome: value.target.value.toUpperCase() })}
                              value={this.state.nome ? this.state.nome : ''}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>

                        {this.state.tipo !== "Caixinha" ? (
                          <>
                            <Col lg="2">
                              <FormGroup>
                                <label className="form-control-label">Número do Banco</label>
                                <Input
                                  className="form-control"
                                  placeholder="Banco"
                                  type="text"
                                  onChange={value => this.setState({ banco: value.target.value.toUpperCase() })}
                                  value={this.state.banco ? this.state.banco : ''}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label">Agência</label>
                                <Input
                                  className="form-control"
                                  placeholder="Agência"
                                  type="text"
                                  onChange={value => this.setState({ agencia: value.target.value.toUpperCase() })}
                                  value={this.state.agencia ? this.state.agencia : ''}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label">Conta</label>
                                <Input
                                  className="form-control"
                                  placeholder="Conta"
                                  type="text"
                                  onChange={value => this.setState({ conta: value.target.value.toUpperCase() })}
                                  value={this.state.conta ? this.state.conta : ''}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label">Chave Pix</label>
                                <Input
                                  className="form-control"
                                  placeholder="Pix"
                                  type="text"
                                  onChange={value => this.setState({ pix: value.target.value.toUpperCase() })}
                                  value={this.state.pix ? this.state.pix : ''}
                                />
                              </FormGroup>
                            </Col>
                          </>
                        ) : ''}

                      </Row>

                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Valor Inícial</label>
                            <IntlCurrencyInput
                              className="form-control"
                              currency="BRL"
                              value={this.state.valorInicial}
                              config={currencyConfig}
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Data do Valor Inícial</label>
                            <Input
                              className="form-control"
                              type="date"
                              value={this.state.dtValorInicial}
                              onChange={value => {
                                if (moment(value.target.value.toUpperCase()).isValid()) {
                                  this.setState({ dtValorInicial: value.target.value.toUpperCase() })
                                }
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                    </div>

                    <FormButtonAction create={this.create} cancel={HISTORY_BACK} history={this.props.history} isEdit={this.props.match.params.uk} />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withSnackbar(FinanceiroContaCRUD);
