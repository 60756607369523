import { Button, Card, CardBody, Col, Container, Form, Input, Row } from "reactstrap";
import { ButtonGroup, FormGroup, List, ListItem, ListItemText } from "@material-ui/core";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import Config from "Config";
import moment from "moment";
import { withSnackbar } from "notistack";
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { BoxLoading } from "react-loadingg";
import api from "services/api";
import { req } from "utils/req";
import TimeLine from "./TimeLine";
import ButtonControl from "views/components/ButtonControl";

const ROUTE_API = '/api/ocorrencia';

class FaleConoscoView extends React.Component {
  state = {
    tipo: {
      id: null,
      nome: null
    },
    relator: {
      id: null,
      nome: null
    },
    respostas: [],
    mensagem: ''
  }

  compararPorData(a, b) {
    const dataA = new Date(a.data);
    const dataB = new Date(b.data);

    return dataA - dataB;
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    id && this.findBy(id);
  }

  findBy = async (id) => {
    this.setState({ loading: true });
    const response = await api.get(`${ROUTE_API}/${id}`);
    response.respostas.sort(this.compararPorData)
    this.setState({ ...this.state, ...response, loading: false });
  }

  handleResponder = async () => {
    if(this.state.mensagem.length === 0) return false;
    let user = JSON.parse(window.localStorage.getItem(Config._USER));

    let data = {
      ocorrencia: { "id": this.state.id },
      mensagem: this.state.mensagem,
      usuario: user
    }
    this.setState({ loading: true });
    await req(`${ROUTE_API}-respostas`, data);
    this.setState({ mensagem: '' });
    this.findBy(this.props.match.params.id);
  }

  handleFinalizar = async () => {
    await this.setState({ status: { "id": 2, "nome": "Finalizado" } });
    await req(`${ROUTE_API}`, this.state, this.state.id);
    this.props.history.push('/admin/fale-conosco');
  }

  render() {
    return (
      <>
        {this.state.loading && (
          <div className="container-load-with-margin">
            <BoxLoading />
          </div>
        )}

        <HeaderButtonGroup
          component={
            <div>
              <div className="text-center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <ButtonControl color='secondary' to='/admin/fale-conosco'>
                    <i className="fas fa-arrow-left"> VOLTAR</i>
                  </ButtonControl>
                </ButtonGroup>
              </div>
            </div>
          }
          titulo={"PROCESSAR OCORRÊNCIA"}
          subTitulo={"Gerenciamento das mensagens"}
        />
        <Container className="mt--7" fluid style={{ paddingBottom: 100 }}>
          {/* {Table} */}
          {this.renderContent()}
        </Container>
      </>
    );
  }

  renderContent() {
    const { status } = this.state;

    return (
      <>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardBody>
                <h6 className="heading-small text-muted mb-4">
                  Documento de Ocorrência
                </h6>
                <Row style={{ marginTop: 10 }}>
                  <List>
                    <ListItem>
                      <ListItemText primary="Tipo" secondary={this.state.tipo.nome} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Data Abertura" secondary={moment(this.state.dataCadastro).format("DD/MM/YYYY HH:MM")} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Data Ocorrência" secondary={moment(this.state.data).format("DD/MM/YYYY HH:MM")} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Descrição" secondary={this.state.descricao} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Relator" secondary={this.state.relator.name} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Envolvidos" />
                    </ListItem>
                  </List>
                </Row>

                <hr className="my-4" />
                <TimeLine respostas={this.state.respostas} msgTitulo={"Linha do Tempo da Conversa"} msgEmpty={"Não existem respostas à ocorrência até o momento."} />
                <hr className="my-4" />
                {status && status.id !== 2 &&
                  <Form>
                    <Row>
                      <Col>
                        <Input
                          className="form-control"
                          type="textarea"
                          rows="5"
                          onChange={value => this.setState({ mensagem: value.target.value })}
                          value={this.state.mensagem}
                        />
                      </Col>
                    </Row>
                    <hr className="my-4" />
                    <div className="pl-lg-4" style={{ marginTop: "10px" }}>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Button color="warning" onClick={() => {
                              confirmAlert({
                                title: "Deseja Finalizar?",
                                message: '',
                                buttons: [
                                  {
                                    label: 'Não'
                                  },
                                  {
                                    label: 'Sim',
                                    onClick: () => this.handleFinalizar()
                                  }
                                ]
                              })
                            }}
                            >
                              FINALIZAR
                            </Button>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Button color="primary" onClick={this.handleResponder}>
                              RESPONDER
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    )
  }
}
export default withSnackbar(FaleConoscoView);
