import React, { Component } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import { FormControl, MenuItem, Select } from '@material-ui/core';

export default class FormFields extends Component {
  render() {
    const { state, handleChange } = this.props;

    return (
      <>
        <div className="mt-margin-left mt-margin-right">
          <Row className="mt-margin-medium-bottom">
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">Nome</label>
                <Input
                  name="nome"
                  type="text"
                  placeholder="Nome"
                  className="form-control"
                  onChange={(value) => handleChange('nome', value.target.value)}
                  value={state.nome ? state.nome : ''}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <FormControl variant="outlined">
                  <label className="form-control-label">Orientação da Página</label>
                  <Select
                    className="mt-select"
                    value={state.orientacaoPagina}
                    onChange={value => handleChange("orientacaoPagina", value.target.value)}
                  >
                    <MenuItem value="portrait">Retrato</MenuItem>
                    <MenuItem value="landscape">Paisagem</MenuItem>
                  </Select>
                </FormControl>
              </FormGroup>
            </Col>
          </Row>
          <Editor
            apiKey="215zb0qgsla41aau558l9p9s2osddsg0n9az2i3ayglpvi6p"
            value={state.text}
            onEditorChange={(content) => handleChange("text", content)}
            init={{
              height: 500,
              selector: 'textarea',
              language: 'pt_BR',
              menubar: 'edit view insert format table',
              // plugins: [
              //   'advlist autolink lists link image charmap',
              //   'searchreplace visualblocks code fullscreen',
              //   'insertdatetime media table paste code help wordcount'
              // ],
              plugins: [
                'paste importcss searchreplace autolink',
                'directionality code visualblocks visualchars fullscreen',
                'image link media template table charmap hr',
                'nonbreaking anchor toc insertdatetime advlist lists wordcount',
                'imagetools textpattern noneditable help charmap quickbars emoticons'
              ],
              toolbar: 'undo redo | fontselect formatselect fontsizeselect | ' +
                'bold italic underline strikethrough forecolor backcolor removeformat |' +
                'alignleft aligncenter alignright alignjustify |' +
                'bullist numlist | outdent indent | table image link |' +
                'charmap fullscreen preview | template ',
              fontsize_formats: '8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 19px 20px ' +
                '21px 22px 23px 24px 25px 26px 26px 27px 28px 29px 30px ' +
                '31px 32px 33px 34px 35px 36px 36px 37px 38px 39px 40px'
            }}
          />
        </div>
      </>
    );
  }
}
